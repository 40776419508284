/* Styles import */
import styles from "./home.module.css";

/* Global import */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Title,
  Button,
} from "../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";

/* Custom import */
import AppBar from "../../components/appBar";
import CarouselHome from "../../components/carousel";
import SearchEccn from "../../components/searchEccn";
import RootStore from "../../stores/rootStore";
import { decode } from "../../utils/jwt";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

interface Props {
  rootStore: import("../../stores/rootStore").default;
}

interface Button {
  id: string;
  pathIcon: string;
  label: string;
  to?: string;
  restrictions?: MenuAccess;
  action?: () => void;
}

const Home: React.FC<Props> = (props) => {
  const [search, setSearch] = useState<string>("");
  const [showSearchEccn, setShowSearchEccn] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname !== "/login") {
      if (!props.rootStore.authStore.checkIsLogged()) {
        navigate("/login");
      }
    }
  }, [navigate]);

  const buttons: Button[] = [
    {
      id: "exportability-button",
      to: "/exportability",
      pathIcon: require("../../../statics/images/screening.svg").default,
      label: i18n.t("global.exportability"),
      restrictions: "Exportabilite",
    },
    {
      id: "new-sale-button",
      to: "/search-flow",
      pathIcon: require("../../../statics/images/flow.svg").default,
      label: i18n.t("global.flows"),
      restrictions: "Flux",
    },
    {
      id: "classification-button",
      action: () => setShowSearchEccn(true),
      pathIcon: require("../../../statics/images/classification.svg").default,
      label: i18n.t("global.classification"),
      restrictions: "Classement",
    },
    {
      id: "screening-button",
      to: "/screening/entity",
      pathIcon: require("../../../statics/images/screening.svg").default,
      label: i18n.t("global.screening"),
      restrictions: "Screening",
    },
    {
      id: "license-mngt-button",
      to: "/license/search",
      pathIcon: require("../../../statics/images/license-mngt.svg").default,
      label: i18n.t("global.license-mngt"),
      restrictions: "Licences",
    },
    {
      id: "regulation-button",
      to: "/regulation/control-lists",
      pathIcon: require("../../../statics/images/reporting.svg").default,
      label: i18n.t("pages.regulation.title"),
    },
  ];

  const renderLink = (props: Button, index: number, rootStore: RootStore) => {
    return (
      <Link key={index} to={props.to as string}>
        {renderButton(props, index, rootStore)}
      </Link>
    );
  };

  const renderButton = (props: Button, index: number, rootStore: RootStore) => {
    const restrictions = props.restrictions ? [props.restrictions] : []; // Mettez `restriction` dans un tableau s'il n'est pas déjà un tableau
    const jwt: JwtData =
      decode(window.localStorage.getItem("access_token") || "") || [];
    const jwtRoles: string[] = jwt.realm_access?.roles;

    return (
      (restrictions === undefined ||
        restrictions.every((restriction) =>
          jwtRoles?.includes(restriction)
        )) && (
        <Button
          key={index}
          id={props.id}
          className={styles.button}
          withIcon
          primary
          size="large"
          onClick={props.action}
        >
          <img
            className={styles.iconButton}
            src={props.pathIcon}
            alt={props.label}
          />
          {props.label}
        </Button>
      )
    );
  };

  return (
    <div>
      <AppBar selectedPage="HOME" rootStore={props.rootStore} />
      <div className={styles.carousel}>
        <CarouselHome
          sources={[
            require("../../../statics/images/a380.jpg"),
            require("../../../statics/images/map.jpeg"),
            require("../../../statics/images/ships.jpeg"),
          ]}
        />
      </div>

      <div className={styles.welcome}>
        <Title tag="h2" className={styles.pStyles}>
          {i18n.t("pages.home.title")}
        </Title>

        <Title tag="h3" className={`${styles.secondP} ${styles.pStyles}`}>
          {i18n.t("pages.home.subtitle")}
        </Title>

        <div className={styles.buttons}>
          {buttons.map((button, index) => {
            return button.to
              ? renderLink(button, index, props.rootStore)
              : renderButton(button, index, props.rootStore);
          })}
        </div>
      </div>

      <SearchEccn
        isOpen={showSearchEccn}
        onClose={() => setShowSearchEccn(false)}
        ouClick={""}
        exportabilityMode={true}
        rootStore={props.rootStore}
      />
    </div>
  );
};

export default inject("rootStore")(observer(Home));
