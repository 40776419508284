import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  FormGroup,
  IconButton,
  TextInput,
  Button,
  Loading,
} from "../../components/horizon-components-react/src/components";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import i18n from "i18next";
import styles from "./login.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import Api from "../../utils/api";
import { runInAction } from "mobx";
import { observer } from "mobx-react";

interface Props {
  rootStore: import("../../stores/rootStore").default;
}

const ResetPassword: React.FC<Props> = observer(({ rootStore }) => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const api = new Api();

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    rootStore.authStore.clearError();

    // Validation de base
    if (password !== confirmPassword) {
      runInAction(() => {
        rootStore.authStore.setError(i18n.t("errors.password-mismatch"));
      });
      return;
    }

    // Expression régulière pour :
    // - Au moins une majuscule (?=.*[A-Z])
    // - Au moins une minuscule (?=.*[a-z])
    // - Au moins un chiffre (?=.*\d)
    // - Au moins un caractère spécial (?=.*[@$!%*?&^#_~=+/:;.,-])
    //   (liste non exhaustive, à adapter selon vos besoins)
    // - Longueur minimale de 10 caractères (.{10,})

    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&^#_~=+/:;.,-]).{10,}$/;

    // Vérifie que le mot de passe respecte nos critères
    if (!passwordRegex.test(password)) {
      runInAction(() => {
        rootStore.authStore.setError(i18n.t("errors.password-policy"));
      });
      return;
    }

    try {
      const userId = searchParams.get("token"); // Récupérer l'utilisateur depuis l'URL
      if (!userId) {
        rootStore.authStore.setError(i18n.t("errors.user-not-found"));
        return;
      }

      // Appel à l'API Keycloak
      await await api.newPassword(password, userId);

      //todo message succèes
      setTimeout(() => navigate("/login"), 3000); // Redirection après 3 secondes
    } catch (err: any) {
      rootStore.authStore.setError(
        err.response?.data?.error ||
          "Une erreur est survenue lors de la réinitialisation."
      );
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.cardLogin}>
        <CardHeader title={i18n.t("pages.newPassword.title")} />

        <CardContent>
          <form id="login-form" onSubmit={onSubmit}>
            <FormGroup>
              <TextInput
                id="password"
                type={showPassword ? "text" : "password"}
                label={i18n.t("pages.login.password")}
                value={password}
                required={true}
                errorText={i18n.t("errors.password-not-defined")}
                onChange={(e) => setPassword(e.target.value)}
              />
              <IconButton
                style={{
                  marginLeft: "400px",
                  position: "absolute",
                  bottom: "0px",
                  right: "10px",
                }}
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              ></IconButton>
            </FormGroup>

            <FormGroup>
              <div className={styles.row}>
                <TextInput
                  id="confirmPassword"
                  type={showPassword ? "text" : "password"}
                  label={i18n.t("pages.newPassword.confirmPassword")}
                  value={confirmPassword}
                  required={true}
                  errorText={i18n.t("errors.confirmPassword-not-defined")}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <IconButton
                  style={{
                    marginLeft: "400px",
                    position: "absolute",
                    bottom: "0px",
                    right: "10px",
                  }}
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </div>
            </FormGroup>

            {rootStore.authStore.getError() && (
              <div className={styles.error}>
                <p>{rootStore.authStore.getError()}</p>
              </div>
            )}
            {rootStore.authStore.loading ? (
              <Loading />
            ) : (
              <Button id="button" type="submit" primary>
                {i18n.t("pages.newPassword.confirm")}
              </Button>
            )}
            <br />
            <a
              href="#"
              className={styles.moreDetails}
              id="moreDetails"
              onClick={() => navigate("/login")}
            >
              {i18n.t("pages.newPassword.goBackLogin")}
            </a>
          </form>
        </CardContent>
      </Card>
    </div>
  );
});

export default ResetPassword;
