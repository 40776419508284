/* Styles import */
import styles from "../subHeader/subHeader.module.css";

/* Global import */
import React from "react";
import { Link } from "react-router-dom";

/* Custom import */
import SubHeader from "../subHeader";
import { decode } from "../../utils/jwt";

interface Props {
  pages: Pages;
  rootStore: import("../../stores/rootStore").default;
}
type Pages = "REPORTING" | "FLOWS" | "LOG";

const headerReportComponent = ({ pages, rootStore }: Props) => {
  const jwt: JwtData =
    decode(window.localStorage.getItem("access_token") || "") || [];
  const jwtRoles: string[] = jwt.realm_access?.roles;

  return (
    <SubHeader>
      <div className={styles.linkContent}>
        {jwtRoles?.includes("SUPER_ADMINISTRATOR") && (
          <Link id="admin-upload" className={`${styles.link}`} to="#">
            Stats
          </Link>
        )}

        {jwtRoles?.includes("Admin") && (
          <Link
            id="admin-tenants"
            className={`${styles.link} ${
              pages === "REPORTING" ? styles.selected : {}
            }`}
            to="/admin/report"
          >
            Reporting
          </Link>
        )}

        {jwtRoles?.includes("Admin") && (
          <Link
            id="admin-countries"
            className={`${styles.link} ${
              pages === "FLOWS" ? styles.selected : {}
            }`}
            to="/search-flowArchive"
          >
            Flows
          </Link>
        )}

        <Link
          id="admin-upload"
          className={`${styles.link} ${pages === "LOG" ? styles.selected : {}}`}
          to="/admin/log"
        >
          Log
        </Link>

        {/* <Link
          id="admin-upload"
          className={`${styles.link} ${pages === "LOG" ? styles.selected : {}}`}
          to="/logtenant"
        >
          Log
        </Link> */}
      </div>
    </SubHeader>
  );
};

export default headerReportComponent;
