/* Global import */
import { observable, action, makeAutoObservable } from "mobx";

const initialJwtState: JwtData = {
  exp: 0,
  iat: 0,
  jti: "",
  iss: "",
  aud: [],
  sub: "",
  typ: "",
  azp: "",
  sid: "",
  acr: "",
  allowed_origins: [],
  realm_access: {
    roles: [],
  },
  resource_access: {},
  scope: "",
  email_verified: false,
  name: "",
  group: [],
  preferred_username: "",
  given_name: "",
  family_name: "",
  email: "",
  ah: [],
  wizard: [],
  search_type: [],
  departure_country: [],
};

export default class JwtStore {
  @observable jwt: JwtData;
  @observable entities: TenantEntity[] = [];
  @observable tenants: string[] = [];
  @observable mainTenant: string = "";

  constructor() {
    makeAutoObservable(this);
    this.jwt = initialJwtState;
  }

  @action getJwt(): JwtData {
    return this.jwt;
  }

  @action resetJwt(): void {
    this.jwt = initialJwtState;
  }

  @action getRoles(): string[] {
    return this.jwt.realm_access.roles || [];
  }

  /**
   * Acces menu MEU (military end users) dans le screening
   * @returns {boolean}
   */
  @action isMeu(): boolean {
    return this.jwt.ah.includes("MEU");
  }

  /**
   * Acces decision tree classement
   * @returns {boolean}
   */
  @action isWizard(): boolean {
    return this.jwt.wizard.includes(1);
  }

  /**
   * Mode par défaut du classement (1: mot clé, 2: part number, 3: decision tree)
   * @returns {SearchType}
   */

  @action getDefaultSearchType(): SearchType {
    return this.jwt.search_type.includes(3)
      ? "CHECKLIST"
      : this.jwt.search_type.includes(2)
        ? "PARTNUMBER"
        : "KEYWORD";
  }

  @action getDefaultSearchTypeAsNumber(): number {
    return this.jwt.search_type.includes(3)
      ? 3
      : this.jwt.search_type.includes(2)
        ? 2
        : 1;
  }

  @action getDefaultDepartureCountryCode(): string {
    return this.jwt.departure_country[0] || "FR";
  }

  @action updateEntities(): void {
    const entities: TenantEntity[] = this.jwt.group
      .map((group) => {
        const parts =
          typeof group === "string" ? group.split("/").filter(Boolean) : []; // Si ce n'est pas une chaîne, utiliser un tableau vide

        return parts.length > 1
          ? {
              id_entite: parts[1], // Deuxième segment du groupe
              id_tenant: parts[0], // Premier segment du groupe
            }
          : null; // Retourne null si les parties sont invalides
      })
      .filter(
        (TenantEntity): TenantEntity is TenantEntity => TenantEntity !== null
      ); // Filtrer les valeurs nulles

    this.entities = entities;
  }

  @action updateTenants(): void {
    const tenants = new Set<string>();

    this.jwt.group
      .map((group) =>
        typeof group === "string" ? group.split("/").filter(Boolean) : []
      )
      .forEach((parts) => {
        if (parts.length > 0) {
          tenants.add(parts[0]); // Premier segment = tenant
        }
      });

    this.tenants = Array.from(tenants);
  }

  @action updateMainTenant(): void {
    if (this.jwt.group.length > 0) {
      const mainGroup = this.jwt.group[0]; // Supposons que le premier groupe est le principal
      const parts = mainGroup.split("/").filter(Boolean);
      this.mainTenant = parts[0] || ""; // Premier segment = main tenant
    } else {
      this.mainTenant = "";
    }
  }

  @action clearJwt(): void {
    this.jwt = initialJwtState;
    this.entities = [];
    this.tenants = [];
    this.mainTenant = "";
  }

  @action getEntities(): TenantEntity[] {
    return this.entities;
  }

  @action getFullName(): string {
    return `${this.jwt.given_name ?? ""} ${this.jwt.family_name ?? ""}`;
  }

  @action getTenants(): string[] {
    return this.tenants;
  }

  @action getMainTenant(): string {
    return this.mainTenant;
  }

  @action setJwt(jwt: JwtData): void {
    this.jwt = jwt;
    this.updateEntities(); // Met à jour les entités
    this.updateTenants(); // Met à jour les tenants
    this.updateMainTenant(); // Met à jour le main tenant
  }

  @action getScreeningType(): UserScreening {
    //type UserScreening = "USE_BVD" | "USE_BUILT_IN_SCREENING";
    return "USE_BUILT_IN_SCREENING"; //todo 2 types de screening a implémenter sur keycloack, pour qui pourquoi ??
  }
}
