/* Styles import */
import styles from "../admin.module.css";
import SearchIcon from "@material-ui/icons/Add";
/* Global import */
import React, { Component, useEffect, useState, useRef } from "react";
import * as xlsx from "xlsx";
import DeleteIcon from "@material-ui/icons/DeleteSweep";
//import Tabs from "@mui/material/Tabs";
//import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Title,
  Checkbox,
  IconButton,
  TextInput,
  Button,
  GridContainer,
  GridRow,
  GridCol,
  Card,
  CardHeader,
  CardContent,
  FormGroup,
  TextArea,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { observer, inject } from "mobx-react";
import { Hidden, Tooltip } from "@material-ui/core";
/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderAdmin from "../../../components/headerAdmin";
import { getToLocaleDate } from "../../../utils/date";
import ProgressUpload from "../../../components/progressUpload";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Help from "@material-ui/icons/Help";
import { serialize } from "v8";
import SearchEccnWizard from "../../../components/searchEccnWizard";
import configuration from "../../../configuration";
import axios from "axios";

interface State {
  classificationDatabaseType: string;
  Q_checklist: Q_checklist[];
  R_checklist: R_checklist[];
  choix_index_rule: number;
  choix_rule: string;
}
interface UpdatedProps {
  [key: string]: any; // Définissez les types appropriés pour les propriétés mises à jour
}

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
const ClassificationDatabase: React.FC<Props> = ({ rootStore }) => {
  const [state, setState] = useState({
    classificationDatabaseType: "DEFENSE_SPACE",
    Q_checklist: [] as any,
    R_checklist: [] as any,
    choix_file: "update",
    choix_index_rule: 0,
    choix_rule: "",
  });

  const setDatabaseClassificationType = () => {
    setTimeout(() => {
      setState({
        ...state,
        classificationDatabaseType:
          rootStore.controlReasonsStore.classificationDatabaseVersion.content ||
          "DEFENSE_SPACE",
      });
    }, 100);
  };
  const inputRefs = useRef([]);
  const [regle, setRegle] = useState(0);
  const [count, setcount] = useState(0);
  const [total, settotal] = useState(0);
  const [fin, setfin] = useState(0);
  const [value, setValue] = useState(0); // État local pour gérer l'onglet actif
  const [showSearchEccn, setShowSearchEccn] = useState(false);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue); // Mettre à jour l'onglet actif lors du changement
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  useEffect(() => {
    const tenant = rootStore.jwtStore.mainTenant;
    const fetchData = async () => {
      const { data: data2 } = await axios.get(
        `${configuration.LARAVEL_API}/get_Question?tenant=` +
          encodeURIComponent(tenant || "")
      );

      setState((prevState) => ({ ...prevState, Q_checklist: data2 }));

      const { data: data3 } = await axios.get(
        `${configuration.LARAVEL_API}/file_classification_version?tenant=` +
          encodeURIComponent(tenant || "")
      );
      rootStore.controlReasonsStore.classificationDatabaseVersion.fileName =
        data3.file_name;
      rootStore.controlReasonsStore.classificationDatabaseVersion.date =
        data3.date_maj;

      setState((prevState) => ({
        ...prevState,
        classificationDatabaseType:
          rootStore.controlReasonsStore.classificationDatabaseVersion.content ||
          "DEFENSE_SPACE",
      }));
      // Code de componentDidMount
      //setDatabaseClassificationType();

      const { data: data1 } = await axios.get(
        `${configuration.LARAVEL_API}/get_Reponse?tenant=` +
          encodeURIComponent(tenant || "")
      );
      setState((prevState) => ({ ...prevState, R_checklist: data1 }));
    };

    fetchData();
  }, ["R_checklist"]);

  const addQ_checklist = (index: number) => {
    const Q_checklist = state.Q_checklist;

    Q_checklist.splice(index + 1, 0, {
      libelle: " ",
      id_tenant: rootStore.jwtStore.mainTenant,
      sous_q: [],
    });
    setState({ ...state, Q_checklist: Q_checklist });
  };
  const handleChoixRuleChange = (nouveauChoixRule: any, index: number) => {
    const { R_checklist } = state;

    // Créer une copie de la liste Q_checklist
    const updatedChecklist = [...R_checklist];

    // Mettre à jour la valeur de libelle pour l'équipement correspondant à l'index
    updatedChecklist[index].answers = nouveauChoixRule;

    // Mettre à jour l'état avec la nouvelle liste mise à jour
    setState({ ...state, R_checklist: updatedChecklist });

    // alert(R_checklist[index].rule);
  };

  const addR_checklist = () => {
    const R_checklist = state.R_checklist;

    R_checklist.push({
      rule: "",
      eu_check: false,
      us_check: false,
      id_tenant: rootStore.jwtStore.mainTenant,
    });

    setState({ ...state, R_checklist: R_checklist });
  };
  const addR_checklist_index = (index: number) => {
    const R_checklist = state.R_checklist;

    R_checklist.splice(index + 1, 0, {
      rule: "",
      eu_check: false,
      us_check: false,
      id_tenant: rootStore.jwtStore.mainTenant,
    });

    setState({ ...state, R_checklist: R_checklist });
  };

  const copyR_checklist_index = (index: number) => {
    // Créer une copie de l'array R_checklist
    const R_checklist = state.R_checklist;

    // Vérifier si l'index est valide
    if (index >= 0 && index < R_checklist.length) {
      // Copier l'enregistrement à l'index spécifié
      const itemToCopy = { ...R_checklist[index] };

      // Insérer la copie dans la liste à la position index + 1
      R_checklist.splice(index + 1, 0, itemToCopy);
    }

    // Mettre à jour l'état avec le nouvel array
    setState({ ...state, R_checklist: R_checklist });
  };
  const updateR_checklist = (index: number, updatedProps: UpdatedProps) => {
    const { R_checklist } = state;

    // Créer une copie de la liste R_checklist
    const updatedChecklist = [...R_checklist];

    // Mettre à jour les propriétés de l'objet reponse correspondant à l'index
    updatedChecklist[index] = {
      ...updatedChecklist[index],
      ...updatedProps,
    };

    // Mettre à jour l'état avec la nouvelle liste mise à jour
    setState({ ...state, R_checklist: updatedChecklist });
  };

  const updateQ_checklist = (index: number, value: string) => {
    const { Q_checklist } = state;

    // Créer une copie de la liste Q_checklist
    const updatedChecklist = [...Q_checklist];

    // Mettre à jour la valeur de libelle pour l'équipement correspondant à l'index
    // value=value.replace("μ", "micro");
    // value=value.replace("°", " ");
    updatedChecklist[index].libelle = value;

    // Mettre à jour l'état avec la nouvelle liste mise à jour
    setState({ ...state, Q_checklist: updatedChecklist });
  };
  const updateSousQ = (
    mainIndex: number,
    sous_qIndex: number,
    value: string
  ) => {
    const Q_checklist = state.Q_checklist;

    // Vérifier si l'index est valide
    if (mainIndex >= 0 && mainIndex < Q_checklist.length) {
      let mainQuestion = Q_checklist[mainIndex];

      // Vérifier si l'index de sous_q est valide
      if (sous_qIndex >= 0 && sous_qIndex < mainQuestion.sous_q.length) {
        // Mettre à jour le libelle de la sous-question
        value = value.replace("μ", "micro");
        value = value.replace("°", " ");
        mainQuestion.sous_q[sous_qIndex].libelle = value;

        // Mettre à jour la question principale dans Q_checklist
        Q_checklist[mainIndex] = mainQuestion;

        // Mettre à jour l'état
        setState({ ...state, Q_checklist: Q_checklist });
      }
    }
  };
  const deleteQ_checklist = (index: number) => {
    // Obtenez le tableau actuel de la liste des questions
    let Q_checklist = [...state.Q_checklist];

    // Supprimez la question à l'indice spécifié
    Q_checklist.splice(index, 1);

    // Mettez à jour l'état avec le nouveau tableau
    setState({ ...state, Q_checklist: Q_checklist });
  };
  const deleteQ_checklist_sous = (index: number, parent: number) => {
    // Obtenez le tableau actuel de la liste des questions
    let Q_checklist = [...state.Q_checklist];

    // Supprimez la question à l'indice spécifié
    Q_checklist[parent].sous_q.splice(index, 1);

    // Mettez à jour l'état avec le nouveau tableau
    setState({ ...state, Q_checklist: Q_checklist });
  };

  const deleteR_checklist = (index: number) => {
    // Obtenez le tableau actuel de la liste des questions
    let R_checklist = [...state.R_checklist];

    // Supprimez la question à l'indice spécifié
    R_checklist.splice(index, 1);

    // Mettez à jour l'état avec le nouveau tableau
    setState({ ...state, R_checklist: R_checklist });
  };
  const saveQ_checklist = async () => {
    const { Q_checklist } = state;

    // Envoyer les données à l'API Laravel
    await axios
      .post(`${configuration.LARAVEL_API}/Post_Question`, {
        Q_checklist,
      })
      .then((response) => {
        // Gérer la réponse de l'API
        console.log(response.data); // Vous pouvez personnaliser cette partie selon vos besoins
      })
      .catch((error) => {
        // Gérer les erreurs lors de l'appel à l'API
        console.error(error);
      });
  };

  const saveR_checklist = async () => {
    const { R_checklist } = state;

    // Envoyer les données à l'API Laravel
    await axios
      .post(`${configuration.LARAVEL_API}/Post_Reponse`, {
        R_checklist,
      })
      .then((response) => {
        // Gérer la réponse de l'API
        console.log(response.data); // Vous pouvez personnaliser cette partie selon vos besoins

        rootStore.notificationStore.setNotification(
          i18n.t("pages.admin.users.updated.title"),
          i18n.t("pages.admin.users.saved.tree"),
          "success"
        );
      })
      .catch((error) => {
        // Gérer les erreurs lors de l'appel à l'API
        console.error(error);
      });
  };
  const handleSubmit = async (file: File) => {
    const { classificationDatabaseType } = state;

    if (classificationDatabaseType) {
      if (file) {
        const reader = new FileReader();
        reader.onload = function (e) {
          if (e.target?.result instanceof ArrayBuffer) {
            const data = new Uint8Array(e.target?.result!);
            const workbook = xlsx.read(data, { type: "array" });

            // Get the first worksheet
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];

            // Convert the worksheet to JSON
            const jsonData = xlsx.utils.sheet_to_json(worksheet, { header: 1 });

            // The number of rows is just the length of the JSON data
            // console.log(`Number of rows: ${jsonData.length}`);

            settotal(jsonData.length);
          }
        };
        reader.readAsArrayBuffer(file);
      }
      //copier en valuer
      let submitPromise =
        rootStore.controlReasonsStore.submitClassificationDatabase(
          file,
          classificationDatabaseType,
          state.choix_file
        );

      // Définissez un intervalle pour exécuter get_classification_count toutes les minutes
      let intervalId = setInterval(() => {
        axios
          .get(
            `${configuration.LARAVEL_API}/get_classification_count?tenant=` +
              encodeURIComponent(rootStore.jwtStore.mainTenant!)
          )
          .then((response) => {
            // Gérer la réponse de l'API
            setcount(response.data);
            //console.log(response.data); // Vous pouvez personnaliser cette partie selon vos besoins
          })
          .catch((error) => {
            // Gérer les erreurs lors de l'appel à l'API
            console.error(error);
          });
      }, 10000); // 60000 millisecondes = 1 minute

      // Attendez que la soumission de la base de données de classification soit terminée
      await submitPromise;
      // Une fois que la soumission est terminée, arrêtez d'exécuter get_classification_count
      clearInterval(intervalId);
    }

    setcount(total);
    setfin(1);
    const tenant = rootStore.jwtStore.mainTenant;
    const response3 = await axios.get(
      `${configuration.LARAVEL_API}/file_classification_version`,
      {
        params: {
          tenant: encodeURIComponent(tenant || ""),
        },
      }
    );
    const data3 = response3.data;
    rootStore.controlReasonsStore.classificationDatabaseVersion.fileName =
      data3.file_name;
    rootStore.controlReasonsStore.classificationDatabaseVersion.date =
      data3.date_maj;

    //window.location.reload();
  };

  const addSousQ_y = (index: number) => {
    // Obtenez le tableau actuel de la liste des questions
    let Q_checklist = [...state.Q_checklist];

    // Ajoutez une nouvelle sous-question à la question spécifiée
    let newSousQ = {
      libelle: "",
      yes_no: "Y",
    };
    if (!Q_checklist[index].sous_q) {
      Q_checklist[index].sous_q = [];
    }
    Q_checklist[index].sous_q.push(newSousQ);

    // Mettez à jour l'état avec le nouveau tableau
    setState({ ...state, Q_checklist: Q_checklist });
  };

  const addSousQ_n = (index: number) => {
    // Obtenez le tableau actuel de la liste des questions
    let Q_checklist = [...state.Q_checklist];

    // Ajoutez une nouvelle sous-question à la question spécifiée
    let newSousQ = {
      libelle: "",
      yes_no: "N",
    };
    if (!Q_checklist[index].sous_q) {
      Q_checklist[index].sous_q = [];
    }
    Q_checklist[index].sous_q.push(newSousQ);

    // Mettez à jour l'état avec le nouveau tableau
    setState({ ...state, Q_checklist: Q_checklist });
  };

  const renderRStep = () => {
    return (
      <div>
        <div className={styles.equipmentBox}></div>
        <GridContainer>{renderRStep1(" ")}</GridContainer>
      </div>
    );
  };

  const renderQStep = () => {
    return (
      <div>
        <div className={styles.section}>
          <GridContainer>{renderQStep1(" ")}</GridContainer>
        </div>
      </div>
    );
  };

  const renderQStep1 = (key: string) => {
    return (
      <div>
        {state.Q_checklist.map((equipment: any, index: number) => {
          return (
            <div className={styles.equipmentBox} key={index}>
              <GridRow>
                <GridCol md={11}>
                  <div className={styles.equipmentHeader}>
                    <b>
                      {i18n.t("pages.admin.classification-database.Question")}
                      {` ${index + 1}`}
                    </b>
                  </div>

                  <TextArea
                    id="equipment-quantity"
                    label=""
                    value={equipment.libelle || ""}
                    key={index}
                    onChange={(e) => updateQ_checklist(index, e.target.value)}
                  />
                  <Button
                    id="add-subquestion"
                    type="button"
                    onClick={() => addSousQ_y(index)}
                    size="medium"
                  >
                    {i18n.t("pages.admin.classification-database.Addyes")}
                  </Button>
                  <Button
                    id="add-subquestion"
                    type="button"
                    onClick={() => addSousQ_n(index)}
                    size="medium"
                  >
                    {i18n.t("pages.admin.classification-database.Addno")}
                  </Button>
                  <Button
                    id="add-equipment"
                    type="button"
                    onClick={() => addQ_checklist(index)}
                    size="medium"
                  >
                    {i18n.t("pages.admin.tenants.table.wizard.question")}
                  </Button>
                </GridCol>
                <GridCol md={1}>
                  <div className={styles.equipmentHeader}>{` Actions `}</div>
                  <IconButton
                    id="search-national"
                    className={styles.searchButton}
                    onClick={() => {
                      addQ_checklist(index);
                    }}
                  >
                    <Tooltip
                      title={
                        i18n.t("pages.admin.classification-database.tool-a") ||
                        ""
                      }
                    >
                      <SearchIcon />
                    </Tooltip>
                  </IconButton>

                  <IconButton onClick={() => deleteQ_checklist(index)}>
                    <Tooltip
                      title={
                        i18n.t("pages.admin.classification-database.tool-s") ||
                        ""
                      }
                    >
                      <DeleteIcon style={{ color: "#0085ad" }} />
                    </Tooltip>
                  </IconButton>
                </GridCol>
              </GridRow>

              {equipment.sous_q &&
                equipment.sous_q.map((sous_q: any, sous_q_index: number) => (
                  <div style={{ marginLeft: "20px" }} key={sous_q_index}>
                    <GridRow>
                      <GridCol md={11}>
                        <div className={styles.equipmentHeader}>
                          {sous_q.yes_no === "Y" && `If Yes `}
                          {sous_q.yes_no === "N" && `If NO `}
                        </div>

                        <TextArea
                          id={`sous-question-${sous_q_index}`}
                          label=""
                          value={sous_q.libelle || ""}
                          onChange={(e) =>
                            updateSousQ(index, sous_q_index, e.target.value)
                          }
                        />
                      </GridCol>
                      <GridCol md={1}>
                        <div className={styles.equipmentHeader}>
                          {` Actions `}
                        </div>

                        <IconButton
                          onClick={() =>
                            deleteQ_checklist_sous(sous_q_index, index)
                          }
                        >
                          <Tooltip
                            title={
                              i18n.t(
                                "pages.admin.classification-database.tool-s"
                              ) || ""
                            }
                          >
                            <DeleteIcon style={{ color: "#0085ad" }} />
                          </Tooltip>
                        </IconButton>
                      </GridCol>
                    </GridRow>
                  </div>
                ))}
            </div>
          );
        })}

        {state.Q_checklist.length === 0 && (
          <>
            <span style={{ marginLeft: "35px" }}></span>

            <Button
              id="add-equipment"
              type="button"
              onClick={() => addQ_checklist(0)}
              size="medium"
            >
              {i18n.t("pages.admin.tenants.table.wizard.question")}
            </Button>
          </>
        )}
        <span style={{ marginLeft: "35px" }}></span>
        <div style={{ textAlign: "right" }}>
          <Button
            id="add-equipment"
            type="button"
            primary={true}
            onClick={() => {
              saveQ_checklist();
              saveR_checklist();
              rootStore.tenantStore.getQuestionsResponse();
            }}
            size="medium"
          >
            Save
          </Button>
        </div>
      </div>
    );
  };
  const renderRStep1 = (key: string) => {
    return (
      <div>
        {state.R_checklist.map((reponse: any, index: number) => {
          return (
            <div className={styles.equipmentBox} key={index}>
              <GridRow>
                <GridCol md={5}>
                  <div className={styles.equipmentHeader}>
                    <b>
                      {i18n.t("pages.admin.classification-database.Rule")}{" "}
                      {index + 1}
                    </b>
                  </div>
                </GridCol>

                <GridCol md={5}>
                  <div className={styles.equipmentHeader}>
                    {i18n.t("pages.admin.classification-database.Answers")}
                    <span style={{ marginLeft: "300px" }}>
                      <Button
                        size="small"
                        onClick={() => {
                          setShowSearchEccn(true);

                          setState({
                            ...state,
                            choix_rule: reponse.answers,
                            choix_index_rule: index,
                          });
                        }}
                      >
                        {i18n.t("pages.admin.classification-database.Aide")}
                      </Button>
                    </span>
                  </div>
                </GridCol>

                <GridCol md={2}>
                  <div className={styles.equipmentHeader}>{`Action`}</div>
                </GridCol>
              </GridRow>

              <GridRow>
                <GridCol md={5}>
                  <TextInput
                    id="rule"
                    label=""
                    value={reponse.rule || ""}
                    type="text"
                    min={0}
                    step={1}
                    errorText={i18n.t("errors")}
                    onChange={(e) =>
                      updateR_checklist(index, {
                        ...reponse,
                        rule: e.target.value,
                      })
                    }
                  />
                </GridCol>

                <GridCol md={5}>
                  <TextInput
                    id="answers"
                    label=""
                    value={reponse.answers || ""}
                    type="text"
                    min={0}
                    step={1}
                    errorText={i18n.t("errors")}
                    onChange={(e) =>
                      updateR_checklist(index, {
                        ...reponse,
                        answers: e.target.value,
                      })
                    }
                  />
                </GridCol>

                <GridCol md={2}>
                  <IconButton
                    id="search-national"
                    className={styles.searchButton}
                    onClick={() => {
                      addR_checklist_index(index);
                    }}
                  >
                    <Tooltip
                      title={
                        i18n.t("pages.admin.classification-database.tool-a") ||
                        ""
                      }
                    >
                      <SearchIcon />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    id="search-national"
                    className={styles.searchButton}
                    onClick={() => {
                      copyR_checklist_index(index);
                    }}
                  >
                    <Tooltip
                      title={
                        i18n.t("pages.admin.classification-database.tool-d") ||
                        ""
                      }
                    >
                      <FileCopyIcon />
                    </Tooltip>
                  </IconButton>
                  <IconButton onClick={() => deleteR_checklist(index)}>
                    <Tooltip
                      title={
                        i18n.t("pages.admin.classification-database.tool-s") ||
                        ""
                      }
                    >
                      <DeleteIcon style={{ color: "#0085ad" }} />
                    </Tooltip>
                  </IconButton>
                </GridCol>
              </GridRow>

              <GridRow>
                <GridCol md={1}></GridCol>
                <GridCol md={5}>
                  <div className={styles.equipmentHeader}>
                    {`EU classification`}
                  </div>
                </GridCol>
                <GridCol md={1}></GridCol>
                <GridCol md={5}>
                  <div className={styles.equipmentHeader}>
                    {`US classification`}
                  </div>
                </GridCol>
              </GridRow>

              <GridRow>
                <GridCol md={6}>
                  <div className={styles.equipmentHeader}>{`HS code`}</div>

                  <Checkbox
                    className={styles.checkbox}
                    large={false}
                    label={""}
                    checked={reponse.HS_check}
                    style={{ marginLeft: "auto" }}
                    onChange={(e) => {
                      updateR_checklist(index, {
                        ...reponse,
                        HS_check: e.target.checked,
                      });
                      //this.injected.rootStore.countriesStore.setControlCategory(countryControlCategory.controlCategory.id, e.target.checked)
                    }}
                  />
                  <TextInput
                    id="HS_code"
                    label=""
                    value={reponse.HS_code || ""}
                    type="text"
                    min={0}
                    step={1}
                    errorText={i18n.t("errors")}
                    onChange={(e) =>
                      updateR_checklist(index, {
                        ...reponse,
                        HS_code: e.target.value,
                      })
                    }
                  />

                  <div
                    className={styles.radioGroup}
                    style={{ display: "flex", gap: "10px" }}
                  >
                    <input
                      type="radio"
                      name={`eu_check_${index}`}
                      value="0"
                      checked={reponse.eu_check == "0"}
                      onChange={(e) => {
                        updateR_checklist(index, {
                          ...reponse,
                          eu_check: e.target.value == "1",
                        });
                      }}
                    />
                    <label style={{ marginRight: "1px", marginTop: "2px" }}>
                      {i18n.t(
                        "pages.admin.classification-database.code-standard"
                      )}
                    </label>

                    <input
                      type="radio"
                      name={`eu_check_${index}`}
                      value="1"
                      checked={reponse.eu_check == "1"}
                      style={{ marginLeft: "15px" }}
                      onChange={(e) => {
                        updateR_checklist(index, {
                          ...reponse,
                          eu_check: e.target.value == "1",
                        });
                      }}
                    />
                    <label style={{ marginLeft: "1px", marginTop: "2px" }}>
                      {i18n.t(
                        "pages.admin.classification-database.code-custom"
                      )}
                    </label>
                  </div>

                  <TextInput
                    id="eu"
                    label=""
                    value={reponse.eu || ""}
                    type="text"
                    min={0}
                    step={1}
                    errorText={i18n.t("errors")}
                    onChange={(e) =>
                      updateR_checklist(index, {
                        ...reponse,
                        eu: e.target.value,
                      })
                    }
                  />
                </GridCol>

                <GridCol md={6}>
                  <div className={styles.equipmentHeader}>{`HTS code`}</div>

                  <Checkbox
                    className={styles.checkbox}
                    large={false}
                    label={""}
                    checked={reponse.HTS_check}
                    style={{ marginLeft: "auto" }}
                    onChange={(e) => {
                      updateR_checklist(index, {
                        ...reponse,
                        HTS_check: e.target.checked,
                      });
                      //this.injected.rootStore.countriesStore.setControlCategory(countryControlCategory.controlCategory.id, e.target.checked)
                    }}
                  />
                  <TextInput
                    id="HS_code"
                    label=""
                    value={reponse.HTS_code || ""}
                    type="text"
                    min={0}
                    step={1}
                    errorText={i18n.t("errors")}
                    onChange={(e) =>
                      updateR_checklist(index, {
                        ...reponse,
                        HTS_code: e.target.value,
                      })
                    }
                  />

                  <div
                    className={styles.radioGroup}
                    style={{ display: "flex", gap: "10px" }}
                  >
                    <input
                      type="radio"
                      name={`us_check_${index}`}
                      value="0"
                      checked={reponse.us_check == "0"}
                      onChange={(e) => {
                        updateR_checklist(index, {
                          ...reponse,
                          us_check: e.target.value == "1",
                        });
                      }}
                    />
                    <label style={{ marginRight: "1px", marginTop: "2px" }}>
                      {i18n.t(
                        "pages.admin.classification-database.code-standard"
                      )}
                    </label>

                    <input
                      type="radio"
                      name={`us_check_${index}`}
                      value="1"
                      checked={reponse.us_check == "1"}
                      style={{ marginLeft: "15px" }}
                      onChange={(e) => {
                        updateR_checklist(index, {
                          ...reponse,
                          us_check: e.target.value == "1",
                        });
                      }}
                    />
                    <label style={{ marginRight: "1px", marginTop: "2px" }}>
                      {i18n.t(
                        "pages.admin.classification-database.code-custom"
                      )}
                    </label>
                  </div>
                  <TextInput
                    id="us"
                    label=""
                    value={reponse.us || ""}
                    type="text"
                    min={0}
                    step={1}
                    errorText={i18n.t("errors")}
                    onChange={(e) =>
                      updateR_checklist(index, {
                        ...reponse,
                        us: e.target.value,
                      })
                    }
                  />
                </GridCol>
              </GridRow>
              <hr></hr>
              <br></br>
            </div>
          );
        })}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Button
              id="add-e"
              type="button"
              onClick={() => addR_checklist()}
              size="medium"
            >
              {i18n.t("pages.admin.tenants.table.wizard.regle")}
            </Button>
          </div>

          <div>
            <Button
              id="save-e"
              type="button"
              primary={true}
              onClick={() => {
                saveQ_checklist();
                saveR_checklist();
              }}
              size="medium"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <AppBar selectedPage="ADMIN" rootStore={rootStore} />

      <HeaderAdmin pages="CLASSIFICATION_DATABASE" rootStore={rootStore} />

      <Card className={styles.body}>
        <CardHeader
          title={i18n.t("pages.admin.classification-database.title")}
        />

        <CardContent>
          <div
            className={styles.radioGroup}
            style={{ display: "flex", gap: "10px" }}
          >
            <input
              type="radio"
              name={`file_update`}
              value="0"
              checked={state.choix_file == "update"}
              onChange={(e) => {
                setState({ ...state, choix_file: "update" });
              }}
            />
            <label style={{ marginRight: "1px", marginTop: "2px" }}>
              {i18n.t("pages.admin.classification-database.file_update")}
            </label>

            <input
              type="radio"
              name={`file_sup`}
              value="1"
              checked={state.choix_file == "erase"}
              style={{ marginLeft: "15px" }}
              onChange={(e) => {
                setState({ ...state, choix_file: "erase" });
              }}
            />
            <label style={{ marginLeft: "1px", marginTop: "2px" }}>
              {i18n.t("pages.admin.classification-database.file_sup")}
            </label>
          </div>

          <FormGroup>
            <ProgressUpload
              onChange={(file) => handleSubmit(file)}
              progress={
                rootStore.controlReasonsStore.classificationDatabasePourcent
              }
              uploadState={
                rootStore.controlReasonsStore.classificationDatabaseState
              }
              label={i18n.t("pages.admin.classification-database.label-upload")}
              subLabel={` ${i18n.t("pages.admin.upload.date")}: 
                    ${getToLocaleDate(
                      rootStore.controlReasonsStore
                        .classificationDatabaseVersion.date
                    )}
                    ${i18n.t("pages.admin.upload.filename")}:
                    ${
                      rootStore.controlReasonsStore
                        .classificationDatabaseVersion.fileName
                    } 
                   
                     
                 
                    `}
            />
          </FormGroup>
          <p>
            {" "}
            {`${i18n.t("pages.admin.upload.enregistrement")}: ${fin == 0 ? count : total}  /`}{" "}
            <b> {`${total}`} </b>
          </p>
        </CardContent>
      </Card>

      <div
        className="onglets-container"
        style={{ paddingLeft: "40px", paddingTop: "40px" }}
      >
        <button
          className={`onglet ${regle === 0 ? "active" : ""}`}
          style={{ margin: "10px 30px 5px 25px", textDecoration: "underline" }}
          onClick={() => setRegle(0)}
        >
          {i18n.t("pages.admin.classification-database.Questions")}
        </button>
        <button
          className={`onglet ${regle !== 0 ? "active" : ""}`}
          style={{ textDecoration: "underline" }}
          onClick={() => setRegle(1)}
        >
          {i18n.t("pages.admin.classification-database.Rules")}
        </button>
      </div>

      {regle === 0 && (
        <Card className={styles.body1}>
          <CardHeader
            title={
              regle === 0
                ? i18n.t("pages.admin.classification-database.titlechecklist")
                : i18n.t("pages.admin.classification-database.titlechecklist1")
            }
          />
          <CardContent>
            <FormGroup>{renderQStep()}</FormGroup>
          </CardContent>
        </Card>
      )}

      {regle !== 0 && (
        <Card className={styles.body1}>
          <CardHeader
            title={
              regle === 0
                ? i18n.t("pages.admin.classification-database.titlechecklist")
                : i18n.t("pages.admin.classification-database.titlechecklist1")
            }
          />

          <CardContent>
            <FormGroup>{renderRStep()}</FormGroup>
          </CardContent>
        </Card>
      )}

      <SearchEccnWizard
        isOpen={showSearchEccn}
        ouClick={""}
        onClose={() => setShowSearchEccn(false)}
        exportabilityMode={true}
        rootStore={rootStore}
        choix_rule={state.choix_rule}
        choix_index_rule={state.choix_index_rule}
        onChoixRuleChange={handleChoixRuleChange}
        setShowSearchEccn={setShowSearchEccn}
      />
    </div>
  );
};

export default inject("rootStore")(observer(ClassificationDatabase));
