/* Styles import */
import styles from "./us.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  GridCol,
  GridRow,
  TextInput,
  Title,
  FormGroup,
  Checkbox,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
// eslint-disable-next-line
import { useNavigate, useParams } from "react-router-dom";

/* Custom import */
import AppBar from "../../../components/appBar";
import Footer from "../../../components/footer";
import HeaderScreeningComponent from "../../../components/headerScreening";
import TimeLine from "../../../components/timeLine";
import { flowUrl } from "../../../utils/generator";
import Api from "../../../utils/api";
import AutoComplete from "../../../components/autoComplete";
import Loader from "../../../components/loader";

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}

type MsgType = {
  code_msg: string;
  Regle: string;
  Message: string;
  Lib_url1: string;
  Url1: string;
  Lib_url2: string;
  Url2: string;
  Lib_url3: string;
  Url3: string;
};

interface State {
  company?: string;
  country?: number;
  flowId?: string;
  liste_us: boolean;
  liste_fr: boolean;
  liste_canadien: boolean;
  liste_eu: boolean;
  liste_uk: boolean;
}

const All: React.FC<Props> = ({ rootStore }) => {
  const api = new Api();
  const { id, type } = useParams() as {
    id?: string;
    type?: string;
  };
  const navigate = useNavigate();
  const navigateTowards = (url: string) => {
    navigate(flowUrl(url, id));
  };
  const goBack = () => {
    navigate(-1);
  };
  const msg_fr = {
    code_msg: "",
    Regle: "",
    Message: "",
    Lib_url1: "",
    Url1: "",
    Lib_url2: "",
    Url2: "",
    Lib_url3: "",
    Url3: "",
  };
  const msg_en = {
    code_msg: "",
    Regle: "",
    Message: "",
    Lib_url1: "",
    Url1: "",
    Lib_url2: "",
    Url2: "",
    Lib_url3: "",
    Url3: "",
  };

  const [state, setState] = useState({
    company: "",
    country: 0,
    liste_us: true,
    liste_fr: true,
    liste_canadien: false,
    liste_eu: true,
    liste_uk: false,
    flowId: "",
  });

  const getCompany = () => {
    const company = rootStore.screeningStore.company;

    rootStore.screeningStore.company = "";
    return company ? company : "";
  };

  useEffect(() => {
    rootStore.countriesStore.getCountries();
    const fetchData = async () => {
      let msg_fr = await api.GcodeToMsg("EXP060");
      let msg_en = await api.GcodeToMsg("EXP061");

      const flowId = id;

      if (flowId) {
        const flow = await api.newGetFlowById(flowId);

        if (flow) {
          setState({
            ...state,
            flowId: flowId,
            company: flow.customer,
            country: flow.destination_country_id,
          });
        }
      } else if (rootStore.screeningStore.company !== "") {
        setState({ ...state, company: getCompany() as string });
        // setCountry(getCountry());
      }
    };

    fetchData(); // Appeler la fonction fetchData une fois, équivalent à componentDidMount

    // Si vous avez besoin de nettoyer quelque chose, vous pouvez le faire ici
    return () => {
      // Code de nettoyage si nécessaire
    };
  }, [id, rootStore.screeningStore.company]);

  const getCountry = () => {
    const countryId = rootStore.screeningStore.country;

    rootStore.screeningStore.country = -1;

    return countryId ? countryId : 0;
  };

  const submitIsDisabled = () => {
    const { company, liste_us, liste_fr, liste_eu, liste_canadien, liste_uk } =
      state;

    return (
      !company ||
      (!liste_us && !liste_fr && !liste_eu && !liste_canadien && !liste_uk)
    );
  };

  const handleSubmit = async () => {
    const {
      company,
      country,
      liste_us,
      liste_fr,
      liste_eu,
      liste_canadien,
      liste_uk,
    } = state;

    if (liste_us) {
      if (company) {
        rootStore.screeningStore.setCompany(company);
        rootStore.screeningStore.setCountry(country);
        await rootStore.screeningStore.getBasicEntities_all(
          company,
          country!,
          liste_us,
          liste_fr,
          liste_eu,
          liste_canadien,
          liste_uk
        );
        navigate(flowUrl("/screening/basic/all/choose-basic-entity", id), {
          state: {
            company: company,
          },
        });
      }
    } else {
      if (company) {
        rootStore.screeningStore.setCompany(company);
        rootStore.screeningStore.setCountry(-1);
        await rootStore.screeningStore.getBasicEntities_all(
          company,
          -1,
          liste_us,
          liste_fr,
          liste_eu,
          liste_canadien,
          liste_uk
        );

        navigateTowards("/screening/basic/all/choose-basic-entity");
      }
    }
  };

  let country = window.navigator.language;
  let intro = "";

  if (country === "fr-FR" || country === "fr") intro = msg_fr.Message;
  else intro = msg_en.Message;
  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"SCREENING"} />

      <HeaderScreeningComponent
        type="BASIC"
        pages="ALL"
        rootStore={rootStore}
      />

      {rootStore.screeningStore.entitiesState === "PENDING" && <Loader />}

      <div className={styles.body}>
        <Title tag="h3" className={styles.pStyles}>
          {i18n.t("pages.screening.entity.title")}
        </Title>
        <br />
        <Title tag="h5" className={styles.pStyles}>
          <div
            className="post__content"
            dangerouslySetInnerHTML={{ __html: intro }}
          ></div>
        </Title>

        <form className={styles.form}>
          <div className={styles.row}>
            <div className={styles.input}>
              <TextInput
                id="company"
                type="text"
                label={i18n.t("pages.screening.entity.company")}
                value={state.company || ""}
                required={true}
                errorText={i18n.t("errors.company-not-defined")}
                onChange={(e) =>
                  setState({ ...state, company: e.target.value })
                }
              />
            </div>

            <div className={`${styles.input} ${styles.autoComplete} `}>
              <AutoComplete
                id="country"
                label={i18n.t("pages.screening.entity.country")}
                options={rootStore.countriesStore.countriesToOptions}
                errorText={i18n.t("errors.country-not-defined")}
                value={state.country || ""}
                onChange={(country) => setState({ ...state, country })}
              />
            </div>
          </div>
        </form>

        <FormGroup>
          <Checkbox
            className={styles.checkbox}
            large={true}
            label={i18n.t("pages.screening.entity.liste_all")}
            checked={state.liste_us}
            onChange={(e) => {
              setState({ ...state, liste_us: e.target.checked });
            }}
          />
          <br />
          <Checkbox
            className={styles.checkbox}
            large={true}
            label={i18n.t("pages.screening.entity.liste_all1")}
            checked={state.liste_eu}
            onChange={(e) => {
              setState({ ...state, liste_eu: e.target.checked });
            }}
          />
          <br />
          <Checkbox
            className={styles.checkbox}
            large={true}
            label={i18n.t("pages.screening.entity.liste_all2")}
            checked={state.liste_fr}
            onChange={(e) => {
              setState({ ...state, liste_fr: e.target.checked });
            }}
          />

          <br />
          <Checkbox
            className={styles.checkbox}
            large={true}
            label={i18n.t("pages.screening.entity.liste_all3")}
            checked={state.liste_canadien}
            onChange={(e) => {
              setState({ ...state, liste_canadien: e.target.checked });
            }}
          />
          <br />

          <Checkbox
            className={styles.checkbox}
            large={true}
            label={i18n.t("pages.screening.entity.liste_all4")}
            checked={state.liste_uk}
            onChange={(e) => {
              setState({ ...state, liste_uk: e.target.checked });
            }}
          />
        </FormGroup>
      </div>

      <Footer
        buttons={[
          {
            id: "previous",
            onClick: () => goBack(),
            label: i18n.t("global.previous"),
          },
          {
            id: "submit",
            onClick: () => handleSubmit(),
            label: i18n.t("components.searchEccn.search-button"),
            primary: true,
            disabled: submitIsDisabled(),
          },
        ]}
      >
        {state.flowId && (
          <GridRow>
            <GridCol md={12} xs={12} alignSelf="center">
              <TimeLine step={2} />
            </GridCol>
          </GridRow>
        )}
      </Footer>
    </div>
  );
};

export default inject("rootStore")(observer(All));
