/* Styles import */
import styles from "../subHeader/subHeader.module.css";

/* Global import */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import i18n from "i18next";
import axios from "axios"; // Import axios

/* Custom import */
import SubHeader from "../subHeader";
import configuration from "../../configuration";

interface Props {
  pages: Pages;
  type: ScreeningMode;
  rootStore: import("../../stores/rootStore").default;
}

type ScreeningMode = "BASIC" | "CLASSIC";
type Pages =
  | "ENTITY"
  | "INDIVIDUAL"
  | "MASS"
  | "OTHER"
  | "ALL"
  | "AHMENU"
  | "DUEDILIGENCE";

const HeaderScreeningComponent = ({ pages, type, rootStore }: Props) => {
  const [menu, setMenu] = useState<string>("NO");

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        //todo doublon avec tenantSTore.getTenantAhData à supprimer
        const response = await axios.get(
          `${configuration.LARAVEL_API}/resources/tenants`,
          {
            params: { name: rootStore.jwtStore.mainTenant }, // Ajout des paramètres via `params`
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "access_token"
              )}`,
            },
          }
        );

        if (response.data?.ah) {
          setMenu(response.data.ah); // Mise à jour de l'état avec la valeur du menu
        } else {
          console.warn("No 'ah' field found in the response.");
        }
      } catch (error) {
        console.error("Error fetching tenant AH:", error);
      }
    };

    fetchMenu();
  }, [rootStore.jwtStore.mainTenant]); // Exécuter uniquement lorsque `mainTenant` change

  if (type === "BASIC") {
    return (
      <SubHeader>
        <Link
          id="entity"
          className={`${styles.link} ${pages === "ALL" ? styles.selected : {}}`}
          to="/screening/basic/All"
        >
          {i18n.t("pages.screening.menu.all")}
        </Link>

        <Link
          id="other"
          className={`${styles.link} ${
            pages === "OTHER" ? styles.selected : {}
          }`}
          to="/screening/basic/other"
        >
          {i18n.t("pages.screening.menu.other")}
        </Link>

        {rootStore.jwtStore.isMeu() && (
          <Link
            id="individual"
            className={`${styles.link} ${
              pages === "AHMENU" ? styles.selected : {}
            }`}
            to="/screening/basic/ah"
          >
            AH MEU list
          </Link>
        )}
        <Link
          id="individual"
          className={`${styles.link} ${
            pages === "DUEDILIGENCE" ? styles.selected : {}
          }`}
          to="/screening/basic/due_diligence"
        >
          Due diligence
        </Link>
      </SubHeader>
    );
  }

  return (
    <SubHeader>
      <Link
        id="entity"
        className={`${styles.link} ${
          pages === "ENTITY" ? styles.selected : {}
        }`}
        to="/screening/entity"
      >
        {i18n.t("pages.screening.menu.entity")}
      </Link>

      <Link
        id="individual"
        className={`${styles.link} ${
          pages === "INDIVIDUAL" ? styles.selected : {}
        }`}
        to="/screening/end-user"
      >
        {i18n.t("pages.screening.menu.individual")}
      </Link>
    </SubHeader>
  );
};

export default HeaderScreeningComponent;
