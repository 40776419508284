/* Styles import */
import styles from "./results.module.css";
import Api from "../../../utils/api";
/* Global import */
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import {
  Title,
  GridRow,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import ArrowIcon from "@material-ui/icons/ArrowRightAlt";
// eslint-disable-next-line
import { useNavigate, useParams } from "react-router-dom";

/* Custom import */
import AppBar from "../../../components/appBar";
import ScreeningResult from "../../../components/screeningResult/screeningResult";
import Footer from "../../../components/footer";
import { flowUrl } from "../../../utils/generator";
import TimeLine from "../../../components/timeLine";

interface Props extends InjectedProps {}
interface InjectedProps {
  rootStore: import("../../../stores/rootStore").default;
}
interface State {}

@inject("rootStore")
@observer
export default class ResultsFlow extends Component<Props, State> {
  navigate = useNavigate();
  navigateToScreening = (url: string) => {
    const { id } = useParams();
    this.navigate(flowUrl(url, id));
  };
  goBack = () => {
    this.navigate(-1);
  };
  getScreeningButton() {
    const screeningType =
      this.props.rootStore.jwtStore.getScreeningType() || undefined;

    if (screeningType === "USE_BUILT_IN_SCREENING") {
      return {
        id: "screeningLink",
        onClick: () => this.navigate("/screening/basic/all/"),
        label: i18n.t("global.screening"),
        primary: true,
      };
    }
    return {
      id: "screeningLink",
      onClick: () => this.navigate("/screening/entity"),
      label: i18n.t("global.screening"),
      primary: true,
    };
  }

  render() {
    return (
      <div>
        <AppBar rootStore={this.props.rootStore} selectedPage="EXPORTABILITY" />

        {this.renderResults()}

        <Footer
          buttons={[
            {
              id: "previous",
              onClick: () => this.goBack(),
              label: i18n.t("global.previous"),
              //  disabled: !activeSaveButton(),
            },
            this.getScreeningButton(),
            {
              id: "licensing",
              onClick: () => this.navigateToScreening("/license/matching"),
              label: i18n.t("global.licensing"),
              primary: true,
            },
          ]}
        >
          <TimeLine step={1} />
        </Footer>
      </div>
    );
  }

  renderResults() {
    const result = this.props.rootStore.flowStore.flowExportability;

    return (
      result && (
        <div className={styles.messageBody}>
          <div className={styles.header}>
            <GridRow justify="space-between">
              <div className={styles.row}>
                {result.globalTrafficLightColor && (
                  <img
                    className={styles.traficLight}
                    src={require(
                      `../../../../statics/images/global-traffic-${result.globalTrafficLightColor.toLowerCase()}.svg`
                    )}
                    alt={"traficLight"}
                  />
                )}

                <div>
                  <Title tag="h2">
                    {i18n.t("pages.exportability.results.message.title")}
                  </Title>
                  <Title tag="h4">
                    <p className={styles.arrow}>
                      {result.departureCountryName}
                      <ArrowIcon />
                      {result.destinationCountryName}
                    </p>
                  </Title>
                </div>
              </div>

              {result.neededLicences.length > 0 && (
                <div className={styles.licenses}>
                  <Title tag="h5">
                    {i18n.t("pages.exportability.results.need-license")}
                  </Title>

                  {result.neededLicences.map((license, index) => {
                    return (
                      <div className={styles.flag} key={index}>
                        {license}
                      </div>
                    );
                  })}
                </div>
              )}
            </GridRow>
          </div>

          {this.renderResult(
            i18n.t("pages.exportability.results.customer-end-user-country"),
            result.customerCountryScreeningDetails
          )}

          {this.renderResult(
            i18n.t(`global.product-type.${result.itemTypeName}`),
            result.generalScreeningDetails
          )}
          {result.consigneesScreeningDetails &&
            this.renderResult(
              i18n.t("pages.exportability.results.consignee-countries"),
              result.consigneesScreeningDetails
            )}

          {(result.optionalItemsNationalLicenses.length > 0 ||
            result.optionalItemsUsItarLicenses.length > 0 ||
            result.optionalItemsUsEarLicenses.length > 0) && (
            <div className={styles.results}>
              <Title tag="h3">
                {i18n.t("pages.exportability.results.optional-items")}
              </Title>

              {this.renderSubResult(
                i18n.t("pages.exportability.results.national-licenses"),
                result.optionalItemsNationalLicenses
              )}

              {this.renderSubResult(
                i18n.t("pages.exportability.results.us-itar-licenses"),
                result.optionalItemsUsItarLicenses
              )}

              {this.renderSubResult(
                i18n.t("pages.exportability.results.us-ear-licenses"),
                result.optionalItemsUsEarLicenses
              )}
            </div>
          )}
        </div>
      )
    );
  }

  renderResult(title: string, results: ScreeningDetails[]) {
    return (
      results.length > 0 && (
        <div className={styles.results}>
          <Title tag="h3">{title}</Title>

          {this.renderListScreening(results)}
        </div>
      )
    );
  }

  renderSubResult(title: string, results: ScreeningDetails[]) {
    return (
      results.length > 0 && (
        <div className={styles.subResults}>
          <Title tag="h5">{title}</Title>

          {this.renderListScreening(results)}
        </div>
      )
    );
  }

  renderListScreening(results: ScreeningDetails[]) {
    return results.map((result, index) => {
      //console.log(result.message)
      let api: Api;
      api = new Api();
      api.CodeToMsg(result.message).then((value) => {
        result.message = value;
        // console.log(value);
      });
      return (
        <ScreeningResult
          rootStore={this.props.rootStore}
          destination={result.departureCountry || ""}
          pdebut={result.destinationCountry || ""}
          key={index}
          result={result}
        />
      );
    });
  }
}
