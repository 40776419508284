/* Global import */
import {
  observable,
  action,
  runInAction,
  makeObservable,
  makeAutoObservable,
} from "mobx";
import i18n from "i18next";
import Moment from "moment";
/* Custiom import */
import Api from "../utils/api";
import { decode } from "../utils/jwt";
import { log } from "console";

export default class AuthStore {
  @observable error: string | undefined = "";
  @observable success: string | undefined = "";
  @observable loading: boolean = false;

  rootStore: import("./rootStore").default;
  api: import("../utils/api").default;

  constructor(rootStore: import("./rootStore").default) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.api = new Api();

    this.checkIsLogged();
  }

  @action async login(email: string, password: string) {
    this.loading = true;
    this.clearError();
    Moment.locale("fr");
    try {
      const authResponse = await this.api.login(email, password);

      const expirationDate = new Date(
        new Date().getTime() + authResponse.expire_in
      );

      if (authResponse.access_token != undefined) {
        window.localStorage.setItem("access_token", authResponse.access_token);
        this.setLogged(authResponse);

        //Addlog
        const logr = this.api.AddLog(
          this.rootStore.jwtStore.mainTenant || "",
          this.rootStore.jwtStore.jwt?.email || "",
          this.rootStore.jwtStore.jwt?.realm_access.roles || "",
          "Login",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "Expiration=" + expirationDate,
          "",
          JSON.stringify(authResponse.access_token) //todo decode JWT
        );
      }
    } catch (error: any) {
      this.setError(error);
    }
  }

  @action async sso(code: string) {
    this.loading = true;
    this.clearError();
    Moment.locale("fr");
    try {
      const authResponse = await this.api.sso(code);

      if (authResponse.access_token != undefined) {
        this.setLogged(authResponse);
      }
    } catch (error: any) {
      this.setError(error);
    }
  }

  @action checkIsLogged(): boolean {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      return true;
    }

    try {
      const accessToken = window.localStorage.getItem("access_token");
      if (!accessToken) {
        // throw new Error(i18n.t("errors.disconected"));
        return false;
      }

      const jwt: JwtData | null = accessToken ? decode(accessToken) : null;

      if (!jwt) {
        throw new Error(i18n.t("errors.invalid-token"));
      }

      // Mise à jour du JWT dans le store si non défini
      if (
        !this.rootStore.jwtStore.jwt ||
        this.rootStore.jwtStore.jwt.exp === 0
      ) {
        this.rootStore.jwtStore.setJwt(jwt);
      }

      // Vérification de la date d'expiration
      const currentTimestamp = Math.floor(Date.now() / 1000);

      if (jwt.exp && jwt.exp < currentTimestamp) {
        throw new Error(i18n.t("errors.token-expired"));
      }

      return true;
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "Unknown error";
      this.setError(errorMessage);
      return false;
    }
  }

  @action async setLogged(authResponse: AuthResponse) {
    window.localStorage.setItem("access_token", authResponse.access_token);

    this.rootStore.jwtStore.setJwt(decode(authResponse.access_token)); //todo use access_token & decode JWT

    // Initialise default value for classification
    this.rootStore.eclabsStore.initializeDepartureCountry();
    this.watchSession(authResponse.access_token);

    await this.rootStore.tenantStore.getQuestionsResponse();

    await this.rootStore.tenantStore.getTenantAhData();
    await this.rootStore.tenantStore.getTenanttempleteData();
    await this.rootStore.countriesStore.setCountryTable();

    this.loading = false;
  }

  @action setError(error?: string) {
    this.loading = false;
    this.success = "";
    this.error = error;
  }

  @action getError() {
    return this.error;
  }

  @action setSuccess(success?: string) {
    this.loading = false;
    this.error = "";
    this.success = success;
  }

  @action getSuccess() {
    return this.success;
  }

  @action setLoading(loading: boolean) {
    this.loading = loading;
  }

  @action async logout() {
    this.clearError();
    this.rootStore.notificationStore.resetNotification();

    try {
      // await this.api.logout();
      window.localStorage.removeItem("access_token");
      this.rootStore.jwtStore.resetJwt();
      window.location.replace("/ui/login");
    } catch (e) {}
  }

  @action clearError() {
    this.error = "";
  }

  @action watchSession(token: string) {
    const decodeToken = decode(
      token || localStorage.getItem("access_token") || ""
    );
    const diff = (decodeToken.exp - decodeToken.iat) * 1000;
    let time = 0;

    const interval = setInterval(() => {
      time = time + 60000;

      if (time >= diff) {
        clearInterval(interval);
        this.setError(i18n.t("errors.token-expired"));
      }
    }, 60000);
  }
}
