import React from "react";
import {
  TableRow,
  TableCell,
  Collapse,
  Box,
  Table,
  TableBody,
  TableHead,
} from "@mui/material";
import i18n from "i18next";

type ExpandableTableRowProps = {
  rowData: any;
  renderContent?: (data: any) => JSX.Element;
  isOpen: boolean;
  regulation: string;
};

const RenderTableEquipment: React.FC<ExpandableTableRowProps> = ({
  rowData,
  renderContent,
  isOpen,
  regulation,
}) => {
  const defaultContentRender = (data: any) => {
    if (!data.equipments || !Array.isArray(data.equipments)) return null;

    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{i18n.t("pages.license.add.model")}</TableCell>
            <TableCell>{i18n.t("pages.license.add.part-number")}</TableCell>
            <TableCell>{i18n.t("pages.license.add.classification")}</TableCell>
            <TableCell>{i18n.t("pages.license.add.unit-price")}</TableCell>
            <TableCell>{i18n.t("pages.license.add.quantity-actual")}</TableCell>
            <TableCell>
              {i18n.t("pages.license.add.quantity-initial")}
            </TableCell>
            <TableCell>{i18n.t("pages.license.add.amount-actual")}</TableCell>
            <TableCell>{i18n.t("pages.license.add.amount-initial")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.equipments.map((equipment: any) => (
            <TableRow key={equipment.id}>
              <TableCell>
                {`${(equipment.model || "")
                  .replace("\t\nF.S.\n0\t\n", "")
                  .replace(/&#39;/g, "'") // Remplacer '&#39;' par l'apostrophe
                  .replace(/&amp;/g, "&") // Remplacer '&amp;' par l'esperluette
                  .replace(/&lt;/g, "<") // Remplacer '&lt;' par le signe inférieur à
                  .replace(/&gt;/g, ">") // Remplacer '&gt;' par le signe supérieur à
                  .replace(/&quot;/g, '"') // Remplacer '&quot;' par la guillemet double
                  .substring(0, 100)}${
                  equipment.model && equipment.model.length > 40 ? "..." : ""
                }`}
              </TableCell>
              <TableCell>{equipment.partNumber}</TableCell>
              <TableCell>{equipment.eccnCode}</TableCell>
              <TableCell>{equipment.unitPrice}</TableCell>
              <TableCell>
                {/* debug ::: INITIALE {equipment.quantityInitial} - ORDERED{" "}{equipment.quantityOrdered} ={" "} */}
                {regulation === "FR_MUNITIONS_LIST"
                  ? equipment.quantityInitial - equipment.quantityOrdered
                  : equipment.quantityActual}
              </TableCell>
              <TableCell>
                {regulation === "FR_MUNITIONS_LIST"
                  ? equipment.quantityInitial
                  : equipment.quantity}
              </TableCell>
              <TableCell>
                {/* debug ::: INITIALE {equipment.amountInitial} - ORDERED{" "}{equipment.amountOrdered}= */}
                {regulation === "FR_MUNITIONS_LIST"
                  ? (equipment.amountInitial || 0) -
                    (equipment.amountOrdered || 0)
                  : equipment.amountActual || 0}
              </TableCell>
              <TableCell>{equipment.amountInitial}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>{defaultContentRender(rowData)}</Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default RenderTableEquipment;
