import React, { useEffect, useState } from "react";
import styles from "../license.module.css";
import DeleteIcon from "@material-ui/icons/DeleteSweep";
import { useNavigate } from "react-router-dom";
import HeaderLicense from "../../../components/headerLicense";
import AppBar from "../../../components/appBar";
import Loader from "../../../components/loader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Add, Remove } from "@material-ui/icons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Box,
  TableSortLabel,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  Typography,
  OutlinedInput,
  SelectChangeEvent,
} from "@mui/material";

import { inject, observer } from "mobx-react";
import Api from "../../../utils/api";
import i18n from "i18next";
import { regulation } from "../../../utils/constant";
import moment from "moment";
import EditIcon from "@material-ui/icons/Create";
import RenderTableEquipment from "./RenderTableEquipment";
import { toJS } from "mobx";
import { convertEntityNameToId } from "../../../utils/generator";
import { log } from "console";

interface RowData {
  show_equipment: string;
  id: number;
  Departure_country: string;
  regulation: string;
  destinationCountryName: string;
  licenseNumber: string;
  companyName: string;
  expirationDate: string;
  status: string;
  actions: string;
  entite: string;
}

interface HeadCell {
  id: keyof RowData;
  label: string;
  disableSorting?: boolean;
  showFilter?: boolean;
  typeFilter?: string;
  options?: Array<{ value: string; label: string }>;
}

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
type ExpandHistoricState = {
  [key: string]: boolean; // ou [key: number]: boolean; selon vos identifiants
};

const PageTableLicence: React.FC<Props> = ({ rootStore }) => {
  const api = new Api();
  const navigate = useNavigate();

  const [headCellsState, setHeadCellsState] = useState<HeadCell[]>([
    {
      id: "show_equipment",
      label: "",
      showFilter: false,
    },
    {
      id: "Departure_country",
      label: i18n.t("pages.search-flow.departure-country"),
      showFilter: true,
      typeFilter: "select",
    },
    {
      id: "entite",
      label: i18n.t("pages.license.add.entity"),
      showFilter: rootStore.jwtStore.getEntities().length > 0,
      typeFilter: "select",
    },
    {
      id: "regulation",
      label: i18n.t("pages.license.add.regulation-minimized"),
      showFilter: true,
      typeFilter: "select",
    },
    {
      id: "destinationCountryName",
      label: i18n.t("pages.license.add.destination-country"),
      showFilter: true,
      typeFilter: "select",
    },
    {
      id: "licenseNumber",
      label: i18n.t("pages.license.add.license-number"),
      showFilter: true,
      typeFilter: "search",
    },
    {
      id: "companyName",
      label: i18n.t("pages.license.add.end-user"),
      showFilter: true,
      typeFilter: "select",
    },
    {
      id: "expirationDate",
      label: i18n.t("pages.license.add.expiration-date"),
    },
    {
      id: "status",
      label: i18n.t("pages.license.search.status"),
      showFilter: true,
      typeFilter: "select",
    },
    { id: "actions", label: i18n.t("pages.license.add.Actions") },
  ]);
  const [objectLicenses, setLicenses] = useState<SearchLicenseDto>();
  const [state, setState] = useState({
    //countries: CCountry[],
    expandedLicense: null,
    detailsOpened: "",
    page: 1,
    departureCountry: "AL",
    open: false,
    open1: false,
    desId: 0,
    licenses: {
      results: [],
    },
    regulation: "",
    compagny: "",
    destinationCountry: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof RowData>();
  const [openRowIds, setOpenRowIds] = useState<number[]>([]);
  const [expandHistoric, setExpandHistoric] = useState<ExpandHistoricState>({});

  const [filterText, setFilterText] = useState<{
    [key in keyof RowData]?: string;
  }>({});

  useEffect(() => {
    if (objectLicenses?.results) {
      const departureCountryOptions = Array.from(
        new Set(
          objectLicenses.results.map((license) =>
            convertpay(license.departureCountryId)
          )
        )
      )
        .filter((country) => country !== undefined)
        .map((country) => ({
          value: country,
          label: country,
        }));

      const destinationCountryOptions = Array.from(
        new Set(
          objectLicenses.results.map(
            (license) => license.destinationCountryName
          )
        )
      )
        .map((country) =>
          country ? { value: country, label: country } : undefined
        )
        .filter((option) => option !== undefined) as Array<{
        value: string;
        label: string;
      }>;

      const licenseStatusOptions = [
        {
          value: i18n.t("global.license-status.PENDING"),
          label: i18n.t("global.license-status.PENDING"),
        },
        {
          value: i18n.t("global.license-status.EXPIRED"),
          label: i18n.t("global.license-status.EXPIRED"),
        },
        {
          value: i18n.t("global.license-status.RENEW"),
          label: i18n.t("global.license-status.RENEW"),
        },
        {
          value: i18n.t(`global.license-status.ACTIVE`),
          label: i18n.t(`global.license-status.ACTIVE`),
        },
      ];

      const entitiesOptions = rootStore.jwtStore
        .getEntities()
        .map((entite) => ({
          value: entite.id_entite,
          label: entite.id_entite,
        }));

      const regulationOptions = [
        { value: "CA_GROUP_1_DU", label: "CA DU" },
        { value: "CA_GROUP_2_ML", label: "CA ML" },
        { value: "DE_MUNITIONS_LIST", label: "DE ML" },
        { value: "DE_DUAL_USE_GUTER", label: "DE DU" },
        { value: "EU_DUAL_USE_LIST", label: "EU DU" },
        { value: "EU_MUNITIONS_LIST", label: "EU ML" },
        { value: "FR_LIST_DOUBLE_USAGE", label: "FR DU" },
        { value: "FR_MUNITIONS_LIST", label: "FR ML" },
        { value: "FR_ARRETE_DU_31_JUILLET_2014_HEL", label: "FR Hélico" },
        { value: "FR_ANSSI", label: "FR ANSSI" },
        { value: "FR_IMPORTATION", label: "FR A2" },
        { value: "US_DUAL_USE_LIST_EAR", label: "US EAR" },
        { value: "US_MUNITIONS_LIST_ITAR", label: "US ITAR" },
        { value: "ES_DUAL_USE_LIST", label: "ES DU" },
        { value: "ES_MUNITION_LIST", label: "ES ML" },
        { value: "UK_DUAL_USE_LIST", label: "UK DU" },
        { value: "UK_MILITARY_LIST", label: "UK ML" },
      ];

      const newHeadCells = headCellsState.map((cell) => {
        if (cell.id === "Departure_country" && cell.typeFilter === "select") {
          return { ...cell, options: departureCountryOptions };
        } else if (
          cell.id === "destinationCountryName" &&
          cell.typeFilter === "select"
        ) {
          return { ...cell, options: destinationCountryOptions };
        } else if (cell.id === "status" && cell.typeFilter === "select") {
          return { ...cell, options: licenseStatusOptions };
        } else if (cell.id === "entite" && cell.typeFilter === "select") {
          return { ...cell, options: entitiesOptions }; // Ajout des options d'entité
        } else if (cell.id === "regulation" && cell.typeFilter === "select") {
          return { ...cell, options: regulationOptions }; // Ajout des options de régu
        }
        return cell;
      });

      setHeadCellsState(newHeadCells);
    }
  }, [objectLicenses?.results, rootStore.jwtStore.getEntities()]);

  const handleClose1 = () => setState({ ...state, open1: false });

  const submitDelete = async (Id?: number) => {
    await rootStore.licenseStore.submitDelete(Id);
  };
  const abregepay = (s: string) => {
    if (s === "FRANCE") return "FR";
    if (s === "Belgium") return "BE";
    if (s === "United Kingdom (UK)") return "UK";
    if (s === "Spain") return "ES";
    if (s === "Singapore") return "SG";

    if (s === "Norvège") return "NO";
    if (s === "Sweden") return "SE";
    if (s === "Netherlands") return "NL";
    if (s === "United States of America (USA)") return "US";
    if (s === "Korea, South") return "KR";
    if (s === "Taiwan") return "TW";
    return s;
  };

  const convertpay = (s: string) => {
    if (s === "FR") return i18n.t("global.countries.FR");
    if (s === "CA") return i18n.t("global.countries.CA");
    if (s === "BE") return i18n.t("global.countries.BE");
    if (s === "SG") return i18n.t("global.countries.SG");
    if (s === "UK") return i18n.t("global.countries.UK");
    if (s === "ES") return i18n.t("global.countries.SP");
    if (s === "DE") return i18n.t("global.countries.DE");

    if (s === "NE") return i18n.t("global.countries.EU1");
    if (s === "IT") return i18n.t("global.countries.IT");

    if (s === "SE") return i18n.t("global.countries.EU2");
    if (s === "NL") return i18n.t("global.countries.EU3");
    if (s === "US") return i18n.t("global.countries.US");
    if (s === "TW") return i18n.t("global.countries.TW");
    if (s === "KR") return i18n.t("global.countries.KR");
    if (s === "US") return i18n.t("global.countries.KR");

    return s;
  };

  const selection_pay = (s: string) => {
    var options = document.querySelectorAll("#regulation option");
    const sb = document.getElementById("regulation") as HTMLSelectElement;

    options.forEach((o) => o.remove());

    const option_tmp1 = new Option(i18n.t("pages.new-flow.select-value"), "");
    sb.options.add(option_tmp1, undefined);

    regulation.map((option, index) => {
      if (
        option.label.substring(0, 2) == "EU" &&
        (s == "BE" || s == "NL" || s == "SE" || s == "SG" || s == "IT")
      ) {
        const option_tmp = new Option(option.label, option.value);
        if (sb) {
          sb.options.add(option_tmp, undefined);
        }
      }
    });

    regulation.map((option, index) => {
      if (
        option.label.substring(0, 2) == s ||
        option.label.substring(0, 2) == "US" ||
        s == "AL"
      ) {
        const option_tmp = new Option(option.label, option.value);
        if (sb) {
          sb.options.add(option_tmp, undefined);
        }
      }
    });
    setState({ ...state, departureCountry: s });
    searchLicense();
  };

  useEffect(() => {
    //todo refaire keycloack
    // if (
    //   rootStore.userStore.user?.departure_country ===
    //   "United States of America (USA)"
    // ) {
    //   selection_pay("USA");
    // }
    searchLicense();
  }, []);

  const searchLicense = async () => {
    setLoading(true);

    let params = `tenantId=${rootStore.jwtStore.mainTenant}`;

    const licensesFetched = (await api.getLicensesDashboard(
      params
    )) as SearchLicenseDto;

    if (licensesFetched) {
      setLicenses(licensesFetched);

      setLoading(false);
    }

    let departurecode = state.departureCountry || "";
    if (departurecode === "AL") departurecode = "All";

    api.AddLog(
      rootStore.jwtStore.mainTenant || "",
      rootStore.jwtStore.jwt?.email || "",
      "", // rootStore.userStore.user?.role || "",
      "Licence list",
      departurecode,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
  };

  type LicenseTypeGr = any;

  let groupedLicenses: { [key: string]: LicenseTypeGr[] } = {};

  // Fonction pour obtenir le numéro de base d'une licence
  const getBaseLicenseNumber = (licenseNumber: string) => {
    if (!licenseNumber) return "";
    const parts = licenseNumber.split(" ");
    return parts.slice(0, 2).join(" ");
  };
  const getLastVersionOfGroupedLicenses = (data: LicenseTypeGr[]) => {
    let groupedLicenses: { [key: string]: LicenseTypeGr[] } = {};
    data.forEach((license) => {
      const baseLicenseNumber = getBaseLicenseNumber(license.licenseNumber);
      if (license.status === "DELETED") return;

      const groupKey = baseLicenseNumber || `single-${license.id}`;

      if (!groupedLicenses[groupKey]) {
        groupedLicenses[groupKey] = [];
      }
      groupedLicenses[groupKey].push(license);
    });

    let lastVersionLicenses: LicenseTypeGr[] = [];
    Object.values(groupedLicenses).forEach((group) => {
      if (group.length > 1 && group[0].licenseNumber) {
        group.sort((a, b) => {
          const versionA = parseFloat(a.licenseNumber.split("/")[1]);
          const versionB = parseFloat(b.licenseNumber.split("/")[1]);
          return versionB - versionA;
        });
      }
      const [latestLicense, ...otherVersions] = group;
      latestLicense.historicVersions = otherVersions;

      lastVersionLicenses.push(latestLicense);
    });

    return lastVersionLicenses;
  };

  const lastVersionLicenses = getLastVersionOfGroupedLicenses(
    objectLicenses?.results || []
  );

  const getLicenseStatusValue = (license: any) => {
    const today = moment();

    if (license.licenseType == "EU001_AUTOR_GENERAL") {
      return i18n.t(`global.license-status.ACTIVE`);
    }
    if (!license.expirationDate) {
      return i18n.t(`global.license-status.PENDING`);
    }
    const expirationDate = moment(license.expirationDate);
    if (expirationDate.isBefore(today)) {
      return i18n.t(`global.license-status.EXPIRED`);
    } else if (expirationDate.diff(today, "months") < 6) {
      return i18n.t(`global.license-status.RENEW`);
    } else {
      return i18n.t(`global.license-status.${license.status.toUpperCase()}`);
    }
  };

  const transformedData = lastVersionLicenses
    ?.map((license) => {
      /** Todo put in backend */
      // const userDepartureCountry = rootStore.userStore.user?.departure_country;
      const userDepartureCountry = null;
      const departureCountryId = license.departureCountryId;

      if (userDepartureCountry) {
        if (userDepartureCountry === "France") {
          if (
            departureCountryId !== state.departureCountry &&
            state.departureCountry !== "AL"
          ) {
            return null;
          }
        } else if (userDepartureCountry === "United States of America (USA)") {
          if (departureCountryId !== "US") {
            return null;
          }
        } else {
          const abbreDepartureCountry = abregepay(userDepartureCountry);
          if (
            departureCountryId !== "US" &&
            departureCountryId !== abbreDepartureCountry
          ) {
            return null;
          }
        }
      }

      if (license.status === "DELETED") return null;

      const transformedLicense = {
        ...license,
        Departure_country: convertpay(departureCountryId),
        expirationDate: license.expirationDate
          ? moment(license.expirationDate).format("YYYY-MM-DD")
          : null,
        status: getLicenseStatusValue(license),
      };
      return transformedLicense;
    })
    .filter((license): license is LicenseTypeGr => license != null); // Filtrer les entrées null

  const handleSearchTextChange =
    (column: keyof RowData) =>
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setFilterText({ ...filterText, [column]: event.target.value as string });
    };

  const handleExpandClick = (rowId: string) => {
    // ou rowId: number
    setExpandHistoric((prevExpandHistoric) => ({
      ...prevExpandHistoric,
      [rowId]: !prevExpandHistoric[rowId],
    }));
  };
  const handleSelectChange =
    (column: keyof RowData) => (event: SelectChangeEvent<string>) => {
      setFilterText({ ...filterText, [column]: event.target.value });
    };
  const sortedRows = (transformedData || []).sort((a, b) => {
    const safeCompare = (value1: any, value2: any) => {
      if (value1 == null) value1 = "";
      if (value2 == null) value2 = "";

      return value1.localeCompare(value2);
    };

    switch (orderBy) {
      case "Departure_country":
        return order === "asc"
          ? safeCompare(a.Departure_country, b.Departure_country)
          : safeCompare(b.Departure_country, a.Departure_country);
      case "regulation":
        return order === "asc"
          ? safeCompare(a.regulation, b.regulation)
          : safeCompare(b.regulation, a.regulation);
      case "destinationCountryName":
        return order === "asc"
          ? safeCompare(a.destinationCountryName, b.destinationCountryName)
          : safeCompare(b.destinationCountryName, a.destinationCountryName);
      case "licenseNumber":
        return order === "asc"
          ? safeCompare(a.licenseNumber, b.licenseNumber)
          : safeCompare(b.licenseNumber, a.licenseNumber);
      case "companyName":
        return order === "asc"
          ? safeCompare(a.companyName, b.companyName)
          : safeCompare(b.companyName, a.companyName);
      case "status":
        return order === "asc"
          ? safeCompare(a.status, b.status)
          : safeCompare(b.status, a.status);
      default:
        return 0;
    }
  });

  const handleRequestSort = (property: keyof RowData) => {
    setOrderBy((currentOrderBy) => {
      const isAsc = currentOrderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      return property;
    });
  };

  const toggleRow = (id: number) => {
    setOpenRowIds((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };

  const renderFilterInput = (headCell: HeadCell) => {
    const inputStyle = {
      height: "35px",
      fontSize: "12px",
      margin: "0 !important",
      padding: "0 !important",
    };
    if (headCell.typeFilter === "select" && headCell.options) {
      const sortedOptions = headCell.options.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
      return (
        <FormControl
          fullWidth
          variant="outlined"
          size="small"
          margin="none"
          style={inputStyle}
        >
          <Select
            value={filterText[headCell.id] || ""}
            onChange={handleSelectChange(headCell.id)}
            style={inputStyle}
          >
            <MenuItem value="">
              <em>{i18n.t(`pages.license.search.all`)}</em>
            </MenuItem>

            {sortedOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      return (
        <FormControl
          variant="outlined"
          fullWidth
          margin="none"
          style={inputStyle}
        >
          <OutlinedInput
            type="text"
            style={inputStyle}
            value={filterText[headCell.id] || ""}
            onChange={handleSearchTextChange(headCell.id)}
          />
        </FormControl>
      );
    }
  };

  const getFilteredData = <T extends { [key: string]: any }>(
    data: T[],
    filters: { [K in keyof T]?: string }
  ): T[] => {
    return data.filter((item: T) => {
      return Object.entries(filters).every(([key, value]) => {
        if (!value) return true;

        const itemValue = item[key as keyof T];

        const valueString = String(itemValue).toLowerCase();

        return valueString.includes(value.toLowerCase());
      });
    });
  };

  const filteredData = getFilteredData(transformedData, filterText);

  /** Custom render */
  const renderRegulation = (license: any) => {
    return i18n.t(`global.regulation-minimized.${license.regulation}`);
  };
  const renderExpirationDate = (license: any) => {
    const today = moment();

    if (
      license.licenseType === "EU001_AUTOR_GENERAL" ||
      ((license.regulation === "US_DUAL_USE_LIST_EAR" ||
        license.regulation === "US_MUNITIONS_LIST_ITAR") &&
        license.departureCountryId === "US")
    ) {
      return i18n.t(`global.license-status.N/A`);
    } else if (license.expirationDate) {
      return moment(license.expirationDate)
        .locale(i18n.language)
        .format(i18n.language === "fr" ? "D MMM YYYY" : "MMM D, YYYY");
    } else {
      return (
        <b>
          <p style={{ color: "#FFA500" }}>
            {i18n.t(`global.license-status.PENDING`)}
          </p>
        </b>
      );
    }
  };

  const renderStatus = (license: any) => {
    const today = moment();

    if (license.licenseType === "EU001_AUTOR_GENERAL") {
      return (
        <b>
          <span style={{ color: "green" }}>
            {i18n.t(`global.license-status.ACTIVE`)}
          </span>
        </b>
      );
    }
    if (!license.expirationDate) {
      return (
        <b>
          <span style={{ color: "#FFA500" }}>
            {i18n.t(`global.license-status.PENDING`)}
          </span>
        </b>
      );
    }

    const expirationDate = moment(license.expirationDate);
    if (isChildLicense(license.id)) {
      return (
        <b>
          <span style={{ color: "red" }}>
            {i18n.t(`global.license-status.ABROG`)}
          </span>
        </b>
      );
    }
    if (expirationDate.isBefore(today)) {
      return (
        <b>
          <span style={{ color: "red" }}>
            {i18n.t(`global.license-status.EXPIRED`)}
          </span>
        </b>
      );
    } else if (expirationDate.diff(today, "months") < 6) {
      return (
        <b>
          <span style={{ color: "orange" }}>
            {i18n.t(`global.license-status.RENEW`)}
          </span>
        </b>
      );
    } else {
      return (
        <b>
          <span style={{ color: "green" }}>
            {i18n.t(`global.license-status.ACTIVE`)}
          </span>
        </b>
      );
    }
  };

  //La licence fait elle partie d'un historique ?
  const isChildLicense = (id: number) => {
    for (const license of transformedData) {
      if (
        license.historicVersions?.some(
          (historicLicense: any) => historicLicense.id === id
        )
      ) {
        return true;
      }
    }
    return false;
  };

  const renderActions = (license: any) => {
    const baseLicenseNumber = getBaseLicenseNumber(license.licenseNumber);
    const isGrouped =
      groupedLicenses[baseLicenseNumber] &&
      groupedLicenses[baseLicenseNumber].length > 1;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <IconButton
          style={{
            visibility: isChildLicense(license.id) ? "hidden" : "visible",
          }}
          title={i18n.t("global.license-status.bwrite")}
          onClick={() =>
            license.status &&
            license.id &&
            navigate(`/license/${license.id}/edit`)
          }
        >
          <EditIcon />
        </IconButton>

        <IconButton
          sx={{
            border:
              license.historicVersions && license.historicVersions.length > 0
                ? "2px solid"
                : "",
          }}
          style={{
            visibility: !(
              license.historicVersions && license.historicVersions.length > 0
            )
              ? "hidden"
              : "visible",
          }}
          onClick={() => handleExpandClick(license.id.toString())}
        >
          <Tooltip title={i18n.t(`pages.license.search.history`)}>
            {expandHistoric[license.id] ? <Remove /> : <Add />}
          </Tooltip>
        </IconButton>
        <IconButton
          onClick={() => {
            setState({ ...state, desId: license.id, open1: true });
          }}
        >
          <Tooltip title="Supprimer">
            <DeleteIcon style={{ color: "#e74c3c" }} />
          </Tooltip>
        </IconButton>
      </div>
    );
  };
  const renderTableLicence = () => (
    <TableContainer>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            {/* Header column */}
            {headCellsState
              .filter(
                (headCell) =>
                  !(
                    rootStore.jwtStore.getEntities().length === 0 &&
                    headCell.id === "entite"
                  )
              )
              .map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sx={{
                    width: headCell.id === "regulation" ? "30px" : "auto",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    paddingLeft: "8px !important",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                    ":last-child": {
                      borderRight: "none",
                    },
                  }}
                >
                  {/* Logique de visibilité (à compléter si nécessaire) */}
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleRequestSort(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
          </TableRow>
          <TableRow>
            {/* Contrôles de filtre */}
            {headCellsState
              .filter(
                (headCell) =>
                  !(
                    rootStore.jwtStore.getEntities().length === 0 &&
                    headCell.id === "entite"
                  )
              )
              .map((headCell) => (
                <TableCell
                  key={headCell.id + "-filter"}
                  sx={{
                    margin: 0,
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                    ":last-child": {
                      borderRight: "none",
                    },
                    paddingY: " 0px !important",
                    paddingLeft: "8px !important",
                    paddingRight: "8px !important",
                  }}
                >
                  {headCell.showFilter ? renderFilterInput(headCell) : null}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((license, index) => {
            const isRowOpen = openRowIds.includes(license.id);
            const baseLicenseNumber = getBaseLicenseNumber(
              license.licenseNumber
            );
            const rowColor = index % 2 === 0 ? "#F6FBFC" : "white"; // ou une autre couleur pour les lignes paires

            return (
              <React.Fragment key={license.id || index}>
                <TableRow
                  key={license.id || index}
                  style={{ backgroundColor: rowColor }}
                >
                  <TableCell
                    sx={{
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                      paddingLeft: "8px !important",
                      paddingY: "0px !important",
                      width: "50px",
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={() => toggleRow(license.id)}
                    >
                      {isRowOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "10%",

                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                      paddingLeft: "8px !important",
                      paddingY: "0px !important",
                    }}
                  >
                    {license.Departure_country || ""}
                  </TableCell>
                  {rootStore.jwtStore.getEntities().length >= 1 && (
                    <TableCell
                      sx={{
                        width: "10%",
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                        paddingLeft: "8px !important",
                        paddingY: "0px !important",
                      }}
                    >
                      {" "}
                      {rootStore.jwtStore.mainTenant == "ALL"
                        ? license.entite
                        : rootStore.jwtStore.mainTenant}
                    </TableCell>
                  )}
                  <TableCell
                    sx={{
                      width: "auto",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                      paddingLeft: "8px !important",
                      paddingY: "0px !important",
                    }}
                  >
                    {renderRegulation(license)}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "10%",

                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                      paddingLeft: "8px !important",
                      paddingY: "0px !important",
                    }}
                  >
                    {license.destinationCountryName || ""}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "10%",

                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                      paddingLeft: "8px !important",
                      paddingY: "0px !important",
                    }}
                  >
                    {license.licenseNumber || ""}
                  </TableCell>
                  <TableCell
                    sx={{
                      maxWidth: 150,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                      paddingLeft: "8px !important",
                      paddingY: "0px !important",
                    }}
                  >
                    {license.companyName || ""}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "10%",

                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                      paddingLeft: "8px !important",
                      paddingY: "0px !important",
                    }}
                  >
                    {renderExpirationDate(license)}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "7%",

                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                      paddingLeft: "8px !important",
                      paddingY: "0px !important",
                    }}
                  >
                    {renderStatus(license)}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "7%",

                      paddingLeft: "8px !important",
                      paddingY: "0px !important",
                    }}
                  >
                    {renderActions(license)}
                  </TableCell>
                </TableRow>
                <RenderTableEquipment
                  key={`equipment-${license.id}`}
                  rowData={license}
                  isOpen={isRowOpen}
                  regulation={license.regulation}
                />

                {license.historicVersions.map(
                  (historicLicense: any, histIndex: any) => {
                    const isHistoricRowOpen = openRowIds.includes(
                      historicLicense.id
                    );
                    if (expandHistoric[license.id]) {
                      return (
                        <React.Fragment
                          key={`historic-${historicLicense.id || histIndex}`}
                        >
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={10}
                            >
                              <Collapse
                                in={expandHistoric[license.id]}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Box>
                                  <Table size="small" className="historicRow">
                                    <TableBody>
                                      <TableRow
                                        sx={{
                                          backgroundColor: "#f5f5f5 !important",
                                        }}
                                      >
                                        <TableCell
                                          sx={{
                                            borderRight:
                                              "1px solid rgba(224, 224, 224, 1)",
                                            paddingLeft: "8px !important",
                                            paddingY: "0px !important",
                                            width: "50px",
                                          }}
                                        >
                                          <IconButton
                                            size="small"
                                            onClick={() =>
                                              toggleRow(historicLicense.id)
                                            }
                                          >
                                            {isRowOpen ? (
                                              <ExpandLessIcon />
                                            ) : (
                                              <ExpandMoreIcon />
                                            )}
                                          </IconButton>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "10%",
                                            borderRight:
                                              "1px solid rgba(224, 224, 224, 1)",
                                            paddingLeft: "8px !important",
                                            paddingY: "0px !important",
                                          }}
                                        >
                                          {convertpay(
                                            historicLicense.departureCountryId
                                          )}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "10%",

                                            borderRight:
                                              "1px solid rgba(224, 224, 224, 1)",
                                            paddingLeft: "8px !important",
                                            paddingY: "0px !important",
                                          }}
                                        >
                                          {rootStore.jwtStore.mainTenant ==
                                          "ALL"
                                            ? historicLicense.entite
                                            : rootStore.jwtStore.mainTenant}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "75px",

                                            borderRight:
                                              "1px solid rgba(224, 224, 224, 1)",
                                            paddingLeft: "8px !important",
                                            paddingY: "0px !important",
                                          }}
                                        >
                                          {renderRegulation(historicLicense)}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "10%",

                                            borderRight:
                                              "1px solid rgba(224, 224, 224, 1)",
                                            paddingLeft: "8px !important",
                                            paddingY: "0px !important",
                                          }}
                                        >
                                          {historicLicense.destinationCountryName ||
                                            ""}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "10%",

                                            borderRight:
                                              "1px solid rgba(224, 224, 224, 1)",
                                            paddingLeft: "8px !important",
                                            paddingY: "0px !important",
                                          }}
                                        >
                                          {historicLicense.licenseNumber || ""}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            maxWidth: 150,
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            borderRight:
                                              "1px solid rgba(224, 224, 224, 1)",
                                            paddingLeft: "8px !important",
                                            paddingY: "0px !important",
                                          }}
                                        >
                                          {historicLicense.companyName || ""}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "10%",

                                            borderRight:
                                              "1px solid rgba(224, 224, 224, 1)",
                                            paddingLeft: "8px !important",
                                            paddingY: "0px !important",
                                          }}
                                        >
                                          {renderExpirationDate(
                                            historicLicense
                                          )}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "7%",

                                            borderRight:
                                              "1px solid rgba(224, 224, 224, 1)",
                                            paddingLeft: "8px !important",
                                            paddingY: "0px !important",
                                          }}
                                        >
                                          {renderStatus(historicLicense)}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "7%",

                                            paddingLeft: "8px !important",
                                            paddingY: "0px !important",
                                          }}
                                        >
                                          {renderActions(historicLicense)}
                                        </TableCell>
                                      </TableRow>
                                      <RenderTableEquipment
                                        rowData={historicLicense}
                                        isOpen={isHistoricRowOpen}
                                        regulation={license.regulation}
                                      />
                                    </TableBody>
                                  </Table>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    }
                  }
                )}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"LICENSE_MNGT"} />

      <HeaderLicense pages="SEARCH" rootStore={rootStore} />

      {loading && <Loader />}

      <div className={styles.body}>
        <Typography
          variant="h2"
          sx={{ fontSize: 34, fontWeight: "medium", margin: "15px" }}
        >
          {i18n.t("pages.license.menu.search")}
        </Typography>
        <div>{renderTableLicence()}</div>
      </div>
      <Dialog
        open={state.open1}
        title="Suppression Licence"
        onClose={handleClose1}
      >
        <DialogContent>
          La licence va etre supprimée ainsi que ses données.
          <br />
          Etes-vous vraiment sûr ?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Annuler</Button>
          <Button
            onClick={() => {
              submitDelete(state.desId);
              handleClose1();
            }}
          >
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default inject("rootStore")(observer(PageTableLicence));
