/* Global import */
import { action, makeObservable, observable } from "mobx";
import { createBrowserHistory } from "history";

/* Custom import */
import Api from "../utils/api";
import i18n from "i18next";
import axios from "axios";

export default class FlowStore {
  rootStore: import("./rootStore").default;

  api: Api;

  /**
   * Flow exportatility
   */
  @observable flowExportability?: ResponseSubmitFlow;
  @observable entite: string = "";

  constructor(rootStore: import("./rootStore").default) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.api = new Api();
  }

  @action
  setEntite(entite: string) {
    this.entite = entite;
  }

  // async getById(id: number) {
  //   try {
  //     const flow = await this.api.getFlowById(id);

  //     return flow;
  //   } catch (e) {}
  // }

  async deleteFlow(params: number) {
    try {
      return await this.api.FlowDelete(params);
    } catch (e) {}
  }

  // async searchFlow(params: SearchFlowParams) {
  //   try {
  //     return await this.api.searchFlow(params);
  //   } catch (e) {}
  // }

  async saveFlow(flow: FormFlowDto) {
    try {
      const flowId = await this.api.saveFlow(flow);

      return flowId;
    } catch (e) {}
  }

  async submitFlow(flow: FormFlowDto) {
    let history = createBrowserHistory();

    //   const results = await this.api.submitFlow(flow);

    const results: ResponseSubmitFlow = {
      departureCountryName: "",
      destinationCountryName: "",
      customerCountryScreeningDetails: [],
      flowId: 0,
      generalScreeningDetails: [],
      globalTrafficLightColor: "GREEN", // Remplacez par une valeur par défaut valide si nécessaire
      itemTypeName: "",
      neededLicences: [],
      optionalItemsNationalLicenses: [],
      optionalItemsUsEarLicenses: [],
      optionalItemsUsItarLicenses: [],
      consigneesScreeningDetails: [], // Optionnel selon votre interface
    };

    if (flow.productType !== "EQUIPMENT") this.flowExportability = results;
    else {
      this.flowExportability = results;
      /** CDC Flux */
      let nationalEccn1: ClassificationEccn | undefined = undefined;
      let usEccn1: ClassificationEccn | undefined = undefined;
      let eccns = [];
      let useccns: string[];
      useccns = [];

      //********************************** */
      this.flowExportability = results;
      this.flowExportability.generalScreeningDetails = [];
      let n = 0;
      if (flow.equipments !== undefined) {
        const response = await axios.post(
          `${process.env.REACT_APP_SCREEN}/checkCountryFlux`,
          {
            equipements: flow.equipments,
            departure_country: flow.departureCountry,
            destination_country: flow.endUserCountry,
            userid: this.rootStore.jwtStore.jwt?.email,
            tenantid: this.rootStore.jwtStore.mainTenant,
            lang: i18n.language,
          }
        );
        if (!response.data) {
          throw new Error("La réponse du réseau n'est pas correcte");
        }

        const data = response.data;

        // Vérifier si la réponse contient les données attendues

        this.rootStore.exportabilityStore.screeningCountryResult = data;

        /*      
       if (flow.equipments !== undefined) {
        for (const equipment of flow.equipments) {
      const updatedScreeningDetails1 =
            this.rootStore.exportabilityStore.screeningCountryResult?.screeningDetails.map(
              (screeningDetail, index) => {
                screeningDetail.keywork = equipment.orderLine;

                if (screeningDetail.exportabilityRule !== "RULE_SANCTIONED") {
                  if (
                    (!equipment.model ||
                      screeningDetail.eccnCode.indexOf(
                        equipment.model || ""
                      ) === -1) &&
                    !screeningDetail.eccnCode.includes("Sanctions")
                  )
                    if (
                      screeningDetail.usOrigin ||
                      useccns.includes(screeningDetail.eccnCode)
                    )
                      return {
                        ...screeningDetail,
                        eccnCode:
                          equipment.model +
                          "    -     " +
                          i18n.t("pages.exportability.codeflowus") +
                          screeningDetail.eccnCode, //equipment.usEccn,
                      };
                    else
                      return {
                        ...screeningDetail,
                        eccnCode:
                          equipment.model +
                          "    -     " +
                          i18n.t("pages.exportability.codeflowfr") +
                          equipment.nationalEccn +
                          "  " +
                          screeningDetail.eccnCode
                            .replace(
                              "Réglementation française",
                              equipment.nationalEccn!
                            )
                            .replace(
                              "French regulation",
                              equipment.nationalEccn!
                            )
                            .replace("Russia HS code not in 833/2014", "")
                            .replace("Russia HS code in 833/2014", "")
                            .replace("Russia HS code Annex XL", ""), // equipment.nationalEccn,
                      };
                  else return screeningDetail;
                } else {
                  return screeningDetail;
                }
              }
            );

          if (
            this.rootStore.exportabilityStore.screeningCountryResult
              ?.screeningDetails !== undefined
          )
            this.rootStore.exportabilityStore.screeningCountryResult.screeningDetails =
              updatedScreeningDetails1!;

          this.flowExportability.generalScreeningDetails =
            this.flowExportability.generalScreeningDetails.concat(
              this.rootStore.exportabilityStore.screeningCountryResult
                ?.screeningDetails!
            );
        }
        
        }

*/

        //********************************** */

        this.flowExportability.generalScreeningDetails =
          this.rootStore.exportabilityStore.screeningCountryResult
            ?.screeningDetails!;
        //console.log(this.flowExportability.generalScreeningDetails)
        /************ajouter titre Corporate policy */
        const updatedScreeningDetails =
          this.flowExportability.generalScreeningDetails.map(
            (screeningDetail, index) => {
              if (screeningDetail.exportabilityRule === "RULE_SANCTIONED") {
                return { ...screeningDetail, eccnCode: "Corporate policy" };
              } else {
                return screeningDetail;
              }
            }
          );

        this.flowExportability.generalScreeningDetails =
          updatedScreeningDetails;

        //***************************** */

        //**********************filtrer  */
        const uniqueCorporatePolicyDetails =
          this.flowExportability.generalScreeningDetails.filter(
            (screeningDetail, index, array) => {
              if (screeningDetail.eccnCode !== "Corporate policy") {
                return true;
              } else {
                return (
                  array.findIndex(
                    (detail) => detail.eccnCode === "Corporate policy"
                  ) === index
                );
              }
            }
          );
        this.flowExportability.generalScreeningDetails =
          uniqueCorporatePolicyDetails;

        const uniqueCorporatePolicyDetails2 =
          this.flowExportability.generalScreeningDetails.filter(
            (screeningDetail, index, array) => {
              if (screeningDetail.eccnCode.substr(0, 8) !== "Sanction") {
                return true;
              } else {
                return (
                  array.findIndex(
                    (detail) => detail.eccnCode.substr(0, 8) === "Sanction"
                  ) === index
                );
              }
            }
          );

        this.flowExportability.generalScreeningDetails =
          uniqueCorporatePolicyDetails2;

        const uniqueCorporatePolicyDetails3 =
          this.flowExportability.generalScreeningDetails.filter(
            (screeningDetail, index, array) => {
              if (!screeningDetail.eccnCode.includes(" Sanction")) {
                return true;
              } else {
                return (
                  array.findIndex((detail) =>
                    detail.eccnCode.includes(" Sanction")
                  ) === index
                );
              }
            }
          );

        this.flowExportability.generalScreeningDetails =
          uniqueCorporatePolicyDetails3;

        this.flowExportability.departureCountryName =
          this.rootStore.exportabilityStore.screeningCountryResult
            ?.departureCountry.name!;
        this.flowExportability.destinationCountryName =
          this.rootStore.exportabilityStore.screeningCountryResult
            ?.countryOfDestination.name!;

        this.flowExportability.globalTrafficLightColor = "GREEN";
        let max2 = this.flowExportability.generalScreeningDetails.length;
        for (var index = 0; index < max2; index++) {
          if (
            this.flowExportability.generalScreeningDetails[index]
              .trafficLightColor == "RED"
          ) {
            this.flowExportability.globalTrafficLightColor = "RED";
          }
          if (
            this.flowExportability.generalScreeningDetails[index]
              .trafficLightColor == "YELLOW"
          ) {
            if (this.flowExportability.globalTrafficLightColor == "GREEN")
              this.flowExportability.globalTrafficLightColor = "YELLOW";
          }
        }
      }
    }
    return results.flowId;
  }
}
