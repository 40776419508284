import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import axios from "axios";
import { CardHeader, Typography } from "@material-ui/core";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
import {
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { LRegulation, regulation, regulation_rep } from "../../utils/constant";
import { Footer } from "../../customComponents/footer";
import { SelectCountry } from "../../customComponents/selectCountry";
import { useParams, useNavigate } from "react-router-dom";
import {
  convertCountryIdToCode,
  convertCountryCodeToId,
  generateLicenseTypeOptionsModern,
  getRegutationByCountry,
  convertEntityNameToId,
  convertCountryIdToLabel,
  getSimpleLicenseTypeOptionsByRegulation,
} from "../../utils/generator";
import AppBar from "../../components/appBar";
import Api from "../../utils/api";
import { flow, toJS } from "mobx";
import {
  Delete,
  Search as Magnifier,
  Save,
  PostAdd,
  ArrowLeft,
  PictureAsPdf,
  UploadFile,
  Refresh,
} from "@mui/icons-material";

import { ConfirmDialog } from "../../customComponents/confirmDialog";
import { SnackbarMsg } from "../../customComponents/snackbarMsg";

import HeaderReportMenu from "../../components/headerReportMenu";
import SemesterSelect from "../../customComponents/selectSemester/selectSemester";

interface Props extends InjectedProps {}
interface InjectedProps {
  rootStore: import("../../stores/rootStore").default;
}
interface CountryType {
  id?: number;
  value: string;
  label: string;
  code?: string;
  phone?: string;
  suggested?: boolean;
}

type SnackbarProps = {
  message: string;
  severity: "success" | "error" | "info" | "warning";
  position?:
    | "top"
    | "bottom"
    | "top-left"
    | "top-right"
    | "bottom-left"
    | "bottom-right"
    | "center";
  size?: "small" | "medium" | "large" | "auto";
  autoHideDuration?: number;
  onClose?: () => void;
};
const Reporting: React.FC<Props> = ({ rootStore }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const api = new Api();
  const [openModal, setOpenModal] = useState(false);
  // const [beginDate, setBeginDate] = useState<Date>();
  // const [finalDate, setFinalDate] = useState<Date>();
  const [countries, setCountries] = useState<Country[]>([]);
  const [countriesDestination, setCountriesDestination] = useState<Country[]>(
    []
  );
  const [entity, setEntite] = useState<string>(
    rootStore.licenseStore.entite !== "ALL" ? rootStore.licenseStore.entite : ""
  );
  const [flows, setFlows] = useState<any>(null);
  const [filtereds, setFilteredFlows] = useState<any>(null);
  const [flowToDisplay, setFlowToDisplay] = useState<any>(null);
  const goBackTofilters = () => {
    setFlows(null);
    setFlowToDisplay(null);
    setFilteredFlows(null);
  };
  const leftButtons = [
    {
      id: "back",
      onClick: () => goBackTofilters(),
      label: i18n.t("global.previous"),
      primary: false,
      // disabled: !activeBackButton(),
      bg: "#0085ad",
      icon: ArrowLeft,
      iconPosition: "left",
    },
  ];
  const rightButtons = [
    {
      id: "export",
      onClick: () => exportFunction(),
      label: i18n.t("global.export"),
      primary: true,
      disabled: !flows || flows.length <= 0,
      bg: "#0085ad",
      icon: PostAdd,
      iconPosition: "left",
    },
  ];
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState<SnackbarProps>({
    message: "",
    severity: "success",
    position: "top",
    size: "medium",
    autoHideDuration: 3000,
  });
  const currentYear = new Date().getFullYear();

  const showSnackbar = (props: SnackbarProps) => {
    setSnackbarProps({
      ...props,
      onClose: () => setOpenSnackbar(false),
    });
    setOpenSnackbar(true);
  };
  const languageToCountryId: Record<string, number> = {
    fr: 63, // France
    en: 191, // Anglais
    es: 168, // Espagne
    it: 86, // Italie
    de: 67, // Allemagne
  };
  useEffect(() => {
    const fetchData = async () => {
      await rootStore.countriesStore.getCountries();
      await rootStore.countriesStore.setCountryTable();
      rootStore.countriesStore.getCountriesDeparture();
      try {
        if (countries.length === 0) {
          const countriesResponse = await axios.get(
            `${process.env.REACT_APP_SCREEN}/countries_departure`
          );
          await rootStore.countriesStore.getCountries();
          setCountries(countriesResponse.data);
          setCountriesDestination(toJS(rootStore.countriesStore.countryTable));
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    fetchData();
  }, []);

  const exportFunction = async () => {
    const response = await api.exportReportings(
      flowToDisplay ? flowToDisplay : flows,
      codeMsg
    );
  };
  const getLicenseTypeOptions = (r: any) => {
    switch (r) {
      case "US_DUAL_USE_LIST_EAR":
        return generateLicenseTypeOptionsModern(["INDIVIDUAL"]);
      case "US_MUNITIONS_LIST_ITAR":
        return generateLicenseTypeOptionsModern([
          "DSP5_PERMANENT",
          "DSP73_TEMPORARY",
          "WDA_WAREHOUSE_AGREEMENT",
        ]);
      case "EU_DUAL_USE_LIST":
      case "FR_ARRETE_DU_31_JUILLET_2014_HEL":
      case "FR_LIST_DOUBLE_USAGE":
        return generateLicenseTypeOptionsModern([
          "INDIVIDUAL",
          "GLOBAL",
          "EU001_AUTOR_GENERAL",
          "PERMANENT",
        ]);
      case "FR_MUNITIONS_LIST":
        return generateLicenseTypeOptionsModern([
          "INDIVIDUAL",
          "GLOBAL",
          "LGT",
          "DEROGATION",
        ]);
      case "FR_IMPORTATION":
        return generateLicenseTypeOptionsModern([
          "AIPE",
          "AIMG",
          "AITMG",
          "AGIMG",
        ]);
      case "DE_DUAL_USE_GUTER":
      case "DE_MUNITIONS_LIST":
      case "CA_GROUP_1_DU":
      case "CA_GROUP_2_ML":
        return generateLicenseTypeOptionsModern([
          "INDIVIDUAL",
          "GLOBAL",
          "COLLECTIVE",
        ]);
      case "UK_DUAL_USE_LIST":
      case "UK_MILITARY_LIST":
        return generateLicenseTypeOptionsModern([
          "GLOBAL_OGEL",
          "INDIVIDUAL_UK",
          "TEMPORARY_UK",
        ]);
      case "ES_DUAL_USE_LIST":
      case "ES_MUNITION_LIST":
      case "EU_MUNITIONS_LIST":
        if (r !== "NL") {
          return generateLicenseTypeOptionsModern([
            "GLOBAL",
            "IMPORT",
            "INDIVIDUAL",
            "TEMPORARY",
          ]);
        }
        break;
      case "EU_MUNITIONS_LIST":
        if (r === "NL") {
          return generateLicenseTypeOptionsModern([
            "INDIVIDUAL",
            "GLOBAL",
            "NL005_AUTOR_GENERAL",
            "NL006_AUTOR_GENERAL",
          ]);
        }
        break;
      default:
        return null;
    }
  };
  type MsgObjectType = {
    code_msg: number;
    Regle: string;
    Message: string;
    Message_fr: string;
    Lib_url1: string | null;
    Url1: string | null;
    Lib_url2: string | null;
    Url2: string | null;
    Lib_url3: string | null;
    Url3: string | null;
    short_en: string | null;
    short_fr: string | null;
  };
  const [dpCountry, setDpCountry] = useState<string | number>(
    rootStore.jwtStore.getDefaultDepartureCountryCode()
  );
  const [codeMsg, setCodeMsg] = useState<string>("");
  const [msgObject, setMsgObject] = useState<MsgObjectType>({
    code_msg: 1,
    Regle: "Test",
    Message: "Ceci est un test de message.",
    Message_fr: "",
    Lib_url1: "Lien 1",
    Url1: "https://example.com",
    Lib_url2: "Lien 2",
    Url2: "https://example.com",
    Lib_url3: null,
    Url3: null,
    short_en: null,
    short_fr: null,
  });
  const [dtCountry, setDtCountry] = useState<string | number>();
  const [eu001, setEu001] = useState<boolean>();
  const [lType, setLType] = useState<string>();
  const [pickedRegulation, setPickedRegulation] = useState<
    string | LRegulation[]
  >("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setCodeMsg("OTHER");
    if (dpCountry === "FR" && pickedRegulation === "FR_MUNITIONS_LIST") {
      setCodeMsg("EXP069");
    }
    if (dpCountry === "FR" && pickedRegulation === "FR_LIST_DOUBLE_USAGE") {
      setCodeMsg("EXP073");
    }
    if (dpCountry === "SE") {
      setCodeMsg("EXP100");
    }
    if (dpCountry === "GB") {
      if (pickedRegulation === "UK_DUAL_USE_LIST") {
        setCodeMsg("EXP101");
      }
      if (pickedRegulation === "UK_MILITARY_LIST") {
        setCodeMsg("EXP102");
      }
    }
  }, [pickedRegulation, dpCountry]);
  const [startDate, setStartDate] = useState<Date | undefined | null>(
    undefined
  );
  const [endDate, setEndDate] = useState<Date | undefined | null>(undefined);
  useEffect(() => {
    const currentLanguage = i18n.language;
    const countryId = languageToCountryId[currentLanguage];

    if (countryId && dpCountry !== countryId) {
      setDpCountry(
        convertCountryIdToCode(
          countryId,
          toJS(rootStore.countriesStore.countryTable)
        )
      );
    }
  }, [countries]);
  useEffect(() => {
    function filterFlowsByDateRange() {
      if (!startDate && !endDate) {
        setFlowToDisplay(flows);
        return;
      }

      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;

      const filteredFlows = flows?.filter(
        (flow: {
          flow_equipment: {
            delivery_date: string | number | Date;
            confirmation_date: string | number | Date;
          };
        }) => {
          const deliveryDate = flow.flow_equipment.delivery_date
            ? new Date(flow.flow_equipment.delivery_date)
            : null;
          const confirmationDate = flow.flow_equipment.confirmation_date
            ? new Date(flow.flow_equipment.confirmation_date)
            : null;

          // Vérifie si la delivery date ou la confirmation date est dans l'intervalle
          const isWithinDateRange =
            (start &&
              deliveryDate &&
              deliveryDate >= start &&
              (!end || deliveryDate <= end)) ||
            (start &&
              confirmationDate &&
              confirmationDate >= start &&
              (!end || confirmationDate <= end));

          return isWithinDateRange;
        }
      );

      setFlowToDisplay(filteredFlows);
    }

    filterFlowsByDateRange();
  }, [startDate, endDate, flows]);

  const resetDateFilters = () => {
    setFlowToDisplay(flows);
    handleSemesterChange(
      new Date(`${currentYear - 100}-01-01`),
      new Date(`${currentYear + 100}-06-30`),
      "Sélectionnez un semestre"
    );
  };

  useEffect(() => {
    function filterFlowsBySemester() {
      if (!startDate || !endDate) {
        setFlowToDisplay(flows);
        return;
      }
      const start = new Date(startDate);
      const end = new Date(endDate);

      const filteredFlows = flows?.filter(
        (flow: {
          flow_equipment: {
            delivery_date: string | number | Date;
            confirmation_date: string | number | Date;
          };
        }) => {
          const deliveryDate = flow.flow_equipment.delivery_date
            ? new Date(flow.flow_equipment.delivery_date)
            : null;
          const confirmationDate = flow.flow_equipment.confirmation_date
            ? new Date(flow.flow_equipment.confirmation_date)
            : null;

          // Vérifie si la delivery date ou confirmation date est dans l'intervalle
          const isWithinSemester =
            (deliveryDate &&
              deliveryDate >= startDate &&
              deliveryDate <= endDate) ||
            (confirmationDate &&
              confirmationDate >= startDate &&
              confirmationDate <= endDate);

          return isWithinSemester;
        }
      );

      setFlowToDisplay(filteredFlows);
    }

    filterFlowsBySemester();
  }, [endDate, startDate, flows]);

  const handleChangeDpCountry = (selectedCountry: CountryType | null) => {
    setDpCountry(selectedCountry ? selectedCountry.value : "");
  };
  const handleChangeDtCountry = (selectedCountry: CountryType | null) => {
    setDtCountry(selectedCountry ? selectedCountry.value : "");
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#0085ad",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "5px",
      // margin: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const choiceDeparture = () => {
    return (
      <SelectCountry
        onChangeInherited={handleChangeDpCountry}
        selected={dpCountry}
        required
        size="small"
        width="100%"
        countries={countries as CountryType[]}
        label={i18n.t("pages.exportability.departure-country")}
      />
    );
  };

  const choiceRegulation = () => {
    return (
      <>
        <Typography variant="subtitle1">
          {i18n.t("pages.license.add.regulation")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {regulation_rep
            .filter((option) =>
              getRegutationByCountry(
                convertCountryCodeToId(
                  dpCountry as string,
                  toJS(rootStore.countriesStore.countryTable)
                ),
                "reporting"
              ).includes(option.value)
            )
            .map((option) => (
              <Box
                key={option.value}
                onClick={() => setPickedRegulation(option.value)}
                sx={{
                  padding: "10px 20px",
                  border: "2px solid",
                  borderColor:
                    pickedRegulation === option.value
                      ? "#0085ad"
                      : "transparent",
                  borderRadius: 1,
                  cursor: "pointer",
                  backgroundColor:
                    pickedRegulation === option.value
                      ? "#e0f2f7" // Bleu clair pour le fond
                      : "grey.100",
                  "&:hover": {
                    backgroundColor: "#b3e0f2", // Intensification du survol
                  },
                  transition: "background-color 0.2s, border-color 0.2s",
                }}
              >
                <Typography>{option.label}</Typography>
              </Box>
            ))}
        </Box>
      </>
    );
  };

  const choiceType = () => {
    const licenseOptions = getSimpleLicenseTypeOptionsByRegulation(
      pickedRegulation,
      dpCountry
    );
    return (
      <>
        <Typography variant="subtitle1">
          {i18n.t("pages.license.add.license-type")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {licenseOptions !== undefined && licenseOptions !== null ? (
            licenseOptions?.map((option: any) => (
              <Box
                key={option.value}
                onClick={() => setLType(option.value)}
                sx={{
                  padding: "10px 20px",
                  border: "2px solid",
                  borderColor:
                    lType === option.value ? "#0085ad" : "transparent",
                  borderRadius: 1,
                  cursor: "pointer",
                  backgroundColor:
                    lType === option.value ? "#e0f2f7" : "grey.100",
                  "&:hover": {
                    backgroundColor: "#b3e0f2", // Intensification du survol
                  },
                  transition: "background-color 0.2s, border-color 0.2s",
                }}
              >
                <Typography>{option.label || "Untitled"}</Typography>
              </Box>
            ))
          ) : (
            <Typography variant="body2">{i18n.t("global.noMatch")}</Typography>
          )}
        </Box>
      </>
    );
  };

  const choiceExporter = () => {
    return (
      <>
        <InputLabel id="exporter-label">
          {i18n.t("pages.license.add.exporter")}
        </InputLabel>
        <Select
          size="small"
          fullWidth
          labelId="exporter-label"
          value={entity || ""}
          variant="standard"
          onChange={(e) => {
            setEntite(e.target.value);
          }}
          label={i18n.t("pages.licence.add.exporter")}
        >
          <MenuItem value="">{i18n.t("global.select")}</MenuItem>
          {
            /* {rootStore.userStore.user?.entite === "ALL"
            ? rootStore.tenantStore.entities.map((entite, index) => (
                <MenuItem key={index} value={entite.id_entite || ""}>
                  {entite.id_entite}
                </MenuItem>
              ))
            : rootStore.tenantStore.entities
                .filter(
                  (entite) =>
                    entite.id_entite === rootStore.userStore.user?.entite
                )
                .map((entite, index) => (
                  <MenuItem key={index} value={entite.id_entite || ""}>
                    {entite.id_entite}
                  </MenuItem>
                ))} */
            rootStore.jwtStore.entities.map((entite, index) => (
              <MenuItem key={index} value={entite.id_entite || ""}>
                {entite.id_entite}
              </MenuItem>
            ))
          }
        </Select>
      </>
    );
  };

  const regulMessage = (msg: MsgObjectType) => {
    return (
      <Box>
        <Box
          sx={{ typography: "body1" }}
          dangerouslySetInnerHTML={{ __html: msg?.Message }}
        />{" "}
        <Box sx={{ mt: 2, display: "flex", gap: 3, flexWrap: "wrap" }}>
          {msg?.Url1 && (
            <Link href={msg?.Url1} target="_blank" rel="noopener noreferrer">
              {msg?.Lib_url1}
            </Link>
          )}
          {msg?.Url2 && (
            <Link href={msg?.Url2} target="_blank" rel="noopener noreferrer">
              {msg.Lib_url2}
            </Link>
          )}
          {msg?.Url3 && (
            <Link href={msg?.Url3} target="_blank" rel="noopener noreferrer">
              {msg?.Lib_url3}
            </Link>
          )}
        </Box>
      </Box>
    );
  };

  const tableFlows = () => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>N° de commande</StyledTableCell>
              <StyledTableCell>Produit</StyledTableCell>
              <StyledTableCell>Date de confirmation</StyledTableCell>
              <StyledTableCell>Date de livraison</StyledTableCell>
              <StyledTableCell>Pays départ</StyledTableCell>
              <StyledTableCell>Pays destination</StyledTableCell>
              <StyledTableCell>Client</StyledTableCell>
              <StyledTableCell>Entité</StyledTableCell>
              <StyledTableCell>DU/ML</StyledTableCell>
              <StyledTableCell>EAR/ITAR</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {flowToDisplay?.map((item: { flow: any; flow_equipment: any }) => {
              const { flow, flow_equipment: equip } = item;
              return (
                <StyledTableRow key={equip?.id}>
                  <StyledTableCell>{flow?.order_num}</StyledTableCell>
                  <StyledTableCell>{equip?.model}</StyledTableCell>
                  <StyledTableCell>
                    {equip?.confirmation_date
                      ? new Date(equip?.confirmation_date).toLocaleDateString()
                      : "N/A"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {equip?.delivery_date
                      ? new Date(equip?.delivery_date).toLocaleDateString()
                      : "N/A"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {convertCountryIdToLabel(
                      flow?.departure_country_id,
                      toJS(rootStore.countriesStore.countryTable)
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {convertCountryIdToLabel(
                      flow?.destination_country_id,
                      toJS(rootStore.countriesStore.countryTable)
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{flow?.customer}</StyledTableCell>
                  <StyledTableCell>{flow?.entity_id}</StyledTableCell>
                  <StyledTableCell>
                    {flow?.has_ml_eccn
                      ? "ML"
                      : flow?.has_du_eccn
                        ? "DU"
                        : "N/A"}
                  </StyledTableCell>{" "}
                  <StyledTableCell>
                    {flow?.has_ear_eccn
                      ? "EAR"
                      : flow?.has_itar_eccn
                        ? "ITAR"
                        : "N/A"}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const handlePreview = async () => {
    setLoading(true);
    try {
      console.log(
        convertCountryCodeToId(
          dpCountry as string,
          toJS(rootStore.countriesStore.countryTable)
        ),
        pickedRegulation,
        lType,
        convertEntityNameToId(entity, toJS(rootStore.tenantStore.entities)),
        codeMsg,
        rootStore.jwtStore.mainTenant
      );
      const response = await api.getReportings(
        convertCountryCodeToId(
          dpCountry as string,
          toJS(rootStore.countriesStore.countryTable)
        ),
        pickedRegulation,
        lType,
        convertEntityNameToId(entity, toJS(rootStore.tenantStore.entities)),
        codeMsg,
        rootStore.jwtStore.mainTenant
      );
      setLoading(false);
      setFlows(response.data);
      setMsgObject(response.objectMsg[0]);
      setFlowToDisplay(response.data);
    } catch (error) {
      setLoading(false);
      console.error("Erreur lors de l'appel API pour le preview :", error);
    }
  };
  const [selectedSemesterLabel, setSelectedSemesterLabel] = useState<string>(
    "Sélectionnez un semestre"
  );

  const handleSemesterChange = (start: Date, end: Date, label: string) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    setStartDate(startDate);
    setEndDate(endDate);
    setSelectedSemesterLabel(label);
  };
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        marginBottom: "10%",
      }}
    >
      {openSnackbar && (
        <SnackbarMsg
          message={snackbarProps.message}
          severity={snackbarProps.severity}
          position={snackbarProps.position}
          size={snackbarProps.size}
          autoHideDuration={snackbarProps.autoHideDuration}
          onClose={snackbarProps.onClose}
        />
      )}
      <AppBar selectedPage="ADMIN" rootStore={rootStore} />
      <HeaderReportMenu pages="REPORTING" rootStore={rootStore} />

      <Box flexGrow={1} overflow="auto">
        {flows === null ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={4}
            padding={3}
            marginTop={3}
          >
            <Box width="35%">{choiceDeparture()}</Box>
            <Box width="35%">{choiceRegulation()}</Box>
            <Box width="35%">{choiceType()}</Box>
            <Box width="35%">
              {rootStore.tenantStore.entities.length !== 0
                ? choiceExporter()
                : ""}
            </Box>
            <Box width="20%">
              <Button
                onClick={handlePreview}
                variant="contained"
                color="primary"
                disabled={loading}
                fullWidth
                key={loading ? "loading" : "idle"}
                sx={{ marginTop: 2 }}
              >
                {loading
                  ? i18n.t("pages.reporting.loading")
                  : i18n.t("pages.reporting.seeResults")}
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={4}
            padding={3}
            marginTop={3}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap={4}
              padding={3}
              marginTop={3}
              width="100%"
            >
              <Box>{regulMessage(msgObject)}</Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                gap={4}
                padding={3}
                marginTop={3}
                width="100%"
              >
                <SemesterSelect
                  // label={selectedSemesterLabel}
                  onChange={(start: Date, end: Date, label: string) =>
                    handleSemesterChange(start, end, label)
                  }
                />
                <TextField
                  sx={{ width: "15%" }}
                  id="begin-date"
                  label={i18n.t("pages.reporting.dateFrom")}
                  type="date"
                  value={startDate ? startDate.toISOString().split("T")[0] : ""}
                  onChange={(e) => {
                    const inputDate = new Date(e.target.value);
                    if (!isNaN(inputDate.getTime())) {
                      setStartDate(inputDate); // Met à jour seulement si la date est valide
                    } else {
                      console.error(
                        "Date invalide entrée pour begin-date :",
                        e.target.value
                      );
                      setStartDate(null); // Ou toute autre gestion pour une date invalide
                    }
                  }}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />

                <TextField
                  sx={{ width: "15%" }}
                  id="final-date"
                  label={i18n.t("pages.reporting.dateEnd")}
                  type="date"
                  value={endDate ? endDate.toISOString().split("T")[0] : ""}
                  onChange={(e) => {
                    const inputDate = new Date(e.target.value);
                    if (!isNaN(inputDate.getTime())) {
                      setEndDate(inputDate); // Met à jour seulement si la date est valide
                    } else {
                      console.error(
                        "Date invalide entrée pour final-date :",
                        e.target.value
                      );
                      setEndDate(null); // Ou toute autre gestion pour une date invalide
                    }
                  }}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />

                <IconButton
                  onClick={resetDateFilters}
                  aria-label="vider les champs"
                >
                  <Refresh />
                </IconButton>
              </Box>
            </Box>
            {tableFlows()}
          </Box>
        )}
      </Box>

      <Footer
        leftButtons={leftButtons}
        rightButtons={rightButtons}
        height="medium"
      />

      <ConfirmDialog
        open={openModal}
        confirmLabel={i18n.t("pages.license.add.licenseLineConfirm")}
        cancelLabel={i18n.t("pages.license.add.licenseLineCancel")}
        dialogText={i18n.t("pages.license.add.licenseModalTitle")}
        size="medium"
        confirmButtonColor="primary"
        cancelButtonColor="primary"
        confirmButtonVariant="contained"
        cancelButtonVariant="outlined"
      />
    </Container>
  );
};

export default inject("rootStore")(observer(Reporting));
