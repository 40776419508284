import React from "react";
import {
  Box,
  Select,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import i18n from "i18next";

type SemesterSelectProps = {
  label?: string;
  onChange: (start: Date, end: Date, label: string) => void;
  disabled?: boolean;
  value?: string;
};

const SemesterSelect: React.FC<SemesterSelectProps> = ({
  label,
  onChange,
  disabled = false,
  value = i18n.t("pages.reporting.all"),
}) => {
  const currentYear = new Date().getFullYear();

  const generateSemesterOptions = () => {
    return [
      {
        label: i18n.t("pages.reporting.all"),
        start: new Date(`${currentYear - 100}-01-01`),
        end: new Date(`${currentYear + 100}-06-30`),
      },
      {
        label: `S1 ${currentYear - 1}`,
        start: new Date(`${currentYear - 1}-01-01`),
        end: new Date(`${currentYear - 1}-06-30`),
      },
      {
        label: `S2 ${currentYear - 1}`,
        start: new Date(`${currentYear - 1}-07-01`),
        end: new Date(`${currentYear - 1}-12-31`),
      },
      {
        label: `S1 ${currentYear}`,
        start: new Date(`${currentYear}-01-01`),
        end: new Date(`${currentYear}-06-30`),
      },
      {
        label: `S2 ${currentYear}`,
        start: new Date(`${currentYear}-07-01`),
        end: new Date(`${currentYear}-12-31`),
      },
      {
        label: `S1 ${currentYear + 1}`,
        start: new Date(`${currentYear + 1}-01-01`),
        end: new Date(`${currentYear + 1}-06-30`),
      },
      {
        label: `S2 ${currentYear + 1}`,
        start: new Date(`${currentYear + 1}-07-01`),
        end: new Date(`${currentYear + 1}-12-31`),
      },
    ];
  };

  const [selectedSemester, setSelectedSemester] = React.useState<string>(
    value as string
  );

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const selectedLabel = event.target.value;
    setSelectedSemester(selectedLabel);

    if (selectedLabel === "") {
      // Option neutre sélectionnée
      onChange(
        new Date(`${currentYear - 100}-01-01`),
        new Date(`${currentYear + 100}-06-30`),
        i18n.t("pages.reporting.all")
      );
    } else {
      const selectedOption = generateSemesterOptions().find(
        (option) => option.label === selectedLabel
      );

      if (selectedOption) {
        const startDateString = selectedOption.start;
        // .toISOString()
        // .split("T")[0];
        const endDateString = selectedOption.end; //.toISOString().split("T")[0];
        onChange(startDateString, endDateString, selectedLabel);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {label && (
        <Typography variant="subtitle1" gutterBottom>
          {label}
        </Typography>
      )}
      <FormControl fullWidth>
        <InputLabel id="semester-select-label" shrink>
          {i18n.t("pages.reporting.semester")}
        </InputLabel>
        <Select
          labelId="semester-select-label"
          id="semester-select"
          value={selectedSemester}
          onChange={handleSelectChange}
          displayEmpty
          disabled={disabled}
          sx={{ minWidth: 250 }}
          label={i18n.t("pages.reporting.semester")}
        >
          {/* <MenuItem value="">
            <em>Aucun semestre sélectionné</em>
          </MenuItem> */}
          {generateSemesterOptions().map((option, index) => (
            <MenuItem key={index} value={option.label}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SemesterSelect;
