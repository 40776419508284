import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  FormGroup,
  IconButton,
  TextInput,
  Button,
  Loading,
} from "../../components/horizon-components-react/src/components";
import { observer, inject } from "mobx-react";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import VpnKeyIcon from "@material-ui/icons/VpnKey"; // Nouvelle icône pour mot de passe oublié
import AccountCircleIcon from "@material-ui/icons/AccountCircle"; // icône SSO
import i18n from "i18next";
import styles from "./login.module.css";
import axios from "axios";
import configuration from "../../configuration";
import { log } from "console";
import Api from "../../utils/api";

interface Props {
  rootStore: import("../../stores/rootStore").default;
}
interface State {
  username: string;
  password: string;
  showPassword: boolean;
}

const Login: React.FC<Props> = ({ rootStore }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const api = new Api();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    rootStore.authStore.setLoading(true);

    if (username && password) {
      rootStore.authStore.login(username, password);
    }
  };

  const handleClick = async (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (username) {
      rootStore.authStore.setLoading(true);
      try {
        await api.resetPassword(username, password);
        rootStore.authStore.setSuccess(
          "Un mail vous à été envoyé pour changer votre mot de passe."
        );
      } catch (error) {
        rootStore.authStore.setError(error as string);
      }
      rootStore.authStore.setLoading(false);
    } else {
      rootStore.authStore.setError("Veuillez saisir votre email.");
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      rootStore.authStore.sso(code);
    }

    if (rootStore.authStore.checkIsLogged()) {
      navigate("/");
    }
  }, [navigate]);
  return (
    <div className={styles.container}>
      <Card className={styles.cardLogin}>
        <CardHeader title={i18n.t("pages.login.title")} />

        <CardContent>
          <form id="login-form" onSubmit={onSubmit}>
            <FormGroup>
              <TextInput
                id="username"
                label={i18n.t("pages.login.username")}
                value={username}
                autoComplete="username"
                required={true}
                errorText={i18n.t("errors.username-not-defined")}
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <div className={styles.passwordContainer}>
                <TextInput
                  id="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  label={i18n.t("pages.login.password")}
                  value={password}
                  required={true}
                  errorText={i18n.t("errors.password-not-defined")}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <IconButton
                  className={styles.togglePassword}
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </div>
            </FormGroup>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}
            >
              <p
                onClick={handleClick}
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <VpnKeyIcon className={styles.icon} />
                {" " + i18n.t("pages.login.passwordforgot") + " ?"}
              </p>
            </div>

            {/* Feedback Section */}
            <div className={styles.feedbackContainer}>
              {rootStore.authStore.getError() != "" && (
                <div className={`${styles.feedback} ${styles.error}`}>
                  <p>{rootStore.authStore.getError()}</p>
                </div>
              )}
              {rootStore.authStore.getSuccess() != "" && (
                <div className={`${styles.feedback} ${styles.success}`}>
                  <p>{rootStore.authStore.getSuccess()}</p>
                </div>
              )}
            </div>

            {/* Submit Button */}
            <div className={styles.buttonContainer}>
              {rootStore.authStore.loading ? (
                <Loading />
              ) : (
                <Button
                  id="button"
                  type="submit"
                  className={`${styles.moreDetails} ${styles.actionButton}`}
                >
                  {i18n.t("pages.login.signin")}
                </Button>
              )}
            </div>
          </form>

          {/* Additional Actions */}
        </CardContent>
      </Card>
    </div>
  );
};

export default inject("rootStore")(observer(Login));
