/* Global import */
import { action, makeObservable, observable, runInAction } from "mobx";

/* Custom import */
import Api from "../utils/api";
import configuration from "../configuration";
import axios from "axios";
interface Entity {
  id: number;
  id_entite: string;
  id_tenant: string;
}

export default class TenantStore {
  rootStore: import("./rootStore").default;
  api: Api;

  @observable tenants: TenantItemReceived[] = [];
  @observable entities: Entity[] = [];
  @observable datalog: [] = [];
  @observable datalogrep: [] = [];
  @observable loading: boolean = false;
  @observable questionsResponse: any;
  @observable tenantAhData: any;
  @observable tenanttempleteData: any;

  constructor(rootStore: import("./rootStore").default) {
    makeObservable(this);
    this.api = new Api();
    this.rootStore = rootStore;
  }
  @action async getLogreplication() {
    this.loading = true;

    try {
      this.datalogrep = await this.api.getLogreplication();
    } catch (e) {}

    this.loading = false;
  }
  @action async getLog(annee: string) {
    this.loading = true;

    try {
      this.datalog = await this.api.getLog(annee);
    } catch (e) {}

    this.loading = false;
  }

  @action async getTenantAhData() {
    const response = await axios.get(
      `${configuration.LARAVEL_API}/resources/tenants?name=${encodeURIComponent(this.rootStore.jwtStore.mainTenant! || "")}`
    );
    this.tenantAhData = response.data;
  }

  @action async getTenanttempleteData() {
    const response = await axios.get(
      `${configuration.LARAVEL_API}/getTenanttemplete?name=${encodeURIComponent(this.rootStore.jwtStore.mainTenant! || "")}`
    );
    this.tenanttempleteData = response.data;
  }
  @action
  async getQuestionsResponse() {
    this.loading = true;

    try {
      const response = await axios.get(
        `${configuration.LARAVEL_API}/get_Question?tenant=${encodeURIComponent(this.rootStore.jwtStore.mainTenant || "")}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
          },
        }
      );
      runInAction(() => {
        this.questionsResponse = response.data;
        this.loading = false;
      });
    } catch (e) {
      runInAction(() => {
        this.loading = false;
        console.error("Failed to fetch questions", e);
      });
    }
  }

  @action async getLogTenant() {
    this.loading = true;

    try {
      this.datalog = await this.api.getLogTenant(
        this.rootStore.jwtStore.mainTenant!
      );
    } catch (e) {}

    this.loading = false;
  }
}
