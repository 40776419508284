/* Styles import */
import styles from "./entity.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  Title,
  TextInput,
  GridCol,
  GridRow,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
// eslint-disable-next-line
import { useNavigate, useParams } from "react-router-dom";

/* Custom import */
import AppBar from "../../../components/appBar";
import Footer from "../../../components/footer";
import AutoComplete from "../../../components/autoComplete";
import Loader from "../../../components/loader";
import { flowUrl } from "../../../utils/generator";
import HeaderScreeningComponent from "../../../components/headerScreening";
import TimeLine from "../../../components/timeLine";
import Api from "../../../utils/api";

interface State {
  company?: string;
  country?: number;
  nationalId?: string;
  flowId?: string;
}

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
const Screening: React.FC<Props> = ({ rootStore }) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const { id } = useParams();

  const [state, setState] = useState({
    company: "",
    country: rootStore.exportabilityStore.countryOfDestination,
    nationalId: "",
    flowId: "",
  });
  const api = new Api();
  useEffect(() => {
    let isMounted = true;

    if (id) {
      (async () => {
        const flow = await api.newGetFlowById(id);

        if (flow && isMounted) {
          setState({ ...state, company: flow.customer });
          setState({ ...state, country: flow.destination_country_id });
          setState({ ...state, flowId: id });
        }
      })();
    }

    rootStore.countriesStore.getCountries();
    return () => {
      isMounted = false;
    };
  }, [rootStore.flowStore, rootStore.countriesStore]);

  const submitIsDisabled = () => {
    return !state.company || !state.country;
  };

  const handleSubmit = async () => {
    const { company, country, nationalId } = state;

    if (company && country) {
      rootStore.screeningStore.setCompany(company);
      rootStore.screeningStore.setCountry(country);
      await rootStore.screeningStore.getEntities(company, country, nationalId);
      navigate(flowUrl("/screening/entity/choose-entity", id));
    }
  };

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"SCREENING"} />

      <HeaderScreeningComponent
        pages="ENTITY"
        type="CLASSIC"
        rootStore={rootStore}
      />

      {rootStore.screeningStore.entitiesState === "PENDING" && <Loader />}

      <div className={styles.image}></div>

      <div className={styles.body}>
        <Title tag="h2" className={styles.pStyles}>
          {i18n.t("pages.screening.entity.title")}
        </Title>

        <form className={styles.form}>
          <div className={styles.row}>
            <div className={styles.input}>
              <TextInput
                id="company"
                type="text"
                label={i18n.t("pages.screening.entity.company")}
                value={state.company || ""}
                required={true}
                errorText={i18n.t("errors.company-not-defined")}
                onChange={(value) =>
                  setState({ ...state, company: value.target.value })
                }
              />
            </div>

            <div className={`${styles.input} ${styles.autoComplete} `}>
              <AutoComplete
                id="country"
                label={i18n.t("pages.screening.entity.country")}
                required={true}
                options={rootStore.countriesStore.countriesToOptions}
                errorText={i18n.t("errors.country-not-defined")}
                value={state.country || ""}
                onChange={(country) => setState({ ...state, country })}
              />
            </div>
          </div>

          <div className={styles.input}>
            <TextInput
              id="id"
              type="text"
              label={i18n.t("pages.screening.entity.id")}
              value={state.nationalId || ""}
              onChange={(value) =>
                setState({ ...state, nationalId: value.target.value })
              }
            />
          </div>
        </form>
      </div>

      <Footer
        buttons={[
          {
            id: "previous",
            onClick: () => goBack(),
            label: i18n.t("global.previous"),
          },
          {
            id: "submit",
            onClick: () => handleSubmit(),
            label: i18n.t("global.submit"),
            primary: true,
            disabled: submitIsDisabled(),
          },
        ]}
      >
        {state.flowId && (
          <GridRow>
            <GridCol md={12} xs={12} alignSelf="center">
              <TimeLine step={2} />
            </GridCol>
          </GridRow>
        )}
      </Footer>
    </div>
  );
};

export default inject("rootStore")(observer(Screening));
