/* Styles import */
import styles from "./newFlow.module.css";

/* Global import */
import React, { Component } from "react";
import {
  Title,
  TextInput,
  Button,
  FormGroup,
  GridContainer,
  GridRow,
  GridCol,
} from "../../components/horizon-components-react/src/components";
import { FormLabel } from "@material-ui/core";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
// eslint-disable-next-line
import { useNavigate, useParams } from "react-router-dom";

/* Custom import */
import AppBar from "../../components/appBar";
import Footer from "../../components/footer";
import AutoComplete from "../../components/autoComplete";
import Loader from "../../components/loader";
import { generateFlowId } from "../../utils/generator";
import HeaderFlow from "../../components/headerFlow/headerFlow";
import { flowType, productType } from "../../utils/constant";
import Api from "../../utils/api";

interface Props extends InjectedProps {}

interface InjectedProps {
  rootStore: import("../../stores/rootStore").default;
}

interface State {
  fid?: number;
  sapId?: string;
  flowType?: FlowType;
  customerCompagny?: string;
  departureCountry?: number;
  flowConsignees: Consignee[];
  endUserCountry?: number;
  productType?: ProductType;
  modelOptions: string[];
  model?: string;
  quantity?: number;
  supplier?: string;
  partNumber?: string;
  designation?: string;
  nationalEccn?: string;
  usEccn?: string;
  equipments: Equipment[];
  loading: boolean;
  types: string[];
  delivery_date: string;
  order_num: string;
  status?: FlowStatus;
}
type EquipmentKey =
  | "equipmentType"
  | "model"
  | "quantity"
  | "partNumber"
  | "designation"
  | "nationalEccn"
  | "usEccn"
  | "supplier";

@inject("rootStore")
@observer
export default class NewFlowArchived extends Component<Props, State> {
  navigate: any;
  api: any;

  constructor(props: Props) {
    super(props);
    this.api = new Api();
    this.navigate = useNavigate();
    this.state = {
      flowConsignees: [
        {
          consigneeName: "",
          consigneeCountryId: 0,
        },
      ],
      modelOptions: [],
      equipments: [
        {
          modelOptions: [],
        },
      ],
      loading: false,
      types: [],
      order_num: "",
      delivery_date: "",
    };
  }

  UNSAFE_componentWillMount() {
    this.props.rootStore.countriesStore.getCountriesDeparture();
  }
  async componentDidMount() {
    const { id } = useParams();
    this.props.rootStore.countriesStore.getCountries();

    if (id) {
      this.getFlow(parseInt(id));
    }
  }
  // eslint-disable-next-line
  async UNSAFE_componentWillReceiveProps(props: Props) {
    const { id } = useParams();
    const flowId = id;

    if (flowId) {
      this.getFlow(parseInt(flowId));
    } else {
      this.setState({
        fid: undefined,
        sapId: undefined,
        flowType: "NEW_SALE",
        customerCompagny: "",
        departureCountry: undefined,
        flowConsignees: [{ consigneeName: "", consigneeCountryId: 0 }],
        endUserCountry: undefined,
        productType: "NONE",
        modelOptions: [],
        model: "",
        quantity: 0,
        partNumber: "",
        designation: "",
        nationalEccn: "",
        usEccn: "",
        equipments: [
          {
            equipmentType: "NONE",
            model: "",
            modelOptions: [],
            quantity: 0,
            partNumber: "",
            designation: "",
            nationalEccn: "",
            usEccn: "",
          },
        ],
        loading: false,
        types: [],
      });
    }
  }
  async getFlow(fid: number) {
    this.setState({ loading: true });

    const flow = await this.api.newGetFlowById(fid);

    if (flow) {
      const types = await this.props.rootStore.classificationStore.getTypes(
        flow.itemType
      );
      let modelOptions: string[] | undefined = [];

      if (flow.itemType !== "EQUIPMENT") {
        modelOptions = await this.props.rootStore.classificationStore.getModels(
          flow.itemType
        );
      }

      const equipments = await Promise.all(
        flow.flowEquipments.map(async (equipment: Equipment) => {
          const equipmentModelOptions =
            await this.props.rootStore.classificationStore.getOptionModels(
              equipment.equipmentType || "",
              flow.itemType
            );

          equipment.modelOptions = equipmentModelOptions || [];
          return equipment;
        })
      );

      this.setState({
        fid,
        sapId: flow.sapId,
        flowType: flow.flowType,
        supplier: flow.supplier,
        customerCompagny: flow.customer,
        departureCountry: flow.departureCountryId,
        endUserCountry: flow.destinationCountryId,
        flowConsignees:
          flow.flowConsignees.length > 0
            ? flow.flowConsignees
            : [{ consigneeName: "", consigneeCountryId: 0 }],
        productType: flow.itemType,
        model: flow.flowAircraft ? flow.flowAircraft.model : undefined,
        quantity: flow.flowAircraft ? flow.flowAircraft.quantity : undefined,
        partNumber: flow.flowAircraft
          ? flow.flowAircraft.partNumber
          : undefined,
        designation: flow.flowAircraft
          ? flow.flowAircraft.designation
          : undefined,
        nationalEccn: flow.flowAircraft
          ? flow.flowAircraft.nationalEccn
          : undefined,
        usEccn: flow.flowAircraft ? flow.flowAircraft.usEccn : undefined,
        equipments:
          equipments.length > 0
            ? equipments
            : [{ quantity: 0, modelOptions: [] }],
        types: types || [],
        modelOptions: modelOptions || [],
        status: flow.status,
      });
    }

    this.setState({ loading: false });
  }

  addConsignee() {
    const { flowConsignees } = this.state;

    flowConsignees.push({
      consigneeName: "",
      consigneeCountryId: 0,
    });

    this.setState({ flowConsignees });
  }

  updateConsignee(
    index: number,
    input: "country" | "name",
    value: number | string
  ) {
    const { flowConsignees } = this.state;

    if (input === "country") {
      flowConsignees[index].consigneeCountryId = value as number;
    } else {
      flowConsignees[index].consigneeName = value as string;
    }

    this.setState({ flowConsignees });
  }

  addEquipment() {
    const equipments = this.state.equipments;

    equipments.push({
      quantity: 0,
      modelOptions: [],
    });

    this.setState({ equipments });
  }

  async updateEquipment(key: EquipmentKey, index: number, value: any) {
    const equipments = this.state.equipments;

    if (key === "equipmentType") {
      const modelOptions =
        await this.props.rootStore.classificationStore.getOptionModels(
          value,
          this.state.productType
        );

      equipments[index].modelOptions = modelOptions || [];
      equipments[index].quantity = 0;
      equipments[index].partNumber = undefined;
      equipments[index].designation = undefined;
      equipments[index].nationalEccn = undefined;
      equipments[index].usEccn = undefined;
    }

    if (key === "model" && this.state.departureCountry) {
      const eccns =
        await this.props.rootStore.classificationStore.getEccnsByModel(
          this.state.departureCountry,
          value
        );

      if (eccns) {
        equipments[index].nationalEccn = eccns.nationalClassification;
        equipments[index].usEccn = eccns.usClassification;
        if (equipments[index].quantity === 0) {
          equipments[index].quantity = 1;
        }
        equipments[index].partNumber = undefined;
        equipments[index].designation = undefined;
      }
    }

    equipments[index][key] = value;

    this.setState({ equipments });
  }

  reinitEquipment() {
    this.setState({
      model: "",
      modelOptions: [],
      quantity: 0,
      partNumber: "",
      designation: "",
      nationalEccn: "",
      usEccn: "",
      equipments: [
        {
          quantity: 0,
          partNumber: "",
          designation: "",
          nationalEccn: "",
          usEccn: "",
          modelOptions: [],
        },
      ],
    });
  }

  async handleProductType(productType: ProductType) {
    this.reinitEquipment();

    this.setState({ productType });

    const types =
      await this.props.rootStore.classificationStore.getTypes(productType);

    if (productType !== "EQUIPMENT") {
      const modelOptions =
        await this.props.rootStore.classificationStore.getModels(productType);

      this.setState({ modelOptions: modelOptions || [] });
    }

    this.setState({ types: types || [] });
  }

  async handleDepartureCountry(departureCountry: string) {
    const intDepartureCountry = parseInt(departureCountry);

    this.setState({ departureCountry: intDepartureCountry });
  }

  async handleModel(model: string) {
    const departureCountry = this.state.departureCountry;

    let newQuantity = 1;

    if (this.state.quantity) {
      if (this.state.quantity > 1) {
        newQuantity = this.state.quantity;
      }
    }

    this.setState({
      model,
      quantity: newQuantity,
      partNumber: "",
      designation: "",
    });

    if (departureCountry) {
      const modelEccn =
        await this.props.rootStore.classificationStore.getEccnsByModel(
          departureCountry,
          model
        );

      if (modelEccn) {
        this.setState({
          nationalEccn: modelEccn.nationalClassification,
          usEccn: modelEccn.usClassification,
        });
      }
    }
  }

  activeSaveButton() {
    const {
      flowType,
      customerCompagny,
      departureCountry,
      endUserCountry,
      status,
    } = this.state;

    return (
      flowType &&
      customerCompagny &&
      departureCountry &&
      endUserCountry &&
      (status === "CLASSIFICATION" || !status)
    );
  }

  cleanConsignees(consignees: Consignee[]) {
    return consignees.filter(
      (consignee) =>
        consignee.consigneeName !== "" && consignee.consigneeCountryId !== 0
    );
  }

  cleanEquipments(equipments: Equipment[]) {
    return equipments
      .filter((equipment) => equipment.quantity !== 0)
      .map((equipment) => {
        return {
          id: equipment.id,
          designation: equipment.designation,
          equipmentType: equipment.equipmentType,
          model: equipment.model,
          nationalEccn: equipment.nationalEccn,
          partNumber: equipment.partNumber,
          supplier: equipment.supplier,
          quantity: equipment.quantity,
          usEccn: equipment.usEccn,
        };
      });
  }

  async handleSave() {
    const {
      fid,
      sapId,
      flowType,
      customerCompagny,
      departureCountry,
      flowConsignees,
      endUserCountry,
      productType,
      model,
      quantity,
      partNumber,
      designation,
      nationalEccn,
      usEccn,
      equipments,
    } = this.state;

    if (flowType && customerCompagny && departureCountry && endUserCountry) {
      this.setState({ loading: true });

      const flowId = await this.props.rootStore.flowStore.saveFlow({
        id: fid,
        sapId,
        flowType,
        customerCompagny,
        departureCountry,
        endUserCountry,
        productType,
        equipments: this.cleanEquipments(equipments),
        flowConsignees: this.cleanConsignees(flowConsignees),
        model,
        quantity,
        partNumber,
        designation,
        nationalEccn,
        usEccn,
      });

      this.setState({ loading: false });

      if (flowId) {
        this.navigate({ pathname: `/flow/${flowId}/classification` });
      }
    }
  }

  async handleSubmit() {
    const {
      fid,
      sapId,
      flowType,
      customerCompagny,
      departureCountry,
      flowConsignees,
      endUserCountry,
      productType,
      model,
      quantity,
      supplier,
      partNumber,
      designation,
      nationalEccn,
      usEccn,
      equipments,
    } = this.state;

    if (
      flowType &&
      customerCompagny &&
      departureCountry &&
      endUserCountry &&
      productType
    ) {
      this.setState({ loading: true });
      const flowId = await this.props.rootStore.flowStore.submitFlow({
        id: fid,
        sapId,
        flowType,
        customerCompagny,
        departureCountry,
        endUserCountry,
        productType,
        equipments: this.cleanEquipments(equipments),
        flowConsignees: this.cleanConsignees(flowConsignees),
        model,
        quantity,
        supplier,
        partNumber,
        designation,
        nationalEccn,
        usEccn,
      });

      this.setState({ loading: false });

      if (flowId) {
        this.navigate({ pathname: `/flow/${flowId}/exportability/results` });
      }
    }
  }

  render() {
    return (
      <div>
        {this.state.loading && <Loader />}

        <AppBar rootStore={this.props.rootStore} selectedPage={"FLOW"} />

        <HeaderFlow pages="ADD" />

        <form className={styles.form}>
          <Title tag="h2">{i18n.t("pages.new-flow.title")}</Title>

          {this.renderGeneralStep()}
        </form>

        <Footer
          justify="flex-end"
          buttons={[
            {
              id: "save",
              onClick: () => this.handleSave(),
              label: i18n.t("global.save"),
              disabled: !this.activeSaveButton(),
            },
          ]}
        />
      </div>
    );
  }

  renderGeneralStep() {
    return (
      <div>
        <div className={styles.section}>
          <GridContainer>
            <Title className={styles.title} tag="h3">
              {i18n.t("pages.new-flow.general.new_title")}
            </Title>

            <GridRow>
              <GridCol md={4}>
                <AutoComplete
                  id="departure-country"
                  label={i18n.t("pages.new-flow.general.departure-country")}
                  required={true}
                  options={
                    this.props.rootStore.countriesStore
                      .countriesDepartureToOptions
                  }
                  errorText={i18n.t("errors.departure-country-not-defined")}
                  value={this.state.departureCountry || ""}
                  onChange={(e) => this.handleDepartureCountry(e)}
                />
              </GridCol>

              <GridCol md={4}>
                <AutoComplete
                  id="end-user-country"
                  label={i18n.t("pages.new-flow.general.pays_des")}
                  required={true}
                  errorText={i18n.t("errors")}
                  onChange={(value) =>
                    this.setState({ endUserCountry: value.target.value })
                  }
                  options={
                    this.props.rootStore.countriesStore.countriesToOptions
                  }
                  value={this.state.endUserCountry || ""}
                />
              </GridCol>

              <GridCol md={4} xs={12}>
                <TextInput
                  id="customer-company"
                  label={i18n.t("pages.new-flow.general.customer-compagny")}
                  value={this.state.customerCompagny || ""}
                  required={true}
                  errorText={i18n.t("errors")}
                  onChange={(value) =>
                    this.setState({ customerCompagny: value.target.value })
                  }
                />
              </GridCol>
            </GridRow>

            <GridRow>
              <GridCol md={4} xs={12} alignSelf="center">
                <FormGroup>
                  <FormLabel className={styles.flowTypeLabel} required={true}>
                    {i18n.t("pages.new-flow.general.flow-type")}
                  </FormLabel>
                  <select
                    id="flow-type"
                    value={this.state.flowType || ""}
                    required={true}
                    onChange={(e) =>
                      this.setState({ flowType: e.target.value as FlowType })
                    }
                  >
                    <option value="">
                      {i18n.t("pages.new-flow.select-value")}
                    </option>
                    {flowType.map((type, index) => {
                      return (
                        <option key={index} value={type.value || ""}>
                          {type.label}
                        </option>
                      );
                    })}
                  </select>
                </FormGroup>
              </GridCol>

              <GridCol md={4} xs={12}>
                <TextInput
                  id="order_num"
                  label={i18n.t("pages.new-flow.general.order_num")}
                  value={this.state.order_num || ""}
                  // required={ true }
                  errorText={i18n.t("errors")}
                  onChange={(value) =>
                    this.setState({ order_num: value.target.value })
                  }
                />
              </GridCol>

              <GridCol md={4} xs={12}>
                <TextInput
                  id="delivery_date"
                  label={i18n.t("pages.new-flow.general.delivery_date")}
                  value={this.state.delivery_date || ""}
                  //  required={ true }
                  errorText={i18n.t("errors")}
                  onChange={(value) =>
                    this.setState({ delivery_date: value.target.value })
                  }
                />
              </GridCol>
            </GridRow>
          </GridContainer>

          <GridContainer>
            <Title className={styles.title} tag="h3">
              {i18n.t("pages.new-flow.dossier")}
            </Title>

            <GridRow>
              <GridCol md={4} xs={12} alignSelf="center"></GridCol>
              {i18n.t("pages.new-flow.dossier")}
            </GridRow>
          </GridContainer>
        </div>
      </div>
    );
  }

  renderProductStep() {
    return (
      <div>
        <div className={styles.section}>
          <GridContainer>
            <Title className={styles.title} tag="h3">
              {i18n.t("pages.new-flow.product.title")}
            </Title>

            <GridRow>
              <GridCol md={4} xs={12} alignSelf="center">
                <FormGroup>
                  <FormLabel className={styles.flowTypeLabel} required={true}>
                    {i18n.t("pages.new-flow.product.item-type")}
                  </FormLabel>
                  <select
                    id="product-type"
                    value={this.state.productType || ""}
                    required={true}
                    onChange={(e) =>
                      this.handleProductType(e.target.value as ProductType)
                    }
                  >
                    <option>{i18n.t("pages.new-flow.select-value")}</option>
                    {productType.map((product, index) => {
                      return (
                        <option key={index} value={product.value || ""}>
                          {product.label}
                        </option>
                      );
                    })}
                  </select>
                </FormGroup>
              </GridCol>
            </GridRow>

            {(this.state.productType === "AIRCRAFT" ||
              this.state.productType === "HELICOPTER" ||
              this.state.productType === "SPACECRAFT") &&
              this.renderOtherStep()}

            {this.state.productType === "EQUIPMENT" &&
              this.renderEquipmentStep("equipment")}
          </GridContainer>
        </div>
      </div>
    );
  }

  renderEquipmentStep(key: string) {
    return (
      <div>
        {this.state.equipments.map((equipment, index) => {
          return (
            <div className={styles.equipmentBox} key={index}>
              <div className={styles.equipmentHeader}>
                {`${i18n.t(`pages.new-flow.product.${key}.label`)} ${
                  index + 1
                }`}
              </div>

              <GridRow>
                <GridCol md={3}>
                  <FormGroup>
                    <FormLabel className={styles.flowTypeLabel}>
                      {i18n.t(`pages.new-flow.product.${key}.type`)}
                    </FormLabel>
                    <select
                      id="equipment-type"
                      value={equipment.equipmentType || ""}
                      onChange={(e) =>
                        this.updateEquipment(
                          "equipmentType",
                          index,
                          e.target.value
                        )
                      }
                    >
                      <option value="">
                        {i18n.t("pages.new-flow.select-value")}
                      </option>
                      {this.state.types.map((type, index) => {
                        return (
                          <option key={index} value={type || ""}>
                            {type}
                          </option>
                        );
                      })}
                    </select>
                  </FormGroup>
                </GridCol>

                <GridCol md={3}>
                  <FormGroup>
                    <FormLabel className={styles.flowTypeLabel}>
                      {i18n.t("pages.new-flow.product.equipment.model.label")}
                    </FormLabel>
                    <select
                      id="equipment-model"
                      value={equipment.model || ""}
                      onChange={(e) =>
                        this.updateEquipment("model", index, e.target.value)
                      }
                    >
                      <option value="">
                        {i18n.t("pages.new-flow.select-value")}
                      </option>
                      {equipment.modelOptions &&
                        equipment.modelOptions.map((equipmentModel, index) => {
                          return (
                            <option key={index} value={equipmentModel || ""}>
                              {equipmentModel}
                            </option>
                          );
                        })}
                    </select>
                  </FormGroup>
                </GridCol>

                <GridCol md={3}>
                  <TextInput
                    id="equipment-quantity"
                    label={i18n.t("pages.new-flow.product.equipment.quantity")}
                    value={equipment.quantity || ""}
                    type="number"
                    min={0}
                    step={1}
                    errorText={i18n.t("errors")}
                    onChange={(value) =>
                      this.updateEquipment("quantity", index, value)
                    }
                  />
                </GridCol>

                <GridCol md={3}>
                  <TextInput
                    id="equipment-supplier"
                    label={i18n.t("pages.new-flow.product.equipment.supplier")}
                    value={equipment.supplier || ""}
                    errorText={i18n.t("errors")}
                    onChange={(value) =>
                      this.updateEquipment("supplier", index, value)
                    }
                  />
                </GridCol>
              </GridRow>

              <GridRow>
                <GridCol md={3}>
                  <TextInput
                    id="equipment-part-number"
                    label={i18n.t(
                      "pages.new-flow.product.equipment.part-number"
                    )}
                    value={equipment.partNumber || ""}
                    errorText={i18n.t("errors")}
                    onChange={(value) =>
                      this.updateEquipment("partNumber", index, value)
                    }
                  />
                </GridCol>

                <GridCol md={3}>
                  <TextInput
                    id="equipment-designation"
                    label={i18n.t(
                      "pages.new-flow.product.equipment.designation"
                    )}
                    value={equipment.designation || ""}
                    errorText={i18n.t("errors")}
                    onChange={(value) =>
                      this.updateEquipment("designation", index, value)
                    }
                  />
                </GridCol>

                <GridCol md={3}>
                  <TextInput
                    onChange={() => {}}
                    label={i18n.t(
                      "pages.new-flow.product.equipment.national-eccn"
                    )}
                    value={equipment.nationalEccn || ""}
                    disabled
                    errorText={i18n.t("errors")}
                  />
                </GridCol>

                <GridCol md={3}>
                  <TextInput
                    onChange={() => {}}
                    label={i18n.t("pages.new-flow.product.equipment.us-eccn")}
                    value={equipment.usEccn || ""}
                    disabled
                    errorText={i18n.t("errors")}
                  />
                </GridCol>
              </GridRow>
            </div>
          );
        })}

        <Button
          id="add-equipment"
          type="button"
          onClick={() => this.addEquipment()}
          size="medium"
        >
          {i18n.t(`pages.new-flow.product.${key}.add`)}
        </Button>
      </div>
    );
  }

  renderOtherStep() {
    return (
      <div>
        <GridRow>
          <GridCol md={3}>
            <FormGroup>
              <FormLabel className={styles.flowTypeLabel}>
                {i18n.t("pages.new-flow.product.equipment.model.label")}
              </FormLabel>
              <select
                id="model"
                value={this.state.model || ""}
                onChange={(e) => this.handleModel(e.target.value)}
              >
                <option></option>
                {this.state.modelOptions.map((model, index) => {
                  return (
                    <option key={index} value={model || ""}>
                      {model}
                    </option>
                  );
                })}
              </select>
            </FormGroup>
          </GridCol>

          <GridCol md={3}>
            <TextInput
              id="quantity"
              label={i18n.t("pages.new-flow.product.equipment.quantity")}
              value={this.state.quantity || ""}
              type="number"
              min={0}
              step={1}
              errorText={i18n.t("errors")}
              onChange={(value) =>
                this.setState({ quantity: parseInt(value.target.value) })
              }
            />
          </GridCol>
          <GridCol md={3}>
            <TextInput
              id="supplier"
              label={i18n.t("pages.new-flow.product.equipment.supplier")}
              value={this.state.supplier || ""}
              errorText={i18n.t("errors")}
              onChange={(value) =>
                this.setState({ supplier: value.target.value })
              }
            />
          </GridCol>
        </GridRow>

        <GridRow>
          <GridCol md={3}>
            <TextInput
              id="part-number"
              label={i18n.t("pages.new-flow.product.equipment.part-number")}
              value={this.state.partNumber || ""}
              errorText={i18n.t("errors")}
              onChange={(value) =>
                this.setState({ partNumber: value.target.value })
              }
            />
          </GridCol>

          <GridCol md={3}>
            <TextInput
              id="designation"
              label={i18n.t("pages.new-flow.product.equipment.designation")}
              value={this.state.designation || ""}
              errorText={i18n.t("errors")}
              onChange={(value) =>
                this.setState({ designation: value.target.value })
              }
            />
          </GridCol>

          <GridCol md={3}>
            <TextInput
              onChange={() => {}}
              label={i18n.t("pages.new-flow.product.equipment.national-eccn")}
              value={this.state.nationalEccn || ""}
              disabled
            />
          </GridCol>

          <GridCol md={3}>
            <TextInput
              onChange={() => {}}
              label={i18n.t("pages.new-flow.product.equipment.us-eccn")}
              value={this.state.usEccn || ""}
              disabled
            />
          </GridCol>
        </GridRow>

        <div className={styles.divider}></div>

        {this.renderEquipmentStep("optional-item")}
      </div>
    );
  }
}
