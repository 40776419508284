/* Custom Import */
import AuthStore from "./authStore";
import JwtStore from "./jwtStore";
import ClassificationStore from "./classificationStore";
import NotificationStore from "./notificationStore";
import CountriesStore from "./countriesStore";
import ScreeningStore from "./screeningStore";
import ControlReasonsStore from "./controlReasonsStore";
import ExportabilityStore from "./exportabilityStore";
import EclabsStore from "./eclabsStore";
import FlowStore from "./flowStore";
import LicenseStore from "./licenseStore";
import TenantStore from "./tenantStore";
import ModelStore from "./modelStore";
import configuration from "../configuration";
import axios from "axios";
import i18n from "i18next";
import { makeAutoObservable } from "mobx";

export default class RootStore {
  authStore: AuthStore;
  jwtStore: JwtStore;
  classificationStore: ClassificationStore;
  notificationStore: NotificationStore;
  countriesStore: CountriesStore;
  screeningStore: ScreeningStore;
  controlReasonsStore: ControlReasonsStore;
  tenantStore: TenantStore;
  exportabilityStore: ExportabilityStore;
  eclabsStore: EclabsStore;
  flowStore: FlowStore;
  licenseStore: LicenseStore;
  modelStore: ModelStore;

  constructor() {
    makeAutoObservable(this);
    this.authStore = new AuthStore(this);
    this.jwtStore = new JwtStore();
    this.classificationStore = new ClassificationStore(this);
    this.notificationStore = new NotificationStore(this);
    this.countriesStore = new CountriesStore(this);
    this.screeningStore = new ScreeningStore(this);
    this.controlReasonsStore = new ControlReasonsStore(this);
    this.exportabilityStore = new ExportabilityStore(this);
    this.eclabsStore = new EclabsStore(this);
    this.flowStore = new FlowStore(this);
    this.licenseStore = new LicenseStore(this);
    this.tenantStore = new TenantStore(this);
    this.modelStore = new ModelStore(this);

    const self = this;

    axios.interceptors.request.use(
      (config) => {
        const url = config.url ?? "";

        const access_token = localStorage.getItem("access_token") || "";
        config.headers["Authorization"] = `Bearer ${access_token}`;

        //java
        // else if (url.startsWith(configuration.API ?? "")) {
        // const token = localStorage.getItem("token") || "";
        // config.headers["authorization"] = token;

        return config;
      },
      (error) => Promise.reject(error)
    );

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          if (error.response.statut === 401) {
            self.authStore.logout();
          } else if (error.response.data && error.response.data.messages) {
            if (
              error.response.data.messages[0] !==
              "HttpMessageNotReadableException@937842805"
            )
              if (
                error.response.data.messages[0] &&
                error.response.data.messages[0].includes("@")
              ) {
                /*  self.notificationStore.setNotification(
              i18n.t('errors.server.title'),
              i18n.t('errors.server.message', { code: error.response.data.messages[0] }),
              'error'
            )
            */
              } else {
                {
                  /*  message erreur recherche par number pas d'exeception        */
                }
                {
                  /*self.notificationStore.setNotification(
              i18n.t('errors.request.title'), 
              error.response.data.messages[0] || i18n.t('errors.request.message'),
              'info'
            )*/
                }
              }
          }
        } else {
          self.notificationStore.setNotification(
            i18n.t("errors.network.title"),
            i18n.t("errors.network.message"),
            "error"
          );
        }

        return Promise.reject(error);
      }
    );
  }
}
