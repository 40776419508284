/* Styles import */
import styles from "../regulation.module.css";

/* Styles import */
import globalStyle from "../../../globalStyles.module.css";

/* Global import */
import React, { Component } from "react";
import i18n from "i18next";
// eslint-disable-next-line

/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderRegulation from "../../../components/headerRegulation";
import {
  Card,
  CardContent,
  Title,
} from "../../../components/horizon-components-react/src/components";
import ListCountry from "../../../components/listCountry";
import configuration from "../../../configuration";
import axios from "axios";

interface Props extends InjectedProps {}
interface InjectedProps {
  rootStore: import("../../../stores/rootStore").default;
}
interface State {
  data: any;
}
interface Item {
  id: number;
  url: string;
}
export default class Wassenaar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  async componentDidMount() {
    axios
      .get(`${configuration.LARAVEL_API}/getWassenaar`)
      .then((response) => response.data)
      .then((resultat) => {
        this.setState({ data: resultat });
      });
  }

  render() {
    const listAllCountries = i18n.t("pages.regulation.sanctions.list", {
      returnObjects: true,
    });

    return (
      <div>
        <AppBar rootStore={this.props.rootStore} selectedPage={"REGULATION"} />

        <HeaderRegulation pages="Wassenaar" />

        <div className={globalStyle.body}>
          <Card>
            <Title tag="h2">Wassenaar Arrangement </Title>

            <br></br>
            <div
              className="post__content"
              dangerouslySetInnerHTML={{
                __html: i18n.t("pages.wassenaar.texte"),
              }}
            ></div>

            <br></br>
            <CardContent>
              <ul className={styles.left_padding_1}>
                {this.state.data.map((item: Item) => (
                  <span>
                    <li key={item.id}>
                      {item.id == 1
                        ? "Wassenaar Arrangement website"
                        : "List of Advisory Questions for the Industry"}
                      <br></br>
                      <a target="_blank" href={item.url}>
                        {item.url}
                      </a>
                    </li>
                    <br></br>
                  </span>
                ))}
              </ul>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
}
