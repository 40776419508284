import { useState } from "react";
import {
  Card,
  CardHeader,
  Stack,
  FormControl,
  TextField,
  FormHelperText,
} from "@mui/material";
import { SelectCountry } from "../../../customComponents/selectCountry";
import React from "react";
import rootStore from "../../../stores/rootStore";
// import i18n from "../../../utils/i18n";
import i18n from "i18next";
import RootStore from "../../../stores/rootStore";
import { convertCountryIdToCode } from "../../../utils/generator";
interface CountryType {
  id?: number;
  value: string;
  label: string;
  code?: string;
  phone?: string;
  suggested?: boolean;
}
type DestinataireProps = {
  rootStore: RootStore;
  currentLicense: any;
  formErrors: any;
  countriesDestination: any;
  handleChangeCustomerCountry: (value: CountryType | null) => void;
  handleChangeFirstCountry: (value: CountryType | null) => void;
  handleChangeDtCountry: (value: CountryType | null) => void;
  handleChangeIntermediaryCountry: (value: CountryType | null) => void;
  setCurrentLicense: (value: any) => void;
};

const Destinataire = ({
  rootStore,
  currentLicense,
  formErrors,
  countriesDestination,
  handleChangeCustomerCountry,
  handleChangeFirstCountry,
  handleChangeDtCountry,
  handleChangeIntermediaryCountry,
  setCurrentLicense,
}: DestinataireProps) => {
  // Gestion du disabledByRDO
  const [disabledByRDO, setDisabledByRDO] = useState<boolean>(
    // rootStore.jwtStore.getRoles().includes("Licences")
    //license read only
    false
  );

  return (
    <Card
      variant="outlined"
      sx={{ p: 2, width: "50%" /*minHeight: "420px", maxHeight: "500px"*/ }}
    >
      <CardHeader title={i18n.t("pages.license.add.destinataire")} />
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={3}
        sx={{ marginBottom: 3 }}
        width={"100%"}
      >
        {/* Affichage du customer_destination_country_id uniquement si la régulation est FR_MUNITIONS_LIST */}
        {currentLicense.regulation === "FR_MUNITIONS_LIST" && (
          <Stack direction="row" spacing={3} width="100%">
            <SelectCountry
              id="customer_destination_country_id"
              label={i18n.t("pages.license.add.client-country-ml")}
              disabled={disabledByRDO}
              onChangeInherited={handleChangeCustomerCountry}
              size="small"
              width="50%"
              countries={countriesDestination as CountryType[]}
              selected={
                convertCountryIdToCode(
                  currentLicense.customer_destination_country_id,
                  countriesDestination
                ) || ""
              }
            />
            <TextField
              label={i18n.t("pages.license.add.client")}
              value={currentLicense.destinataire || ""}
              onChange={(e) =>
                setCurrentLicense((prevLicense: any) => ({
                  ...prevLicense,
                  destinataire: e.target.value,
                }))
              }
              variant="standard"
              fullWidth
            />
          </Stack>
        )}

        {/* Affichage du champ destinataire pour toutes les régulations sauf FR_MUNITIONS_LIST */}
        {currentLicense.regulation !== "FR_MUNITIONS_LIST" && (
          <Stack direction="column" spacing={3} width="100%">
            <TextField
              label={i18n.t("pages.license.add.client")}
              value={currentLicense.destinataire || ""}
              onChange={(e) =>
                setCurrentLicense((prevLicense: any) => ({
                  ...prevLicense,
                  destinataire: e.target.value,
                }))
              }
              variant="standard"
              fullWidth
            />
          </Stack>
        )}

        {/* Deuxième ligne : C (first_destination_country_id) - D (recipient) */}
        {currentLicense.regulation === "FR_MUNITIONS_LIST" && (
          <Stack direction="row" spacing={3} width="100%">
            <SelectCountry
              id="first_destination_country_id"
              label={i18n.t("pages.license.add.first-destination-country-ml")}
              disabled={disabledByRDO}
              onChangeInherited={handleChangeFirstCountry}
              size="small"
              width="50%"
              countries={countriesDestination as CountryType[]}
              selected={
                convertCountryIdToCode(
                  currentLicense.first_destination_country_id,
                  countriesDestination
                ) || ""
              }
            />
            <TextField
              id="first-recipient"
              label={i18n.t("pages.license.add.first-recipient")}
              value={currentLicense.recipient || ""}
              onChange={(e) =>
                setCurrentLicense((prevLicense: any) => ({
                  ...prevLicense,
                  recipient: e.target.value,
                }))
              }
              variant="standard"
              fullWidth
            />
          </Stack>
        )}

        {/* Troisième ligne : E (destination_country_id) - F (company_name) */}
        <Stack direction="row" spacing={3} width="100%">
          <FormControl
            required
            error={formErrors.destination_country !== ""}
            sx={{ width: "50%" }}
          >
            <SelectCountry
              onChangeInherited={handleChangeDtCountry}
              selected={convertCountryIdToCode(
                currentLicense.destination_country_id,
                countriesDestination
              )}
              required={
                currentLicense.regulation === "FR_MUNITIONS_LIST" &&
                currentLicense.license_type === "GLOBAL"
                  ? false
                  : true
              }
              disabled={disabledByRDO}
              size="small"
              width="100%"
              countries={countriesDestination as CountryType[]}
              label={
                currentLicense.regulation === "FR_MUNITIONS_LIST"
                  ? i18n.t("pages.license.add.destination-country-ml")
                  : i18n.t("pages.license.add.tdestination-country")
              }
            />
            <FormHelperText
              error
              sx={{
                position: "absolute",
                bottom: "-1.5em",
                height: "1.5em",
                whiteSpace: "nowrap",
              }}
            >
              {formErrors.destination_country}
            </FormHelperText>
          </FormControl>
          <TextField
            id="end-user"
            label={i18n.t("pages.license.add.end-user")}
            value={currentLicense.company_name || ""}
            error={!!formErrors["company_name"]}
            helperText={formErrors["company_name"]}
            required={
              !(
                currentLicense.regulation === "FR_MUNITIONS_LIST" &&
                currentLicense.license_type === "GLOBAL"
              )
            }
            disabled={disabledByRDO}
            onChange={(e) =>
              setCurrentLicense((prevLicense: any) => ({
                ...prevLicense,
                company_name: e.target.value,
              }))
            }
            variant="standard"
            sx={{ width: "100%" }}
          />
        </Stack>

        {/* Quatrième ligne : G (intermediary-country) */}
        {currentLicense.regulation === "FR_MUNITIONS_LIST" && (
          <Stack direction="column" spacing={2} width="100%">
            <SelectCountry
              id="intermediary-country"
              label={i18n.t("pages.license.add.intermediair-country")}
              disabled={disabledByRDO}
              onChangeInherited={handleChangeIntermediaryCountry}
              size="small"
              width="100%"
              countries={countriesDestination as CountryType[]}
              selected={
                convertCountryIdToCode(
                  currentLicense.intermediair_destination_country_id,
                  countriesDestination
                ) || ""
              }
            />
          </Stack>
        )}
      </Stack>
    </Card>
  );
};

export default Destinataire;
