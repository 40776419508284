/* Styles import */
import styles from "./listCountry.module.css";

/* Global import */
import React, { useState } from "react";
import configuration from "../../configuration";
import axios from "axios";

interface Props {
  list: any;
  country: string;
  categorie: string;
}

const ListCountryEdit = ({ list, country, categorie }: Props) => {
  function modifier(categorie: string, index: number, ligne: string) {
    const url = prompt("Nouvelle URl", ligne);
    if (url) {
      axios
        .post(
          `${configuration.LARAVEL_API}/controle_send`,
          {
            categorie,
            index,
            ligne: url,
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          alert("Mise a jour bien effectuée");
          window.location.reload();
        });
    }
  }

  //const array_value=liens.replace("[","").replace("]","").split(',')

  return (
    <li className={styles.element} key={country}>
      {" "}
      {country} :
      <ul className={styles.list}>
        {list.map((list: any) => {
          return (
            <li key={list.id}>
              {list.titre}

              <input
                name={list.id}
                type="text"
                value={list.url}
                onClick={() => modifier(categorie, list.id, list.url)}
                style={{ width: "100%" }}
              />
            </li>
          );
        })}
      </ul>
    </li>
  );
};

export default ListCountryEdit;
