import React, { useEffect, useState, useCallback } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community/";
import "@inovua/reactdatagrid-community/index.css";
import Api from "../../utils/api";
import configuration from "../../configuration";
import axios from "axios";
type PeopleType = {
  code_msg: string;
  Regle: string;
  short_en: string;
  short_fr: string;
  Message: string;
  Message_fr: string;
  Lib_url1: string;
  Url1: string;
  Lib_url2: string;
  Url2: string;
  Lib_url3: string;
  Url3: string;
};

const gridStyle = { minHeight: 550 };

const columns = [
  {
    name: "code_msg",
    sortable: false,
    header: "Id",
    defaultVisible: false,
    defaultWidth: 80,
    editable: false,
  },
  { name: "Regle", sortable: false, header: "Règles", defaultFlex: 1 },
  { name: "short_en", sortable: false, header: "Short EN", defaultFlex: 1 },
  { name: "short_fr", sortable: false, header: "Short FR", defaultFlex: 1 },
  { name: "Message", sortable: false, header: "Messages EN", defaultFlex: 1 },
  {
    name: "Message_fr",
    sortable: false,
    header: "Messages FR",
    defaultFlex: 1,
  },
  { name: "Lib_url1", sortable: false, header: "Texte URL1", defaultFlex: 1 },
  { name: "Url1", sortable: false, header: "URL1", defaultFlex: 1 },
  { name: "Lib_url2", sortable: false, header: "Texte URL2", defaultFlex: 1 },
  { name: "Url2", sortable: false, header: "URL2", defaultFlex: 1 },
  { name: "Lib_url3", sortable: false, header: "Texte URL3", defaultFlex: 1 },
  { name: "Url3", sortable: false, header: "URL3", defaultFlex: 1 },
];

const ListMsg = () => {
  let api: Api;
  const [dataSource, setDataSource] = useState<PeopleType[]>([]);

  useEffect(() => {
    axios.get(`${configuration.LARAVEL_API}/msgexport`).then((resp) => {
      setDataSource(resp.data);
    });
  }, []);
  const onEditComplete = useCallback(
    ({ value, columnId, rowIndex }) => {
      const data = [...dataSource];

      api = new Api();
      if (columnId == "Regle") data[rowIndex]["Regle"] = value;
      if (columnId == "short_en") data[rowIndex]["short_en"] = value;
      if (columnId == "short_fr") data[rowIndex]["short_fr"] = value;
      if (columnId == "Message") data[rowIndex]["Message"] = value;
      if (columnId == "Message_fr") data[rowIndex]["Message_fr"] = value;
      if (columnId == "Lib_url1") data[rowIndex]["Lib_url1"] = value;
      if (columnId == "Url1") data[rowIndex]["Url1"] = value;
      if (columnId == "Lib_url2") data[rowIndex]["Lib_url2"] = value;
      if (columnId == "Url2") data[rowIndex]["Url2"] = value;
      if (columnId == "Lib_url3") data[rowIndex]["Lib_url3"] = value;
      if (columnId == "Url3") data[rowIndex]["Url3"] = value;

      const logr = api.PostMsg(
        data[rowIndex]["code_msg"],
        data[rowIndex]["Regle"],
        data[rowIndex]["short_en"],
        data[rowIndex]["short_fr"],
        data[rowIndex]["Message"],
        data[rowIndex]["Message_fr"],
        data[rowIndex]["Lib_url1"],
        data[rowIndex]["Url1"],
        data[rowIndex]["Lib_url2"],
        data[rowIndex]["Url2"],
        data[rowIndex]["Lib_url3"],
        data[rowIndex]["Url3"]
      );

      setDataSource(data);
    },
    [dataSource]
  );

  return (
    <div>
      <div>
        <ReactDataGrid
          idProperty="id"
          style={gridStyle}
          onEditComplete={onEditComplete}
          editable
          columns={columns}
          dataSource={dataSource}
        />
      </div>
    </div>
  );
};

export default ListMsg;
