/* Global import */
import { observable, action, makeAutoObservable, makeObservable } from "mobx";
import fileDownload from "js-file-download";

/* Custom import */
import Api from "../utils/api";
import minimis from "./minimis.json";
import i18n from "i18next";
import configuration from "../configuration";
import axios from "axios";

type MsgType = {
  code_msg: string;
  Regle: string;
  short_en: string;
  short_fr: string;
  Message: string;
  Lib_url1: string;
  Url1: string;
  Lib_url2: string;
  Url2: string;
  Lib_url3: string;
  Url3: string;
};

export default class ExportabilityStore {
  [x: string]: any;
  rootStore: import("./rootStore").default;
  api: Api;

  @observable nationalEccn?: ClassificationEccn;
  @observable usEccn?: ClassificationEccn;
  @observable departureCountry?: number;
  @observable typeflow?: string;
  @observable countryOfDestination?: number;
  @observable usOrigin: boolean = false;

  /* Exportability country */
  @observable screeningCountryResult?: ScreeningCountryResult;
  @observable screeningCountryState: RequestState;

  constructor(rootStore: import("./rootStore").default) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.api = new Api();

    this.screeningCountryState = "INITIALIZE";
  }

  @action setCountryState(countryState: RequestState) {
    if (countryState) this.screeningCountryState = countryState;
  }

  @action setNationalEccn(nationalEccn?: ClassificationEccn) {
    this.nationalEccn = nationalEccn;
  }

  @action setUsEccn(usEccn?: ClassificationEccn) {
    this.usEccn = usEccn;
  }

  @action setDepartureCountry(departureCountry: number) {
    this.departureCountry = departureCountry;

    if (departureCountry === 202) {
      this.setNationalEccn();
      this.setUsOrigin(true);
    } else {
      this.setUsOrigin(false);
    }
  }

  @action setCountryOfDestination(countryOfDestination: number) {
    this.countryOfDestination = countryOfDestination;
  }

  @action setUsOrigin(usOrigin: boolean) {
    this.usOrigin = usOrigin;

    if (usOrigin === false) {
      this.setUsEccn();
    }
  }

  @action async checkCountryv2(
    eccns: ClassificationEccn[],
    countryOfOrigin: number,
    countryOfDestination: number,
    lang: string
  ) {
    this.screeningCountryState = "PENDING";

    try {
      for (var index = 0; index < eccns.length; index++) {
        if (!eccns[index].usOrigin) this.setNationalEccn(eccns[index]);
        if (eccns[index].usOrigin) this.setUsEccn(eccns[index]);
      }

      const response = await axios.post(
        `${process.env.REACT_APP_SCREEN}/checkCountry`,
        {
          national_code: this.nationalEccn?.eccnCode,
          us_code: this.usEccn?.eccnCode,
          departure_country: countryOfOrigin,
          destination_country: countryOfDestination,
          userid: this.rootStore.jwtStore.jwt?.email,
          tenantid: this.rootStore.jwtStore.mainTenant,
          lang: lang,
          typeflow: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("La réponse du réseau n'est pas correcte");
      }

      const { data } = response;

      // Vérifier si la réponse contient les données attendues

      this.screeningCountryResult = data;

      this.screeningCountryState = "DONE";
    } catch (error) {
      // Gérer les erreurs éventuelles
      console.error("Erreur checkcountry :", error);
    }

   // console.log(this.screeningCountryResult);
  }

  @action async checkCountry(
    eccns: ClassificationEccn[],
    countryOfOrigin: number,
    countryOfDestination: number
  ) {
    try {
      this.screeningCountryState = "PENDING";

      let departureFrance =
        await this.rootStore.countriesStore.getCountryById(63);
      let departureCountry =
        await this.rootStore.countriesStore.getCountryById(countryOfOrigin);
      const endCountry =
        await this.rootStore.countriesStore.getCountryById(
          countryOfDestination
        );
      //****************** Etude du cas particulier de chine et inde ************/

      const departureCountryOrigine = { ...departureCountry };
      let Eu_China = "KO";
      if (
        departureCountry?.name == "China" ||
        departureCountry?.name == "India"
      ) {
        Eu_China = "OK";
        departureCountry = departureFrance;
        countryOfOrigin = 63;
      }

      //*************************entrée 0 du graph Etat initial**************************************** */
      let oksanction = true;
      let okZA = true;
      let EuropreOrigin = await this.api.isEurope(countryOfOrigin);
      let EuropeDestination = await this.api.isEurope(countryOfDestination);
      let UNembargoDestination =
        await this.api.isUNEmabargo(countryOfDestination);

      const screeningCountryResult = await this.api.compute(
        countryOfOrigin,
        countryOfDestination,
        eccns,
        this.rootStore.jwtStore.jwt?.email || ""
      );
      const tscreeningCountryResult = screeningCountryResult;

      for (var index = 0; index < eccns.length; index++) {
        if (!eccns[index].usOrigin) this.setNationalEccn(eccns[index]);
        if (eccns[index].usOrigin) this.setUsEccn(eccns[index]);
      }

      if (this.nationalEccn?.eccnCode == undefined)
        this.setNationalEccn(eccns[0]);

      let literalAnnex = "{$annex}";
      let MsAnnexe = " ";
      let literalSupplement = "{$supplement}";
      let MsSupplement = " ";
      const response = await axios.get(
        `${configuration.LARAVEL_API}/hs-codes-russia/code/${this.nationalEccn?.eccnCode.replaceAll(".", "").replaceAll(" ", "")}/annexe`
      );
      const data = response.data;
      if (data.success) {
        MsAnnexe = data.annexe;
      }

      const response1 = await axios.get(
        `${configuration.LARAVEL_API}/hs-codes-us-russia/code/${this.usEccn?.eccnCode.replaceAll(".", "").replaceAll(" ", "")}/literalSupplement`
      );
      const data1 = response1.data;
      if (data1.success) {
        MsSupplement = data1.annexe;
      }

      if (this.nationalEccn?.usOrigin) {
        this.setNationalEccn(undefined);
      }
      //******************remove duplicate */
      let max3 = screeningCountryResult.screeningDetails.length;
      const array = [...screeningCountryResult.screeningDetails];
      let ok_rem = "KO";
      for (var index = 0; index < max3; index++) {
        if (
          screeningCountryResult.screeningDetails[index].exportabilityRule ===
            "RULE_SANCTIONED" &&
          screeningCountryResult.screeningDetails[index]
            .eccnExportabilityCategory === "CUSTOMER_COUNTRY" &&
          ok_rem === "OK"
        )
          array.splice(index, 1);

        if (
          screeningCountryResult.screeningDetails[index].exportabilityRule ===
            "RULE_SANCTIONED" &&
          screeningCountryResult.screeningDetails[index]
            .eccnExportabilityCategory === "CUSTOMER_COUNTRY"
        )
          ok_rem = "OK";
      }
      screeningCountryResult.screeningDetails = array;
      //console.log(screeningCountryResult.screeningDetails)

      //* **************************** autorisation EU */
      for (var index = 0; index < eccns.length; index++) {
        const feu = await this.api.EU001(
          countryOfOrigin,
          countryOfDestination,
          screeningCountryResult.screeningDetails[index].eccnCode
        );
        //console.log(screeningCountryResult.screeningDetails[index].eccnCode)
        if (
          feu !== "blanc" &&
          !screeningCountryResult.screeningDetails[index].usOrigin &&
          !screeningCountryResult.screeningDetails[index].eccnCode.includes(
            "AMA"
          )
        ) {
          // console.log('<> blanc')
          if (feu === "vert") {
            var here = "here";

            screeningCountryResult.globalTrafficLightColor = "YELLOW";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
            screeningCountryResult.screeningDetails[index].message = "EXP030";

            screeningCountryResult.screeningDetails[index].moreDetailsLabel =
              "Please check details";
            screeningCountryResult.screeningDetails[index].moreDetailsLink =
              "https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32019R2199#d1e40-230-1 ";
          }

          if (feu === "orange") {
            screeningCountryResult.globalTrafficLightColor = "YELLOW";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
            screeningCountryResult.screeningDetails[index].message = "EXP031";
          }
        }
      }
      ///******************** cdc 22  */
      let DoMu = "KO";
      for (var index = 0; index < eccns.length; index++) {
        if (eccns.length == 2 && index == 0) index++;
        const usEccnRegex = "^[0-9][A-E][012569][0-9]{2}|^EAR99$";

        if (eccns[index].eccnCode.match(usEccnRegex)) {
          if (endCountry?.name === "****Ukraine Crimea region") {
            screeningCountryResult.globalTrafficLightColor = "YELLOW";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
            screeningCountryResult.screeningDetails[index].message =
              "See US Part 746, § 746.6 CRIMEA REGION OF UKRAINE\n" +
              "(1) General prohibition.\n" +
              "A license is required to export or reexport any item subject to the EAR to the Ukraine Crimea region\n" +
              " and the transfer within the Ukraine Crimea region, except food and medicine\n" +
              "designated as EAR99 or software that is necessary to enable the exchange of personal\n" +
              "communications over the Internet (such as instant messaging, chat and email, social\n" +
              "networking, sharing of photos and movies, Web browsing, and blogging), provided that such\n" +
              "software is designated EAR99 or is classified as mass market software under Export Control\n" +
              "Classification Number (ECCN) 5D992.c of the EAR, and provided further that such\n" +
              "software is widely available to the public at no cost to the user.";
            screeningCountryResult.screeningDetails[index].moreDetailsLabel =
              "Please check all the details on the official text here.";
            screeningCountryResult.screeningDetails[index].moreDetailsLink =
              "https://www.ecfr.gov/cgi-bin/text-idx?SID=29ba24a4d4a88579de755d9d6aad9382&mc=true&node=se15.2.746_16&rgn=div8 ";
          }
          if (endCountry?.name === "Iraq" && eccns[index].usOrigin) {
            screeningCountryResult.globalTrafficLightColor = "YELLOW";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
            screeningCountryResult.screeningDetails[index].message = "EXP033";
            screeningCountryResult.screeningDetails[index].moreDetailsLabel =
              "Please check details of the official text here";
            screeningCountryResult.screeningDetails[index].moreDetailsLink =
              "https://www.ecfr.gov/cgi-bin/text-idx?SID=29ba24a4d4a88579de755d9d6aad9382&mc=true&node=se15.2.746_13&rgn=div8 ";

            // si controlReasonsForRule4  est tableau non vide remplacer message EXP033 par EXP087
            // Récupérer le tableau
            const controlReasons =
              screeningCountryResult.screeningDetails[index]
                .controlReasonsForRule4;

            // Vérifier si le tableau contient des éléments
            if (controlReasons && controlReasons.length > 0) {
              screeningCountryResult.screeningDetails[index].message = "EXP087";
            }
          }

          if (endCountry?.name === "Russia") {
            if (
              screeningCountryResult.screeningDetails[index]
                .trafficLightColor !== "YELLOW"
            ) {
              screeningCountryResult.globalTrafficLightColor = "YELLOW";
              screeningCountryResult.screeningDetails[index].trafficLightColor =
                "YELLOW";
              screeningCountryResult.screeningDetails[index].message = "EXP018";
              screeningCountryResult.screeningDetails[index].moreDetailsLabel =
                "Please check details of the official text here";
              screeningCountryResult.screeningDetails[index].moreDetailsLink =
                "https://www.ecfr.gov/cgi-bin/text-idx?SID=29ba24a4d4a88579de755d9d6aad9382&mc=true&node=se15.2.746_15&rgn=div8 ";
            }
          }
          if (endCountry?.name === "Belarus") {
            if (
              screeningCountryResult.screeningDetails[index]
                .trafficLightColor !== "YELLOW"
            ) {
              screeningCountryResult.globalTrafficLightColor = "YELLOW";
              screeningCountryResult.screeningDetails[index].trafficLightColor =
                "YELLOW";
              screeningCountryResult.screeningDetails[index].message = "EXP042";
              screeningCountryResult.screeningDetails[index].moreDetailsLabel =
                "Please check details of the official text here";
              screeningCountryResult.screeningDetails[index].moreDetailsLink =
                "https://www.ecfr.gov/cgi-bin/text-idx?SID=29ba24a4d4a88579de755d9d6aad9382&mc=true&node=se15.2.746_15&rgn=div8 ";
            }
          }
        }
        if (
          eccns[index].eccnCode.includes("515.y") &&
          eccns[index].eccnCode.includes("9")
        ) {
          if (
            endCountry?.name === "Hong Kong" ||
            endCountry?.name === "China" ||
            endCountry?.name === "Russia" ||
            endCountry?.name === "Venezuela"
          ) {
            screeningCountryResult.globalTrafficLightColor = "YELLOW";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
            screeningCountryResult.screeningDetails[index].message =
              "US part 742, §742.6 Regional stability. According to §742.6(a)(7) a license is required to export or reexport to China, Russia, or Venezuela any item described in a .y paragraph of a 9x515 or ‘‘600 series’’ ECCN, except for exports or reexports to Russia for use in, with, or for the International Space Station (ISS), including launch to the ISS.";
            screeningCountryResult.screeningDetails[index].moreDetailsLabel =
              "Please check all the details on the official text here.";
            screeningCountryResult.screeningDetails[index].moreDetailsLink =
              "https://www.ecfr.gov/cgi-bin/text-idx?mc=true&node=pt15.2.742&rgn=div5#se15.2.742_16 ";
          }
        }

        let chine_rest = [
          "3A090",
          "3B090",
          "3E001",
          "3D001",
          "4A090",
          "4B090",
          "4D090",
          "4E001",
        ];
        let chine_rest_z = [
          "3A001.z",
          "4A003.z",
          "4A004.z",
          "4A005.z",
          "5A002.z",
          "5A004.z",
          "5A992.z",
          "5D002.z",
          "5D992.z",
        ];
        const USembargo = await this.api.isUSEmabargo(countryOfDestination);
        if (
          (chine_rest.includes(eccns[index].eccnCode.slice(0, 5)) &&
            eccns[index].usOrigin) ||
          (chine_rest_z.includes(eccns[index].eccnCode.slice(0, 7)) &&
            eccns[index].usOrigin)
        ) {
          if (
            endCountry?.name === "China" ||
            endCountry?.name === "Macau" ||
            USembargo === "OK"
          ) {
            screeningCountryResult.globalTrafficLightColor = "YELLOW";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
            DoMu = "OK";
            screeningCountryResult.screeningDetails[index].message = "EXP088";
            screeningCountryResult.screeningDetails[index].moreDetailsLabel =
              "Please check all the details on the official text here.";
            screeningCountryResult.screeningDetails[index].moreDetailsLink =
              "https://www.ecfr.gov/cgi-bin/text-idx?mc=true&node=pt15.2.742&rgn=div5#se15.2.742_16 ";
          }
        }
      }

      //* ************************ regle  EAR99

      for (
        var index = 0;
        index < screeningCountryResult.screeningDetails.length;
        index++
      ) {
        if (
          screeningCountryResult.screeningDetails[index].eccnCode === "EAR99" ||
          (screeningCountryResult.screeningDetails[index].eccnCode.startsWith(
            "5A992"
          ) &&
            screeningCountryResult.screeningDetails[index].eccnCode.replace(
              /\./g,
              ""
            ) !== "5A992z") ||
          (screeningCountryResult.screeningDetails[index].eccnCode.startsWith(
            "5D992"
          ) &&
            screeningCountryResult.screeningDetails[index].eccnCode.replace(
              /\./g,
              ""
            ) !== "5D992z")
        ) {
          if (endCountry?.name === "Iran") {
            // console.log("yes c est ca")
            if (screeningCountryResult.screeningDetails[index].eccnCode)
              screeningCountryResult.screeningDetails[
                index
              ].destinationCountry =
                screeningCountryResult.screeningDetails[index].eccnCode;

            screeningCountryResult.globalTrafficLightColor = "YELLOW";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
            screeningCountryResult.screeningDetails[index].message = "EXP013";
            screeningCountryResult.screeningDetails[index].moreDetailsLabel =
              "Please check details";
            screeningCountryResult.screeningDetails[index].moreDetailsLink =
              "https://www.ecfr.gov/cgi-bin/retrieveECFR?n=15y2.1.3.4.30#se15.2.746_17";
          }
        }
        if (
          screeningCountryResult.screeningDetails[index].eccnCode === "EAR99"
        ) {
          if (endCountry?.name === "Ukraine Crimea region") {
            screeningCountryResult.globalTrafficLightColor = "YELLOW";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
            screeningCountryResult.screeningDetails[index].message = "EXP027";
            screeningCountryResult.screeningDetails[index].moreDetailsLabel =
              "Please check details in the official text here";
            screeningCountryResult.screeningDetails[index].moreDetailsLink =
              "https://www.ecfr.gov/cgi-bin/retrieveECFR?n=15y2.1.3.4.30#se15.2.746_16";
          }
          if (endCountry?.name === "Ukraine Donetsk and Luhansk") {
            screeningCountryResult.globalTrafficLightColor = "YELLOW";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
            screeningCountryResult.screeningDetails[index].message = "EXP050";
            screeningCountryResult.screeningDetails[index].moreDetailsLabel =
              "Please check details in the official text here";
            screeningCountryResult.screeningDetails[index].moreDetailsLink =
              "https://www.ecfr.gov/cgi-bin/retrieveECFR?n=15y2.1.3.4.30#se15.2.746_16";
          }
          if (endCountry?.name === "Cuba") {
            screeningCountryResult.screeningDetails[index].destinationCountry =
              screeningCountryResult.screeningDetails[index].eccnCode;
            screeningCountryResult.globalTrafficLightColor = "YELLOW";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
            screeningCountryResult.screeningDetails[index].message = "EXP012";
            screeningCountryResult.screeningDetails[index].moreDetailsLabel =
              "Please check details in the official text here";
            screeningCountryResult.screeningDetails[index].moreDetailsLink =
              "https://www.ecfr.gov/cgi-bin/text-idx?SID=29ba24a4d4a88579de755d9d6aad9382&amp;mc=true&amp;node=se15.2.746_12&amp;rgn=div8";
          }

          if (endCountry?.name === "Korea, North") {
            screeningCountryResult.screeningDetails[index].destinationCountry =
              screeningCountryResult.screeningDetails[index].eccnCode;

            screeningCountryResult.globalTrafficLightColor = "YELLOW";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
            screeningCountryResult.screeningDetails[index].message = "EXP015";
            screeningCountryResult.screeningDetails[index].moreDetailsLink =
              "https://www.ecfr.gov/cgi-bin/text-idx?SID=29ba24a4d4a88579de755d9d6aad9382&amp;mc=true&amp;node=se15.2.746_14&amp;rgn=div8";
          }

          if (endCountry?.name === "*****Sudan") {
            screeningCountryResult.screeningDetails[index].destinationCountry =
              screeningCountryResult.screeningDetails[index].eccnCode;

            screeningCountryResult.globalTrafficLightColor = "YELLOW";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
            screeningCountryResult.screeningDetails[index].message =
              "According to BIS, Sudan is subject to anti-terrorism controls as described in Section\n" +
              "742.10 of the EAR and a BIS license is required to export or reexport most items on the\n" +
              "Commerce Control List (CCL) to Sudan.  However, items designated EAR99 do not\n" +
              "require a BIS license for export or reexport to Sudan, unless you know or should know\n" +
              "that the items are intended for a prohibited person or end user as described in Part 744\n" +
              "of the EAR.\n";
            screeningCountryResult.screeningDetails[index].moreDetailsLabel =
              "Please check details";
            screeningCountryResult.screeningDetails[index].moreDetailsLink =
              "https://www.bis.doc.gov/index.php/policy-guidance/country-guidance/sanctioned-destinations/sudan#:~:text=Items%20designated%20EAR99%20do%20not,Part%20744%20of%20the%20EAR.&text=A%20license%20exception%20is%20a,an%20item%20without%20a%20license";
          }

          if (endCountry?.name === "Syria") {
            screeningCountryResult.screeningDetails[index].destinationCountry =
              screeningCountryResult.screeningDetails[index].eccnCode;

            screeningCountryResult.globalTrafficLightColor = "YELLOW";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
            screeningCountryResult.screeningDetails[index].message = "EXP014";
            screeningCountryResult.screeningDetails[index].moreDetailsLabel =
              "Please check details in the official text here";
            screeningCountryResult.screeningDetails[index].moreDetailsLink =
              "https://www.ecfr.gov/cgi-bin/text-idx?SID=29ba24a4d4a88579de755d9d6aad9382&amp;mc=true&amp;node=se15.2.746_19&amp;rgn=div8";
          }

          if (
            screeningCountryResult.screeningDetails[index].trafficLightColor ==
            "GREEN"
          ) {
            screeningCountryResult.screeningDetails[index].message = "EXP032";
            screeningCountryResult.screeningDetails[index].moreDetailsLabel =
              "Please check details in Supplement No. 4 to Part 774, (6) Step 6";
            screeningCountryResult.screeningDetails[index].moreDetailsLink =
              "https://www.ecfr.gov/cgi-bin/retrieveECFR?gp=1&ty=HTML&h=L&n=15y2.1.3.4.45&r=PART#ap15.2.774_12.4";
          }
        }
      }

      //* ********************************************************** */

      //************************ cdc UK */
      const usEccnRegex1 = "^[0-9][A-E][0-9][0-9]{2}";
      const EccnPL = "^PL[0-9][0-9][0-9][0-9]";

      if (
        departureCountry?.name === "United Kingdom (UK)" &&
        !eccns[0].usOrigin
      ) {
        const iseurope = EuropeDestination; // await this.api.isEurope(countryOfDestination);

        if (iseurope == "OK") {
          if (
            eccns[0].eccnCode.match(usEccnRegex1) ||
            eccns[0].eccnCode.match(EccnPL)
          ) {
            const isAnnexe = await this.api.isAnnexe(eccns[0].eccnCode);

            if (isAnnexe != "OK") {
              if (
                screeningCountryResult.screeningDetails[0].exportabilityRule !==
                "RULE_SANCTIONED"
              ) {
                screeningCountryResult.screeningDetails[0].exportabilityRule =
                  "";
                screeningCountryResult.screeningDetails[0].usOrigin = false;
                screeningCountryResult.screeningDetails[0].trafficLightColor =
                  "YELLOW";
                screeningCountryResult.screeningDetails[0].message = "EXP016";
                screeningCountryResult.screeningDetails[0].moreDetailsLabel =
                  "Please check details in the official OGEL Export of Dual-Use items to EU Member States.";
                screeningCountryResult.screeningDetails[0].moreDetailsLink =
                  "https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/945532/Open-General-Export-Licence-Export-of-Dual-Use-items-to-EU-Member-States.pdf";
              } else {
                let max = screeningCountryResult.screeningDetails.length;
                /*
                const tmp = tscreeningCountryResult.screeningDetails.map(
                  (obj) => ({ ...obj })
                );
  
  */
                const emptyScreeningDetails: ScreeningDetails = {
                  eccnCode: "",
                  message: "",
                  trafficLightColor: "RED", // Valeur par défaut
                  usOrigin: false,
                };
                screeningCountryResult.screeningDetails.push(
                  emptyScreeningDetails
                );
                screeningCountryResult.screeningDetails[0].exportabilityRule =
                  "";
                screeningCountryResult.screeningDetails[0].usOrigin = false;
                screeningCountryResult.screeningDetails[0].trafficLightColor =
                  "YELLOW";
                screeningCountryResult.screeningDetails[0].message = "EXP016";
                screeningCountryResult.screeningDetails[0].moreDetailsLabel =
                  "Please check details in the official OGEL Export of Dual-Use items to EU Member States.";
                screeningCountryResult.screeningDetails[0].moreDetailsLink =
                  "https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/945532/Open-General-Export-Licence-Export-of-Dual-Use-items-to-EU-Member-States.pdf";
              }
            }
          }
        } else {
          if (
            eccns[0].eccnCode.match(usEccnRegex1) ||
            eccns[0].eccnCode.match(EccnPL)
          ) {
            if (
              screeningCountryResult.screeningDetails[0].exportabilityRule !==
              "RULE_SANCTIONED"
            ) {
              screeningCountryResult.screeningDetails[0].exportabilityRule = "";
              screeningCountryResult.screeningDetails[0].usOrigin = false;
              screeningCountryResult.screeningDetails[0].trafficLightColor =
                "YELLOW";
              screeningCountryResult.screeningDetails[0].message = "EXP017";
              screeningCountryResult.screeningDetails[0].moreDetailsLabel =
                "Please check the Guide to licensing procedure and other restrictions for export, dual-use licences.";
              screeningCountryResult.screeningDetails[0].moreDetailsLink =
                "https://www.gov.uk/guidance/export-controls-dual-use-items-software-and-technology-goods-for-torture-and-radioactive-sources#get-an-export-or-trade-licence";
            } else {
              let max = screeningCountryResult.screeningDetails.length;

              /* const tmp = tscreeningCountryResult.screeningDetails.map(
                (obj) => ({
                  ...obj,
                })
              );*/
              const emptyScreeningDetails: ScreeningDetails = {
                eccnCode: "",
                message: "",
                trafficLightColor: "RED", // Valeur par défaut
                usOrigin: false,
              };
              screeningCountryResult.screeningDetails.push(
                emptyScreeningDetails
              );
              screeningCountryResult.screeningDetails[0].exportabilityRule = "";
              screeningCountryResult.screeningDetails[0].usOrigin = false;
              screeningCountryResult.screeningDetails[0].trafficLightColor =
                "YELLOW";
              screeningCountryResult.screeningDetails[0].message = "EXP017";
              screeningCountryResult.screeningDetails[0].moreDetailsLabel =
                "Please check the Guide to licensing procedure and other restrictions for export, dual-use licences.";
              screeningCountryResult.screeningDetails[0].moreDetailsLink =
                "https://www.gov.uk/guidance/export-controls-dual-use-items-software-and-technology-goods-for-torture-and-radioactive-sources#get-an-export-or-trade-licence";
            }
          }
        }
      }

      // CDC UK  simo  transformation du code EXP017 en code EXP081
      const EccnPL9009 = "^PL9009";
      if (
        eccns[0].eccnCode.match(EccnPL9009) &&
        endCountry?.name === "Iran" &&
        departureCountry?.name === "United Kingdom (UK)" &&
        !eccns[0].usOrigin
      ) {
        if (screeningCountryResult.screeningDetails[0].message === "EXP017") {
          screeningCountryResult.screeningDetails[0].trafficLightColor = "RED";
          screeningCountryResult.screeningDetails[0].message = "EXP082";
        }
      }

      if (
        (endCountry?.name === "Australia" ||
          endCountry?.name === "Canada" ||
          endCountry?.name === "Japan" ||
          endCountry?.name === "New Zealand" ||
          endCountry?.name === "Norway" ||
          endCountry?.name === "Switzerland" ||
          endCountry?.name === "United States of America (USA)") &&
        departureCountry?.name === "United Kingdom (UK)" &&
        !eccns[0].usOrigin &&
        (eccns[0].eccnCode.match(usEccnRegex1) ||
          eccns[0].eccnCode.match(EccnPL))
      ) {
        if (screeningCountryResult.screeningDetails[0].message === "EXP017") {
          screeningCountryResult.screeningDetails[0].trafficLightColor =
            "YELLOW";
          screeningCountryResult.screeningDetails[0].message = "EXP083";
        }
      }

      for (
        var index = 0;
        index < screeningCountryResult.screeningDetails.length;
        index++
      ) {
        const UNembargo = UNembargoDestination; //await this.api.isUNEmabargo(countryOfDestination);
        const EUembargo = await this.api.isEUEmabargo(countryOfDestination);

        //********* corporate policy */
        if (
          screeningCountryResult.screeningDetails[index].exportabilityRule ===
          "RULE_SANCTIONED"
        ) {
          //this.rootStore.jwtStore.mainTenant
          //countryOfDestination
          const policy = await this.api.Getpolicy(
            countryOfDestination,
            this.rootStore.jwtStore.mainTenant || ""
          );

          if (policy) {
            const policy1 = await this.api.get_policy_file(
              this.rootStore.jwtStore.mainTenant || ""
            );

            if (policy1) {
              screeningCountryResult.screeningDetails[index].moreDetailsLabel =
                "Download the Corporate Policy";
              screeningCountryResult.screeningDetails[index].moreDetailsLink =
                "http://tool.ec-compliance.com:8090/ecback/public/upload/" +
                policy1[0].file_name;
            }

            if (policy[0].feu == "RED")
              screeningCountryResult.screeningDetails[index].trafficLightColor =
                "RED";
            if (policy[0].feu == "YELLOW")
              screeningCountryResult.screeningDetails[index].trafficLightColor =
                "YELLOW";
            if (policy[0].feu == "GREEN")
              screeningCountryResult.screeningDetails[index].trafficLightColor =
                "GREEN";
            if (policy[0].feu == null)
              screeningCountryResult.screeningDetails[index].trafficLightColor =
                "GREEN";
            screeningCountryResult.screeningDetails[index].message =
              policy[0].message;
          }
        }

        if (
          (screeningCountryResult.screeningDetails[index].eccnCode == "NC" ||
            screeningCountryResult.screeningDetails[index].eccnCode ===
              "NOT LISTED" ||
            screeningCountryResult.screeningDetails[index].eccnCode == "NL") &&
          screeningCountryResult.screeningDetails[index].exportabilityRule !==
            "RULE_SANCTIONED"
        ) {
          if (UNembargo == "OK" || EUembargo == "OK") {
            screeningCountryResult.globalTrafficLightColor = "YELLOW";
            screeningCountryResult.screeningDetails[index].destinationCountry =
              screeningCountryResult.screeningDetails[index].eccnCode;
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
            screeningCountryResult.screeningDetails[index].message = "EXP002";
            screeningCountryResult.screeningDetails[index].moreDetailsLabel =
              "Please check details in regulation 428/2009, Article 4";
            screeningCountryResult.screeningDetails[index].moreDetailsLink =
              "https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32009R0428";

            if (departureCountry?.name === "United Kingdom (UK)") {
              screeningCountryResult.globalTrafficLightColor = "YELLOW";
              screeningCountryResult.screeningDetails[
                index
              ].destinationCountry =
                screeningCountryResult.screeningDetails[index].eccnCode;
              screeningCountryResult.screeningDetails[index].trafficLightColor =
                "YELLOW";
              screeningCountryResult.screeningDetails[index].message = "EXP058";
              screeningCountryResult.screeningDetails[index].moreDetailsLabel =
                "Please check details in regulation 428/2009, Article 4";
              screeningCountryResult.screeningDetails[index].moreDetailsLink =
                "https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32009R0428";
            }
          } else {
            screeningCountryResult.screeningDetails[index].destinationCountry =
              screeningCountryResult.screeningDetails[index].eccnCode;
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "GREEN";

            screeningCountryResult.screeningDetails[index].message = "EXP059";

            if (
              (screeningCountryResult.screeningDetails[index].eccnCode ==
                "NC" ||
                screeningCountryResult.screeningDetails[index].eccnCode ===
                  "NOT LISTED" ||
                screeningCountryResult.screeningDetails[index].eccnCode ==
                  "NL") &&
              departureCountry?.name === "Canada"
            ) {
              screeningCountryResult.screeningDetails[index].message =
                "EXPajout";
            }
            if (
              (screeningCountryResult.screeningDetails[index].eccnCode ==
                "NC" ||
                screeningCountryResult.screeningDetails[index].eccnCode ===
                  "NOT LISTED" ||
                screeningCountryResult.screeningDetails[index].eccnCode ==
                  "NL") &&
              departureCountry?.name === "France"
            ) {
              screeningCountryResult.screeningDetails[index].message = "EXP098";
            }

            screeningCountryResult.screeningDetails[index].moreDetailsLabel =
              "";
            screeningCountryResult.screeningDetails[index].moreDetailsLink = "";

            const feu1 = await this.api.EU001(
              countryOfOrigin,
              countryOfDestination,
              "NC"
            );

            const iseurope1 = EuropeDestination; //await this.api.isEurope(countryOfDestination);
            if (iseurope1 === "OK" || feu1 === "vert") {
              screeningCountryResult.screeningDetails[
                index
              ].destinationCountry =
                screeningCountryResult.screeningDetails[index].eccnCode;
              screeningCountryResult.screeningDetails[index].trafficLightColor =
                "GREEN";
              screeningCountryResult.screeningDetails[index].message = "EXP001";
              screeningCountryResult.screeningDetails[index].moreDetailsLabel =
                "";
              screeningCountryResult.screeningDetails[index].moreDetailsLink =
                "";
            }
          }

          if (endCountry?.name === "Ukraine Crimea region") {
            const iseurope = EuropreOrigin;

            if (iseurope == "OK") {
              screeningCountryResult.globalTrafficLightColor = "YELLOW";
              screeningCountryResult.screeningDetails[
                index
              ].destinationCountry =
                screeningCountryResult.screeningDetails[index].eccnCode;
              screeningCountryResult.screeningDetails[index].trafficLightColor =
                "YELLOW";
              screeningCountryResult.screeningDetails[index].message = "EXP003";
              screeningCountryResult.screeningDetails[index].moreDetailsLabel =
                "Please check details in EU regulation No 692/2014.";
              screeningCountryResult.screeningDetails[index].moreDetailsLink =
                "https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:02014R0692-20141220#tocId6";
            }
          }

          ///  meme regle de la Crimea pour le NC
          if (endCountry?.name === "Ukraine Donetsk and Luhansk") {
            const iseurope = EuropreOrigin;

            if (iseurope == "OK") {
              screeningCountryResult.globalTrafficLightColor = "YELLOW";
              screeningCountryResult.screeningDetails[
                index
              ].destinationCountry =
                screeningCountryResult.screeningDetails[index].eccnCode;
              screeningCountryResult.screeningDetails[index].trafficLightColor =
                "YELLOW";
              screeningCountryResult.screeningDetails[index].message = "EXP047";
              screeningCountryResult.screeningDetails[index].moreDetailsLabel =
                "Please check details in EU regulation No 692/2014.";
              screeningCountryResult.screeningDetails[index].moreDetailsLink =
                "https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:02014R0692-20141220#tocId6";
            }
          }
        }

        if (UNembargo == "OK") {
          if (
            endCountry?.name === "Iraq" &&
            !eccns[index].usOrigin &&
            eccns[index].eccnCode.includes("ML")
          ) {
            screeningCountryResult.globalTrafficLightColor = "YELLOW";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
            screeningCountryResult.screeningDetails[index].message = "EXP021";
            screeningCountryResult.screeningDetails[index].moreDetailsLabel =
              "";
            screeningCountryResult.screeningDetails[index].moreDetailsLink = "";
          }
        }
      }

      if (departureCountry?.name === "France" && !eccns[0].usOrigin) {
        let max = screeningCountryResult.screeningDetails.length;

        if (
          eccns[0].eccnCode.includes("5A002") ||
          eccns[0].eccnCode.includes("5D002") ||
          eccns[0].eccnCode.includes("5E002")
        ) {
          const emptyScreeningDetails: ScreeningDetails = {
            eccnCode: "",
            message: "",
            trafficLightColor: "RED", // Valeur par défaut
            usOrigin: false,
          };
          screeningCountryResult.screeningDetails.push(emptyScreeningDetails);

          screeningCountryResult.screeningDetails[max].eccnCode = i18n.t(
            "pages.exportability.titre_fr_regle"
          );
          screeningCountryResult.screeningDetails[max].trafficLightColor =
            "YELLOW";
          screeningCountryResult.screeningDetails[max].usOrigin = false;
          screeningCountryResult.screeningDetails[max].message = "EXP011";
          screeningCountryResult.screeningDetails[max].exportabilityRule =
            "REST_IMPORT";
          screeningCountryResult.screeningDetails[max].moreDetailsLabel =
            "Voir le détail des démarches à accomplir ici.";
          screeningCountryResult.screeningDetails[max].moreDetailsLink =
            "https://www.ssi.gouv.fr/entreprise/reglementation/controle-reglementaire-sur-la-cryptographie/demarches-a-accomplir";
        }
      }

      const iseurope = EuropreOrigin;
      const iseur006 = await this.api.isEu006(countryOfDestination);
      for (var index = 0; index < eccns.length; index++) {
        if (iseur006 == "OK" && iseurope == "OK" && !eccns[index].usOrigin) {
          if (
            (eccns[index].eccnCode.includes("1C350") ||
              eccns[index].eccnCode.includes("1C450")) &&
            eccns[index].eccnCode != "1C350.4" &&
            !eccns[index].eccnCode.includes("1C350.23") &&
            !eccns[index].eccnCode.includes("1C350.29") &&
            !eccns[index].eccnCode.includes("1C450.a.1") &&
            !eccns[index].eccnCode.includes("1C450.a.2") &&
            !eccns[index].eccnCode.includes("1C450.a.3") &&
            !eccns[index].eccnCode.includes("1C450.b.7")
          ) {
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
            screeningCountryResult.globalTrafficLightColor = "YELLOW";
            screeningCountryResult.screeningDetails[index].message = "EXP053";
            screeningCountryResult.screeningDetails[index].moreDetailsLabel =
              "Please check details in Annex II F of the 2021/821 regulation (you will need to scroll down)";
            screeningCountryResult.screeningDetails[index].moreDetailsLink =
              "https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32021R0821&from=FR#d1e32-425-1";
          }
        }
      }

      ///************* regle EU005 */
      const iseur005 = await this.api.isEu005(countryOfDestination);
      for (var index = 0; index < eccns.length; index++) {
        if (iseur005 == "OK" && iseurope == "OK" && !eccns[index].usOrigin) {
          if (
            eccns[index].eccnCode.includes("5A001.b.2") ||
            eccns[index].eccnCode.includes("5A001.c") ||
            eccns[index].eccnCode.includes("5A001.d") ||
            eccns[index].eccnCode.includes("5B001") ||
            eccns[index].eccnCode.includes("5D001") ||
            eccns[index].eccnCode.includes("5E001.a")
          ) {
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
            screeningCountryResult.globalTrafficLightColor = "YELLOW";
            screeningCountryResult.screeningDetails[index].message = "EXP057";
            screeningCountryResult.screeningDetails[index].moreDetailsLabel =
              "Please check details in Annex II F of the 2021/821 regulation (you will need to scroll down)";
            screeningCountryResult.screeningDetails[index].moreDetailsLink =
              "https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32021R0821&from=FR#d1e32-425-1";
          }
        }
      }

      let ajout_exp = false;
      for (
        var index = 0;
        index < screeningCountryResult.screeningDetails.length;
        index++
      ) {
        if (
          !screeningCountryResult.screeningDetails[index].usOrigin &&
          departureCountry?.name === "Canada" &&
          (screeningCountryResult.screeningDetails[index].eccnCode === "NC" ||
            screeningCountryResult.screeningDetails[index].eccnCode === "NL" ||
            screeningCountryResult.screeningDetails[index].eccnCode ===
              "NOT LISTED" ||
            screeningCountryResult.screeningDetails[index].eccnCode === "5400")
        ) {
          if (
            endCountry?.name === "Korea, North" ||
            endCountry?.name === "Korea, North" ||
            endCountry?.name === "Cuba" ||
            endCountry?.name === "Iran" ||
            endCountry?.name === "Syria"
          ) {
            let max4 = screeningCountryResult.screeningDetails.length;

            const emptyScreeningDetails: ScreeningDetails = {
              eccnCode: screeningCountryResult.screeningDetails[index].eccnCode,
              message: "",
              trafficLightColor: "RED", // Valeur par défaut
              usOrigin: false,
            };

            screeningCountryResult.screeningDetails.push(emptyScreeningDetails);
            screeningCountryResult.screeningDetails[max4].trafficLightColor =
              "YELLOW";
            screeningCountryResult.screeningDetails[max4].message = "EXP022";

            if (
              screeningCountryResult.screeningDetails[index].eccnCode === "5400"
            ) {
              screeningCountryResult.screeningDetails[max4].message = "EXP024";
              ajout_exp = true;
            }

            break;
          } else {
            let max4 = screeningCountryResult.screeningDetails.length;

            const emptyScreeningDetails: ScreeningDetails = {
              eccnCode: screeningCountryResult.screeningDetails[index].eccnCode,
              message: "",
              trafficLightColor: "RED", // Valeur par défaut
              usOrigin: false,
            };

            screeningCountryResult.screeningDetails.push(emptyScreeningDetails);

            screeningCountryResult.screeningDetails[max4].trafficLightColor =
              "GREEN";
            screeningCountryResult.screeningDetails[max4].message = "EXP023";
            if (
              screeningCountryResult.screeningDetails[max4].eccnCode.trim() ==
              "5400"
            ) {
              screeningCountryResult.screeningDetails[max4].message = "EXP025";
              screeningCountryResult.screeningDetails[max4].trafficLightColor =
                "YELLOW";
            }
            ajout_exp = true;
            break;
          }
        }
      }

      if (ajout_exp) {
        max3 = screeningCountryResult.screeningDetails.length;
        const array4 = [...screeningCountryResult.screeningDetails];
        for (var index = 0; index < max3; index++) {
          if (
            screeningCountryResult.screeningDetails[index].message === "EXP010"
          )
            array4.splice(index, 1);
          if (
            screeningCountryResult.screeningDetails[index].message === "EXP001"
          )
            array4.splice(index, 1);
        }
        screeningCountryResult.screeningDetails = array4;
      }
      //******************************canada */
      let pays_canda_GEP41 = [
        "Australia",
        "Austria",
        "Belgium",
        "the Czech Republic",
        "Denmark",
        "Estonia",
        "Finland",
        "France",
        "Germany",
        "Greece",
        "Hungary",
        "Iceland",
        "Ireland",
        "Italy",
        "Japan",
        "Latvia",
        "Lithuania",
        "Luxembourg",
        "the Netherlands",
        "New Zealand",
        "Norway",
        "Poland",
        "Portugal",
        "the Republic of Korea",
        "Slovakia",
        "Slovenia",
        "Spain",
        "Sweden",
        "Switzerland",
        "Turkey",
        "United Kingdom (UK)",
        "United States of America (USA)",
      ];
      const DUREGX1_c = "^[0-9][A-E][0-9][0-9]{2}";
      //const iseurope_c = await this.api.isEurope(countryOfDestination);

      if (screeningCountryResult.screeningDetails.length === 1) {
        if (departureCountry?.name === "Canada")
          if (
            !screeningCountryResult.screeningDetails[0].usOrigin &&
            !(
              screeningCountryResult.screeningDetails[0].eccnCode === "NC" ||
              screeningCountryResult.screeningDetails[0].eccnCode === "NL" ||
              screeningCountryResult.screeningDetails[0].eccnCode ===
                "NOT LISTED" ||
              screeningCountryResult.screeningDetails[0].eccnCode === "5400"
            )
          ) {
            const emptyScreeningDetails: ScreeningDetails = {
              eccnCode: "",
              message: "",
              trafficLightColor: "RED", // Valeur par défaut
              usOrigin: false,
            };

            /*
            const tmp = tscreeningCountryResult.screeningDetails.map((obj) => ({
              ...obj,
            }));*/
            screeningCountryResult.screeningDetails.push(emptyScreeningDetails);
            screeningCountryResult.screeningDetails[1].exportabilityRule = "";
            screeningCountryResult.screeningDetails[1].usOrigin = false;
            screeningCountryResult.screeningDetails[1].trafficLightColor =
              "RED";
            screeningCountryResult.screeningDetails[1].message = "EXPajout";
            screeningCountryResult.screeningDetails[1].moreDetailsLabel = "";
            screeningCountryResult.screeningDetails[1].moreDetailsLink = "";
          }
      }

      for (
        var index = 0;
        index < screeningCountryResult.screeningDetails.length;
        index++
      ) {
        if (departureCountry?.name === "Canada")
          if (
            !screeningCountryResult.screeningDetails[index].usOrigin &&
            !(
              screeningCountryResult.screeningDetails[index].eccnCode.trim() ==
                "NC" ||
              screeningCountryResult.screeningDetails[index].message.trim() ==
                "EXP072" ||
              screeningCountryResult.screeningDetails[index].eccnCode.trim() ===
                "NL" ||
              screeningCountryResult.screeningDetails[index].eccnCode.trim() ===
                "NOT LISTED" ||
              screeningCountryResult.screeningDetails[index].eccnCode.trim() ===
                "5400" ||
              screeningCountryResult.screeningDetails[index].message.trim() ===
                "EXP024"
            )
          ) {
            if (
              screeningCountryResult.screeningDetails[index]
                .exportabilityRule !== "RULE_SANCTIONED"
            ) {
              screeningCountryResult.screeningDetails[index].message = "EXP107";
              screeningCountryResult.screeningDetails[index].trafficLightColor =
                "YELLOW";
              if (
                pays_canda_GEP41.includes(endCountry?.name!) &&
                screeningCountryResult.screeningDetails[index].eccnCode.match(
                  DUREGX1_c
                )
              ) {
                screeningCountryResult.screeningDetails[index].message =
                  "EXP108";
              }

              break;
            } else {
              {
                //this.rootStore.jwtStore.mainTenant
                //countryOfDestination
                const policy = await this.api.Getpolicy(
                  countryOfDestination,
                  this.rootStore.jwtStore.mainTenant || ""
                );

                if (policy) {
                  const policy1 = await this.api.get_policy_file(
                    this.rootStore.jwtStore.mainTenant || ""
                  );

                  if (policy1) {
                    screeningCountryResult.screeningDetails[
                      index
                    ].moreDetailsLabel = "Download the Corporate Policy";
                    screeningCountryResult.screeningDetails[
                      index
                    ].moreDetailsLink =
                      "http://tool.ec-compliance.com:8090/ecback/public/upload/" +
                      policy1[0].file_name;
                  }

                  if (policy[0].feu == "RED")
                    screeningCountryResult.screeningDetails[
                      index
                    ].trafficLightColor = "RED";
                  if (policy[0].feu == "YELLOW")
                    screeningCountryResult.screeningDetails[
                      index
                    ].trafficLightColor = "YELLOW";
                  if (policy[0].feu == "GREEN")
                    screeningCountryResult.screeningDetails[
                      index
                    ].trafficLightColor = "GREEN";
                  if (policy[0].feu == null)
                    screeningCountryResult.screeningDetails[
                      index
                    ].trafficLightColor = "GREEN";
                  screeningCountryResult.screeningDetails[index].message =
                    policy[0].message;
                }
              }
            }
          }
      }
      max3 = screeningCountryResult.screeningDetails.length;
      const array4 = [...screeningCountryResult.screeningDetails];
      for (var index = 0; index < max3; index++) {
        if (
          screeningCountryResult.screeningDetails[index].message === "EXPajout"
        )
          array4.splice(index, 1);

        if (
          departureCountry?.name === "South Africa" &&
          screeningCountryResult.screeningDetails[index].message === "EXP019"
        )
          array4.splice(index, 1);
      }
      screeningCountryResult.screeningDetails = array4;
      //*******************cdc pas de vert usa to China, Russia, Venezuela************************** */
      for (
        var index = 0;
        index < screeningCountryResult.screeningDetails.length;
        index++
      ) {
        if (
          endCountry?.name === "China" ||
          endCountry?.name === "Russia" ||
          endCountry?.name === "Venezuela"
        ) {
          if (screeningCountryResult.screeningDetails[index].usOrigin) {
            if (
              screeningCountryResult.screeningDetails[index]
                .trafficLightColor == "GREEN" &&
              screeningCountryResult.screeningDetails[index].message !==
                "EXP126" &&
              screeningCountryResult.screeningDetails[index].message !==
                "EXP127"
            )
              screeningCountryResult.screeningDetails[index].trafficLightColor =
                "YELLOW";
          }
        }
      }

      const DUREGX = "^[0-9][A-E][0-9][0-9]{2}";
      const UNembargo = UNembargoDestination; //await this.api.isUNEmabargo(countryOfDestination);
      // const EUembargo = await this.api.isEUEmabargo(countryOfDestination);
      const NorwaySanction = await this.api.isNorwaySanction(endCountry?.name!);

      for (
        var index = 0;
        index < screeningCountryResult.screeningDetails.length;
        index++
      ) {
        if (
          screeningCountryResult.screeningDetails[index].exportabilityRule !==
            "RULE_SANCTIONED" &&
          departureCountry?.name === "Norway" &&
          !screeningCountryResult.screeningDetails[index].usOrigin &&
          screeningCountryResult.screeningDetails[index].eccnCode.match(DUREGX)
        ) {
          screeningCountryResult.screeningDetails[index].trafficLightColor =
            "YELLOW";
          screeningCountryResult.screeningDetails[index].message = "EXP028";
          screeningCountryResult.screeningDetails[index].moreDetailsLabel =
            "Please check the Guide to licensing procedure and other restrictions for export, dual-use licences.";
          screeningCountryResult.screeningDetails[index].moreDetailsLink =
            "https://www.gov.uk/guidance/export-controls-dual-use-items-software-and-technology-goods-for-torture-and-radioactive-sources#get-an-export-or-trade-licence";
        }

        if (
          screeningCountryResult.screeningDetails[index].exportabilityRule !==
            "RULE_SANCTIONED" &&
          departureCountry?.name === "Norway" &&
          NorwaySanction === "OK" &&
          !screeningCountryResult.screeningDetails[index].usOrigin &&
          (screeningCountryResult.screeningDetails[index].eccnCode === "NC" ||
            screeningCountryResult.screeningDetails[index].eccnCode === "NL" ||
            screeningCountryResult.screeningDetails[index].eccnCode ===
              "NOT LISTED")
        ) {
          screeningCountryResult.screeningDetails[index].trafficLightColor =
            "YELLOW";
          screeningCountryResult.screeningDetails[index].message = "EXP029";
          screeningCountryResult.screeningDetails[index].moreDetailsLabel =
            "Please check the Guide to licensing procedure and other restrictions for export, dual-use licences.";
          screeningCountryResult.screeningDetails[index].moreDetailsLink =
            "https://www.gov.uk/guidance/export-controls-dual-use-items-software-and-technology-goods-for-torture-and-radioactive-sources#get-an-export-or-trade-licence";
        }
        if (
          screeningCountryResult.screeningDetails[index].exportabilityRule !==
            "RULE_SANCTIONED" &&
          departureCountry?.name === "Norway" &&
          NorwaySanction !== "OK" &&
          !screeningCountryResult.screeningDetails[index].usOrigin &&
          (screeningCountryResult.screeningDetails[index].eccnCode === "NC" ||
            screeningCountryResult.screeningDetails[index].eccnCode === "NL")
        ) {
          screeningCountryResult.screeningDetails[index].trafficLightColor =
            "GREEN";
          screeningCountryResult.screeningDetails[index].message = "EXP044";
          screeningCountryResult.screeningDetails[index].moreDetailsLabel =
            "Please check the Guide to licensing procedure and other restrictions for export, dual-use licences.";
          screeningCountryResult.screeningDetails[index].moreDetailsLink =
            "https://www.gov.uk/guidance/export-controls-dual-use-items-software-and-technology-goods-for-torture-and-radioactive-sources#get-an-export-or-trade-licence";
        }
      }

      //**********************************regle 744  **********************           */
      let countryfin = 0;
      if (endCountry?.id) countryfin = endCountry.id;

      const is744 = await this.api.is744(countryfin);

      //console.log('706')
      if (is744 === "OK") {
        let oui = "ko";
        let eccn_enc = "";
        for (var index = 0; index < eccns.length; index++) {
          if (eccns[index].usOrigin) {
            oui = "ok";
            eccn_enc = eccns[index].eccnCode;
          }
        }

        if (oui === "ok") {
          const is744_code = await this.api.is744_code(eccn_enc);
          if (is744_code === "OK") {
            for (
              var index = 0;
              index < screeningCountryResult.screeningDetails.length;
              index++
            ) {
              if (
                screeningCountryResult.screeningDetails[index].message.includes(
                  "According to US part 774, no US license is required for this item for"
                ) ||
                screeningCountryResult.screeningDetails[index].message ===
                  "EXP114"
              ) {
                screeningCountryResult.screeningDetails.splice(index, 1);
                break;
              }
            }
            let max4 = screeningCountryResult.screeningDetails.length;
            /*
            const tmp1 = tscreeningCountryResult.screeningDetails.map(
              (obj) => ({
                ...obj,
              })
            );*/
            const emptyScreeningDetails: ScreeningDetails = {
              eccnCode: "",
              message: "",
              trafficLightColor: "RED", // Valeur par défaut
              usOrigin: false,
            };
            screeningCountryResult.screeningDetails.push(emptyScreeningDetails);

            screeningCountryResult.screeningDetails[max4].eccnCode = eccn_enc;
            screeningCountryResult.screeningDetails[max4].trafficLightColor =
              "YELLOW";
            screeningCountryResult.screeningDetails[max4].message = "EXP009";
            screeningCountryResult.screeningDetails[max4].exportabilityRule =
              "RULE_744";
            screeningCountryResult.screeningDetails[max4].moreDetailsLabel =
              "Please check the Guide to licensing procedure and other restrictions for export, dual-use licences.";
            screeningCountryResult.screeningDetails[max4].moreDetailsLink =
              "https://www.gov.uk/guidance/export-controls-dual-use-items-software-and-technology-goods-for-torture-and-radioactive-sources#get-an-export-or-trade-licence";
          }
        }
      }

      // traitement
      const DUREGX1 = "^[0-9][A-E][0-9][0-9]{2}";

      if (endCountry?.id) countryfin = endCountry.id;

      const ISITAR = await this.api.isitar(countryfin);
      if (ISITAR === "OK")
        for (
          var index = 0;
          index < screeningCountryResult.screeningDetails.length;
          index++
        ) {
          if (
            screeningCountryResult.screeningDetails[index].eccnCode
              .substring(0, 5)
              .match(DUREGX1) &&
            screeningCountryResult.screeningDetails[index].eccnCode.includes(
              ".y"
            ) &&
            (screeningCountryResult.screeningDetails[index].eccnCode.includes(
              "6"
            ) ||
              screeningCountryResult.screeningDetails[index].eccnCode.includes(
                "5"
              )) &&
            (endCountry?.name === "China" ||
              endCountry?.name === "Russia" ||
              endCountry?.name === "Venezuela") &&
            screeningCountryResult.screeningDetails[index].usOrigin &&
            (screeningCountryResult.screeningDetails[index].message.includes(
              "According to US part 774,"
            ) ||
              screeningCountryResult.screeningDetails[index].message ===
                "EXP114" ||
              screeningCountryResult.screeningDetails[index].message.includes(
                "EXP054"
              ))
          ) {
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
            screeningCountryResult.screeningDetails[index].message = "EXP037";
            screeningCountryResult.screeningDetails[index].moreDetailsLabel =
              "";
            screeningCountryResult.screeningDetails[index].moreDetailsLink = "";
          }
        }

      ///******************** exportability  russia belarus EXP026   */

      //regle 01
      if (
        departureCountry?.name === "France" &&
        (endCountry?.name === "Russia" || endCountry?.name === "Belarus")
      ) {
        for (
          var index = 0;
          index < screeningCountryResult.screeningDetails.length;
          index++
        ) {
          if (!screeningCountryResult.screeningDetails[index].usOrigin) {
            if (
              screeningCountryResult.screeningDetails[index].eccnCode.match(
                DUREGX1
              )
            ) {
              if (
                screeningCountryResult.screeningDetails[index].message ===
                "EXP026"
              ) {
                screeningCountryResult.screeningDetails[index].message =
                  "EXP038";
                screeningCountryResult.screeningDetails[
                  index
                ].trafficLightColor = "RED";
                if (endCountry?.name === "Belarus") {
                  screeningCountryResult.screeningDetails[index].message =
                    "EXP040";
                  //  screeningCountryResult.screeningDetails[index].trafficLightColor="YELLOW"
                }
              }
            }
          }
        }
      }

      //************** cdc complement */
      for (
        var index = 0;
        index < screeningCountryResult.screeningDetails.length;
        index++
      ) {
        if (
          (endCountry?.name === "Belarus" || endCountry?.name === "Russia") &&
          (screeningCountryResult.screeningDetails[index].eccnCode === "NC" ||
            screeningCountryResult.screeningDetails[index].eccnCode === "NL" ||
            screeningCountryResult.screeningDetails[index].eccnCode ===
              "NOT LISTED")
        ) {
          if (
            screeningCountryResult.screeningDetails[index].message === "EXP002"
          ) {
            screeningCountryResult.screeningDetails[index].message = "EXP046";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";

            if (endCountry?.name === "Belarus") {
              screeningCountryResult.screeningDetails[index].message = "EXP048";
              // screeningCountryResult.screeningDetails[index].trafficLightColor="YELLOW"
            }
          }
        }
      }

      // CDC   netherlande
      const iseurope1 = await this.api.isEurope(endCountry?.id!);
      if (departureCountry?.name === "Netherlands" && iseurope1 === "OK") {
        for (
          var index = 0;
          index < screeningCountryResult.screeningDetails.length;
          index++
        ) {
          if (!screeningCountryResult.screeningDetails[index].usOrigin) {
            if (
              screeningCountryResult.screeningDetails[index].eccnCode.match(
                DUREGX1
              )
            ) {
              screeningCountryResult.screeningDetails[index].message = "EXP062";
              screeningCountryResult.screeningDetails[index].trafficLightColor =
                "GREEN";
            }
          }
        }
      }

      /// regle 02
      if (
        departureCountry?.name !== "France" &&
        (endCountry?.name === "Russia" || endCountry?.name === "Belarus") &&
        iseurope == "OK"
      ) {
        for (
          var index = 0;
          index < screeningCountryResult.screeningDetails.length;
          index++
        ) {
          if (!screeningCountryResult.screeningDetails[index].usOrigin) {
            if (
              screeningCountryResult.screeningDetails[index].eccnCode.match(
                DUREGX1
              )
            ) {
              if (
                screeningCountryResult.screeningDetails[index].message ===
                "EXP026"
              ) {
                screeningCountryResult.screeningDetails[index].message =
                  "EXP039";
                screeningCountryResult.screeningDetails[
                  index
                ].trafficLightColor = "RED";
                if (endCountry?.name === "Belarus") {
                  screeningCountryResult.screeningDetails[index].message =
                    "EXP041";
                  //   screeningCountryResult.screeningDetails[index].trafficLightColor="YELLOW"
                }
              }
            }

            if (
              screeningCountryResult.screeningDetails[index].eccnCode ===
                "NC" ||
              screeningCountryResult.screeningDetails[index].eccnCode ===
                "NL" ||
              screeningCountryResult.screeningDetails[index].eccnCode ===
                "NOT LISTED"
            ) {
              if (
                screeningCountryResult.screeningDetails[index].message ===
                "EXP002"
              ) {
                // cdc complement voir plus haut
                //screeningCountryResult.screeningDetails[index].message="EXP039"

                if (endCountry?.name === "Belarus") {
                  // cdc complement voir plus haut
                  // screeningCountryResult.screeningDetails[index].trafficLightColor="RED"
                  //  screeningCountryResult.screeningDetails[index].message="EXP041"
                  //  screeningCountryResult.screeningDetails[index].trafficLightColor="YELLOW"
                }
              }
            }
          }
        }
      }
      ///regle us
      for (
        var index = 0;
        index < screeningCountryResult.screeningDetails.length;
        index++
      ) {
        if (
          (endCountry?.name === "Russia" || endCountry?.name === "Belarus") &&
          screeningCountryResult.screeningDetails[index].usOrigin
        ) {
          if (
            screeningCountryResult.screeningDetails[index].message !==
              "EXP126" &&
            screeningCountryResult.screeningDetails[index].message !==
              "EXP127" &&
            (screeningCountryResult.screeningDetails[index].eccnCode.substring(
              0,
              1
            ) === "0" ||
              screeningCountryResult.screeningDetails[index].eccnCode.substring(
                0,
                1
              ) === "1" ||
              screeningCountryResult.screeningDetails[index].eccnCode.substring(
                0,
                1
              ) === "2" ||
              screeningCountryResult.screeningDetails[index].eccnCode.substring(
                0,
                1
              ) === "3" ||
              screeningCountryResult.screeningDetails[index].eccnCode.substring(
                0,
                1
              ) === "4" ||
              screeningCountryResult.screeningDetails[index].eccnCode.substring(
                0,
                1
              ) === "5" ||
              screeningCountryResult.screeningDetails[index].eccnCode.substring(
                0,
                1
              ) === "6" ||
              screeningCountryResult.screeningDetails[index].eccnCode.substring(
                0,
                1
              ) === "7" ||
              screeningCountryResult.screeningDetails[index].eccnCode.substring(
                0,
                1
              ) === "8" ||
              screeningCountryResult.screeningDetails[index].eccnCode.substring(
                0,
                1
              ) === "9")
          ) {
            screeningCountryResult.screeningDetails[index].message = "EXP043";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "RED";
            screeningCountryResult.screeningDetails[
              index
            ].controlReasonsForRule4 = undefined;
          }
        }
      }

      //******************remove duplicate EXP043 */
      max3 = screeningCountryResult.screeningDetails.length;
      const array1 = [...screeningCountryResult.screeningDetails];
      ok_rem = "KO";
      for (var index = 0; index < max3; index++) {
        if (
          screeningCountryResult.screeningDetails[index].message === "EXP043" &&
          ok_rem === "OK"
        )
          array1.splice(index, 1);

        if (screeningCountryResult.screeningDetails[index].message === "EXP043")
          ok_rem = "OK";
      }
      screeningCountryResult.screeningDetails = array1;

      //******************************CDC germany remove EXP0026 */
      max3 = screeningCountryResult.screeningDetails.length;
      const array2 = [...screeningCountryResult.screeningDetails];
      ok_rem = "KO";
      let ok_rem_05 = "KO";

      for (var index = 0; index < max3; index++) {
        if (
          screeningCountryResult.screeningDetails[index].message === "EXP075" ||
          screeningCountryResult.screeningDetails[index].message === "EXP105"
        )
          ok_rem = "OK";

        if (screeningCountryResult.screeningDetails[index].message === "EXP105")
          ok_rem_05 = "OK";
      }

      for (var index = 0; index < max3; index++) {
        if (
          screeningCountryResult.screeningDetails[index].message === "EXP026" &&
          ok_rem === "OK"
        )
          array2.splice(index, 1);

        if (
          (screeningCountryResult.screeningDetails[index].message ===
            "EXP004" ||
            screeningCountryResult.screeningDetails[index].message ===
              "EXP030") &&
          ok_rem_05 === "OK"
        )
          array2.splice(index, 1);
      }
      screeningCountryResult.screeningDetails = array2;

      max3 = screeningCountryResult.screeningDetails.length;
      const array3 = [...screeningCountryResult.screeningDetails];
      for (var index = 0; index < max3; index++) {
        if (
          screeningCountryResult.screeningDetails[index].message === "EXP075" &&
          ok_rem_05 === "OK"
        )
          array3.splice(index, 1);
      }
      screeningCountryResult.screeningDetails = array3;
      //*******************  cdd russia  **********************************************/
      if (departureCountry?.name === "Russia") {
        let max4 = screeningCountryResult.screeningDetails.length;
        const emptyScreeningDetails: ScreeningDetails = {
          eccnCode: "",
          message: "",
          trafficLightColor: "RED", // Valeur par défaut
          usOrigin: false,
        }; /*
        const tmp1 = tscreeningCountryResult.screeningDetails.map((obj) => ({
          ...obj,
        }));*/
        screeningCountryResult.screeningDetails.push(emptyScreeningDetails);

        screeningCountryResult.screeningDetails[max4].eccnCode =
          "Restrictions on exports from " + departureCountry?.name || "";

        screeningCountryResult.screeningDetails[max4].destinationCountry = "";
        screeningCountryResult.screeningDetails[max4].publicListName = "";
        screeningCountryResult.screeningDetails[max4].keywork = "";
        screeningCountryResult.screeningDetails[max4].trafficLightColor =
          "YELLOW";
        screeningCountryResult.screeningDetails[max4].message = "EXP051";
        screeningCountryResult.screeningDetails[max4].additionalMessageLabel =
          "Please check details on the Russian government website";
        screeningCountryResult.screeningDetails[max4].additionalMessageLink =
          "http://government.ru/en/docs/44762/";
        screeningCountryResult.screeningDetails[max4].exportabilityRule =
          "RUSSIA_NC";
      }

      let out = "ko";

      for (var index = 0; index < eccns.length; index++) {
        if (
          eccns[index].usOrigin &&
          (eccns[index].eccnCode == "5A001.f.1" ||
            eccns[index].eccnCode == "5A980" ||
            eccns[index].eccnCode == "5D001.a" ||
            eccns[index].eccnCode == "5D001.b" ||
            eccns[index].eccnCode == "5D001.c" ||
            eccns[index].eccnCode == "5D980.a" ||
            eccns[index].eccnCode == "5D980.b" ||
            eccns[index].eccnCode == "5E001.a" ||
            eccns[index].eccnCode == "5E980")
        ) {
          for (
            var index1 = 0;
            index1 < screeningCountryResult.screeningDetails.length;
            index1++
          )
            if (
              screeningCountryResult.screeningDetails[index1].message ==
              "EXP010"
            ) {
              screeningCountryResult.screeningDetails[index1].message =
                "EXP052";
              out = "ok";
            }

          if (out === "ok") continue;

          let max4 = screeningCountryResult.screeningDetails.length;

          const emptyScreeningDetails: ScreeningDetails = {
            eccnCode: "",
            message: "",
            trafficLightColor: "RED", // Valeur par défaut
            usOrigin: false,
          };
          screeningCountryResult.screeningDetails.push(emptyScreeningDetails);

          screeningCountryResult.screeningDetails[max4].eccnCode =
            eccns[index].eccnCode;
          screeningCountryResult.screeningDetails[max4].destinationCountry = "";
          screeningCountryResult.screeningDetails[max4].publicListName = "";
          screeningCountryResult.screeningDetails[max4].keywork = "";

          screeningCountryResult.screeningDetails[max4].trafficLightColor =
            "YELLOW";
          screeningCountryResult.screeningDetails[max4].message = "EXP052";
        }
      }
      ///********************

      if (DoMu === "OK") {
        let max1Mu = screeningCountryResult.screeningDetails.length;
        for (var index = 0; index < max1Mu; index++) {
          if (
            screeningCountryResult.screeningDetails[index].message === "EXP009"
          ) {
            screeningCountryResult.screeningDetails[index].message = "EXPajout";
          }
        }
      }

      let is_hs_russia1 = "KO";
      const regexp1 = /^(?!00)\d+([\s.]\d+)*$/;
      let remplacer26_1 = 0;
      const iseurope_cont = EuropreOrigin;
      let is_contournement = await this.api.isContournement_russia(
        endCountry?.name!
      );
      var element = screeningCountryResult.screeningDetails.length;
      if (
        iseurope_cont === "OK" &&
        endCountry?.name !== "Russia" &&
        endCountry?.name !== "Belarus"
      ) {
        for (var index = 0; index < element; index++) {
          if (
            regexp1.test(
              screeningCountryResult.screeningDetails[index].eccnCode
            ) &&
            !screeningCountryResult.screeningDetails[index].usOrigin
          ) {
            is_hs_russia1 = await this.api.get_hs_codes_russia(
              screeningCountryResult.screeningDetails[index].eccnCode
            );

            //******************* */

            for (var index = 0; index < element; index++) {
              let msg = screeningCountryResult.screeningDetails[index].message;

              //cas particulier russie NC (guerre en ukrain)
              if (
                msg === "EXP026" ||
                msg === "EXP004" ||
                (msg === "EXP030" && endCountry?.name === "Japan")
              ) {
                remplacer26_1 = 1;
                if (is_hs_russia1 === "OK" && is_contournement === "OK") {
                  screeningCountryResult.screeningDetails[index].message =
                    "EXP115";
                  screeningCountryResult.screeningDetails[
                    index
                  ].trafficLightColor = "YELLOW";
                  screeningCountryResult.screeningDetails[
                    index
                  ].exportabilityRule = "";
                  screeningCountryResult.screeningDetails[index].eccnCode =
                    "Russia EU contournement";

                  if (MsAnnexe.toUpperCase().includes("ANNEX XL")) {
                    screeningCountryResult.screeningDetails[index].message =
                      "EXP118";
                    screeningCountryResult.screeningDetails[
                      index
                    ].trafficLightColor = "RED";

                    screeningCountryResult.screeningDetails[
                      index
                    ].exportabilityRule = "";
                    screeningCountryResult.screeningDetails[index].eccnCode =
                      "Russia HS code Annex XL";
                  }
                } else {
                  screeningCountryResult.screeningDetails[index].message =
                    "EXP116";
                  screeningCountryResult.screeningDetails[
                    index
                  ].trafficLightColor = "GREEN";
                  screeningCountryResult.screeningDetails[
                    index
                  ].exportabilityRule = "";
                  screeningCountryResult.screeningDetails[index].eccnCode =
                    "Russia EU no contournement";

                  if (MsAnnexe) {
                    if (MsAnnexe.toUpperCase().includes("ANNEX XL")) {
                      screeningCountryResult.screeningDetails[index].message =
                        "EXP118";
                      screeningCountryResult.screeningDetails[
                        index
                      ].trafficLightColor = "YELLOW";
                      if (endCountry?.name === "Russia")
                        screeningCountryResult.screeningDetails[
                          index
                        ].trafficLightColor = "RED";

                      screeningCountryResult.screeningDetails[
                        index
                      ].exportabilityRule = "";
                      screeningCountryResult.screeningDetails[index].eccnCode =
                        "Russia HS code Annex XL";
                    }
                  }
                }
              }
            }
            //****************** */

            if (remplacer26_1 == 0) {
              ///********************************** */

              let max4 = screeningCountryResult.screeningDetails.length;
              const emptyScreeningDetails: ScreeningDetails = {
                eccnCode: "",
                message: "",
                trafficLightColor: "RED", // Valeur par défaut
                usOrigin: false,
              }; /*
              const tmp1 = tscreeningCountryResult.screeningDetails.map(
                (obj) => ({ ...obj })
              );*/
              screeningCountryResult.screeningDetails.push(
                emptyScreeningDetails
              );
              if (is_hs_russia1 == "OK" && is_contournement === "OK") {
                screeningCountryResult.screeningDetails[index].message =
                  "EXP115";
                screeningCountryResult.screeningDetails[
                  index
                ].trafficLightColor = "YELLOW";
                screeningCountryResult.screeningDetails[
                  index
                ].exportabilityRule = "";
                screeningCountryResult.screeningDetails[index].eccnCode =
                  "Russia EU contournement";

                if (MsAnnexe.toUpperCase().includes("ANNEX XL")) {
                  screeningCountryResult.screeningDetails[index].message =
                    "EXP118";

                  screeningCountryResult.screeningDetails[
                    index
                  ].trafficLightColor = "YELLOW";

                  if (endCountry?.name === "Russia")
                    screeningCountryResult.screeningDetails[
                      index
                    ].trafficLightColor = "RED";

                  screeningCountryResult.screeningDetails[
                    index
                  ].exportabilityRule = "";
                  screeningCountryResult.screeningDetails[index].eccnCode =
                    "Russia HS code Annex XL";
                }
              } else {
                screeningCountryResult.screeningDetails[index].message =
                  "EXP116";
                screeningCountryResult.screeningDetails[
                  index
                ].trafficLightColor = "GREEN";
                screeningCountryResult.screeningDetails[
                  index
                ].exportabilityRule = "";
                screeningCountryResult.screeningDetails[index].eccnCode =
                  "Russia EU no contournement";

                if (MsAnnexe.toUpperCase().includes("ANNEX XL")) {
                  screeningCountryResult.screeningDetails[index].message =
                    "EXP118";

                  screeningCountryResult.screeningDetails[
                    index
                  ].trafficLightColor = "YELLOW";

                  if (endCountry?.name === "Russia")
                    screeningCountryResult.screeningDetails[
                      index
                    ].trafficLightColor = "RED";

                  screeningCountryResult.screeningDetails[
                    index
                  ].exportabilityRule = "";
                  screeningCountryResult.screeningDetails[index].eccnCode =
                    "Russia HS code Annex XL";
                }
              }

              //*********************************** */
            }
          }
        }
      }

      //////////////// CDC départ Sweden
      let ajouter = "no";
      const iseurope4 = EuropreOrigin; // await this.api.isEurope(countryOfOrigin);
      for (
        var index = 0;
        index < screeningCountryResult.screeningDetails.length;
        index++
      ) {
        if (
          screeningCountryResult.screeningDetails[index].eccnCode.includes(
            "A2"
          ) ||
          screeningCountryResult.screeningDetails[index].eccnCode.includes(
            "ML"
          ) ||
          (screeningCountryResult.screeningDetails[index].eccnCode.includes(
            "X"
          ) &&
            !screeningCountryResult.screeningDetails[index].eccnCode.includes(
              "Russia HS code Annex XL"
            )) ||
          screeningCountryResult.screeningDetails[index].eccnCode.startsWith(
            "00"
          )
        ) {
          if (iseurope4 === "OK") {
            if (endCountry?.name === "France") {
              ajouter = "yes";
            }
          } else {
            //   if (departureCountry?.name === "United States of America (USA)") {
            if (endCountry?.name === "France") {
              ajouter = "yes";
            }
            //   }
          }
        }

        if (!screeningCountryResult.screeningDetails[index].usOrigin) {
          if (
            screeningCountryResult.screeningDetails[index].eccnCode.includes(
              "00"
            )
          ) {
            if (departureCountry?.name === "Germany") {
              if (
                screeningCountryResult.screeningDetails[index].message.includes(
                  "Military equipment. A license from"
                )
              ) {
                screeningCountryResult.screeningDetails[
                  index
                ].trafficLightColor = "YELLOW";
                screeningCountryResult.screeningDetails[index].message =
                  "EXP074";
              }
            }
          }

          if (
            screeningCountryResult.screeningDetails[index].eccnCode.includes(
              "ML"
            ) ||
            screeningCountryResult.screeningDetails[index].eccnCode.includes(
              "A2"
            )
          ) {
            if (departureCountry?.name === "Sweden") {
              if (
                screeningCountryResult.screeningDetails[index].message.includes(
                  "Military equipment. A license from"
                )
              ) {
                screeningCountryResult.screeningDetails[
                  index
                ].trafficLightColor = "YELLOW";
                screeningCountryResult.screeningDetails[index].message =
                  "EXP070";
              }
            } else {
              if (departureCountry?.name === "France") {
                if (
                  screeningCountryResult.screeningDetails[
                    index
                  ].message.includes("Military equipment. A license from")
                ) {
                  screeningCountryResult.screeningDetails[
                    index
                  ].trafficLightColor = "YELLOW";
                  screeningCountryResult.screeningDetails[index].message =
                    "EXP005";
                }
              } else {
                if (iseurope4 === "OK") {
                  if (
                    screeningCountryResult.screeningDetails[
                      index
                    ].message.includes("Military equipment. A license from")
                  ) {
                    screeningCountryResult.screeningDetails[
                      index
                    ].trafficLightColor = "YELLOW";
                    screeningCountryResult.screeningDetails[index].message =
                      "EXP071";
                  }
                }
              }
            }
          }
        }
      }

      if (ajouter == "yes") {
        let max4 = screeningCountryResult.screeningDetails.length;
        /*const tmp1 = tscreeningCountryResult.screeningDetails.map((obj) => ({
          ...obj,
        }));
*/
        const emptyScreeningDetails: ScreeningDetails = {
          eccnCode: "",
          message: "",
          trafficLightColor: "RED", // Valeur par défaut
          usOrigin: false,
        };
        screeningCountryResult.screeningDetails.push(emptyScreeningDetails);

        screeningCountryResult.screeningDetails[max4].eccnCode = i18n.t(
          "pages.exportability.titre_fr_regle"
        );
        screeningCountryResult.screeningDetails[max4].destinationCountry = "";
        screeningCountryResult.screeningDetails[max4].publicListName = "";
        screeningCountryResult.screeningDetails[max4].keywork = "";
        screeningCountryResult.screeningDetails[max4].exportabilityRule =
          "RUSSIA_NC";
        screeningCountryResult.screeningDetails[max4].trafficLightColor =
          "YELLOW";

        screeningCountryResult.screeningDetails[max4].message = "EXP072";
      }

      ///***************************cdc AUKUS */

      let aukus = "K0";

      if (
        endCountry?.name === "Australia" ||
        endCountry?.name === "United Kingdom (UK)" ||
        endCountry?.name === "Northern Ireland (UK)"
      ) {
        for (
          var index = 0;
          index < screeningCountryResult.screeningDetails.length;
          index++
        ) {
          if (
            screeningCountryResult.screeningDetails[index].usOrigin &&
            ((screeningCountryResult.screeningDetails[index].eccnCode.includes(
              "0A501"
            ) &&
              !screeningCountryResult.screeningDetails[index].eccnCode
                .replace(/\./g, "")
                .includes("0A501y")) ||
              screeningCountryResult.screeningDetails[index].eccnCode.includes(
                "0A502"
              ) ||
              screeningCountryResult.screeningDetails[index].eccnCode.includes(
                "0A503"
              ) ||
              screeningCountryResult.screeningDetails[index].eccnCode.replace(
                /\./g,
                ""
              ) == "0A504i" ||
              screeningCountryResult.screeningDetails[index].eccnCode.replace(
                /\./g,
                ""
              ) == "0A505a" ||
              screeningCountryResult.screeningDetails[index].eccnCode.replace(
                /\./g,
                ""
              ) == "0A505x" ||
              screeningCountryResult.screeningDetails[index].eccnCode.includes(
                "0D501"
              ) ||
              screeningCountryResult.screeningDetails[index].eccnCode.includes(
                "0D505"
              ) ||
              screeningCountryResult.screeningDetails[index].eccnCode.includes(
                "0E501"
              ) ||
              screeningCountryResult.screeningDetails[index].eccnCode.replace(
                /\./g,
                ""
              ) == "0E501a" ||
              screeningCountryResult.screeningDetails[index].eccnCode.replace(
                /\./g,
                ""
              ) == "0E501b" ||
              screeningCountryResult.screeningDetails[index].eccnCode.includes(
                "0E504"
              ) ||
              screeningCountryResult.screeningDetails[index].eccnCode.includes(
                "0E505"
              ))
          ) {
            aukus = "OK";
          }
        }

        let eccn_enc = "";
        /*
            const tmp1 = tscreeningCountryResult.screeningDetails.map((obj) => ({
            ...obj,
          }));*/
        if (aukus === "OK") {
          for (
            var index = 0;
            index < screeningCountryResult.screeningDetails.length;
            index++
          ) {
            if (
              screeningCountryResult.screeningDetails[index].message.includes(
                "According to US part 774, no US license is required for this item for"
              ) ||
              screeningCountryResult.screeningDetails[index].message ===
                "EXP114"
            ) {
              eccn_enc =
                screeningCountryResult.screeningDetails[index].eccnCode;
              screeningCountryResult.screeningDetails.splice(index, 1);
              break;
            }
          }

          let max4 = screeningCountryResult.screeningDetails.length;

          const emptyScreeningDetails: ScreeningDetails = {
            eccnCode: "",
            message: "",
            trafficLightColor: "RED", // Valeur par défaut
            usOrigin: false,
          };

          screeningCountryResult.screeningDetails.push(emptyScreeningDetails);

          screeningCountryResult.screeningDetails[max4].eccnCode = eccn_enc;

          screeningCountryResult.screeningDetails[max4].trafficLightColor =
            "YELLOW";
          screeningCountryResult.screeningDetails[max4].message = "EXP112";
          screeningCountryResult.screeningDetails[max4].exportabilityRule =
            "RULE_744";
          screeningCountryResult.screeningDetails[max4].moreDetailsLabel =
            "Please check the Guide to licensing procedure and other restrictions for export, dual-use licences.";
          screeningCountryResult.screeningDetails[max4].moreDetailsLink =
            "https://www.gov.uk/guidance/export-controls-dual-use-items-software-and-technology-goods-for-torture-and-radioactive-sources#get-an-export-or-trade-licence";
        }
      }
      /// AUKUS 2

      let aukus2 = "K0";

      if (
        endCountry?.name === "Australia" ||
        endCountry?.name === "United Kingdom (UK)" ||
        endCountry?.name === "Canada" ||
        endCountry?.name === "Northern Ireland (UK)"
      ) {
        for (
          var index = 0;
          index < screeningCountryResult.screeningDetails.length;
          index++
        ) {
          if (
            //9A515.a, 9A515.a.1, 9A515.a.2, 9A515.a.3, 9A515.a.4, 9A515.g ,9E515.f

            screeningCountryResult.screeningDetails[index].usOrigin &&
            (screeningCountryResult.screeningDetails[index].eccnCode.replace(
              /\./g,
              ""
            ) == "9A515a" ||
              screeningCountryResult.screeningDetails[index].eccnCode.replace(
                /\./g,
                ""
              ) == "9A515a1" ||
              screeningCountryResult.screeningDetails[index].eccnCode.replace(
                /\./g,
                ""
              ) == "9A515a2" ||
              screeningCountryResult.screeningDetails[index].eccnCode.replace(
                /\./g,
                ""
              ) == "9A515a3" ||
              screeningCountryResult.screeningDetails[index].eccnCode.replace(
                /\./g,
                ""
              ) == "9A515a4" ||
              screeningCountryResult.screeningDetails[index].eccnCode.replace(
                /\./g,
                ""
              ) == "9A515g" ||
              screeningCountryResult.screeningDetails[index].eccnCode.replace(
                /\./g,
                ""
              ) == "9E515f")
          ) {
            aukus2 = "OK";
          }
        }
        let eccn_enc = "";
        /*       const tmp1 = tscreeningCountryResult.screeningDetails.map((obj) => ({
            ...obj,
          }));*/
        if (aukus2 === "OK") {
          for (
            var index = 0;
            index < screeningCountryResult.screeningDetails.length;
            index++
          ) {
            if (
              screeningCountryResult.screeningDetails[index].message.includes(
                "According to US part 774, no US license is required for this item for"
              ) ||
              screeningCountryResult.screeningDetails[index].message ===
                "EXP114"
            ) {
              eccn_enc =
                screeningCountryResult.screeningDetails[index].eccnCode;
              screeningCountryResult.screeningDetails.splice(index, 1);
              break;
            }
          }
          let max4 = screeningCountryResult.screeningDetails.length;
          const emptyScreeningDetails: ScreeningDetails = {
            eccnCode: "",
            message: "",
            trafficLightColor: "RED", // Valeur par défaut
            usOrigin: false,
          };
          screeningCountryResult.screeningDetails.push(emptyScreeningDetails);

          screeningCountryResult.screeningDetails[max4].eccnCode = eccn_enc;
          screeningCountryResult.screeningDetails[max4].trafficLightColor =
            "YELLOW";
          screeningCountryResult.screeningDetails[max4].message = "EXP113";
          screeningCountryResult.screeningDetails[max4].exportabilityRule =
            "RULE_744";
          screeningCountryResult.screeningDetails[max4].moreDetailsLabel =
            "Please check the Guide to licensing procedure and other restrictions for export, dual-use licences.";
          screeningCountryResult.screeningDetails[max4].moreDetailsLink =
            "https://www.gov.uk/guidance/export-controls-dual-use-items-software-and-technology-goods-for-torture-and-radioactive-sources#get-an-export-or-trade-licence";
        }
      }

      //*************** CDC same county remove  EU ML EXP071 / FR ML EXP005 / Germany ML EXP074  */

      for (
        var index = 0;
        index < screeningCountryResult.screeningDetails.length;
        index++
      ) {
        if (endCountry?.name == departureCountry?.name) {
          if (
            screeningCountryResult.screeningDetails[index].message ===
              "EXP071" ||
            screeningCountryResult.screeningDetails[index].message ===
              "EXP005" ||
            screeningCountryResult.screeningDetails[index].message === "EXP074"
          ) {
            screeningCountryResult.screeningDetails[index].message = "EXP076";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "GREEN";
          }
        }
      }

      //***************************************** */
      for (
        var index = 0;
        index < screeningCountryResult.screeningDetails.length;
        index++
      ) {
        if (departureCountry?.name === "South Africa") {
          if (
            screeningCountryResult.screeningDetails[index].message === "EXP010"
          ) {
            screeningCountryResult.screeningDetails[index].message = "EXP119";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
            okZA = false;
          }
        }
      }

      //******************CDC  netherland */

      for (
        var index = 0;
        index < screeningCountryResult.screeningDetails.length;
        index++
      ) {
        if (departureCountry?.name === "Netherlands") {
          if (
            screeningCountryResult.screeningDetails[index].message === "EXP071"
          ) {
            screeningCountryResult.screeningDetails[index].message = "EXP089";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
          }
        }
      }

      //*************** CDC singapour  */
      let remplacementDU = 0;
      let remplacementML = 0;
      const UNembargo1 = UNembargoDestination; //await this.api.isUNEmabargo(countryOfDestination);
      for (
        var index = 0;
        index < screeningCountryResult.screeningDetails.length;
        index++
      ) {
        if (UNembargo1 !== "OK") {
          if (departureCountry?.name == "France") {
            if (
              screeningCountryResult.screeningDetails[index].eccnCode.includes(
                "ML22"
              )
            ) {
              if (
                screeningCountryResult.screeningDetails[index].message ==
                "EXP005"
              )
                screeningCountryResult.screeningDetails[index].message =
                  "EXP090";
            }

            ///////////////////AMA
            if (
              screeningCountryResult.screeningDetails[index].eccnCode.includes(
                "AMA"
              )
            ) {
              if (
                screeningCountryResult.screeningDetails[index].message ==
                "EXP005"
              )
                screeningCountryResult.screeningDetails[index].message =
                  "EXP099";
            }
          }
        }

        if (
          departureCountry?.name == "Taiwan" &&
          !screeningCountryResult.screeningDetails[index].usOrigin
        ) {
          if (
            screeningCountryResult.screeningDetails[index].eccnCode.match(
              DUREGX1
            )
          ) {
            screeningCountryResult.screeningDetails[index].message = "EXP094";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
          }
        }

        if (
          departureCountry?.name == "Korea, South" &&
          !screeningCountryResult.screeningDetails[index].usOrigin
        ) {
          if (
            screeningCountryResult.screeningDetails[index].eccnCode.match(
              DUREGX1
            )
          ) {
            screeningCountryResult.screeningDetails[index].message = "EXP093";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
          }
        }

        if (departureCountry?.name == "Singapore") {
          if (
            screeningCountryResult.screeningDetails[index].eccnCode.match(
              DUREGX1
            )
          ) {
            if (remplacementDU == 0) remplacementDU = 1;
            if (
              screeningCountryResult.screeningDetails[index].message ===
              "EXP010"
            ) {
              remplacementDU = 2;
              screeningCountryResult.screeningDetails[index].message = "EXP077";
              screeningCountryResult.screeningDetails[index].trafficLightColor =
                "YELLOW";
            }
          }

          if (
            screeningCountryResult.screeningDetails[index].eccnCode.includes(
              "ML"
            ) ||
            screeningCountryResult.screeningDetails[index].eccnCode.includes(
              "A2"
            )
          ) {
            if (remplacementML == 0) remplacementML = 1;
            if (
              screeningCountryResult.screeningDetails[index].message.includes(
                "Military equipment"
              ) ||
              screeningCountryResult.screeningDetails[index].message == "EXP019"
            ) {
              remplacementML = 2;
              if (UNembargo1 == "OK") {
                screeningCountryResult.screeningDetails[index].message =
                  "EXP079";
                screeningCountryResult.screeningDetails[
                  index
                ].trafficLightColor = "RED";
              } else {
                screeningCountryResult.screeningDetails[index].message =
                  "EXP078";
                screeningCountryResult.screeningDetails[
                  index
                ].trafficLightColor = "YELLOW";
              }
            }
          }
        }
      }

      if (remplacementDU == 1) {
        let max4 = screeningCountryResult.screeningDetails.length;
        /*
        const tmp1 = tscreeningCountryResult.screeningDetails.map((obj) => ({
          ...obj,
        }));*/

        const emptyScreeningDetails: ScreeningDetails = {
          eccnCode: "",
          message: "",
          trafficLightColor: "RED", // Valeur par défaut
          usOrigin: false,
        };
        screeningCountryResult.screeningDetails.push(emptyScreeningDetails);
        screeningCountryResult.screeningDetails[max4].eccnCode =
          "Singapore Jurisdiction";
        screeningCountryResult.screeningDetails[max4].exportabilityRule = "";
        screeningCountryResult.screeningDetails[max4].trafficLightColor =
          "YELLOW";
        screeningCountryResult.screeningDetails[max4].message = "EXP077";
      }
      if (remplacementML == 1) {
        let max4 = screeningCountryResult.screeningDetails.length;
        const emptyScreeningDetails: ScreeningDetails = {
          eccnCode: "",
          message: "",
          trafficLightColor: "RED", // Valeur par défaut
          usOrigin: false,
        };
        /*      const tmp1 = tscreeningCountryResult.screeningDetails.map((obj) => ({
          ...obj,
        }));*/
        screeningCountryResult.screeningDetails.push(emptyScreeningDetails);
        if (UNembargo1 == "OK") {
          screeningCountryResult.screeningDetails[max4].eccnCode =
            "Singapore Jurisdiction";
          screeningCountryResult.screeningDetails[max4].trafficLightColor =
            "RED";
          screeningCountryResult.screeningDetails[max4].message = "EXP079";
          screeningCountryResult.screeningDetails[max4].exportabilityRule = "";
        } else {
          screeningCountryResult.screeningDetails[max4].eccnCode =
            "Singapore Jurisdiction";
          screeningCountryResult.screeningDetails[max4].trafficLightColor =
            "YELLOW";
          screeningCountryResult.screeningDetails[max4].message = "EXP078";
          screeningCountryResult.screeningDetails[max4].exportabilityRule = "";
        }
      }

      //********************* cdc X russia */
      const regexp_x = /^X\.[A-E]/;
      if (endCountry?.name === "Russia" || endCountry?.name === "Belarus") {
        let remplacer26_X = 0;
        let oui_x = 0;
        for (var index = 0; index < eccns.length; index++) {
          if (regexp_x.test(eccns[index].eccnCode)) {
            oui_x = 1;
            //******************* */
            let max33 = screeningCountryResult.screeningDetails.length;
            for (var index = 0; index < max33; index++) {
              let msg = screeningCountryResult.screeningDetails[index].message;
              //console.log(msg)

              //cas particulier russie NC (guerre en ukrain)
              if (msg === "EXP026") {
                remplacer26_X = 1;

                screeningCountryResult.screeningDetails[index].message =
                  "EXP086";
                screeningCountryResult.screeningDetails[
                  index
                ].trafficLightColor = "RED";
                screeningCountryResult.screeningDetails[
                  index
                ].exportabilityRule = "";
                screeningCountryResult.screeningDetails[index].eccnCode =
                  "Russia X code";
              }
            }
          }
        }
        if (remplacer26_X == 0 && oui_x == 1) {
          ///********************************** */

          let max4 = screeningCountryResult.screeningDetails.length;

          const emptyScreeningDetails: ScreeningDetails = {
            eccnCode: "",
            message: "",
            trafficLightColor: "RED", // Valeur par défaut
            usOrigin: false,
          };
          /*        const tmp1 = tscreeningCountryResult.screeningDetails.map((obj) => ({
            ...obj,
          }));
      
      */
          screeningCountryResult.screeningDetails.push(emptyScreeningDetails);

          screeningCountryResult.screeningDetails[index].message = "EXP086";
          screeningCountryResult.screeningDetails[index].trafficLightColor =
            "RED";
          screeningCountryResult.screeningDetails[index].exportabilityRule = "";
          screeningCountryResult.screeningDetails[index].eccnCode =
            "Russia X code";

          //*********************************** */
        }
      }

      ///******************** cdc Russia code Table HS_code_Russia*/

      const russiareg = "/^(?!00)d+([s.]d+)*$/;";

      let is_hs_russia = "KO";
      let is_us_hs_russia = "KO";
      const regexp = /^(?!00)\d+([\s.]\d+)*$/;
      let remplacer26 = 0;
      if (endCountry?.name === "Russia" || endCountry?.name === "Belarus") {
        for (var index = 0; index < eccns.length; index++) {
          if (regexp.test(eccns[index].eccnCode) && !eccns[index].usOrigin) {
            is_hs_russia = await this.api.get_hs_codes_russia(
              eccns[index].eccnCode
            );
            is_us_hs_russia = await this.api.get_us_hs_codes_russia(
              eccns[index].eccnCode
            );
            //******************* */
            let max33 = screeningCountryResult.screeningDetails.length;
            for (var index = 0; index < max33; index++) {
              let msg = screeningCountryResult.screeningDetails[index].message;
              //console.log(msg)

              //cas particulier russie NC (guerre en ukrain)
              if (
                msg === "EXP026" ||
                (msg == "EXP010" &&
                  departureCountry?.name == "United Kingdom (UK)")
              ) {
                remplacer26 = 1;
                if (is_hs_russia === "OK") {
                  if (EuropreOrigin === "OK") oksanction = false;
                  if (
                    endCountry?.name === "Russia" ||
                    departureCountry?.name == "China" ||
                    departureCountry?.name == "India"
                  ) {
                    if (departureCountry?.name == "United Kingdom (UK)")
                      screeningCountryResult.screeningDetails[index].message =
                        "EXP123";
                    else
                      screeningCountryResult.screeningDetails[index].message =
                        "EXP084";
                  } else
                    screeningCountryResult.screeningDetails[index].message =
                      "EXP121";

                  screeningCountryResult.screeningDetails[
                    index
                  ].trafficLightColor = "RED";
                  if (
                    MsAnnexe.toUpperCase().includes("ANNEX XVIII") &&
                    endCountry?.name !== "Belarus"
                  ) {
                    screeningCountryResult.screeningDetails[
                      index
                    ].trafficLightColor = "YELLOW";
                    if (departureCountry?.name != "United Kingdom (UK)")
                      screeningCountryResult.screeningDetails[index].message =
                        "EXP117";
                    else {
                      screeningCountryResult.screeningDetails[index].message =
                        "EXP123";
                      screeningCountryResult.screeningDetails[
                        index
                      ].trafficLightColor = "RED";
                    }
                  }
                  if (
                    MsAnnexe.toUpperCase().includes("ANNEX XXIII") &&
                    endCountry?.name !== "Belarus"
                  ) {
                    if (departureCountry?.name === "United Kingdom (UK)") {
                      screeningCountryResult.screeningDetails[index].message =
                        "EXP125";

                      screeningCountryResult.screeningDetails[
                        index
                      ].trafficLightColor = "RED";
                    }
                  }

                  if (
                    MsAnnexe.toUpperCase().includes("ANNEX XL") &&
                    endCountry?.name !== "Belarus"
                  ) {
                    if (departureCountry?.name != "United Kingdom (UK)")
                      screeningCountryResult.screeningDetails[index].message =
                        "EXP118";
                    else
                      screeningCountryResult.screeningDetails[index].message =
                        "EXP125";
                    screeningCountryResult.screeningDetails[
                      index
                    ].trafficLightColor = "YELLOW";
                    if (endCountry?.name === "Russia")
                      screeningCountryResult.screeningDetails[
                        index
                      ].trafficLightColor = "RED";

                    screeningCountryResult.screeningDetails[
                      index
                    ].exportabilityRule = "";
                    if (departureCountry?.name != "United Kingdom (UK)")
                      screeningCountryResult.screeningDetails[index].eccnCode =
                        "Russia HS code Annex XL";
                    else
                      screeningCountryResult.screeningDetails[index].eccnCode =
                        "UK to Russia HS G7";
                  } else {
                    screeningCountryResult.screeningDetails[index].eccnCode =
                      "Russia HS code in 833/2014";
                  }
                  screeningCountryResult.screeningDetails[
                    index
                  ].exportabilityRule = "";
                } else {
                  if (endCountry?.name === "Russia") {
                    if (departureCountry?.name == "United Kingdom (UK)")
                      screeningCountryResult.screeningDetails[index].message =
                        "EXP124";
                    else
                      screeningCountryResult.screeningDetails[index].message =
                        "EXP085";
                  } else
                    screeningCountryResult.screeningDetails[index].message =
                      "EXP122";

                  oksanction = false;
                  screeningCountryResult.screeningDetails[
                    index
                  ].trafficLightColor = "GREEN";
                  screeningCountryResult.screeningDetails[
                    index
                  ].exportabilityRule = "";
                  screeningCountryResult.screeningDetails[index].eccnCode =
                    "Russia HS code not in 833/2014";
                }
              }
            }
            //****************** */

            if (remplacer26 == 0) {
              ///********************************** */

              let max4 = screeningCountryResult.screeningDetails.length;
              const emptyScreeningDetails: ScreeningDetails = {
                eccnCode: "",
                message: "",
                trafficLightColor: "RED", // Valeur par défaut
                usOrigin: false,
              };
              /*            const tmp1 = tscreeningCountryResult.screeningDetails.map(
                (obj) => ({ ...obj })
              );
              */
              screeningCountryResult.screeningDetails.push(
                emptyScreeningDetails
              );
              if (is_hs_russia == "OK") {
                if (
                  endCountry?.name === "Russia" ||
                  departureCountry?.name == "China" ||
                  departureCountry?.name == "India"
                ) {
                  if (departureCountry?.name == "United Kingdom (UK)")
                    screeningCountryResult.screeningDetails[index].message =
                      "EXP123";
                  else
                    screeningCountryResult.screeningDetails[index].message =
                      "EXP084";
                } else
                  screeningCountryResult.screeningDetails[index].message =
                    "EXP121";

                screeningCountryResult.screeningDetails[
                  index
                ].trafficLightColor = "RED";

                if (
                  MsAnnexe.toUpperCase().includes("ANNEX XVIII") &&
                  endCountry?.name !== "Belarus" &&
                  departureCountry?.name != "United Kingdom (UK)"
                ) {
                  screeningCountryResult.screeningDetails[index].message =
                    "EXP117";
                  screeningCountryResult.screeningDetails[
                    index
                  ].trafficLightColor = "YELLOW";
                }

                if (
                  MsAnnexe.toUpperCase().includes("ANNEX XL") &&
                  endCountry?.name !== "Belarus" &&
                  departureCountry?.name != "United Kingdom (UK)"
                ) {
                  screeningCountryResult.screeningDetails[index].message =
                    "EXP118";
                  screeningCountryResult.screeningDetails[
                    index
                  ].trafficLightColor = "RED";

                  screeningCountryResult.screeningDetails[
                    index
                  ].exportabilityRule = "";
                  screeningCountryResult.screeningDetails[index].eccnCode =
                    "Russia HS code Annex XL";
                } else {
                  screeningCountryResult.screeningDetails[index].eccnCode =
                    "Russia HS code in 833/2014";
                }

                screeningCountryResult.screeningDetails[
                  index
                ].exportabilityRule = "";
              } else {
                if (endCountry?.name === "Russia") {
                  if (departureCountry?.name == "United Kingdom (UK)")
                    screeningCountryResult.screeningDetails[index].message =
                      "EXP124";
                  else
                    screeningCountryResult.screeningDetails[index].message =
                      "EXP085";
                } else
                  screeningCountryResult.screeningDetails[index].message =
                    "EXP122";

                oksanction = false;
                screeningCountryResult.screeningDetails[
                  index
                ].trafficLightColor = "GREEN";
                screeningCountryResult.screeningDetails[
                  index
                ].exportabilityRule = "";
                screeningCountryResult.screeningDetails[index].eccnCode =
                  "Russia HS code not in 833/2014";
              }

              //*********************************** */
            }
          }
        }
      }

      //*******************************les deux regles Exportabilité  SBDU et EU008 */

      let ECCN_EU008 = [
        "5A002a2",
        "5A002a3",
        "5A002b",
        "5D002a1",
        "5D002b",
        "5D002c1",
        "5E002b",
      ];

      let pays_interdit = [
        "Afghanistan",
        "Armenia",
        "Azerbaijan",
        "Belarus",
        "Cambodia",
        "Central African Republic",
        "China",
        "Congo",
        "Democratic Republic of the Congo",
        "Egypt",
        "Eritrea",
        "Georgia",
        "Hong Kong",
        "Iran",
        "Iraq",
        "Israel",
        "Kazakhstan",
        "Kyrgyzstan",
        "Lebanon",
        "Libya",
        "Macau",
        "Myanmar (Burma)",
        "Malaysia",
        "Mali",
        "Mauritius",
        "Mongolia",
        "Myanmar/Burma",
        "North Korea",
        "Oman",
        "Pakistan",
        "Qatar",
        "Russia",
        "Saudi Arabia",
        "Somalia",
        "South Sudan",
        "Sudan",
        "Syria",
        "Tajikistan",
        "Turkmenistan",
        "United Arab Emirates",
        "Uzbekistan",
        "Venezuela",
        "Yemen",
        "Zimbabwe",
      ];

      const DUREGX4 = "^[0-9][A-E][0-9][0-9]{2}";
      const iseurope3 = EuropeDestination; // await this.api.isEurope(countryOfDestination);
      //const EUembargo2 = await this.api.isEUEmabargo(countryOfDestination);

      if (departureCountry?.name === "France" && iseurope3 !== "OK") {
        for (
          var index = 0;
          index < screeningCountryResult.screeningDetails.length;
          index++
        ) {
          if (!screeningCountryResult.screeningDetails[index].usOrigin) {
            if (
              screeningCountryResult.screeningDetails[index].eccnCode.match(
                DUREGX4
              )
            ) {
              if (
                screeningCountryResult.screeningDetails[index].message ===
                "EXP026"
              ) {
                let Is_eu_annex_iv = await this.api.Is_eu_annex_iv(
                  screeningCountryResult.screeningDetails[index].eccnCode
                );
                if (Is_eu_annex_iv !== "OK") {
                  screeningCountryResult.screeningDetails[index].message =
                    "EXP091";
                  screeningCountryResult.screeningDetails[
                    index
                  ].trafficLightColor = "YELLOW";
                }
              }
            }
          }
        }
      }

      const iseurope5 = EuropreOrigin; // await this.api.isEurope(countryOfOrigin);

      if (
        iseurope5 === "OK" &&
        iseurope3 !== "OK" &&
        !pays_interdit.includes(endCountry?.name || "")
      ) {
        for (
          var index = 0;
          index < screeningCountryResult.screeningDetails.length;
          index++
        ) {
          if (!screeningCountryResult.screeningDetails[index].usOrigin) {
            if (
              ECCN_EU008.includes(
                screeningCountryResult.screeningDetails[index].eccnCode.replace(
                  /\./g,
                  ""
                )
              )
            ) {
              if (
                screeningCountryResult.screeningDetails[index].message ===
                "EXP026"
              ) {
                screeningCountryResult.screeningDetails[index].message =
                  "EXP092";
                screeningCountryResult.screeningDetails[
                  index
                ].trafficLightColor = "YELLOW";
              }

              if (
                departureCountry?.name === "France" &&
                screeningCountryResult.screeningDetails[index].message ===
                  "EXP091"
              ) {
                screeningCountryResult.screeningDetails[index].message =
                  "EXP092";
                screeningCountryResult.screeningDetails[
                  index
                ].trafficLightColor = "YELLOW";
              }
            }
          }
        }
      }

      for (
        var index = 0;
        index < screeningCountryResult.screeningDetails.length;
        index++
      ) {
        if (departureCountry?.name === "France")
          if (
            screeningCountryResult.screeningDetails[index].message === "EXP026"
          ) {
            screeningCountryResult.screeningDetails[index].message = "EXP091";
          }

        if (departureCountry?.name === "Germany")
          if (
            screeningCountryResult.screeningDetails[index].message === "EXP026"
          ) {
            screeningCountryResult.screeningDetails[index].message = "EXP095";
          }

        if (departureCountry?.name === "United Kingdom (UK)")
          if (
            screeningCountryResult.screeningDetails[index].message.includes(
              "Military equipment. A license from "
            )
          ) {
            screeningCountryResult.screeningDetails[index].message = "EXP096";
          }

        if (departureCountry?.name === "South Africa")
          if (
            screeningCountryResult.screeningDetails[index].message.includes(
              "Military equipment. A license from "
            )
          ) {
            screeningCountryResult.screeningDetails[index].message = "EXP120";
          }
      }

      /// french eccn

      for (
        var index = 0;
        index < screeningCountryResult.screeningDetails.length;
        index++
      ) {
        if (departureCountry?.name === "France") {
          if (
            screeningCountryResult.screeningDetails[index].message === "EXP091"
          ) {
            const iseurope_french = EuropeDestination;
            //await this.api.isEurope(countryOfDestination);

            if (iseurope_french != "OK") {
              let code_french = await this.api.get_code_french(
                screeningCountryResult.screeningDetails[index].eccnCode
              );
              if (code_french !== "KO") {
                screeningCountryResult.screeningDetails[index].message =
                  "EXP110";
                screeningCountryResult.screeningDetails[
                  index
                ].trafficLightColor = "YELLOW";
              }
            }
          }
        }
      }

      ///**********************************EuDeux */

      for (var index = 0; index < eccns.length; index++) {
        const feu = await this.api.EU002(
          countryOfOrigin,
          countryOfDestination,
          screeningCountryResult.screeningDetails[index].eccnCode
        );

        if (
          screeningCountryResult.screeningDetails[index].message === "EXP091" ||
          screeningCountryResult.screeningDetails[index].message === "EXP026" ||
          screeningCountryResult.screeningDetails[index].message === "EXP095"
        )
          if (feu === "orange") {
            // screeningCountryResult.globalTrafficLightColor = "YELLOW";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
            screeningCountryResult.screeningDetails[index].message = "EXP128";
          }
      }

      //**********************cdc FR ML To CHINA */

      for (
        var index = 0;
        index < screeningCountryResult.screeningDetails.length;
        index++
      ) {
        if (
          departureCountry?.name === "France" &&
          endCountry?.name === "China"
        ) {
          if (
            screeningCountryResult.screeningDetails[index].message === "EXP019"
          ) {
            screeningCountryResult.screeningDetails[index].message = "EXP111";
            screeningCountryResult.screeningDetails[index].trafficLightColor =
              "YELLOW";
          }
        }
      }

      //************************EU 2021/821  */

      for (
        var index = 0;
        index < screeningCountryResult.screeningDetails.length;
        index++
      ) {
        if (departureCountry?.name === "France" || EuropreOrigin) {
          if (
            screeningCountryResult.screeningDetails[index].message === "EXP004"
          ) {
            const isAnnexe = await this.api.isAnnexe(
              screeningCountryResult.screeningDetails[index].eccnCode
            );
            const russiareg = "^[0-9. ]+$";
            if (isAnnexe != "OK" && departureCountry?.name === "France") {
              screeningCountryResult.screeningDetails[index].message = "EXP097";
              screeningCountryResult.screeningDetails[index].trafficLightColor =
                "GREEN";
            }

            if (
              screeningCountryResult.screeningDetails[index].eccnCode.match(
                russiareg
              )
            ) {
              screeningCountryResult.screeningDetails[index].message =
                "EXPajout";
            }
          }
        }
      }

      //EXP030 Exlude

      let Exclus = false;
      for (
        var index = 0;
        index < screeningCountryResult.screeningDetails.length;
        index++
      ) {
        if (
          screeningCountryResult.screeningDetails[index].message === "EXP118"
        ) {
          Exclus = true;
        }
      }

      for (
        var index = 0;
        index < screeningCountryResult.screeningDetails.length;
        index++
      ) {
        if (
          screeningCountryResult.screeningDetails[index].message === "EXP030"
        ) {
          if (Exclus)
            screeningCountryResult.screeningDetails[index].message = "EXPajout";
        }
      }

      max3 = screeningCountryResult.screeningDetails.length;
      const array5 = [...screeningCountryResult.screeningDetails];
      for (var index = 0; index < max3; index++) {
        if (
          screeningCountryResult.screeningDetails[index].message === "EXPajout"
        )
          array5.splice(index, 1);
      }
      screeningCountryResult.screeningDetails = array5;
      //****************message aditionel EXP054 */

      let ajout_exp0054 = 0;
      let max33 = screeningCountryResult.screeningDetails.length;
      let code_exception = "";
      for (var index = 0; index < max33; index++) {
        let msg = screeningCountryResult.screeningDetails[index].message;

        if (msg === "EXP054") {
          ajout_exp0054 = 1;
          code_exception =
            screeningCountryResult.screeningDetails[index].eccnCode;
        }
      }

      if (ajout_exp0054 == 1) {
        let max4 = screeningCountryResult.screeningDetails.length;
        /*
        const tmp1 = tscreeningCountryResult.screeningDetails.map((obj) => ({
          ...obj,
        }));*/

        const emptyScreeningDetails: ScreeningDetails = {
          eccnCode: "",
          message: "",
          trafficLightColor: "RED", // Valeur par défaut
          usOrigin: false,
        };
        screeningCountryResult.screeningDetails.push(emptyScreeningDetails);
        screeningCountryResult.screeningDetails[index].controlReasonsForRule4 =
          undefined;
        screeningCountryResult.screeningDetails[index].message = "EXP106";
        screeningCountryResult.screeningDetails[index].trafficLightColor =
          "YELLOW";
        screeningCountryResult.screeningDetails[index].exportabilityRule =
          "EXCEPTION";

        screeningCountryResult.screeningDetails[index].eccnCode =
          "U.S license exceptions";

        code_exception = await this.api.get_code_exception(code_exception);
      }
      //***************enlever les double***************************** */

      screeningCountryResult.screeningDetails =
        screeningCountryResult.screeningDetails.filter(
          (detail, index, self) =>
            index === self.findIndex((t) => t.message === detail.message)
        );

      //******************************************** */
      let max34 = screeningCountryResult.screeningDetails.length;
      let okDU = false;
      const DUREGX5 = "^[0-9][A-E][0-9][0-9]{2}";
      for (var index = 0; index < max34; index++) {
        if (
          screeningCountryResult.screeningDetails[index].eccnCode.match(DUREGX5)
        ) {
          okDU = true;
        }
      }

      if (okDU && okZA && departureCountry?.name === "South Africa") {
        let max4 = screeningCountryResult.screeningDetails.length;
        /*
        const tmp1 = tscreeningCountryResult.screeningDetails.map((obj) => ({
          ...obj,
        }));*/

        const emptyScreeningDetails: ScreeningDetails = {
          eccnCode: "",
          message: "",
          trafficLightColor: "YELLOW", // Valeur par défaut
          usOrigin: false,
        };
        screeningCountryResult.screeningDetails.push(emptyScreeningDetails);
        screeningCountryResult.screeningDetails[max34].controlReasonsForRule4 =
          undefined;
        screeningCountryResult.screeningDetails[max34].message = "EXP119";
        screeningCountryResult.screeningDetails[max34].trafficLightColor =
          "YELLOW";
        screeningCountryResult.screeningDetails[max34].exportabilityRule = "";

        screeningCountryResult.screeningDetails[max34].eccnCode = "";
      }

      if (Eu_China === "OK") {
        countryOfOrigin = departureCountryOrigine.id!;
        departureCountry = {
          ...departureCountryOrigine,
          name: departureCountryOrigine.name ?? "Nom par défaut",
          id: departureCountryOrigine.id ?? 0, // Remplace par une valeur par défaut si `id` est `undefined`
        };
      }
      for (
        var index = 0;
        index < screeningCountryResult.screeningDetails.length;
        index++
      ) {
        if (
          departureCountry?.name === "China" &&
          !screeningCountryResult.screeningDetails[index].usOrigin
        ) {
          screeningCountryResult.screeningDetails[index].eccnCode = i18n.t(
            "pages.exportability.titre_eu_chine"
          );
          screeningCountryResult.screeningDetails[index].exportabilityRule =
            "RUSSIA_NC";
        }
        if (
          departureCountry?.name === "India" &&
          !screeningCountryResult.screeningDetails[index].usOrigin
        ) {
          screeningCountryResult.screeningDetails[index].eccnCode = i18n.t(
            "pages.exportability.titre_eu_inde"
          );
          screeningCountryResult.screeningDetails[index].exportabilityRule =
            "RUSSIA_NC";
        }
        if (
          screeningCountryResult.screeningDetails[index].message === "EXP010"
        ) {
          if (Eu_China == "OK")
            screeningCountryResult.screeningDetails[index].message = "EXPajout";
        }
      }

      max3 = screeningCountryResult.screeningDetails.length;
      const array6 = [...screeningCountryResult.screeningDetails];
      for (var index = 0; index < max3; index++) {
        if (
          screeningCountryResult.screeningDetails[index].message === "EXPajout"
        )
          array6.splice(index, 1);
      }
      screeningCountryResult.screeningDetails = array6;
      //******************* (gestionnaire code message) ********************/
      let max1 = screeningCountryResult.screeningDetails.length;
      for (var index = 0; index < max1; index++) {
        let msg = screeningCountryResult.screeningDetails[index].message;
   

        //cas particulier russie NC (guerre en ukrain)
        if (departureCountry?.name === "Russia" && msg === "EXP001") {
          screeningCountryResult.screeningDetails[index].message = "EXP049";
          screeningCountryResult.screeningDetails[index].trafficLightColor =
            "YELLOW";
          screeningCountryResult.screeningDetails[index].exportabilityRule =
            "RUSSIA_NC";

          screeningCountryResult.screeningDetails[index].eccnCode = i18n.t(
            "pages.exportability.titre_russie"
          );

          // screeningCountryResult.screeningDetails[index].eccnCode="Applicable regulation"
        }

        //////////////////////////////////////

        let Gmsg: MsgType = {
          code_msg: "",
          Regle: "",
          Message: "",
          short_fr: "",
          short_en: "",
          Lib_url1: "",
          Url1: "",
          Lib_url2: "",
          Url2: "",
          Lib_url3: "",
          Url3: "",
        };
        let Lib1 = "KO";
        let Url1 = "KO";
        let Lib2 = "KO";
        let Url2 = "KO";
        let Lib3 = "KO";
        let Url3 = "KO";

        if (
          screeningCountryResult.screeningDetails[index].message.startsWith(
            "EXP"
          )
        ) {
          //console.log(screeningCountryResult.screeningDetails[index].message);
          Gmsg = await this.api.GcodeToMsg(
            screeningCountryResult.screeningDetails[index].message
          );
          screeningCountryResult.screeningDetails[index].message = Gmsg.Message;

          if (msg === "EXP106") {
            if (code_exception)
              screeningCountryResult.screeningDetails[index].message =
                screeningCountryResult.screeningDetails[index].message +
                "<br>" +
                code_exception;
          }
          if (this.typeflow === "FLOW") {
            if (i18n.language !== "fr-FR" && i18n.language !== "fr")
              Gmsg.short_fr = Gmsg.short_en;

            screeningCountryResult.screeningDetails[index].eccnCode =
              screeningCountryResult.screeningDetails[index].keywork! +
              screeningCountryResult.screeningDetails[index].eccnCode +
              "    -     " +
              (Gmsg.short_fr ?? " ");
          }
          Lib1 = Gmsg.Lib_url1;
          Url1 = Gmsg.Url1;
          Lib2 = Gmsg.Lib_url2;
          Url2 = Gmsg.Url2;
          Lib3 = Gmsg.Lib_url3;
          Url3 = Gmsg.Url3;
        }
        if (Lib1 !== "KO")
          screeningCountryResult.screeningDetails[index].moreDetailsLabel =
            Lib1;
        if (Url1 !== "KO")
          screeningCountryResult.screeningDetails[index].moreDetailsLink = Url1;
        if (Lib2 !== "KO")
          screeningCountryResult.screeningDetails[
            index
          ].additionalMessageLabel = Lib2;
        if (Url2 !== "KO")
          screeningCountryResult.screeningDetails[index].additionalMessageLink =
            Url2;
        if (Lib3 !== "KO")
          screeningCountryResult.screeningDetails[
            index
          ].additionalMessageLabel1 = Lib3;
        if (Url3 !== "KO")
          screeningCountryResult.screeningDetails[
            index
          ].additionalMessageLink1 = Url3;
      }

      if (i18n.language === "fr-FR" || i18n.language === "fr") {
        MsAnnexe = MsAnnexe?.replaceAll("ANNEX", "ANNEXE");
        MsAnnexe = MsAnnexe?.replaceAll("Annex", "Annexe");
        MsAnnexe = MsAnnexe?.replaceAll("XXIIIB", "XXIII TER");
        MsAnnexe = MsAnnexe?.replaceAll("XXIIIC", "XXIII QUATER");
      }
      max1 = screeningCountryResult.screeningDetails.length;
      for (var index = 0; index < max1; index++) {
        let msg = screeningCountryResult.screeningDetails[index].message;
        if (msg.includes(literalAnnex)) {
          screeningCountryResult.screeningDetails[index].message =
            screeningCountryResult.screeningDetails[index].message.replaceAll(
              "{$annex}",
              MsAnnexe || " "
            );
        }
        if (msg.includes(literalSupplement)) {
          screeningCountryResult.screeningDetails[index].message =
            screeningCountryResult.screeningDetails[index].message.replaceAll(
              "{$supplement}",
              MsSupplement || " "
            );
        }

        let literalDestination = "{$dest_country}";
        if (msg.includes(literalDestination)) {
          screeningCountryResult.screeningDetails[index].message =
            screeningCountryResult.screeningDetails[index].message.replaceAll(
              "{$dest_country}",
              endCountry?.name! || " "
            );
        }

        let literalDepart = "{$departure_country}";
        if (msg.includes(literalDepart)) {
          screeningCountryResult.screeningDetails[index].message =
            screeningCountryResult.screeningDetails[index].message.replaceAll(
              "{$departure_country}",
              departureCountry?.name! || " "
            );
        }

        let literalNationalEccn = "{$national_ecnn}";
        if (msg.includes(literalNationalEccn)) {
          screeningCountryResult.screeningDetails[index].message =
            screeningCountryResult.screeningDetails[index].message.replaceAll(
              "{$national_ecnn}",
              this.nationalEccn?.eccnCode! || " "
            );
        }
        let literalUsEccn = "{$us_ecnn}";
        if (msg.includes(literalUsEccn)) {
          screeningCountryResult.screeningDetails[index].message =
            screeningCountryResult.screeningDetails[index].message.replaceAll(
              "{$us_ecnn}",
              this.usEccn?.eccnCode! || " "
            );
        }
      }

      //*********************************************************************** */

      //*********** modification regle4 nouvelle approche */
      for (
        var index = 0;
        index < screeningCountryResult.screeningDetails.length;
        index++
      ) {
        // UK hors europe*****************************
        if (
          screeningCountryResult.screeningDetails[index].message?.includes(
            "The European Union"
          )
        ) {
          if (departureCountry?.name === "United Kingdom (UK)") {
            screeningCountryResult.screeningDetails[index].message =
              screeningCountryResult.screeningDetails[index].message?.replace(
                "The European Union",
                "The United Kingdom (UK)"
              );
          }
        }

        //************************************************

        if (
          screeningCountryResult.screeningDetails[index].message?.includes(
            "A license from the US department of Commerce is required."
          )
        ) {
          screeningCountryResult.screeningDetails[index].message =
            screeningCountryResult.screeningDetails[index].message?.replace(
              "A license from the US department of Commerce is required.",
              "According to US Part 738.4, a license from the US Department of Commerce is required based on the reasons for control listed below and the destination country, unless a license exception applies."
            );
          if (departureCountry?.name === "United States of America (USA)") {
            screeningCountryResult.screeningDetails[index].message =
              screeningCountryResult.screeningDetails[index].message?.replace(
                "Please check if this ECCN is eligible for de minimis.",
                ""
              );
          } else {
            let okmini = "KO";
            minimis.map((value) => {
              if (
                value.code ===
                screeningCountryResult.screeningDetails[index].eccnCode
              )
                okmini = "OK";
            });

            if (
              screeningCountryResult.screeningDetails[index].eccnCode.includes(
                "515"
              ) &&
              screeningCountryResult.screeningDetails[index].eccnCode.includes(
                "9"
              )
            )
              okmini = "OK";
            if (
              screeningCountryResult.screeningDetails[index].eccnCode.substr(
                2,
                1
              ) === "6"
            )
              okmini = "OK";

            if (okmini == "OK") {
              screeningCountryResult.screeningDetails[index].message =
                screeningCountryResult.screeningDetails[index].message?.replace(
                  "Please check if this ECCN is eligible for de minimis.",
                  "Please be aware that there is no de minimis level for this item in case it should be incorporated in a non-US made item."
                );
              screeningCountryResult.screeningDetails[index].moreDetailsLabel =
                "Please check exact details of the de minimis rules in Part 734.4";
              screeningCountryResult.screeningDetails[index].moreDetailsLink =
                "https://www.ecfr.gov/cgi-bin/text-idx?rgn=div8&amp;node=15:2.1.3.4.22.0.1.4";
            } else {
              screeningCountryResult.screeningDetails[index].message =
                screeningCountryResult.screeningDetails[index].message?.replace(
                  "Please check if this ECCN is eligible for de minimis.",
                  "In case this item is incorporated in a non-US made superset, please check if the superset is eligible for de minimis rule."
                );
              screeningCountryResult.screeningDetails[index].moreDetailsLabel =
                "Please check exact details of the de minimis rules in Part 734.4";
              screeningCountryResult.screeningDetails[index].moreDetailsLink =
                "https://www.ecfr.gov/cgi-bin/text-idx?rgn=div8&amp;node=15:2.1.3.4.22.0.1.4";
            }
          }
        }
      }

      const sanction = await this.api.GetSanction(
        departureCountry?.name || "",
        endCountry?.name || ""
      );

      if (sanction && oksanction) {
        let max4 = screeningCountryResult.screeningDetails.length;
        const emptyScreeningDetails: ScreeningDetails = {
          eccnCode: "",
          message: "",
          trafficLightColor: "RED", // Valeur par défaut
          usOrigin: false,
        };
        /*      const tmp1 = tscreeningCountryResult.screeningDetails.map((obj) => ({
          ...obj,
        }));*/
        screeningCountryResult.screeningDetails.push(emptyScreeningDetails);

        screeningCountryResult.screeningDetails[max4].eccnCode =
          "Sanctions on " + endCountry?.name || "";
        if (sanction[0].Org)
          screeningCountryResult.screeningDetails[max4].eccnCode =
            sanction[0].Org + " Sanctions on " + endCountry?.name || "";

        screeningCountryResult.screeningDetails[max4].destinationCountry = "";
        screeningCountryResult.screeningDetails[max4].publicListName = "";
        screeningCountryResult.screeningDetails[max4].keywork = "";
        if (
          screeningCountryResult.screeningDetails[max4].controlReasonsForRule4
        )
          screeningCountryResult.screeningDetails[max4].controlReasonsForRule4 =
            undefined;

        if (screeningCountryResult.screeningDetails[max4].exportabilityRule)
          screeningCountryResult.screeningDetails[max4].exportabilityRule =
            undefined;

        if (sanction[0].Light == "YELLOW")
          screeningCountryResult.screeningDetails[max4].trafficLightColor =
            "YELLOW";
        if (sanction[0].Light == "GREEN")
          screeningCountryResult.screeningDetails[max4].trafficLightColor =
            "GREEN";
        if (sanction[0].Light == "RED")
          screeningCountryResult.screeningDetails[max4].trafficLightColor =
            "RED";
        screeningCountryResult.screeningDetails[max4].message =
          sanction[0].Message;
        screeningCountryResult.screeningDetails[max4].moreDetailsLabel =
          sanction[0].Link_text;
        screeningCountryResult.screeningDetails[max4].moreDetailsLink =
          sanction[0].Link;
        screeningCountryResult.screeningDetails[max4].additionalMessageLabel =
          sanction[0].Link_text1;
        screeningCountryResult.screeningDetails[max4].additionalMessageLink =
          sanction[0].Link1;
        screeningCountryResult.screeningDetails[max4].additionalMessageLabel1 =
          sanction[0].Link_text2;
        screeningCountryResult.screeningDetails[max4].additionalMessageLink1 =
          sanction[0].Link2;
      }

      //**********************Get sanction Import */
      const Importsanction = await this.api.GetSanctionImport(
        departureCountry?.name || "",
        endCountry?.name || ""
      );

      if (Importsanction) {
        let max4 = screeningCountryResult.screeningDetails.length;
        const emptyScreeningDetails: ScreeningDetails = {
          eccnCode: "",
          message: "",
          trafficLightColor: "RED", // Valeur par défaut
          usOrigin: false,
        };
        /*      const tmp1 = tscreeningCountryResult.screeningDetails.map((obj) => ({
          ...obj,
        }));*/
        screeningCountryResult.screeningDetails.push(emptyScreeningDetails);

        if (i18n.language === "fr-FR")
          screeningCountryResult.screeningDetails[max4].eccnCode =
            "Restrictions sur les importations en " +
            endCountry?.name +
            " de " +
            departureCountry?.name;
        else
          screeningCountryResult.screeningDetails[max4].eccnCode =
            endCountry?.name +
            " restrictions on imports from " +
            departureCountry?.name;

        //if (sanction[0].Org)
        //  screeningCountryResult.screeningDetails[max4].eccnCode=sanction[0].Org+" Sanctions on "+endCountry?.name||""

        screeningCountryResult.screeningDetails[max4].destinationCountry = "";
        screeningCountryResult.screeningDetails[max4].publicListName = "";
        screeningCountryResult.screeningDetails[max4].keywork = "";
        if (
          screeningCountryResult.screeningDetails[max4].controlReasonsForRule4
        )
          screeningCountryResult.screeningDetails[max4].controlReasonsForRule4 =
            undefined;

        // if (screeningCountryResult.screeningDetails[max4].exportabilityRule)
        screeningCountryResult.screeningDetails[max4].exportabilityRule =
          "REST_IMPORT";

        if (Importsanction[0].Light == "YELLOW")
          screeningCountryResult.screeningDetails[max4].trafficLightColor =
            "YELLOW";
        if (Importsanction[0].Light == "GREEN")
          screeningCountryResult.screeningDetails[max4].trafficLightColor =
            "GREEN";
        if (Importsanction[0].Light == "RED")
          screeningCountryResult.screeningDetails[max4].trafficLightColor =
            "RED";
        screeningCountryResult.screeningDetails[max4].message =
          Importsanction[0].Message;
        screeningCountryResult.screeningDetails[max4].moreDetailsLabel =
          Importsanction[0].Link_text;
        screeningCountryResult.screeningDetails[max4].moreDetailsLink =
          Importsanction[0].Link;
        screeningCountryResult.screeningDetails[max4].additionalMessageLabel =
          Importsanction[0].Link_text1;
        screeningCountryResult.screeningDetails[max4].additionalMessageLink =
          Importsanction[0].Link1;
        screeningCountryResult.screeningDetails[max4].additionalMessageLabel1 =
          Importsanction[0].Link_text2;
        screeningCountryResult.screeningDetails[max4].additionalMessageLink1 =
          Importsanction[0].Link2;
      }

      //****************** Get Sanction */
      if (departureCountry?.name != "United States of America (USA)") {
        let oui = "ko";
        for (var index = 0; index < eccns.length; index++) {
          if (eccns[index].usOrigin) oui = "ok";
        }

        if (oui == "ok") {
          //********************** cas us ********************* */
          const sanction = await this.api.GetSanction(
            "United States of America (USA)",
            endCountry?.name || ""
          );

          if (sanction) {
            let max4 = screeningCountryResult.screeningDetails.length;
            const emptyScreeningDetails: ScreeningDetails = {
              eccnCode: "",
              message: "",
              trafficLightColor: "RED", // Valeur par défaut
              usOrigin: false,
            };
            /*          const tmp1 = tscreeningCountryResult.screeningDetails.map(
              (obj) => ({
                ...obj,
              })
            );*/
            // const tmp1=tscreeningCountryResult.screeningDetails.slice()

            screeningCountryResult.screeningDetails.push(emptyScreeningDetails);

            screeningCountryResult.screeningDetails[max4].eccnCode =
              "U.S. Sanctions";
            if (sanction[0].Org)
              screeningCountryResult.screeningDetails[max4].eccnCode =
                "U.S. Sanctions";

            screeningCountryResult.screeningDetails[max4].destinationCountry =
              "";
            screeningCountryResult.screeningDetails[max4].publicListName = "";
            screeningCountryResult.screeningDetails[max4].keywork = "";
            if (
              screeningCountryResult.screeningDetails[max4]
                .controlReasonsForRule4
            )
              screeningCountryResult.screeningDetails[
                max4
              ].controlReasonsForRule4 = undefined;

            if (screeningCountryResult.screeningDetails[max4].exportabilityRule)
              screeningCountryResult.screeningDetails[max4].exportabilityRule =
                undefined;

            if (sanction[0].Light == "YELLOW")
              screeningCountryResult.screeningDetails[max4].trafficLightColor =
                "YELLOW";
            if (sanction[0].Light == "GREEN")
              screeningCountryResult.screeningDetails[max4].trafficLightColor =
                "GREEN";
            if (sanction[0].Light == "RED")
              screeningCountryResult.screeningDetails[max4].trafficLightColor =
                "RED";
            screeningCountryResult.screeningDetails[max4].message =
              sanction[0].Message;
            screeningCountryResult.screeningDetails[max4].moreDetailsLabel =
              sanction[0].Link_text;
            screeningCountryResult.screeningDetails[max4].moreDetailsLink =
              sanction[0].Link;
            screeningCountryResult.screeningDetails[
              max4
            ].additionalMessageLabel = sanction[0].Link_text1;
            screeningCountryResult.screeningDetails[
              max4
            ].additionalMessageLink = sanction[0].Link1;
            screeningCountryResult.screeningDetails[
              max4
            ].additionalMessageLabel1 = sanction[0].Link_text2;
            screeningCountryResult.screeningDetails[
              max4
            ].additionalMessageLink1 = sanction[0].Link2;
            //  screeningCountryResult.screeningDetails=screeningCountryResult.screeningDetails.reverse();
          }

          //*************************************************** */
        }
      }
      //********************************************************************** */
      let max5 = screeningCountryResult.screeningDetails.length;
      for (var index = 1; index < max5; index++) {
        if (
          screeningCountryResult.screeningDetails[index].exportabilityRule ===
          "RULE_SANCTIONED"
        ) {
          const tmp1 = screeningCountryResult.screeningDetails[0];

          screeningCountryResult.screeningDetails[0] =
            screeningCountryResult.screeningDetails[index];
          screeningCountryResult.screeningDetails[index] = tmp1;
        }
      }

      //console.log(screeningCountryResult.screeningDetails);
      //PASSE ICI
      //console.log(screeningCountryResult.screeningDetails);
      //************************************************************** */
      //console.log('749')

      screeningCountryResult.globalTrafficLightColor = "GREEN";
      let max2 = screeningCountryResult.screeningDetails.length;
      for (var index = 0; index < max2; index++) {
        if (
          screeningCountryResult.screeningDetails[index].trafficLightColor ==
          "RED"
        ) {
          screeningCountryResult.globalTrafficLightColor = "RED";
        }
        if (
          screeningCountryResult.screeningDetails[index].trafficLightColor ==
          "YELLOW"
        ) {
          if (screeningCountryResult.globalTrafficLightColor == "GREEN")
            screeningCountryResult.globalTrafficLightColor = "YELLOW";
        }
      }
      //console.log('770')
      //******************** la log      *********************/
      let ligne = "";
      for (var index = 0; index < eccns.length; index++) {
        if (eccns[index].usOrigin) {
          ligne = ligne + " US =" + eccns[index].eccnCode;
        } else {
          ligne = ligne + " N =" + eccns[index].eccnCode;
        }
      }

      if (screeningCountryResult.screeningDetails.length > 1) {
        //Addlog
        const logr = this.api.AddLog(
          this.rootStore.jwtStore.mainTenant || "",
          this.rootStore.jwtStore.jwt?.email || "",
          this.rootStore.jwtStore.jwt?.realm_access.roles || "",
          "Exportability",
          ligne,
          screeningCountryResult.globalTrafficLightColor,
          departureCountry?.name || "",
          endCountry?.name || "",
          screeningCountryResult.screeningDetails[0].eccnCode || "",
          screeningCountryResult.screeningDetails[1].eccnCode || "",
          "",
          "",
          "",
          "",
          JSON.stringify(screeningCountryResult)
        );
      } else {
        //Addlog
        const logr = this.api.AddLog(
          this.rootStore.jwtStore.mainTenant || "",
          this.rootStore.jwtStore.jwt?.email || "",
          this.rootStore.jwtStore.jwt?.realm_access.roles || "",
          "Exportability",
          ligne,
          screeningCountryResult.globalTrafficLightColor,
          departureCountry?.name || "",
          endCountry?.name || "",
          screeningCountryResult.screeningDetails[0].eccnCode || "",
          "",
          "",
          "",
          "",
          "",
          JSON.stringify(screeningCountryResult)
        );
      }
      /** ***********************************************/
      this.screeningCountryResult = {
        departureCountry: departureCountry || { id: 0, name: "" },
        countryOfDestination: endCountry || { id: 0, name: "" },
        globalTrafficLightColor: screeningCountryResult.globalTrafficLightColor,
        screeningDetails: screeningCountryResult.screeningDetails,
      };
     // console.log(this.screeningCountryResult);
      //const navigate = useNavigate()
      if (this.typeflow !== "FLOW") this.screeningCountryState = "DONE";
      return;
      // navigate("/exportability/results")
    } catch (e) {}

    this.screeningCountryState = "DONE";
  }

  @action async getDocument(documentName: string) {
    try {
      const document = await this.api.getDocument(documentName);

      fileDownload(document, documentName);
    } catch (e) {}
  }
}
