/* Styles import */
import styles from "../admin.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  Card,
  FormGroup,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  SearchInput,
  Loading,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
import EditIcon from "@material-ui/icons/Create";
import Api from "../../../utils/api";
/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderAdmin from "../../../components/headerAdmin";
import CountryEdit from "../../../components/countryEdit";
import ProgressUpload from "../../../components/progressUpload";
import configuration from "../../../configuration";
import axios from "axios";
import { decode } from "../../../utils/jwt";

interface State {
  search: string;
  selectedCountry?: Country;
  filename: string;
  file_date: string;
}

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
const AdminCountries: React.FC<Props> = ({ rootStore }) => {
  const jwt: JwtData =
    decode(window.localStorage.getItem("access_token") || "") || [];
  const jwtRoles: string[] = jwt.realm_access?.roles;

  const api = new Api();

  const [state, setState] = useState({
    search: "",
    filename: "",
    file_date: "",
    selectedCountry: undefined as Country | undefined,
  });

  const handleSubmit = async (file: File) => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = new FormData();

        if (file) {
          // Vérifier l'extension du fichier
          const allowedExtensions = ["doc", "docx", "pdf"];
          const fileExtension = file.name.split(".").pop()?.toLowerCase();
          if (!allowedExtensions.includes(fileExtension || "")) {
            reject("Extension de fichier non autorisée");
            return;
          }

          data.append("file", file);
        }

        const response = await axios({
          method: "POST",
          url:
            `${configuration.LARAVEL_API}/policy_file?id_tenant=` +
            rootStore.jwtStore.mainTenant,
          headers: {
            "content-type": "multipart/form-data",
          },

          data,
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  // eslint-disable-next-line
  useEffect(() => {
    const fetchData = async () => {
      const policy = await api.get_policy_file(
        rootStore.jwtStore.mainTenant || ""
      );

      if (policy) {
        setState({
          ...state,
          file_date: policy[0].date_maj,
          filename: policy[0].file_name,
        });
      }

      rootStore.countriesStore.getCountries();
      rootStore.countriesStore.getSanctionEU();
      rootStore.countriesStore.getSanctionUS();
      rootStore.countriesStore.getSanctionUN();
      rootStore.countriesStore.getAutoEU();
      rootStore.countriesStore.getCountriesDeparture();
    };

    fetchData();
  }, []);

  const { search } = state;

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage="ADMIN" />

      <HeaderAdmin pages="COUNTRIES" rootStore={rootStore} />

      {rootStore.jwtStore.getRoles().includes("Admin") && (
        <Card className={styles.body}>
          <h4>Corporate policy :</h4>
          <div style={{ marginLeft: "20px" }}>
            <FormGroup>
              <ProgressUpload
                onChange={(file) => handleSubmit(file)}
                progress={
                  rootStore.controlReasonsStore.classificationDatabasePourcent
                }
                uploadState={
                  rootStore.controlReasonsStore.classificationDatabaseState
                }
                label={"Upload the Corporate policy file :"}
                subLabel={` ${i18n.t("pages.admin.upload.date")}: 
                    ${state.file_date}
                     |   ${i18n.t("pages.admin.upload.filename")}:
                    ${state.filename}`}
              />
            </FormGroup>
          </div>
        </Card>
      )}

      {jwtRoles?.includes("SUPER_ADMINISTRATOR") && (
        <Card className={styles.body}>
          <h4>Departure countries :</h4>
          <div style={{ marginLeft: "20px" }}>
            {rootStore.countriesStore.countriesDeparture.map(
              (tmpcountry, index) => {
                return (
                  <React.Fragment>
                    <b> {tmpcountry.name}</b>,{" "}
                  </React.Fragment>
                );
              }
            )}
          </div>
          <br />
          <h4>Countries under EU arms embargo :</h4>
          <div style={{ marginLeft: "20px" }}>
            {rootStore.countriesStore.SanctionEU.map((sanction, index) => {
              return (
                <React.Fragment>
                  <b> {sanction}</b>,{" "}
                </React.Fragment>
              );
            })}
          </div>
          <br />
          <h4>Countries under US embargo :</h4>
          <div style={{ marginLeft: "20px" }}>
            {rootStore.countriesStore.SanctionUS.map((sanctionUs, index) => {
              return (
                <React.Fragment>
                  <b> {sanctionUs}</b>,{" "}
                </React.Fragment>
              );
            })}
          </div>
          <br />
          <h4>Countries under UN arms embargo : </h4>
          <div style={{ marginLeft: "20px" }}>
            {rootStore.countriesStore.SanctionUN.map((sanctionUn, index) => {
              return (
                <React.Fragment>
                  <b> {sanctionUn}</b>,{" "}
                </React.Fragment>
              );
            })}
          </div>
          <br />
          <h4>Countries with EU001 authorisation : </h4>
          <div style={{ marginLeft: "20px" }}>
            {rootStore.countriesStore.AutoEU.map((AutoEU, index) => {
              return (
                <React.Fragment>
                  <b> {AutoEU}</b>,{" "}
                </React.Fragment>
              );
            })}
          </div>
          <br />
        </Card>
      )}
      <Card className={styles.body}>
        <div className={styles.headerCard}>
          <CardHeader title={i18n.t("pages.admin.countries.title")} />

          <SearchInput
            id="search"
            value={search || ""}
            onChange={(value) =>
              setState({ ...state, search: value.target.value })
            }
            placeholder={i18n.t("pages.admin.countries.search-placeholder")}
          />
        </div>

        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{i18n.t("pages.admin.countries.country")}</TableCell>
                <TableCell>{i18n.t("pages.admin.countries.action")}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rootStore.countriesStore.countries
                .filter(
                  (country) =>
                    country.name.toLowerCase().indexOf(search.toLowerCase()) !==
                    -1
                )
                .map((country, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{country.name}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            setState({ ...state, selectedCountry: country })
                          }
                          className={styles.actionIcon}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>

          {rootStore.countriesStore.loadingCountries && <Loading />}
        </CardContent>
      </Card>

      <CountryEdit
        country={state.selectedCountry}
        rootStore={rootStore}
        onClose={() => {
          setState({ ...state, selectedCountry: undefined });
          rootStore.countriesStore.getSanctionEU();
          rootStore.countriesStore.getSanctionUS();
          rootStore.countriesStore.getSanctionUN();
          rootStore.countriesStore.getAutoEU();
        }}
      />
    </div>
  );
};

export default inject("rootStore")(observer(AdminCountries));
