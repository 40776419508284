/* Styles import */
import styles from "../subHeader/subHeader.module.css";

/* Global import */
import React from "react";
import { Link } from "react-router-dom";
import i18n from "i18next";

/* Custom import */
import SubHeader from "../subHeader";
import { decode } from "../../utils/jwt";

interface Props {
  pages: Pages;
  rootStore: import("../../stores/rootStore").default;
}
type Pages =
  | "TENANTS"
  | "USERS"
  | "COUNTRIES"
  | "EXPORTMSG"
  | "SANCTION"
  | "US_RULES"
  | "EUROPEAN_RULES"
  | "CLASSIFICATION_DATABASE"
  | "CONTROL_LISTE"
  | "SCREENING"
  | "SCREENING_TENANT"
  | "SYSTEM";

const headerAdminComponent = ({ pages, rootStore }: Props) => {
  const jwt: JwtData =
    decode(window.localStorage.getItem("access_token") || "") || [];
  const jwtRoles: string[] = jwt.realm_access?.roles;

  return (
    <SubHeader>
      {jwtRoles?.includes("Admin") && (
        <div className={styles.linkContent}>
          <Link
            id="classification-database"
            className={`${styles.link} ${
              pages === "CLASSIFICATION_DATABASE" ? styles.selected : {}
            }`}
            to="/admin/classification-database"
          >
            {i18n.t("pages.admin.menu.classification-database")}
          </Link>

          <Link
            id="admin-countries"
            className={`${styles.link} ${
              pages === "COUNTRIES" ? styles.selected : {}
            }`}
            to="/admin/countries"
          >
            {/*exportability*/}
            {i18n.t("pages.admin.menu.countries")}
          </Link>

          <Link
            id="classification-database"
            className={`${styles.link} ${
              pages === "SCREENING_TENANT" ? styles.selected : {}
            }`}
            to="/admin/screening-tenant"
          >
            Screening
          </Link>
        </div>
      )}
      {jwtRoles?.includes("SUPER_ADMINISTRATOR") && (
        <div className={styles.linkContent}>
          <Link
            id="admin-countries"
            className={`${styles.link} ${
              pages === "COUNTRIES" ? styles.selected : {}
            }`}
            to="/admin/countries"
          >
            {/*exportability*/}
            {i18n.t("pages.admin.menu.countries")}
          </Link>

          <Link
            id="admin-upload"
            className={`${styles.link} ${
              pages === "EXPORTMSG" ? styles.selected : {}
            }`}
            to="/admin/exportmsg"
          >
            Messages
          </Link>

          <Link
            id="admin-german"
            className={`${styles.link} ${
              pages === "EUROPEAN_RULES" ? styles.selected : {}
            }`}
            to="/admin/european-rules"
          >
            {i18n.t("pages.admin.menu.germanEccn")}
          </Link>

          <Link
            id="admin-upload"
            className={`${styles.link} ${
              pages === "SANCTION" ? styles.selected : {}
            }`}
            to="/admin/sanction"
          >
            Sanctions
          </Link>
          <Link
            id="admin-screening"
            className={`${styles.link} ${
              pages === "SCREENING" ? styles.selected : {}
            }`}
            to="/admin/screening"
          >
            {`Screening`}
          </Link>

          <Link
            id="admin-german"
            className={`${styles.link} ${
              pages === "SYSTEM" ? styles.selected : {}
            }`}
            to="/admin/system"
          >
            {i18n.t("pages.admin.menu.crawlermenu")}
          </Link>
        </div>
      )}
    </SubHeader>
  );
};

export default headerAdminComponent;
