/* Styles import */
import styles from "../admin.module.css";
import i18n from "i18next";
/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
} from "../../../components/horizon-components-react/src/components";
import { observer, inject } from "mobx-react";

/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderAdmin from "../../../components/headerAdmin";
import ProgressUpload from "../../../components/progressUpload";
import { getToLocaleDate } from "../../../utils/date";
import Api from "../../../utils/api";
import axios from "axios";

interface State {
  filename: string;
  file_date: string;
  filename_exclus: string;
  file_date_exclus: string;
}
interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
const GermanEcc: React.FC<Props> = ({ rootStore }) => {
  const api = new Api();
  const [state, setState] = useState({
    filename_exclus: "",
    file_date_exclus: "",
    filename_russia: "",
    file_date_russia: "",

    filename_correlation: "",
    file_date_correlation: "",

    filename_valid_hs_codes: "",
    file_date_valid_hs_codes: "",

    filename_belarus: "",
    file_date_belarus: "",
    filename_us_russia: "",
    file_date_us_russia: "",
    filename_exceptions: "",
    file_date_exceptions: "",
    filename_french_eccn: "",
    file_date_french_eccn: "",
    filename_contournement_russia: "",
    file_date_contournement_russia: "",
    filename_EU_ANNEX_IV: "",
    file_date_EU_ANNEX_IV: "",
    filename_norway_sanction: "",
    file_date_norway_sanction: "",
  });

  const [file_date_EU_ANNEX_IV, setfile_date_EU_ANNEX_IV] = useState("");
  const [filename_EU_ANNEX_IV, setfilename_EU_ANNEX_IV] = useState("");

  const [file_date_french_eccn, setfile_date_french_eccn] = useState("");
  const [filename_french_eccn, setfilename_french_eccn] = useState("");

  const [file_date_russia, setfile_date_russia] = useState("");
  const [filename_russia, setfilename_russia] = useState("");

  const [file_date_correlation, setfile_date_correlation] = useState("");
  const [filename_correlation, setfilename_correlation] = useState("");


  const [file_date_valid_hs_codes, setfile_date_valid_hs_codes] = useState("");
  const [filename_valid_hs_codes, setfilename_valid_hs_codes] = useState("");

  const [file_date_correlation_eu_du, setfile_date_correlation_eu_du] = useState("");
  const [filename_correlation_eu_du, setfilename_correlation_eu_du] = useState("");

  const [file_date_belarus, setfile_date_belarus] = useState("");
  const [filename_belarus, setfilename_belarus] = useState("");

  file_date_french_eccn;

  const [file_date_contournement_russia, setfile_date_contournement_russia] =
    useState("");
  const [filename_contournement_russia, setfilename_contournement_russia] =
    useState("");

  const [file_date_us_russia, setfile_date_us_russia] = useState("");
  const [filename_us_russia, setfilename_us_russia] = useState("");

  const [file_date_exclus, setfile_date_exclus] = useState("");
  const [filename_exclus, setfilename_exclus] = useState("");
  const [file_date_exclus2, setfile_date_exclus2] = useState("");
  const [filename_exclus2, setfilename_exclus2] = useState("");

  const [file_date_GERMAN_ECCN, setfile_date_GERMAN_ECCN] = useState("");
  const [filename_GERMAN_ECCN, setfilename_GERMAN_ECCN] = useState("");

  const [file_date_norway_sanction, setfile_date_norway_sanction] =
    useState("");
  const [filename_norway_sanction, setfilename_norway_sanction] = useState("");

  const [
    file_date_COUNTRY_CONTROL_REASON,
    setfile_date_COUNTRY_CONTROL_REASON,
  ] = useState("");
  const [filename_COUNTRY_CONTROL_REASON, setfilename_COUNTRY_CONTROL_REASON] =
    useState("");

  const [file_date_ECCN_CONTROL_REASON, setfile_date_ECCN_CONTROL_REASON] =
    useState("");
  const [filename_ECCN_CONTROL_REASON, setfilename_ECCN_CONTROL_REASON] =
    useState("");

  const [file_date_ECCN_744_21, setfile_date_ECCN_744_21] = useState("");
  const [filename_ECCN_744_21, setfilename_ECCN_744_21] = useState("");

  const [file_date_codes_exceptions, setfile_date_codes_exceptions] =
    useState("");
  const [filename_codes_exceptions, setfilename_codes_exceptions] =
    useState("");

  const [file_date_us_hs_russia, setfile_date_us_hs_russia] = useState("");
  const [filename_us_hs_russia, setfilename_us_hs_russia] = useState("");

  // eslint-disable-next-line
  useEffect(() => {
    const fetchData = async () => {
      const policy = await api.get_file_parametre();
      const simplifiedPolicy = policy.map(
        ({ categorie, file_name, date_maj }) => ({
          categorie,
          file_name,
          date_maj,
        })
      );
      const getFileInfoByCategory = (category: string) => {
        return simplifiedPolicy.find(({ categorie }) => categorie === category);
      };

      const EU_ANNEX_IV = getFileInfoByCategory("EU_ANNEX_IV");
      setfile_date_EU_ANNEX_IV(EU_ANNEX_IV?.date_maj || "");
      setfilename_EU_ANNEX_IV(EU_ANNEX_IV?.file_name || "");

      const ECCN_EXCLUS2 = getFileInfoByCategory("ECCN_EXCLUS2");
      setfile_date_exclus2(ECCN_EXCLUS2?.date_maj || "");
      setfilename_exclus2(ECCN_EXCLUS2?.file_name || "");

      const ECCN_EXCLUS = getFileInfoByCategory("ECCN_EXCLUS");
      setfile_date_exclus(ECCN_EXCLUS?.date_maj || "");
      setfilename_exclus(ECCN_EXCLUS?.file_name || "");

      const french_eccn = getFileInfoByCategory("french_eccn");
      setfile_date_french_eccn(french_eccn?.date_maj || "");
      setfilename_french_eccn(french_eccn?.file_name || "");

      const GERMAN_ECCN = getFileInfoByCategory("GERMAN_ECCN");
      setfile_date_GERMAN_ECCN(GERMAN_ECCN?.date_maj || "");
      setfilename_GERMAN_ECCN(GERMAN_ECCN?.file_name || "");

      const norway_sanction = getFileInfoByCategory("norway_sanction");
      setfile_date_norway_sanction(norway_sanction?.date_maj || "");
      setfilename_norway_sanction(norway_sanction?.file_name || "");

      const Russia_file = getFileInfoByCategory("codes_russia");
      setfile_date_russia(Russia_file?.date_maj || "");
      setfilename_russia(Russia_file?.file_name || "");

      const correlation_file = getFileInfoByCategory("codes_correlation");
      setfile_date_correlation(correlation_file?.date_maj || "");
      setfilename_correlation(correlation_file?.file_name || "");

      const valid_hs_codes_file = getFileInfoByCategory("valid_hs_codes");
      setfile_date_valid_hs_codes(valid_hs_codes_file?.date_maj || "");
      setfilename_valid_hs_codes(valid_hs_codes_file?.file_name || "");



     const correlation_file_eu_du = getFileInfoByCategory("codes_correlation_eu_du");
      setfile_date_correlation_eu_du(correlation_file_eu_du?.date_maj || "");
      setfilename_correlation_eu_du(correlation_file_eu_du?.file_name || "");


      const Belarus_file = getFileInfoByCategory("codes_belarus");
      setfile_date_belarus(Belarus_file?.date_maj || "");
      setfilename_belarus(Belarus_file?.file_name || "");

      const Contournement_russia = getFileInfoByCategory(
        "contournement_russia"
      );
      setfile_date_contournement_russia(Contournement_russia?.date_maj || "");
      setfilename_contournement_russia(Contournement_russia?.file_name || "");

      const COUNTRY_CONTROL_REASON = getFileInfoByCategory(
        "COUNTRY_CONTROL_REASON"
      );
      setfile_date_COUNTRY_CONTROL_REASON(
        COUNTRY_CONTROL_REASON?.date_maj || ""
      );
      setfilename_COUNTRY_CONTROL_REASON(
        COUNTRY_CONTROL_REASON?.file_name || ""
      );

      const ECCN_CONTROL_REASON = getFileInfoByCategory("ECCN_CONTROL_REASON");
      setfile_date_ECCN_CONTROL_REASON(ECCN_CONTROL_REASON?.date_maj || "");
      setfilename_ECCN_CONTROL_REASON(ECCN_CONTROL_REASON?.file_name || "");

      const ECCN_744_21 = getFileInfoByCategory("ECCN_744_21");
      setfile_date_ECCN_744_21(ECCN_744_21?.date_maj || "");
      setfilename_ECCN_744_21(ECCN_744_21?.file_name || "");

      const codes_exceptions = getFileInfoByCategory("codes_exceptions");
      setfile_date_codes_exceptions(codes_exceptions?.date_maj || "");
      setfilename_codes_exceptions(codes_exceptions?.file_name || "");

      const us_hs_russia = getFileInfoByCategory("us_hs_russia");
      setfile_date_us_hs_russia(us_hs_russia?.date_maj || "");
      setfilename_us_hs_russia(us_hs_russia?.file_name || "");
    };

    fetchData();
  }, []);

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage="ADMIN" />

      <HeaderAdmin pages="EUROPEAN_RULES" rootStore={rootStore} />

      <Card className={styles.body}>
        <CardHeader title="EU001 - EU 2021/821 Annex II and Annex IV" />
        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadAnnexe(file)
            }
            progress={rootStore.controlReasonsStore.annexePourcent}
            uploadState={rootStore.controlReasonsStore.annexeState}
            label={i18n.t("pages.admin.eu-annexe.label")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: ${file_date_EU_ANNEX_IV}
                ${i18n.t("pages.admin.upload.filename")}: ${filename_EU_ANNEX_IV}`}
          />
        </CardContent>
      </Card>
      <Card className={styles.body}>
        <CardHeader title="EU002 - EU 2021/821 Annex II" />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadEccnsExclus2(file)
            }
            progress={rootStore.controlReasonsStore.EccnExclusPourcent}
            uploadState={rootStore.controlReasonsStore.EccnExclusState}
            label={`Upload the EU002 ECCNs`}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: ${
              file_date_exclus2
            }
                ${i18n.t("pages.admin.upload.filename")}: ${filename_exclus2}`}
          />
        </CardContent>
      </Card>
      <Card className={styles.body}>
        <CardHeader title={i18n.t("pages.admin.eu-annexe.title")} />
        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadEccnsExclus(file)
            }
            progress={rootStore.controlReasonsStore.EccnExclusPourcent}
            uploadState={rootStore.controlReasonsStore.EccnExclusState}
            label={`Upload the EU 2021/821 Annex II Letter I ECCNs`}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: ${
              file_date_exclus
            }
                ${i18n.t("pages.admin.upload.filename")}: ${filename_exclus}`}
          />
        </CardContent>
      </Card>
      <Card className={styles.body}>
        <CardHeader title="French ECCN administration" />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadFrenchEccns(file)
            }
            progress={rootStore.controlReasonsStore.frenchEccnPourcent}
            uploadState={rootStore.controlReasonsStore.frenchEccnState}
            label="Upload the French ECCNs table"
            subLabel={` ${i18n.t("pages.admin.upload.date")}: 
                  ${getToLocaleDate(file_date_french_eccn)}
                  ${i18n.t("pages.admin.upload.filename")}:
                   ${filename_french_eccn}`}
          />
        </CardContent>
      </Card>
      <Card className={styles.body}>
        <CardHeader title={i18n.t("pages.admin.german-eccn-reasons.title")} />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadGermanEccns(file)
            }
            progress={rootStore.controlReasonsStore.germanEccnPourcent}
            uploadState={rootStore.controlReasonsStore.germanEccnState}
            label={i18n.t("pages.admin.german-eccn-reasons.label")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: 
                  ${file_date_GERMAN_ECCN}
                  ${i18n.t("pages.admin.upload.filename")}:
                   ${filename_GERMAN_ECCN}`}
          />
        </CardContent>
      </Card>

      <Card className={styles.body}>
        <CardHeader title={i18n.t("pages.admin.norway-sanction.title")} />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.norwaysanction(file)
            }
            progress={rootStore.controlReasonsStore.EccnExclusPourcent}
            uploadState={rootStore.controlReasonsStore.EccnExclusState}
            label={i18n.t("pages.admin.norway-sanction.label")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: 
                    ${file_date_norway_sanction}
                     |   ${i18n.t("pages.admin.upload.filename")}:
                    ${filename_norway_sanction}`}
          />
        </CardContent>
      </Card>

      <Card className={styles.body}>
        <CardHeader title="EU dual-use correlation table " />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadCodesCorrelation_eu_du(file)
            }
            progress={rootStore.controlReasonsStore.EccnExclusPourcent12}
            uploadState={rootStore.controlReasonsStore.EccnExclusState12}
            label="Upload the EU dual-use correlation table"
            subLabel={` ${i18n.t("pages.admin.upload.date")}: 
                    ${file_date_correlation_eu_du}
                     |   ${i18n.t("pages.admin.upload.filename")}:
                    ${filename_correlation_eu_du}`}
          />
        </CardContent>
      </Card>


      <Card className={styles.body}>
        <CardHeader title="EU 765/2006 – Sanctions on Belarus" />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadCodesBelarus(file)
            }
            progress={rootStore.controlReasonsStore.EccnExclusPourcent10}
            uploadState={rootStore.controlReasonsStore.EccnExclusState10}
            label={`Upload the Belarus prohibited HS codes`}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: ${file_date_belarus}
                ${i18n.t("pages.admin.upload.filename")}: ${filename_belarus}`}
          />
        </CardContent>
      </Card>

      <Card className={styles.body}>
        <CardHeader title="EU 833/2014 – Sanctions on Russia " />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadCodesRussia(file)
            }
            progress={rootStore.controlReasonsStore.EccnExclusPourcent4}
            uploadState={rootStore.controlReasonsStore.EccnExclusState4}
            label={`Upload the Russia prohibited HS codes`}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: ${file_date_russia}
                ${i18n.t("pages.admin.upload.filename")}: ${filename_russia}`}
          />
        </CardContent>
        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadCodesCorrelation(file)
            }
            progress={rootStore.controlReasonsStore.EccnExclusPourcent11}
            uploadState={rootStore.controlReasonsStore.EccnExclusState11}
            label={`Upload the FAQ sanctions on Russia correlation table`}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: ${file_date_correlation}
                ${i18n.t("pages.admin.upload.filename")}: ${filename_correlation}`}
          />
        </CardContent>

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadContournement_russia(file)
            }
            progress={rootStore.controlReasonsStore.EccnExclusPourcent6}
            uploadState={rootStore.controlReasonsStore.EccnExclusState6}
            label={`Charger la liste des pays de contournement`}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: ${file_date_contournement_russia}
                ${i18n.t("pages.admin.upload.filename")}: ${filename_contournement_russia}`}
          />
        </CardContent>
      </Card>


      <Card className={styles.body}>
        <CardHeader title="HS codes" />
	   <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadValid_hs_codes(file)
            }
            progress={rootStore.controlReasonsStore.EccnExclusPourcent13}
            uploadState={rootStore.controlReasonsStore.EccnExclusState13}
            label={"HS codes"}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: ${file_date_valid_hs_codes}
                ${i18n.t("pages.admin.upload.filename")}: ${filename_valid_hs_codes}`}
          />

        </CardContent>
      </Card>
 





      <Card className={styles.body}>
        <CardHeader title={i18n.t("pages.admin.control-reasons.title")} />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadCountries(file)
            }
            progress={rootStore.controlReasonsStore.countriesPourcent}
            uploadState={rootStore.controlReasonsStore.countriesState}
            label={i18n.t("pages.admin.control-reasons.country-reasons")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: ${file_date_COUNTRY_CONTROL_REASON}
                ${i18n.t("pages.admin.upload.filename")}: ${filename_COUNTRY_CONTROL_REASON}`}
          />

          <ProgressUpload
            onChange={(file) => rootStore.controlReasonsStore.uploadEccns(file)}
            progress={rootStore.controlReasonsStore.eccnsPourcent}
            uploadState={rootStore.controlReasonsStore.eccnsState}
            label={i18n.t("pages.admin.control-reasons.eccn-reasons")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: ${file_date_ECCN_CONTROL_REASON}
                  ${i18n.t("pages.admin.upload.filename")}: ${
                    filename_ECCN_CONTROL_REASON
                  }`}
          />
        </CardContent>
      </Card>

      <Card className={styles.body}>
        <CardHeader title={i18n.t("pages.admin.list744.title")} />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadList744(file)
            }
            progress={rootStore.controlReasonsStore.list744Pourcent}
            uploadState={rootStore.controlReasonsStore.list744State}
            label={i18n.t("pages.admin.list744.label")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}:
                  ${file_date_ECCN_744_21}
                  ${i18n.t("pages.admin.upload.filename")}:
                   ${filename_ECCN_744_21}`}
          />
        </CardContent>
      </Card>

      <Card className={styles.body}>
        <CardHeader title="US license exceptions" />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadCodesexceptions(file)
            }
            progress={rootStore.controlReasonsStore.EccnExclusPourcent4}
            uploadState={rootStore.controlReasonsStore.EccnExclusState4}
            label={`Upload the US license exceptions`}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: ${
              file_date_codes_exceptions
            }
                ${i18n.t("pages.admin.upload.filename")}: ${
                  filename_codes_exceptions
                }`}
          />
        </CardContent>
      </Card>

      <Card className={styles.body}>
        <CardHeader title="US HTS codes – Sanctions on Russia " />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadCodesUsRussia(file)
            }
            progress={rootStore.controlReasonsStore.EccnExclusPourcent7}
            uploadState={rootStore.controlReasonsStore.EccnExclusState7}
            label={`Upload US-prohibited HTS codes to Russia`}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: ${file_date_us_hs_russia}
                ${i18n.t("pages.admin.upload.filename")}: ${filename_us_hs_russia}`}
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default inject("rootStore")(observer(GermanEcc));
