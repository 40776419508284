/* Global import */
import { observable, action, computed, makeAutoObservable } from "mobx";
import { runInThisContext } from "vm";

/* Custom import */
import Api from "../utils/api";
import { getCountry } from "../utils/navigator";
import configuration from "../configuration";
import axios from "axios";

//type PartnumberInfos={code:string,title:string,uscode:string}

export default class ClassificationStore {
  rootStore: import("./rootStore").default;
  api: Api;

  @observable search: string = "";
  @observable synonym: string = "";

  @observable reponse: string[] = [];
  @observable reponseSousQ: { type: string; reponse: string }[][] = []; // tableau de tableaux pour les sous-questions
  @observable regulation: string = "";
  @observable uniqueus: string = "KO";
  @observable uniqueusparam: string = "KO";
  @observable emptycustom: string = "";
  @observable countryCode: EccnCountry = getCountry();
  @observable itemType: ItemType = "ALL";
  @observable eccns: Eccn[] = [];
  @observable loading: boolean = false;
  @observable intangible: boolean = false;
  @observable searchType: SearchType = "KEYWORD";

  constructor(rootStore: import("./rootStore").default) {
    makeAutoObservable(this);
    this.api = new Api();
    this.rootStore = rootStore;
  }

  initializeDepartureCountry() {
    this.countryCode = getCountry();

    this.findCountryByCode(this.countryCode);
  }

  @action async findCountryByCode(countryCode: EccnCountry) {
    const departureCountry =
      await this.rootStore.countriesStore.findCountryByCode(countryCode);

    if (departureCountry) {
      this.rootStore.exportabilityStore.setDepartureCountry(
        departureCountry.id
      );
    }
  }
  @action setReponse = (newReponse: string[]) => {
    this.reponse = newReponse;
  };

  @action setReponseSousQ = (
    newReponseSousQ: { type: string; reponse: string }[][]
  ) => {
    this.reponseSousQ = newReponseSousQ;
  };

  @action async getsynonym(terme: string) {
    const synonym = await this.api.getSynonym(terme);

    return synonym;
  }

  @action async searchEccn() {
    this.loading = true;
    this.emptycustom = "";
    //*********************************************************** */
    var normal = false;
    const { data: tenanttempleteData } = await axios.get(
      `${configuration.LARAVEL_API}/getTenanttemplete?name=${encodeURIComponent(
        `${this.rootStore.jwtStore.mainTenant!}`
      )}`
    );
    if (this.searchType === "PARTNUMBER") {
      if (tenanttempleteData == 1) {
        normal = true;
      }
    }

    //******************************************************** */
    if (this.searchType === "CHECKLIST" || normal) {
      //this.eccns = await this.api.searchEccn(this.search, country, this.itemType)
      this.loading = true;
      if (normal) {
        const response2 = await axios.get(
          `${configuration.LARAVEL_API}/get_classificationCustom`,
          {
            params: {
              tenant: this.rootStore.jwtStore.mainTenant,
              country: "france",
              partNumber: this.search,
            },
          }
        );

        var data2 = response2.data;

        if (data2 !== "KO") {
          data2 = {
            ...data2,
            eu_check: data2.eu_classification === "NEC" ? true : false,
            us_check: data2.us_classification === "NEC" ? true : false,
            HS_check: true,
            HTS_check: true,
            eu: data2.eu_classification,
            HS_code: data2.eu_hs_code,
            made: data2.made,
            us: data2.us_classification,
            HTS_code: data2.us_hts_code,
          };

          if (data2.eu_classification == "" && data2.us_classification == "") {
            this.emptycustom = this.search;
          }
        }

        //   this.searchType="CHECKLIST"
      } else {
        var response2 = await axios.post(
          `${configuration.LARAVEL_API}/wizard`,
          {
            reponses: this.reponse,
            sousreponse: this.reponseSousQ,
            tenant: this.rootStore.jwtStore.mainTenant,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        var data2 = response2.data;

        //this.setState({ Q_checklist: data2 });
      }
      if (data2 !== "KO") {
        let country = this.countryCode;
        if (this.countryCode == "SE") country = "EU";
        if (this.countryCode == "NO") country = "EU";
        if (this.countryCode == "NL") country = "EU";
        if (this.countryCode == "TW") country = "EU";
        if (this.countryCode == "KR") country = "EU";
        if (this.countryCode == "SG") country = "EU";
        if (this.countryCode == "BE") country = "FR";
        if (this.countryCode == "IT") country = "EU";
        if (this.countryCode == "CA") country = "EU";
        if (this.countryCode == "DK") country = "EU";
        if (this.countryCode == "PT") country = "EU";
        if (this.countryCode == "CH") country = "EU";

        try {
          type EccnArray = Eccn[] | [];
          let newRes: Eccn[] = [];
          let newResus: Eccn[] = [];
          let final: Eccn[] = [];

          newRes = [];
          newResus = [];
          final = [];

          if (data2.eu_check) {
            const eccnElement: Eccn = {
              code: data2.eu,
              description: "",
              description_en: "",
              keywords: "",
              notes: "",
              tech_notes: "",
              notes_en: "",
              tech_notes_en: "",
              related_codes: "",
              related_controls: "",
              publicListName: "",
              type: "PR",
              isUsOrigin: false,
              designation: "",
              hs_code: data2.HS_code,
              HS_check: data2.HS_check,
              hts_code: data2.HTS_code,
              HTS_check: data2.HTS_check,
              eu_check: data2.eu_check,
              us_check: data2.us_check,
            };
            newRes.push(eccnElement);
            final = newRes.map((item) => ({ ...item, usorigine: false }));
          } else if (data2.eu !== null) {
            if (data2.eu.toUpperCase() === "NOT LISTED") data2.eu = "NC";

            if (this.intangible && data2.eu !== "NC" && data2.eu !== "EAR99") {
              let codeeu = data2.eu;
              if (data2.eu.length > 6 && !data2.eu.endsWith(".")) {
                codeeu = codeeu + ".";
              }

              newRes = await this.api.searchEccn(
                codeeu.slice(0, 5),
                country,
                "TECHNOLOGY_IT"
              );
            } else
              newRes = await this.api.searchEccn(
                data2.eu,
                country,
                this.itemType
              );
            this.loading = true;
            if (newRes) {
              newRes = newRes.filter(
                (eccn) =>
                  data2.eu === eccn.code ||
                  this.intangible ||
                  data2.eu.replace(/\./g, "").toUpperCase() ===
                    eccn.code.replace(/\./g, "").toUpperCase()
              );
              newRes = newRes.filter(
                (eccn) => eccn.publicListName !== "US Dual-Use List (EAR)"
              );
              newRes = newRes.filter(
                (eccn) => eccn.publicListName !== "US Munitions List (ITAR)"
              );

              final = newRes.map((item) => ({
                ...item,
                usorigine: false,
                type: "PR",
                eu_check: data2.eu_check,
                us_check: data2.us_check,
                hs_code: data2.HS_code,
                hts_code: data2.HTS_code,
                HS_check: data2.HS_check,
                HTS_check: data2.HTS_check,
              }));
            }
          }

          if (data2.us_check) {
            const eccnElement: Eccn = {
              code: data2.us,
              description: "",
              description_en: "",
              keywords: "",
              notes: "",
              tech_notes: "",
              notes_en: "",
              tech_notes_en: "",
              related_codes: "",
              related_controls: "",
              publicListName: "",
              type: "PR",
              isUsOrigin: true,
              designation: "",
              hs_code: data2.HS_code,
              HS_check: data2.HS_check,
              hts_code: data2.HTS_code,
              HTS_check: data2.HTS_check,
              eu_check: data2.eu_check,
              us_check: data2.us_check,
            };
            newResus.push(eccnElement);
            final = [...final, ...newResus];
            //final = newResus.map(item => ({ ...item, usorigine: true }));
          } else if (data2.us !== null) {
            if (
              this.intangible &&
              this.search !== "NC" &&
              this.search !== "EAR99"
            ) {
              let codeus = data2.us;

              newResus = await this.api.searchEccn(
                codeus.slice(0, 5),
                "US",
                "TECHNOLOGY_IT"
              );
            } else
              newResus = await this.api.searchEccn(
                data2.us,
                "US",
                this.itemType
              );
            this.loading = true;
            if (newResus) {
              newResus = newResus.filter(
                (eccn) =>
                  data2.us === eccn.code ||
                  this.intangible ||
                  data2.us.replace(/\./g, "").toUpperCase() ===
                    eccn.code.replace(/\./g, "").toUpperCase()
              );
              newResus = newResus.map((item) => ({
                ...item,
                usorigine: true,
                eu_check: data2.eu_check,
                us_check: data2.us_check,
                hs_code: data2.HS_code,
                hts_code: data2.HTS_code,
                HS_check: data2.HS_check,
                HTS_check: data2.HTS_check,
              }));
              final = [...final, ...newResus];
            }
          }

          final = final.filter(
            (eccn, index, self) => self.indexOf(eccn) === index
          );
          this.eccns = final;
        } catch (e) {}
      } else {
        this.eccns = [];
      }
    } else
      try {
        if (this.searchType === "KEYWORD") {
          this.loading = true;
          this.uniqueus = "OK";
          let country = this.countryCode;
          if (this.countryCode == "SE") country = "EU";
          if (this.countryCode == "NO") country = "EU";
          if (this.countryCode == "NL") country = "EU";
          if (this.countryCode == "TW") country = "EU";
          if (this.countryCode == "KR") country = "EU";
          if (this.countryCode == "SG") country = "EU";
          if (this.countryCode == "BE") country = "FR";
          if (this.countryCode == "IT") country = "EU";
          if (this.countryCode == "CA") country = "EU";
          if (this.countryCode == "DK") country = "EU";
          if (this.countryCode == "PT") country = "EU";
          if (this.countryCode == "CH") country = "EU";
          if (this.search === "NOT LISTED") this.search = "NC";

          if (
            this.intangible &&
            this.search !== "NC" &&
            this.search !== "EAR99"
          )
            this.eccns = await this.api.searchEccn(
              this.search,
              country,
              "TECHNOLOGY"
            );
          else {
            let teccns: Eccn[] = [];
            teccns = [];
            this.eccns = [];
            this.loading = true;
            teccns = await this.api.searchEccn(
              this.search,
              country,
              this.itemType
            );
            const sliceSize = 50;
            for (let i = 0; i < teccns.length; i += sliceSize) {
              const slice = teccns.slice(i, i + sliceSize);
              this.eccns = [...this.eccns, ...slice];
            }

            teccns = [];
          }
          var tcode = "";
          if (this.eccns) {
            for (
              let index = 0;
              index < Math.min(10, this.eccns.length);
              index++
            ) {
              const teccns = this.eccns[index];
              tcode = teccns.code + "," + tcode;
            }
          }

          this.synonym = await this.getsynonym(this.search);

          //Addlog
          const logr = this.api.AddLog(
            this.rootStore.jwtStore.mainTenant || "",
            this.rootStore.jwtStore.jwt?.email || "",
            this.rootStore.jwtStore.jwt?.realm_access.roles || "",
            "Classification",
            this.search,
            tcode,
            this.countryCode,
            "",
            "",
            "",
            "",
            "",
            "Keyword =" + this.search,
            "",
            JSON.stringify(this.eccns)
          );
        } else {
          let country = this.countryCode;
          var tenant = this.rootStore.jwtStore.mainTenant!;

          var jeccns_class = await this.api.searchClassification(
            this.search,
            tenant,
            country
          );

          let eccns_class: any[] = [];
          if (jeccns_class) {
            eccns_class = Object.values(jeccns_class).map((obj) => ({
              id: obj.id,
              part_number: obj.part_number,
              business_unit: obj.business_unit,
              product: obj.product,
              country: obj.country,
              classification: obj.classification,
              hs_code: obj.hs_code,
              tenant: obj.tenant,
              author: obj.author,
              made: obj.made,
              date_chargement: obj.date_chargement,
            }));
          }

          if (eccns_class.length > 0) {
            //console.log("taille eccn_class")
            //console.log(eccns_class.length)
            this.uniqueusparam = "KO";
            if (eccns_class.length == 1) this.uniqueusparam = "OK";
            let tempcode = "";
            let ths_code = "";
            let made = "";
            type EccnArray = Eccn[] | [];

            eccns_class
              .reduce(
                async (prevPromise, teccns) => {
                  this.loading = true;
                  if (teccns.classification.toUpperCase() === "NOT LISTED")
                    teccns.classification = "NC";
                  const res = await prevPromise;

                  let newRes;
                  if (teccns.country !== "US") {
                    if (teccns.country == "SE") country = "EU";
                    if (teccns.country == "DE") country = "DE";
                    if (teccns.country == "NO") country = "EU";
                    if (teccns.country == "NL") country = "EU";
                    if (teccns.country == "TW") country = "EU";
                    if (teccns.country == "KR") country = "EU";
                    if (teccns.country == "UK") country = "UK";
                    if (teccns.country == "SG") country = "EU";
                    if (teccns.country == "BE") country = "FR";
                    if (teccns.country == "IT") country = "EU";
                    if (teccns.country == "CH") country = "EU";
                    if (teccns.country == "DK") country = "EU";
                    if (teccns.country == "PT") country = "EU";
                    if (teccns.country == "CA") country = "EU";
                    if (teccns.country == "FR") country = "FR";
                    if (teccns.country == "US") country = "US";

                    newRes = await this.api.searchEccn(
                      teccns.classification,
                      country,
                      this.itemType
                    );
                    if (
                      this.intangible &&
                      teccns.classification !== "NC" &&
                      teccns.classification !== "EAR99"
                    )
                      newRes = await this.api.searchEccn(
                        teccns.classification,
                        country,
                        "TECHNOLOGY_IT"
                      );

                    newRes = newRes.filter(
                      (eccn) => eccn.publicListName !== "US Dual-Use List (EAR)"
                    );
                    newRes = newRes.filter(
                      (eccn) =>
                        eccn.publicListName !== "US Munitions List (ITAR)"
                    );

                    newRes = newRes.filter(
                      (eccn) =>
                        teccns.classification === eccn.code ||
                        this.intangible ||
                        teccns.classification.replace(/\./g, "") ===
                          eccn.code.replace(/\./g, "")
                    );
                  } else {
                    newRes = await this.api.searchEccn(
                      teccns.classification,
                      "US",
                      this.itemType
                    );

                    if (
                      this.intangible &&
                      teccns.classification !== "NC" &&
                      teccns.classification !== "EAR99"
                    )
                      newRes = await this.api.searchEccn(
                        teccns.classification,
                        "US",
                        "TECHNOLOGY_IT"
                      );

                    newRes = newRes.filter(
                      (eccn) =>
                        teccns.classification === eccn.code ||
                        this.intangible ||
                        teccns.classification.replace(/\./g, "") ===
                          eccn.code.replace(/\./g, "")
                    );
                  }
                  tempcode = teccns.product;
                  ths_code = teccns.hs_code;
                  if (teccns.made !== "" && teccns.made !== null)
                    made = " - Made in : " + teccns.made;

                  if (ths_code === "null") ths_code = " ";

                  if (newRes) {
                    newRes = newRes.map((eccn) => {
                      return {
                        ...eccn,
                        related_codes:
                          teccns.part_number +
                          " - " +
                          tempcode +
                          " - HS code : " +
                          ths_code +
                          made +
                          "$" +
                          teccns.country,
                      };
                    });
                  }
                  return [...res, ...newRes] as EccnArray;
                },
                Promise.resolve([]) as Promise<EccnArray>
              )
              .then((result: any[]) => {
                //  this.eccns = result;

                this.eccns = result.filter(
                  (eccn, index, self) => self.indexOf(eccn) === index
                );

                if (this.eccns) {
                  this.eccns = this.eccns.map((eccn) => {
                    return { ...eccn, keywords: "" };
                  });
                }
              });
          } else {
            this.eccns = [];
          }

          const logr = this.api.AddLog(
            this.rootStore.jwtStore.mainTenant || "",
            this.rootStore.jwtStore.jwt?.email || "",
            this.rootStore.jwtStore.jwt?.realm_access.roles || "",
            "Classification",
            this.search,
            "code",
            this.countryCode,
            "",
            "",
            "",
            "",
            "",
            "Part number=" + this.search,
            "",
            JSON.stringify(this.eccns)
          );
        }
      } catch (e) {}
    //console.log(this.eccns)

    this.loading = false;
  }

  @action async deepSearchEccn(code: string, regulation: string) {
    this.loading = true;

    try {
      this.search = code;
      this.regulation = regulation;
      let teccns: Eccn[] = [];
      teccns = [];

      teccns = await this.api.deepSearchEccn(this.search, this.regulation);
      this.eccns = [];
      const sliceSize = 10;
      for (let i = 0; i < teccns.length; i += sliceSize) {
        const slice = teccns.slice(i, i + sliceSize);
        this.eccns = [...this.eccns, ...slice];
      }

      teccns = [];
    } catch (e) {}

    this.loading = false;
  }
  @computed get eccnLists1() {
    const eccnLists: { [key: string]: Eccn[] } = {};

    this.eccns.sort((a, b) => {
      const lastTwoCharsA = a.related_codes.slice(-2);
      const lastTwoCharsB = b.related_codes.slice(-2);

      if (lastTwoCharsA < lastTwoCharsB) return -1;
      if (lastTwoCharsA > lastTwoCharsB) return 1;
      return 0;
    });

    this.eccns.forEach((eccn) => {
      eccn.isUsOrigin = false;

      if (eccnLists["ALL"]) {
        eccnLists["ALL"].push(eccn);
      } else {
        eccnLists["ALL"] = [eccn];
      }
    });

    return eccnLists;
  }
  @computed get eccnLists() {
    const eccnLists: { [key: string]: Eccn[] } = {};

    if (this.searchType !== "PARTNUMBER") {
      this.eccns.forEach((eccn) => {
        if (eccn.publicListName === "FR Import (A2)") {
          eccn.isUsOrigin = false;

          if (eccnLists[eccn.publicListName]) {
            eccnLists[eccn.publicListName].push(eccn);
          } else {
            eccnLists[eccn.publicListName] = [eccn];
          }
        }
      });
      this.eccns.forEach((eccn) => {
        if (eccn.publicListName === "FR Munitions list") {
          eccn.isUsOrigin = false;

          if (eccnLists[eccn.publicListName]) {
            eccnLists[eccn.publicListName].push(eccn);
          } else {
            eccnLists[eccn.publicListName] = [eccn];
          }
        }
      });
      this.eccns.forEach((eccn) => {
        if (eccn.publicListName === "") {
          // eccn.isUsOrigin = false;

          if (eccnLists[eccn.publicListName]) {
            eccnLists[eccn.publicListName].push(eccn);
          } else {
            eccnLists[eccn.publicListName] = [eccn];
          }
        }
      });
      this.eccns.forEach((eccn) => {
        if (eccn.publicListName === "DE Munitions list") {
          eccn.isUsOrigin =
            eccn.publicListName.indexOf("ITAR") !== -1 ||
            eccn.publicListName.indexOf("EAR") !== -1;

          if (eccnLists[eccn.publicListName]) {
            eccnLists[eccn.publicListName].push(eccn);
          } else {
            eccnLists[eccn.publicListName] = [eccn];
          }
        }
      });

      this.eccns.forEach((eccn) => {
        if (eccn.publicListName === "ES Munitions list") {
          eccn.isUsOrigin =
            eccn.publicListName.indexOf("ITAR") !== -1 ||
            eccn.publicListName.indexOf("EAR") !== -1;

          if (eccnLists[eccn.publicListName]) {
            eccnLists[eccn.publicListName].push(eccn);
          } else {
            eccnLists[eccn.publicListName] = [eccn];
          }
        }
      });

      this.eccns.forEach((eccn) => {
        if (eccn.publicListName === "EU Munitions List") {
          eccn.isUsOrigin =
            eccn.publicListName.indexOf("ITAR") !== -1 ||
            eccn.publicListName.indexOf("EAR") !== -1;

          if (eccnLists[eccn.publicListName]) {
            eccnLists[eccn.publicListName].push(eccn);
          } else {
            eccnLists[eccn.publicListName] = [eccn];
          }
        }
      });

      this.eccns.forEach((eccn) => {
        if (eccn.publicListName === "UK Military List") {
          eccn.isUsOrigin =
            eccn.publicListName.indexOf("ITAR") !== -1 ||
            eccn.publicListName.indexOf("EAR") !== -1;

          if (eccnLists[eccn.publicListName]) {
            eccnLists[eccn.publicListName].push(eccn);
          } else {
            eccnLists[eccn.publicListName] = [eccn];
          }
        }
      });

      this.eccns.forEach((eccn) => {
        if (eccn.publicListName === "US Munitions List (ITAR)") {
          eccn.isUsOrigin =
            eccn.publicListName.indexOf("ITAR") !== -1 ||
            eccn.publicListName.indexOf("EAR") !== -1;

          if (eccnLists[eccn.publicListName]) {
            eccnLists[eccn.publicListName].push(eccn);
          } else {
            eccnLists[eccn.publicListName] = [eccn];
          }
        }
      });
      this.eccns.forEach((eccn) => {
        if (eccn.publicListName === "EU Dual-Use List") {
          eccn.isUsOrigin = false;

          if (eccnLists[eccn.publicListName]) {
            eccnLists[eccn.publicListName].push(eccn);
          } else {
            eccnLists[eccn.publicListName] = [eccn];
          }
        }
      });

      this.eccns.forEach((eccn) => {
        if (eccn.publicListName === "FR Liste Double Usage") {
          eccn.isUsOrigin = false;

          if (eccnLists[eccn.publicListName]) {
            eccnLists[eccn.publicListName].push(eccn);
          } else {
            eccnLists[eccn.publicListName] = [eccn];
          }
        }
      });

      this.eccns.forEach((eccn) => {
        if (
          eccn.publicListName !== "FR Liste Double Usage" &&
          eccn.publicListName !== "EU Dual-Use List" &&
          eccn.publicListName !== "UK Military List" &&
          eccn.publicListName !== "ES Munitions list" &&
          eccn.publicListName !== "DE Munitions list" &&
          eccn.publicListName !== "US Munitions List (ITAR)" &&
          eccn.publicListName !== "FR Munitions list" &&
          eccn.publicListName !== "EU Munitions List" &&
          eccn.publicListName !== ""
        ) {
          eccn.isUsOrigin =
            eccn.publicListName.indexOf("ITAR") !== -1 ||
            eccn.publicListName.indexOf("EAR") !== -1;

          if (eccnLists[eccn.publicListName]) {
            eccnLists[eccn.publicListName].push(eccn);
          } else {
            eccnLists[eccn.publicListName] = [eccn];
          }
        }
      });
    } else {
      this.eccns.forEach((eccn) => {
        if (eccn.publicListName === "FR Liste Double Usage") {
          eccn.isUsOrigin = false;

          if (eccnLists[eccn.publicListName]) {
            eccnLists[eccn.publicListName].push(eccn);
          } else {
            eccnLists[eccn.publicListName] = [eccn];
          }
        }
      });

      this.eccns.forEach((eccn) => {
        if (eccn.publicListName === "FR Munitions list") {
          eccn.isUsOrigin = false;

          if (eccnLists[eccn.publicListName]) {
            eccnLists[eccn.publicListName].push(eccn);
          } else {
            eccnLists[eccn.publicListName] = [eccn];
          }
        }
      });
      this.eccns.forEach((eccn) => {
        if (eccn.publicListName === "") {
          // eccn.isUsOrigin = false;

          if (eccnLists[eccn.publicListName]) {
            eccnLists[eccn.publicListName].push(eccn);
          } else {
            eccnLists[eccn.publicListName] = [eccn];
          }
        }
      });
      this.eccns.forEach((eccn) => {
        if (eccn.publicListName === "DE Munitions list") {
          eccn.isUsOrigin =
            eccn.publicListName.indexOf("ITAR") !== -1 ||
            eccn.publicListName.indexOf("EAR") !== -1;

          if (eccnLists[eccn.publicListName]) {
            eccnLists[eccn.publicListName].push(eccn);
          } else {
            eccnLists[eccn.publicListName] = [eccn];
          }
        }
      });

      this.eccns.forEach((eccn) => {
        if (eccn.publicListName === "ES Munitions list") {
          eccn.isUsOrigin =
            eccn.publicListName.indexOf("ITAR") !== -1 ||
            eccn.publicListName.indexOf("EAR") !== -1;

          if (eccnLists[eccn.publicListName]) {
            eccnLists[eccn.publicListName].push(eccn);
          } else {
            eccnLists[eccn.publicListName] = [eccn];
          }
        }
      });

      this.eccns.forEach((eccn) => {
        if (eccn.publicListName === "EU Munitions List") {
          eccn.isUsOrigin =
            eccn.publicListName.indexOf("ITAR") !== -1 ||
            eccn.publicListName.indexOf("EAR") !== -1;

          if (eccnLists[eccn.publicListName]) {
            eccnLists[eccn.publicListName].push(eccn);
          } else {
            eccnLists[eccn.publicListName] = [eccn];
          }
        }
      });

      this.eccns.forEach((eccn) => {
        if (eccn.publicListName === "UK Military List") {
          eccn.isUsOrigin =
            eccn.publicListName.indexOf("ITAR") !== -1 ||
            eccn.publicListName.indexOf("EAR") !== -1;

          if (eccnLists[eccn.publicListName]) {
            eccnLists[eccn.publicListName].push(eccn);
          } else {
            eccnLists[eccn.publicListName] = [eccn];
          }
        }
      });
      this.eccns.forEach((eccn) => {
        if (
          eccn.publicListName !== "FR Liste Double Usage" &&
          eccn.publicListName !== "UK Military List" &&
          eccn.publicListName !== "US Dual-Use List (EAR)" &&
          eccn.publicListName !== "ES Munitions list" &&
          eccn.publicListName !== "DE Munitions list" &&
          eccn.publicListName !== "US Munitions List (ITAR)" &&
          eccn.publicListName !== "FR Munitions list" &&
          eccn.publicListName !== "EU Munitions List" &&
          eccn.publicListName !== ""
        ) {
          eccn.isUsOrigin =
            eccn.publicListName.indexOf("ITAR") !== -1 ||
            eccn.publicListName.indexOf("EAR") !== -1;

          if (eccnLists[eccn.publicListName]) {
            eccnLists[eccn.publicListName].push(eccn);
          } else {
            eccnLists[eccn.publicListName] = [eccn];
          }
        }
      });

      this.eccns.forEach((eccn) => {
        if (eccn.publicListName === "US Munitions List (ITAR)") {
          eccn.isUsOrigin =
            eccn.publicListName.indexOf("ITAR") !== -1 ||
            eccn.publicListName.indexOf("EAR") !== -1;

          if (eccnLists[eccn.publicListName]) {
            eccnLists[eccn.publicListName].push(eccn);
          } else {
            eccnLists[eccn.publicListName] = [eccn];
          }
        }
      });
      this.eccns.forEach((eccn) => {
        if (eccn.publicListName === "US Dual-Use List (EAR)") {
          eccn.isUsOrigin =
            eccn.publicListName.indexOf("ITAR") !== -1 ||
            eccn.publicListName.indexOf("EAR") !== -1;

          if (eccnLists[eccn.publicListName]) {
            eccnLists[eccn.publicListName].push(eccn);
          } else {
            eccnLists[eccn.publicListName] = [eccn];
          }
        }
      });
    }

    if (eccnLists && eccnLists["EU Dual-Use List"])
      eccnLists["EU Dual-Use List"] = eccnLists["EU Dual-Use List"].filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.code === item.code)
      );

    return eccnLists;
  }
  @action reset() {
    this.search = "";
    this.countryCode = getCountry();
    this.itemType = "ALL";
    this.eccns = [];
    this.loading = false;
  }

  @action clear() {
    this.eccns = [];
    this.loading = false;
  }

  setSearch(search: string) {
    this.search = search;

    if (this.searchType == "PARTNUMBER") {
      if (search.length >= 3) {
        this.searchEccn();
      } else {
        if (search.length > 0) {
          alert("Veuillez saisir au moins 3 caractères");
        }
        this.clear();
      }
    } else {
      if (search.length >= 2 || this.searchType == "CHECKLIST") {
        this.searchEccn();
      } else {
        this.clear();
      }
    }
  }

  setCountryCode(countryCode: EccnCountry) {
    this.countryCode = countryCode;

    this.findCountryByCode(countryCode);

    if (this.search.length >= 3) {
      // this.searchEccn();
    }
  }

  setItemType(itemType: ItemType) {
    this.itemType = itemType;

    if (this.search.length >= 3) {
      //this.searchEccn();
    }
  }
  setIntangile(value: boolean) {
    this.intangible = value;
  }

  setSearchType(searchType: SearchType) {
    this.clear();
    this.searchType = searchType;
  }
}
