/* Styles import */
import styles from "./exportability.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  Title,
  TextInput,
  Checkbox,
  IconButton,
} from "../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Api from "../../utils/api";
/* Custom import */
import AppBar from "../../components/appBar";
import Footer from "../../components/footer";
import SearchEccn from "../../components/searchEccn";
//import AutoComplete from "../../components/autoComplete";
import { useNavigate } from "react-router-dom";
import { getCountry } from "../../utils/navigator";

interface State {
  classificationIsOpen: boolean;
  countryOfDestination?: number;
  nationalEccnError?: boolean;
  usEccnError?: boolean;
  usCoche?: boolean;
  ouClick: string;
  departureCountry: number;
}
type EccnType = "nationalEccn" | "usEccn";

interface Props {
  rootStore: import("../../stores/rootStore").default;
}

const Exportability: React.FC<Props> = ({ rootStore }) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    countryOfDestination: 0,
    //classificationIsOpen: false,
    nationalEccnError: false,
    usEccnError: false,
    usCoche: false,
    departureCountry: rootStore.exportabilityStore.departureCountry,
    ouClick: "",
  });
  api: Api;
  const [classificationIsOpen, setClassOpen] = useState<boolean>(false);

  const [iseurope, setIseurope] = useState<string>("");
  /*let nationalEccn = rootStore.exportabilityStore.nationalEccn
  const usEccn = rootStore.exportabilityStore.usEccn
  const typeflow = rootStore.exportabilityStore.typeflow
  const countryOfDestination = rootStore.exportabilityStore.countryOfDestination
  const usOrigin = rootStore.exportabilityStore.usOrigin*/

  let { nationalEccn, usEccn, departureCountry, countryOfDestination } =
    rootStore.exportabilityStore;
  let usOrigin = rootStore.exportabilityStore.usOrigin;
  // console.log(usOrigin);

  useEffect(() => {
    rootStore.countriesStore.getCountries();
    rootStore.countriesStore.getCountriesDeparture();
  }, []);

  const submitIsActive = () => {
    return (
      ((nationalEccn && !state.nationalEccnError) ||
        (usEccn && usOrigin && !state.usEccnError) ||
        (departureCountry == 147 && !nationalEccn)) &&
      departureCountry &&
      countryOfDestination
    );
  };

  const handleChangeEccn = async (key: EccnType, eccnCode: string) => {
    let api = new Api();
    eccnCode =
      eccnCode.substr(0, 3).toUpperCase() + eccnCode.substr(3).toLowerCase();

    if (eccnCode.toUpperCase() === "NOT LISTED")
      eccnCode = eccnCode.toUpperCase();
    //if (eccnCode.length<4) eccnCode=eccnCode.toUpperCase()

    let eccn: ClassificationEccn | undefined = undefined;

    if (eccnCode) {
      eccn = {
        eccnCode,
        usOrigin: key === "usEccn",
      };
    }

    if (key === "usEccn") {
      const validUsEccn = validateUsEccn(eccnCode);

      rootStore.exportabilityStore.setUsEccn(eccn);
      if (await validUsEccn) {
        setState({ ...state, usEccnError: false });
      } else {
        setState({ ...state, usEccnError: true });
      }
    } else {
      const validNationalEccn = await validateNationalEccn(eccnCode);

      rootStore.exportabilityStore.setNationalEccn(eccn);
      let code_french = "KO";

      if (eccnCode.length > 4)
        code_french = await api.get_code_french(eccnCode);

      if (validNationalEccn || code_french !== "KO") {
        setState({ ...state, nationalEccnError: false });
      } else {
        setState({ ...state, nationalEccnError: true });
      }
    }
  };

  const fromRoman = (str: string) => {
    let result = 0;

    // the result is now a number, not a string
    const decimal = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
    const roman = [
      "M",
      "CM",
      "D",
      "CD",
      "C",
      "XC",
      "L",
      "XL",
      "X",
      "IX",
      "V",
      "IV",
      "I",
    ];

    for (let i = 0; i <= decimal.length; i++) {
      // Check for the first characters in the string
      while (str.indexOf(roman[i]) === 0) {
        // Add the decimal value to our result counter
        result += decimal[i];

        // Remove the matched Roman letter from the beginning
        str = str.replace(roman[i], "");
      }
    }
    return result;
  };

  const validateUsEccn = async (value: string) => {
    let api = new Api();

    if (value === "") {
      return true;
    }
    const usEccnRegex = "^[0-9. ]+$|^[0-9][A-E][0123569][0-9]{2}|^EAR99$";
    const usItarRegex =
      "^(Category )?((?=[DCLXVI])M*(C[D]|D?C*)(X[CL]|L?X*)(I[XV]|V?I*))";
    let itarBetweenOneAndTwentyOne = false;
    const matchingItar = value.match(usItarRegex);
    const regexp1 = /^(?!00)\d+([\s.]\d+)*$/;
/*
    if (
      rootStore.exportabilityStore.departureCountry == 38 ||
      rootStore.exportabilityStore.departureCountry == 80
    ) {
      return regexp1.test(value);
    }
*/
    if (matchingItar) {
      const matchingItarInt = fromRoman(matchingItar[2]);

      if (matchingItarInt >= 1 && matchingItarInt <= 21) {
        itarBetweenOneAndTwentyOne = true;
      }
    }

    if (
      value.replace(/[\.\s]/g, "").length >= 6 &&
      /^[0-9]+$/.test(value.replace(/[\.\s]/g, ""))
    ) {
      const valide = await api.get_valide_hs(value);

      return valide === "OK";
    }

    return value.match(usEccnRegex) || itarBetweenOneAndTwentyOne;
  };

  const validateNationalEccn = async (value: string) => {
    let api = new Api();

    if (value === "") {
      return true;
    }
    const regexp1 = /^(?!00)\d+([\s.]\d+)*$/;
/*
    if (
      rootStore.exportabilityStore.departureCountry == 38 ||
      rootStore.exportabilityStore.departureCountry == 80
    ) {
      return regexp1.test(value);
    }
*/
    let nationalEccnRegex =
      "^[0-9][A-E][01234][0-9]{2}|5400|^NC$|^NL$|^NOT LISTED$";
    if (rootStore.exportabilityStore.departureCountry == 67)
      nationalEccnRegex =
        "^[0-9][A-E][012349][0-9]{2}|5400|^NC$|^NL$|^NOT LISTED$";

    if (rootStore.exportabilityStore.departureCountry == 191)
      nationalEccnRegex =
        "^[0-9][A-E][01234][0-9]{2}|5400|^NC$||^NL$|^NOT LISTED$|^PL[0-9]{4}";
    /*
    if (
      rootStore.exportabilityStore.countryOfDestination == 147 ||
      iseurope === "KO" ||
      rootStore.exportabilityStore.countryOfDestination == 17
    )*/
    nationalEccnRegex =
      "^[0-9][A-E][01234][0-9]{2}|^X.[A-E]|^[ds.]+$|5400|^NC$||^NL$|^NOT LISTED$|^PL[0-9]{4}";

    const europeanMlRegex = "^ML[0-9]{1,2}|^AMA|^[A-E]2\\.[1-9]|[a-z]$";
    const germanMlRegex = "^00[0-2][0-9][a-z]?|^AWG$";
    const frenchHel = "^HEL|^hel";
    const russiareg = "^[ds.]+$";
    if (
      value.replace(/[\.\s]/g, "").length >= 6 &&
      /^[0-9]+$/.test(value.replace(/[\.\s]/g, ""))
    ) {
      const valide = await api.get_valide_hs(value);

      return valide === "OK";
    }
    return (
      value.match(nationalEccnRegex) ||
      value.match(europeanMlRegex) ||
      value.match(germanMlRegex) ||
      value.match(frenchHel)
    );
  };

  const handleSearchEccn = (eccn?: ClassificationEccn) => {
    setState({ ...state, ouClick: "" });
    setClassOpen(false);
    if (eccn) {
      if (eccn.usOrigin) {
        rootStore.exportabilityStore.setUsEccn(eccn);
      } else {
        rootStore.exportabilityStore.setNationalEccn(eccn);
      }
    }
  };

  const handleCountryOfDestination = async (country: number) => {
    let api = new Api();

    const iseurope_tmp = await api.isEurope(country);

    setIseurope(iseurope_tmp);

    setState({ ...state, countryOfDestination: country });
    rootStore.exportabilityStore.setCountryOfDestination(country);
  };

  const handleSubmit = () => {
    // const countryOfDestination = countryOfDestination

    //  if (!rootStore.authStore.isLogged) window.location.reload();

    let nationalEccn1: ClassificationEccn | undefined = undefined;

    if (!rootStore.exportabilityStore.nationalEccn && departureCountry == 147) {
      nationalEccn1 = {
        keyword: "void",
        usOrigin: false,
        eccnCode: "NC",
      };
      nationalEccn = nationalEccn1;
      //handleChangeEccn('nationalEccn', 'NC')
    }

    if (departureCountry && countryOfDestination && (nationalEccn || usEccn)) {
      const eccns = [];

      if (nationalEccn) {
        eccns.push(nationalEccn);
      }

      if (rootStore.exportabilityStore.usOrigin)
        if (usEccn) {
          eccns.push(usEccn);
        }
      rootStore.exportabilityStore.typeflow = "";
      /*
      rootStore.exportabilityStore.checkCountry(
        //todo Bizarre ici on envoyait History à cette fonction, ça été changé par navigate...
        eccns,
        departureCountry,
        countryOfDestination
      );
      */

      rootStore.exportabilityStore.checkCountryv2(
        //todo Bizarre ici on envoyait History à cette fonction, ça été changé par navigate...
        eccns,
        departureCountry,
        countryOfDestination,
        i18n.language
      );

      // rootStore.authStore.checkIsConnect();
      navigate("/exportability/results");
      //console.log(rootStore.exportabilityStore)
    }
  };
  //alert(usEccn?.eccnCode)
  if (nationalEccn?.eccnCode.toUpperCase() == "NC")
    nationalEccn.eccnCode = "NOT LISTED";
  //const { classificationIsOpen } = state;
  //  if (countryOfDestination!==undefined)setState({...state,  countryOfDestination: countryOfDestination })
  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage="EXPORTABILITY" />

      <div className={styles.image}></div>

      <div className={styles.body}>
        <Title tag="h2">{i18n.t("pages.exportability.title")}</Title>

        <form className={styles.form}>
          <div className={styles.row}>
            <div className={styles.input}>
              <Autocomplete
                style={{ width: 300 }}
                size="small"
                id="departure-country"
                options={rootStore.countriesStore.countriesDepartureToOptions}
                getOptionLabel={(option) => option.label}
                value={
                  departureCountry !== undefined
                    ? rootStore.countriesStore.countriesDepartureToOptions.find(
                        (option) => option.value === departureCountry
                      ) || null
                    : null
                }
                onChange={(event, value) => {
                  if (value) {
                    rootStore.exportabilityStore.setDepartureCountry(
                      value.value
                    );
                    setState({ ...state, departureCountry: value.value });
                    if (value.value === 202) {
                      setState({ ...state, ouClick: "LoupeUS" });
                      if (nationalEccn) nationalEccn.eccnCode = "";
                      rootStore.exportabilityStore.setUsOrigin(true);
                      setState({ ...state, usCoche: true });
                    }
                    handleChangeEccn(
                      "nationalEccn",
                      nationalEccn ? nationalEccn.eccnCode : ""
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n.t("pages.exportability.departure-country")}
                    required
                    error={Boolean("")}
                    helperText={""}
                  />
                )}
              />
            </div>

            <div className={styles.input}>
              <Autocomplete
                style={{ width: 300 }}
                size="small"
                id="country-of-destination"
                options={rootStore.countriesStore.countriesToOptions}
                getOptionLabel={(option) => option.label}
                value={
                  countryOfDestination !== undefined
                    ? rootStore.countriesStore.countriesToOptions.find(
                        (option) => option.value === countryOfDestination
                      ) || null
                    : null
                }
                onChange={(event, value) => {
                  if (value) {
                    handleCountryOfDestination(value.value);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n.t("pages.exportability.country-of-destination")}
                    required
                    error={Boolean("")}
                    helperText={""}
                  />
                )}
              />
            </div>
          </div>

          <div className={styles.row} style={{ paddingTop: "30px" }}>
            {departureCountry !== 202 && (
              <div className={styles.input}>
                <div className={styles.row}>
                  <TextInput
                    id="national-eccn"
                    type="text"
                    label={i18n.t("pages.exportability.national-eccn")}
                    value={nationalEccn ? nationalEccn.eccnCode : ""}
                    required={usEccn === undefined && departureCountry !== 147}
                    disabled={departureCountry === 202}
                    errorText={i18n.t("errors.eccn-not-defined")}
                    onChange={(e) =>
                      handleChangeEccn("nationalEccn", e.target.value)
                    }
                  />
                  <IconButton
                    id="search-national"
                    className={styles.searchButton}
                    onClick={() => {
                      setClassOpen(true);
                      setState({
                        ...state,
                        ouClick: "LoupeNat",
                      });
                    }}
                  >
                    <SearchIcon />
                  </IconButton>

                  {state.nationalEccnError && (
                    <span className={styles.eccnError}>
                      {i18n.t("pages.exportability.error-message")}
                    </span>
                  )}
                </div>
              </div>
            )}
            {departureCountry !== 202 && (
              <div className={styles.input}>
                <Checkbox
                  id="us-origin"
                  large
                  label={i18n.t("pages.exportability.us-origin")}
                  checked={usOrigin}
                  onChange={(e) => {
                    rootStore.exportabilityStore.setUsOrigin(e.target.checked);
                    setState({ ...state, usCoche: e.target.checked });
                  }}
                />
              </div>
            )}
            <div className={styles.input}>
              {usOrigin && (
                <div className={styles.row}>
                  <TextInput
                    id="secondEccn"
                    type="text"
                    label={i18n.t("pages.exportability.us-eccn")}
                    value={usEccn ? usEccn.eccnCode : ""}
                    required={nationalEccn === undefined}
                    errorText={i18n.t("errors.eccn-not-defined")}
                    onChange={(e) => handleChangeEccn("usEccn", e.target.value)}
                  />

                  <IconButton
                    id="search-us"
                    className={styles.searchButton}
                    onClick={() => {
                      setClassOpen(true);
                      setState({
                        ...state,
                        ouClick: "LoupeUS",
                      });
                    }}
                  >
                    <SearchIcon />
                  </IconButton>

                  {state.usEccnError && (
                    <span className={styles.eccnError}>
                      {i18n.t("pages.exportability.error-message")}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        </form>
      </div>

      <Footer
        buttons={[
          {
            id: "open-classification",
            onClick: () => {
              setClassOpen(true);
              setState({
                ...state,
                ouClick: "home",
              });
            },
            label: i18n.t("pages.exportability.search-ECCN"),
          },
          {
            id: "submit",
            onClick: () => handleSubmit(),
            label: i18n.t("global.submit"),
            primary: true,
            disabled: !submitIsActive(),
          },
        ]}
      />

      {
        <SearchEccn
          isOpen={classificationIsOpen}
          onClose={(eccn) => handleSearchEccn(eccn)}
          exportabilityMode={true}
          ouClick={state.ouClick}
          rootStore={rootStore}
        />
      }
    </div>
  );
};

export default inject("rootStore")(observer(Exportability));
