/* Styles import */
import styles from "./result.module.css";
import moment from "moment";
import "moment/locale/fr";
import jsPDF from "jspdf";
/* Global import */
import React, { useEffect, useState } from "react";
import {
  GridCol,
  GridRow,
  Title,
} from "../../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
// eslint-disable-next-line
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Api from "../../../../utils/api";
/* Custom import */
import html2canvas from "html2canvas";
import AppBar from "../../../../components/appBar";
import Footer from "../../../../components/footer";
import { flowUrl } from "../../../../utils/generator";
import TimeLine from "../../../../components/timeLine";
import {
  Card,
  Table,
  TableRow,
  TableCell,
} from "../../../../components/horizon-components-react/src/components";
import configuration from "../../../../configuration";
import axios from "axios";

interface Props {
  rootStore: import("../../../../stores/rootStore").default;
}

interface InjectedProps {
  rootStore: import("../../../../stores/rootStore").default;
}
interface Props extends InjectedProps {}
const ResultBasicEntity: React.FC<Props> = ({ rootStore }) => {
  const api = new Api();
  const location = useLocation();

  const { id } = useParams();
  const {
    company,
    company_us,
    company_eu,
    company_fr,
    company_canada,
    company_uk,
  } = location.state || {};

  const navigate = useNavigate();

  const [msg, setMsg] = useState({
    code_msg: "",
    Regle: "",
    Message: "",
    Lib_url1: "",
    Url1: "",
    Lib_url2: "",
    Url2: "",
    Lib_url3: "",
    Url3: "",
  });

  const [msg1, setMsg1] = useState("");
  const [Lib1, setLib1] = useState("");
  const [Url1, setUrl1] = useState("");
  const [Lib2, setLib2] = useState("");
  const [Url2, setUrl2] = useState("");
  const [Lib3, setLib3] = useState("");
  const [Url3, setUrl3] = useState("");

  const [DPmsg1, setDPMsg1] = useState("");
  const [DPLib1, setDPLib1] = useState("");
  const [DPUrl1, setDPUrl1] = useState("");
  const [DPLib2, setDPLib2] = useState("");
  const [DPUrl2, setDPUrl2] = useState("");
  const [DPLib3, setDPLib3] = useState("");
  const [DPUrl3, setDPUrl3] = useState("");

  const [bmsg1, setBMsg1] = useState("");
  const [bLib1, setBLib1] = useState("");
  const [bUrl1, setBUrl1] = useState("");
  const [bLib2, setBLib2] = useState("");
  const [bUrl2, setBUrl2] = useState("");
  const [bLib3, setBLib3] = useState("");
  const [bUrl3, setBUrl3] = useState("");

  const [smsg1, setSMsg1] = useState("");
  const [sLib1, setSLib1] = useState("");
  const [sUrl1, setSUrl1] = useState("");
  const [sLib2, setSLib2] = useState("");
  const [sUrl2, setSUrl2] = useState("");
  const [sLib3, setSLib3] = useState("");
  const [sUrl3, setSUrl3] = useState("");

  const [nosanction, setNosanction] = useState("Screening .... ");
  const [nosanction_fr, setNosanctionFr] = useState("Screening .... ");
  const [nosanction_eu, setNosanctionEU] = useState("Screening .... ");
  const [nosanction_uk, setNosanctionUK] = useState("Screening .... ");
  const [nosanction_canada, setNosanctionCanada] = useState("Screening .... ");

  const [post, setPost] = useState<BasicEntityFinale[]>();
  const [post_fr, setPostFr] = useState<BasicEntityFinale_fr[]>();
  const [post_eu, setPostEU] = useState<BasicEntityFinale_eu[]>();
  const [post_canada, setPostCanada] = useState<BasicEntityFinale_canada[]>();
  const [post_uk, setPostUK] = useState<BasicEntityFinale_uk[]>();

  const getButtons = () => {
    const buttons = [
      {
        id: "previous",
        onClick: () => {
          navigate(
            flowUrl(
              rootStore.screeningStore.skipPage // Utiliser la valeur du store pour déterminer la redirection
                ? "/screening/basic/all"
                : "/screening/basic/all/choose-basic-entity",
              id
            )
          );
          rootStore.screeningStore.setSkipPage(false); // Réinitialise skipPage à false après redirection
        },

        label: i18n.t("global.previous"),
        primary: false,
        disabled: false,
      },

      {
        id: "PDF",
        onClick: () => handleDownloadImage(),
        label: i18n.t("global.pdf"),
      },
      {
        id: "due",
        onClick: () => {
          let currentPostList = null as any;

          // Déterminer quelle liste utiliser
          if (rootStore.screeningStore.liste_fr) {
            currentPostList = post_fr;
          } else if (rootStore.screeningStore.liste_eu) {
            currentPostList = post_eu;
          } else if (rootStore.screeningStore.liste_uk) {
            currentPostList = post_uk;
          } else if (rootStore.screeningStore.liste_canadien) {
            currentPostList = post_canada;
          } else if (rootStore.screeningStore.liste_us) {
            currentPostList = company_us;
          }
          let idEntity =
            currentPostList && currentPostList.length > 0
              ? (currentPostList[0].name as any)
              : null;
          if (idEntity == null) {
            return navigate(
              flowUrl(`/screening/basic/due_diligence/${company}`)
            );
          }

          navigate(flowUrl(`/screening/basic/due_diligence/${idEntity}`));
        },
        label: i18n.t("global.due_diligence"),
      },
    ];

    if (id) {
      buttons.push({
        id: "licensing",
        onClick: () => navigate(flowUrl("/license/matching", id)),
        label: i18n.t("global.licensing"),
        primary: true,
        disabled: !(
          rootStore.screeningStore.screeningBasicEntityResult &&
          rootStore.screeningStore.screeningBasicEntityResult
            .trafficLightColor === "GREEN"
        ),
      });

      buttons.push({
        id: "Due",
        onClick: () => {},
        label: "Due Diligence",
        primary: true,
        disabled: !(
          rootStore.screeningStore.screeningBasicEntityResult &&
          rootStore.screeningStore.screeningBasicEntityResult
            .trafficLightColor === "YELLOW"
        ),
      });
    }
    return buttons;
  };

  useEffect(() => {
    const loadComponentData = async () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      const msgData = await api.GcodeToMsg("EXP035");
      setMsg(msgData);
      setMsg1(msgData.Message);
      setLib1(msgData.Lib_url1);
      setUrl1(msgData.Url1);
      setLib2(msgData.Lib_url2);
      setUrl2(msgData.Url2);
      setLib3(msgData.Lib_url3);
      setUrl3(msgData.Url3);
      setLib3(msgData.Lib_url3);

      const bmsgResponse = await api.GcodeToMsg("EXP036");

      setBMsg1(bmsgResponse.Message);
      setBLib1(bmsgResponse.Lib_url1);
      setBUrl1(bmsgResponse.Url1);
      setBLib2(bmsgResponse.Lib_url2);
      setBLib3(bmsgResponse.Lib_url3);

      setBUrl2(bmsgResponse.Url2);
      setBUrl3(bmsgResponse.Url3);

      const DPmsgData = await api.GcodeToMsg("EXP109");

      setDPMsg1(DPmsgData.Message);
      setDPLib1(DPmsgData.Lib_url1);
      setDPUrl1(DPmsgData.Url1);
      setDPLib2(DPmsgData.Lib_url2);
      setDPUrl2(DPmsgData.Url2);
      setDPLib3(DPmsgData.Lib_url3);
      setDPUrl3(DPmsgData.Url3);
      setDPLib3(DPmsgData.Lib_url3);

      const smsgResponse = await api.GcodeToMsg("EXP055");

      setSMsg1(smsgResponse.Message);
      setSLib1(smsgResponse.Lib_url1);
      setSUrl1(smsgResponse.Url1);
      setSLib2(smsgResponse.Lib_url2);
      setSUrl2(smsgResponse.Url2);
      setSLib3(smsgResponse.Lib_url3);
      setSUrl3(smsgResponse.Url3);

      if (rootStore.screeningStore.liste_us) {
        axios
          .get(
            `${configuration.LARAVEL_API}/screenEntite?companyName=` +
              encodeURIComponent(`${company_us}`)
          )
          .then((response) => {
            setPost(response.data.resultat);

            if (!response.data.resultat)
              setNosanction(i18n.t("global.nosanction"));
          })
          .catch((error) => {
            setNosanction(i18n.t("global.nosanction"));
          });

        if (!post)
          setTimeout(() => {
            setNosanction(i18n.t("global.nosanction"));
          }, 1000);
      }

      ///*************************************** FR */
      if (rootStore.screeningStore.liste_fr) {
        axios
          .get(
            `${configuration.LARAVEL_API}/entite_fr?companyName=` +
              encodeURIComponent(`${company_fr}`)
          )
          .then((response) => {
            setPostFr(response.data.results);

            if (!response.data.resultats)
              setNosanctionFr(i18n.t("global.nosanction"));
          })
          .catch((error) => {
            setNosanctionFr(i18n.t("global.nosanction"));
          });

        if (!post_fr)
          setTimeout(() => {
            setNosanctionFr(i18n.t("global.nosanction"));
          }, 1000);
      }
      //*************************************eu  */
      if (rootStore.screeningStore.liste_eu) {
        axios
          .get(
            `${configuration.LARAVEL_API}/entite_eu?companyName=` +
              encodeURIComponent(`${company_eu}`)
          )
          .then((response) => {
            setPostEU(response.data.results);

            if (!response.data.resultat)
              setNosanctionEU(i18n.t("global.nosanction"));
          })
          .catch((error) => {
            setNosanctionEU(i18n.t("global.nosanction"));
          });

        if (!post_eu)
          setTimeout(() => {
            setNosanctionEU(i18n.t("global.nosanction"));
          }, 1000);
      }

      //**********************************canada */
      if (rootStore.screeningStore.liste_canadien) {
        try {
          const response = await axios.get(
            `${configuration.LARAVEL_API}/entite_canada?companyName=` +
              encodeURIComponent(`${company_canada}`)
          );
          setPostCanada(response.data.results);
        } catch (error) {
          setNosanctionCanada(i18n.t("global.nosanction"));
        }
        if (!post_canada)
          setTimeout(() => {
            setNosanctionCanada(i18n.t("global.nosanction"));
          }, 1000);

        if (!post_canada)
          setTimeout(() => {
            setNosanctionCanada(i18n.t("global.nosanction"));
          }, 1000);
      }

      //**********************************UK */
      if (rootStore.screeningStore.liste_uk) {
        axios
          .get(
            `${configuration.LARAVEL_API}/entite_uk?companyName=` +
              encodeURIComponent(`${company_uk}`)
          )
          .then((response) => {
            setPostUK(response.data.results);
          })
          .catch((error) => {
            setNosanctionUK(i18n.t("global.nosanction"));
          });
        if (!post_uk)
          setTimeout(() => {
            setNosanctionUK(i18n.t("global.nosanction"));
          }, 1000);

        if (!post_uk)
          setTimeout(() => {
            setNosanctionUK(i18n.t("global.nosanction"));
          }, 1000);
      }
    };
    loadComponentData();
  }, []);

  const handleDownloadImage = async () => {
    const element = document.getElementById("print")!;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/jpg");
    const maDate = new Date();
    const auteur = rootStore.jwtStore.getFullName();

    const societe = rootStore.jwtStore.mainTenant;
    var afDate = moment(maDate).format("YYYY-MM-DD");

    let country = window.navigator.language;
    let datrap = moment().locale("en").format("ll");

    if (country === "fr-FR") datrap = moment().locale("fr").format("ll");

    var margin = 10;
    var imgWidth = 210 - 2 * margin;
    var pageHeight = 295;
    var imgHeight = (canvas.height * imgWidth) / canvas.width;
    var heightLeft = imgHeight;
    var doc = new jsPDF("p", "mm", [210, heightLeft + 100]);

    var position = 20;

    doc.setFontSize(14);
    doc.text(
      i18n.t("global.titrescreening"),
      doc.internal.pageSize.getWidth() / 2 - 20,
      10
    );

    doc.setFontSize(10);
    doc.text(i18n.t("global.rapport") + " " + datrap, 10, 20);
    doc.text(i18n.t("global.autheur") + " : " + auteur, 10, 25);
    doc.text(i18n.t("global.societe") + " : " + societe, 10, 30);

    doc.addImage(data, "PNG", margin, position + 50, imgWidth, imgHeight);

    doc.text(
      i18n.t("global.footer"),
      doc.internal.pageSize.getWidth() / 2 - 30,
      heightLeft + 90
    );
    doc.save(afDate + " Screening " + company + ".pdf");

    const link = document.createElement("a");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const screeningBasicEntityResult =
    rootStore.screeningStore.screeningBasicEntityResult;

  let feu = "green";
  if (
    (screeningBasicEntityResult &&
      screeningBasicEntityResult.trafficLightColor !== "GREEN") ||
    (post_fr != undefined && post_fr.length != 0) ||
    (post_eu != undefined && post_eu.length != 0) ||
    (post_canada != undefined && post_canada.length != 0) ||
    (post_uk != undefined && post_uk.length != 0) ||
    (post != undefined && post.length != 0)
  ) {
    feu = "red";
  }

  if (
    company === undefined &&
    company_canada === undefined &&
    company_fr === undefined &&
    company_eu === undefined &&
    company_uk === undefined
  ) {
    feu = "green";
  }

  if (feu === "green") {
    rootStore.screeningStore.setSkipPage(true);
  }
  return (
    <div>
      <AppBar selectedPage={"SCREENING"} rootStore={rootStore} />

      <div className={styles.image}></div>
      <div id="print" style={{ paddingBottom: "125px" }}>
        <div>
          <div className={styles.row}>
            <img
              className={styles.traficLight}
              id={feu}
              src={require(
                `../../../../../statics/images/global-traffic-${feu}.svg`
              )}
              alt={"traffic"}
            />

            <div>
              <Title tag="h2">Screening</Title>
              <Title tag="h4">{company}</Title>
            </div>
          </div>

          {rootStore.screeningStore.liste_us && (
            <div className={styles.body}>
              <Title tag="h2">
                {i18n.t("pages.screening.entity.result.titleBasic")} US
              </Title>
              <div>
                {screeningBasicEntityResult && (
                  <div className={styles.message}>
                    <div
                      className={`${styles.traficLightBox} ${
                        styles[
                          screeningBasicEntityResult.trafficLightColor.toLowerCase()
                        ]
                      }`}
                    ></div>
                    <img
                      className={styles.traficLight}
                      src={require(
                        `../../../../../statics/images/traffic-${screeningBasicEntityResult.trafficLightColor.toLowerCase()}.svg`
                      )}
                      alt={"trafic"}
                    />

                    <div>
                      <p>
                        {post &&
                          post.map((s) => (
                            <div>
                              <b>
                                {" "}
                                {s.type === "Individual"
                                  ? screeningBasicEntityResult.message.replace(
                                      "entity",
                                      "person"
                                    )
                                  : screeningBasicEntityResult.message}{" "}
                              </b>
                            </div>
                          ))}
                        {!post && (
                          <div>
                            <b>
                              {nosanction}

                              {company}
                            </b>
                          </div>
                        )}
                        <div>
                          <br />
                        </div>
                        {post &&
                          post.map(
                            (s) =>
                              s.source.includes(
                                "Entity List (EL) - Bureau of Industry and Security"
                              ) && (
                                <div>
                                  <div>
                                    <p>
                                      {msg1 &&
                                        msg1.split("<br>").map((i, key) => {
                                          return <div key={key}>{i}</div>;
                                        })}
                                    </p>
                                  </div>
                                  <div>
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={Url1}
                                    >
                                      {Lib1}
                                    </a>
                                    <a
                                      target="_blank"
                                      style={{ marginLeft: "50px" }}
                                      rel="noopener noreferrer"
                                      href={Url2}
                                    >
                                      {Lib2}
                                    </a>
                                    <a
                                      target="_blank"
                                      style={{ marginLeft: "50px" }}
                                      rel="noopener noreferrer"
                                      href={Url3}
                                    >
                                      {Lib3}
                                    </a>
                                  </div>
                                </div>
                              )
                          )}

                        {post &&
                          post.map(
                            (s) =>
                              s.source.includes("ITAR Debarred (DTC)") && (
                                <div>
                                  <div>
                                    <p>
                                      {DPmsg1 &&
                                        DPmsg1.split("<br>").map((i, key) => {
                                          return <div key={key}>{i}</div>;
                                        })}
                                    </p>
                                  </div>
                                  <div>
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={DPUrl1}
                                    >
                                      {DPLib1}
                                    </a>
                                    <a
                                      target="_blank"
                                      style={{ marginLeft: "50px" }}
                                      rel="noopener noreferrer"
                                      href={DPUrl2}
                                    >
                                      {DPLib2}
                                    </a>
                                    <a
                                      target="_blank"
                                      style={{ marginLeft: "50px" }}
                                      rel="noopener noreferrer"
                                      href={DPUrl3}
                                    >
                                      {DPLib3}
                                    </a>
                                  </div>
                                </div>
                              )
                          )}

                        {post &&
                          post.map(
                            (s) =>
                              s.source.includes(
                                "Military End User (MEU) List - Bureau of Industry and Security"
                              ) && (
                                <div>
                                  <div>
                                    <p>
                                      {bmsg1 &&
                                        bmsg1.split("<br>").map((i, key) => {
                                          return <div key={key}>{i}</div>;
                                        })}
                                    </p>
                                  </div>
                                  <div>
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={bUrl1}
                                    >
                                      {bLib1}
                                    </a>

                                    <a
                                      target="_blank"
                                      style={{ marginLeft: "50px" }}
                                      rel="noopener noreferrer"
                                      href={bUrl2}
                                    >
                                      {bLib2}
                                    </a>
                                    <a
                                      target="_blank"
                                      style={{ marginLeft: "50px" }}
                                      rel="noopener noreferrer"
                                      href={bUrl3}
                                    >
                                      {bLib3}
                                    </a>
                                  </div>
                                </div>
                              )
                          )}

                        {post &&
                          post.map(
                            (s) =>
                              s.source.includes(
                                "Specially Designated Nationals (SDN)"
                              ) && (
                                <div>
                                  <div>
                                    <p>
                                      {smsg1 &&
                                        smsg1.split("<br>").map((i, key) => {
                                          return <div key={key}>{i}</div>;
                                        })}
                                    </p>
                                  </div>
                                  <div>
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={sUrl1}
                                    >
                                      {sLib1}
                                    </a>

                                    <a
                                      target="_blank"
                                      style={{ marginLeft: "50px" }}
                                      rel="noopener noreferrer"
                                      href={sUrl2}
                                    >
                                      {sLib2}
                                    </a>
                                    <a
                                      target="_blank"
                                      style={{ marginLeft: "50px" }}
                                      rel="noopener noreferrer"
                                      href={sUrl3}
                                    >
                                      {sLib3}
                                    </a>
                                  </div>
                                </div>
                              )
                          )}
                      </p>
                    </div>
                  </div>
                )}
                <br />
                <br />
                {post &&
                  post.map((s) => (
                    <Card>
                      <Table>
                        <TableRow>
                          <TableCell>{"Source"}</TableCell>
                          <TableCell>{s.source}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{"Type"}</TableCell>
                          <TableCell>{s.type}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{"Start date"}</TableCell>
                          <TableCell>{s.start_date}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{"Remarks"}</TableCell>
                          <TableCell>{s.remarks}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{"Source list"}</TableCell>
                          <TableCell>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={s.source_list_url}
                            >
                              {s.source_list_url}
                            </a>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{"License requirement"}</TableCell>
                          <TableCell>{s.license_requirement}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{"License policy"}</TableCell>
                          <TableCell>{s.license_policy}</TableCell>
                        </TableRow>
                      </Table>
                    </Card>
                  ))}
              </div>
            </div>
          )}
          {
            //**************************  FR ************** */
          }

          {rootStore.screeningStore.liste_fr && (
            <div className={styles.body}>
              <Title tag="h2">
                {i18n.t("pages.screening.entity.result.titleBasic")} FR
              </Title>

              {(post_fr === undefined || post_fr?.length === 0) && (
                <div>
                  {
                    <div className={styles.message}>
                      <div
                        className={`${styles.traficLightBox} ${styles["green"]}`}
                      ></div>
                      <img
                        className={styles.traficLight}
                        src={
                          require(
                            `../../../../../statics/images/traffic-green.svg`
                          ).default
                        }
                        alt={"trafic"}
                      />

                      <b>
                        {nosanction_fr}

                        {company}
                      </b>
                    </div>
                  }
                  <div>
                    <p>
                      <div>
                        <br />
                      </div>
                    </p>
                  </div>
                </div>
              )}

              {post_fr && post_fr?.length !== 0 && (
                <div>
                  {
                    <div className={styles.message}>
                      <div
                        className={`${styles.traficLightBox} ${styles["red"]}`}
                      ></div>
                      <img
                        className={styles.traficLight}
                        src={
                          require(
                            `../../../../../statics/images/traffic-red.svg`
                          ).default
                        }
                        alt={"trafic"}
                      />

                      <div>
                        <p>
                          <b>
                            {
                              //screeningBasicEntityResult.message
                            }{" "}
                          </b>
                          <div>
                            <br />
                          </div>

                          {post_fr &&
                            post_fr.map(
                              (s) =>
                                s.type.indexOf("Personne morale") !== -1 && (
                                  <div>
                                    <div>
                                      <b>
                                        {i18n.t(
                                          "pages.screening.entity.result.entite"
                                        ) +
                                          ` ` +
                                          s.name +
                                          `  ` +
                                          i18n.t(
                                            "pages.screening.entity.result.rest_fr"
                                          )}
                                      </b>
                                    </div>
                                  </div>
                                )
                            )}

                          {post_fr &&
                            post_fr.map(
                              (s) =>
                                s.type.indexOf("Personne morale") === -1 && (
                                  <div>
                                    <div>
                                      <b>
                                        {i18n.t(
                                          "pages.screening.entity.result.person"
                                        ) +
                                          ` ` +
                                          s.name +
                                          `  ` +
                                          i18n.t(
                                            "pages.screening.entity.result.rest_fr"
                                          )}
                                      </b>
                                    </div>
                                  </div>
                                )
                            )}
                        </p>
                      </div>
                    </div>
                  }

                  <div>
                    <br />
                  </div>
                  {post_fr && post_fr.map((s) => <div></div>)}

                  <br />
                  <br />
                  {post_fr &&
                    post_fr.map((s) => (
                      <Card>
                        <Table>
                          <TableRow>
                            <TableCell>{i18n.t("global.name")}</TableCell>
                            <TableCell>{s.name}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{i18n.t("global.Type")}</TableCell>
                            <TableCell>{s.type}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {i18n.t("global.localisation")}
                            </TableCell>
                            <TableCell>{s.localisation}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {i18n.t("global.reglementation")}
                            </TableCell>
                            <TableCell>{s.reglementation}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{i18n.t("global.Function")}</TableCell>
                            <TableCell>{s.fonction}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{i18n.t("global.Note")}</TableCell>
                            <TableCell>{s.note}</TableCell>
                          </TableRow>
                        </Table>
                      </Card>
                    ))}
                </div>
              )}
            </div>
          )}
          {
            //**************************  eu ************** */
          }
          {rootStore.screeningStore.liste_eu && (
            <div className={styles.body}>
              <Title tag="h2">
                {i18n.t("pages.screening.entity.result.titleBasic")} EU
              </Title>
              {(post_eu == undefined || post_eu?.length === 0) && (
                <div>
                  {
                    <div className={styles.message}>
                      <div
                        className={`${styles.traficLightBox} ${styles["green"]}`}
                      ></div>
                      <img
                        className={styles.traficLight}
                        src={
                          require(
                            `../../../../../statics/images/traffic-green.svg`
                          ).default
                        }
                        alt={"trafic"}
                      />

                      <b>
                        {nosanction_eu}

                        {company}
                      </b>
                    </div>
                  }
                  <div>
                    <p>
                      <div>
                        <br />
                      </div>
                    </p>
                  </div>
                </div>
              )}

              {(post_eu == undefined || post_eu?.length !== 0) && (
                <div>
                  {
                    <div className={styles.message}>
                      <div
                        className={`${styles.traficLightBox} ${styles["red"]}`}
                      ></div>
                      <img
                        className={styles.traficLight}
                        src={
                          require(
                            `../../../../../statics/images/traffic-red.svg`
                          ).default
                        }
                        alt={"trafic"}
                      />

                      <div>
                        <p>
                          <b>
                            {
                              //screeningBasicEntityResult.message
                            }{" "}
                          </b>
                          <div>
                            <br />
                          </div>

                          {post_eu &&
                            post_eu.map(
                              (s) =>
                                !s.Entity_SubjectType_ClassificationCode.includes(
                                  "person"
                                ) && (
                                  <div>
                                    <div>
                                      <b>
                                        {i18n.t(
                                          "pages.screening.entity.result.entite"
                                        ) +
                                          ` ` +
                                          s.name +
                                          `  ` +
                                          i18n.t(
                                            "pages.screening.entity.result.rest"
                                          )}
                                      </b>
                                    </div>
                                  </div>
                                )
                            )}

                          {post_eu &&
                            post_eu.map(
                              (s) =>
                                s.Entity_SubjectType_ClassificationCode.includes(
                                  "person"
                                ) && (
                                  <div>
                                    <div>
                                      <b>
                                        {i18n.t(
                                          "pages.screening.entity.result.person"
                                        ) +
                                          ` ` +
                                          s.name +
                                          `  ` +
                                          i18n.t(
                                            "pages.screening.entity.result.rest"
                                          )}
                                      </b>
                                    </div>
                                  </div>
                                )
                            )}
                        </p>
                      </div>
                    </div>
                  }
                  <br />
                  <br />
                  {post_eu &&
                    post_eu.map((s) => (
                      <Card>
                        <Table>
                          <TableRow>
                            <TableCell>{i18n.t("global.name")}</TableCell>
                            <TableCell>{s.name}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {i18n.t("global.EU regulation")}
                            </TableCell>
                            <TableCell>
                              {s.Entity_Regulation_NumberTitle}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{i18n.t("global.Programme")}</TableCell>
                            <TableCell>
                              {s.Entity_Regulation_Programme}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{i18n.t("global.Type")}</TableCell>
                            <TableCell>
                              {s.Entity_SubjectType_ClassificationCode}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{i18n.t("global.Start date")}</TableCell>
                            <TableCell>
                              {s.Entity_Regulation_EntryIntoForceDate}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{i18n.t("global.Note")}</TableCell>
                            <TableCell>{s.Entity_Remark}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{i18n.t("global.Function")}</TableCell>
                            <TableCell>{s.NameAlias_Function}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {i18n.t("global.Link to publication")}
                            </TableCell>
                            <TableCell>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={s.Entity_Regulation_PublicationUrl}
                              >
                                {s.Entity_Regulation_PublicationUrl}
                              </a>
                            </TableCell>
                          </TableRow>
                        </Table>
                      </Card>
                    ))}
                </div>
              )}
            </div>
          )}
          {
            //**************************  canada ************** */
          }
          {rootStore.screeningStore.liste_canadien && (
            <div className={styles.body}>
              <Title tag="h2">
                {i18n.t("pages.screening.entity.result.titleBasic")} canada
              </Title>

              {(post_canada == undefined || post_canada?.length === 0) && (
                <div>
                  {
                    <div className={styles.message}>
                      <div
                        className={`${styles.traficLightBox} ${styles["green"]}`}
                      ></div>
                      <img
                        className={styles.traficLight}
                        src={
                          require(
                            `../../../../../statics/images/traffic-green.svg`
                          ).default
                        }
                        alt={"trafic"}
                      />

                      <b>
                        {nosanction_canada}

                        {company}
                      </b>
                    </div>
                  }
                  <div>
                    <p>
                      <div>
                        <br />
                      </div>
                    </p>
                  </div>
                </div>
              )}

              {(post_canada == undefined || post_canada?.length !== 0) && (
                <div>
                  {
                    <div className={styles.message}>
                      <div
                        className={`${styles.traficLightBox} ${styles["red"]}`}
                      ></div>
                      <img
                        className={styles.traficLight}
                        src={
                          require(
                            `../../../../../statics/images/traffic-red.svg`
                          ).default
                        }
                        alt={"trafic"}
                      />

                      <div>
                        <p>
                          <b>
                            {
                              //screeningBasicEntityResult.message
                            }{" "}
                          </b>
                          <div>
                            <br />
                          </div>

                          {post_canada &&
                            post_canada.map(
                              (s) =>
                                s.type && (
                                  <div>
                                    <div>
                                      <b>
                                        {i18n.t(
                                          "pages.screening.entity.result.entite"
                                        ) +
                                          ` ` +
                                          s.name +
                                          `  ` +
                                          i18n.t(
                                            "pages.screening.entity.result.rest_ca"
                                          )}
                                      </b>
                                    </div>
                                  </div>
                                )
                            )}

                          {post_canada &&
                            post_canada.map(
                              (s) =>
                                !s.type && (
                                  <div>
                                    <div>
                                      <b>
                                        {i18n.t(
                                          "pages.screening.entity.result.person"
                                        ) +
                                          ` ` +
                                          s.name +
                                          `  ` +
                                          i18n.t(
                                            "pages.screening.entity.result.rest_ca"
                                          )}
                                      </b>
                                    </div>
                                  </div>
                                )
                            )}
                        </p>
                      </div>
                    </div>
                  }
                  <br />
                  <br />
                  {post_canada &&
                    post_canada.map((s) => (
                      <Card>
                        <Table>
                          <TableRow>
                            <TableCell>{i18n.t("global.name")}</TableCell>
                            <TableCell>{s.name} </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{i18n.t("global.Type")}</TableCell>
                            <TableCell>
                              {s.type
                                ? i18n.t(
                                    "pages.screening.entity.result.entite1"
                                  )
                                : i18n.t(
                                    "pages.screening.entity.result.person1"
                                  )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {i18n.t("global.reglementation")}
                            </TableCell>
                            <TableCell>{s.Regulation}</TableCell>
                          </TableRow>
                        </Table>
                      </Card>
                    ))}
                </div>
              )}
            </div>
          )}

          {
            //************************** uk ************** */
          }
          {rootStore.screeningStore.liste_uk && (
            <div className={styles.body}>
              <Title tag="h2">
                {i18n.t("pages.screening.entity.result.titleBasic")} UK
              </Title>

              {(post_uk == undefined || post_uk?.length === 0) && (
                <div>
                  {
                    <div className={styles.message}>
                      <div
                        className={`${styles.traficLightBox} ${styles["green"]}`}
                      ></div>
                      <img
                        className={styles.traficLight}
                        src={
                          require(
                            `../../../../../statics/images/traffic-green.svg`
                          ).default
                        }
                        alt={"trafic"}
                      />

                      <b>
                        {nosanction_uk}

                        {company}
                      </b>
                    </div>
                  }
                  <div>
                    <p>
                      <div>
                        <br />
                      </div>
                    </p>
                  </div>
                </div>
              )}

              {(post_uk == undefined || post_uk?.length !== 0) && (
                <div>
                  {
                    <div className={styles.message}>
                      <div
                        className={`${styles.traficLightBox} ${styles["red"]}`}
                      ></div>
                      <img
                        className={styles.traficLight}
                        src={
                          require(
                            `../../../../../statics/images/traffic-red.svg`
                          ).default
                        }
                        alt={"trafic"}
                      />

                      <div>
                        <p>
                          <b>
                            {
                              //screeningBasicEntityResult.message
                            }{" "}
                          </b>
                          <div>
                            <br />
                          </div>

                          {post_uk &&
                            post_uk.map(
                              (s) =>
                                s.Type && (
                                  <div>
                                    <div>
                                      <b>
                                        {s.Type !== "Individual"
                                          ? i18n.t(
                                              "pages.screening.entity.result.entite"
                                            )
                                          : i18n.t(
                                              "pages.screening.entity.result.person"
                                            )}{" "}
                                        {` ` +
                                          s.name +
                                          `  ` +
                                          i18n.t(
                                            "pages.screening.entity.result.rest_uk"
                                          )}
                                      </b>
                                    </div>
                                  </div>
                                )
                            )}

                          {post_uk &&
                            post_uk.map(
                              (s) =>
                                !s.Type && (
                                  <div>
                                    <div>
                                      <b>
                                        {s.Type !== "Individual"
                                          ? i18n.t(
                                              "pages.screening.entity.result.entite"
                                            )
                                          : i18n.t(
                                              "pages.screening.entity.result.person"
                                            )}{" "}
                                        {` ` +
                                          s.name +
                                          `  ` +
                                          i18n.t(
                                            "pages.screening.entity.result.rest_uk"
                                          )}
                                      </b>
                                    </div>
                                  </div>
                                )
                            )}
                        </p>
                      </div>
                    </div>
                  }
                  <br />
                  <br />
                  {post_uk &&
                    post_uk.map((s) => (
                      <Card>
                        <Table>
                          <TableRow>
                            <TableCell>{i18n.t("global.name")}</TableCell>
                            <TableCell>{s.name}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{i18n.t("global.Type")}</TableCell>
                            <TableCell>
                              {s.Type != "Individual"
                                ? i18n.t(
                                    "pages.screening.entity.result.entite1"
                                  )
                                : i18n.t(
                                    "pages.screening.entity.result.person1"
                                  )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{i18n.t("global.regime")}</TableCell>
                            <TableCell>{s.Regime}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{i18n.t("global.source")}</TableCell>
                            <TableCell>{s.Source}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {i18n.t("global.designation_date")}
                            </TableCell>
                            <TableCell>{s.DesignationDate}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {i18n.t("global.sanctions_imposed")}
                            </TableCell>
                            <TableCell>{s.SanctionsImposed}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {i18n.t("global.reasons_for_sanction")}
                            </TableCell>
                            <TableCell>{s.ReasonsForSanction}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {i18n.t("global.date_of_birth")}
                            </TableCell>
                            <TableCell>{s.DateOfBirth}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {i18n.t("global.nationality")}
                            </TableCell>
                            <TableCell>{s.Nationality}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{i18n.t("global.positions")}</TableCell>
                            <TableCell>{s.Positions}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {i18n.t("global.other_informations")}
                            </TableCell>
                            <TableCell>{s.OtherInformations}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{i18n.t("global.address")}</TableCell>
                            <TableCell>{s.Address}</TableCell>
                          </TableRow>
                        </Table>
                      </Card>
                    ))}
                </div>
              )}
            </div>
          )}
        </div>{" "}
      </div>
      <Footer buttons={getButtons()}>
        {id && (
          <GridRow>
            <GridCol md={12} xs={12} alignSelf="center">
              <TimeLine step={2} />
            </GridCol>
          </GridRow>
        )}
      </Footer>
    </div>
  );
};

export default inject("rootStore")(observer(ResultBasicEntity));
